import React, { useEffect } from 'react'
import { CourseRoleForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { courseRoleSelectors, courseRoleActions } from '@Store/studies'

export const EditCourseRole = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, courseRoleId } = useParams()
  const courseReference = useSelector(courseRoleSelectors.courseRole)
  const isSuccess = useSelector(courseRoleSelectors.isSuccess)
  const isLoading = useSelector(courseRoleSelectors.isLoading)
  const options = useSelector(courseRoleSelectors.options)
  const permissions = useSelector(courseRoleSelectors.permissions)
  const errors = useSelector(courseRoleSelectors.errors)

  useEffect(() => {
    dispatch(
      courseRoleActions.get({
        data: { studyProgrammeId, courseRoleId },
        history,
      })
    )
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This Course Role will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          courseRoleActions.delete({
            data: { studyProgrammeId, courseRoleId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(courseRoleActions.edit({ data, courseRoleId, studyProgrammeId }))
  }

  const onCancel = memoData => {
    dispatch(courseRoleActions.set(memoData))
    dispatch(courseRoleActions.setErrors([]))
  }

  return (
    <CourseRoleForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onCancel}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={courseReference}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      initialErrors={errors}
    />
  )
}

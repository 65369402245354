import React from 'react'
import { StudentFinancialSupportForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { studentFinancialSupportSelectors, studentFinancialSupportActions } from '@Store/studies'

export const CreateStudentFinancialSupport = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname
	const contactId = pathname.split('/')[5]
	const studyProgrammeId = pathname.split('/')[6]
	const studentFinancialSupport = useSelector(studentFinancialSupportSelectors.studentFinancialSupport)
	const options = useSelector(studentFinancialSupportSelectors.options)
	const isLoading = useSelector(studentFinancialSupportSelectors.isLoading)

	const onCreate = data => {
		dispatch(
			studentFinancialSupportActions.create({
				data,
				history,
				studentCourseSessionId: programmeRecord.student_course_session_id,
				studyProgrammeId,
				contactId,
			})
		)
	}

	const onCancel = () => {
		handleBack()
	}

	return (
		<StudentFinancialSupportForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onCreate}
			onCancel={onCancel}
			canEdit={true}
			isNew
			initialValue={studentFinancialSupport}
			options={options}
			isLoading={isLoading}
		/>
	)
}

export const TOGGLE_LOADING = 'module/instance/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'module/instance/TOGGLE_SUCCESS'
export const GET_SUBJECT = 'module/instance/GET_SUBJECT'
export const SET_SUBJECT = 'module/instance/SET_SUBJECT'
export const CREATE_SUBJECT = 'module/instance/CREATE_SUBJECT'
export const EDIT_SUBJECT = 'module/instance/EDIT_SUBJECT'
export const DELETE_SUBJECT = 'module/instance/DELETE_SUBJECT'
export const SET_OPTIONS = 'module/instance/SET_OPTIONS'
export const GET_STATIC_OPTIONS = 'module/instance/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'module/instance/GET_DYNAMIC_OPTIONS'

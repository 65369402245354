export const permissions = {
  viewer: [],
  manager: ['create', 'edit', 'createNote'],
  admin: [
    'create',
    'import',
    'createUser',
    'editApplicationFormInstructions',
    'createPerson',
    'createNote',
    'edit',
    'delete',
    'createAttachment',
    'editUser',
    'importCSV',
    'createRole',
  ],
  'super-admin': [
    'create',
    'edit',
    'delete',
    'createPerson',
    'editApplicationFormInstructions',
    'editNote',
    'deleteNote',
    'createNote',
    'createAttachment',
    'editAttachment',
    'deleteAttachment',
    'editUser',
    'importCSV',
    'createUser',
    'createRole',
  ],
}

export const role = {
  id: 2,
  name: 'test',
  user_ids: [2],
  instance_ids: [],
  permissions: [
    {
      name: 'Contacts',
      label: 'contacts',
      permissions: ['view_own', 'view_other'],
      categories: [
        {
          name: 'All contacts',
          label: 'all-contacts',
          permissions: ['view_other'],
          fields: [
            { name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] },
            { name: 'Ohoto', label: 'ohoto', permissions: ['view_own', 'view_other'] },
            { name: 'Ohoto 2', label: 'ohoto2', permissions: ['view_own', 'view_other'] },
            { name: 'Ohoto 3', label: 'ohoto3', permissions: ['view_own', 'view_other'] },
          ],
          profile: {
            fields: [
              { name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] },
              { name: 'Photo 1', label: 'photo1', permissions: ['view_own', 'view_other'] },
              { name: 'Photo 2', label: 'photo2', permissions: ['view_own', 'view_other'] },
              { name: 'Photo 3', label: 'photo3', permissions: ['view_own', 'view_other'] },
              { name: 'Ohoto', label: 'ohoto', permissions: ['view_own', 'view_other'] },
            ],
          },
          categories: null,
        },
        {
          name: 'Students',
          label: 'students',
          permissions: ['view_own', 'view_other'],
          fields: [
            { name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] },
            { name: 'Tatar', label: 'Tatar', permissions: ['view_own', 'view_other'] },
          ],
          profile: {
            fields: [
              { name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] },
              { name: 'Ohoto', label: 'ohoto', permissions: ['view_own', 'view_other'] },
            ],
          },
          categories: [
            {
              name: 'Student Details',
              label: 'student-details',
              fields: [{ name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] }],
              permissions: ['view_own', 'view_other'],
            },
            {
              name: 'Notes',
              label: 'notes',
              value: [
                { name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'], type: 'checkbox' },
                { name: 'Ohoto', label: 'ohoto', permissions: ['view_own', 'view_other'] },
              ],
              permissions: ['view_own', 'view_other'],
            },
          ],
        },
      ],
    },
    {
      name: 'Studies',
      label: 'studies',
      permissions: ['view_own', 'view_other'],
      categories: [
        {
          name: 'Test',
          label: 'test',
          permissions: ['view_other'],
          fields: [{ name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] }],
          profile: {
            fields: [{ name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] }],
          },
          categories: [
            {
              name: 'Student Details',
              label: 'student-details',
              fields: [{ name: 'Photo1', label: 'photo1', permissions: ['view_own', 'view_other'] }],
              permissions: ['view_own', 'view_other'],
            },
          ],
        },
        {
          name: 'Test 22',
          label: 'test22',
          permissions: ['view_other'],
          fields: [
            { name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] },
            { name: 'Tatar', label: 'Tatar', permissions: ['view_own', 'view_other'] },
          ],
          profile: {
            fields: [{ name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] }],
          },
          categories: [
            {
              name: 'Student Details',
              label: 'student-details',
              permissions: ['view_own', 'view_other'],
              fields: [{ name: 'Photo', label: 'photo', permissions: ['view_own', 'view_other'] }],
            },
          ],
        },
      ],
    },
  ],
  additional_functions: {
    students: {
      csv_import: true,
      csv_export: true,
      xml_export: true,
      moodle_export: true,
    },
    programmes: {
      push_to_application: true,
      csv_import: true,
    },
    modules: {
      csv_import: true,
    },
    module_instances: {
      csv_import: true,
    },
  },
  create_data_permissions: [{ label: 'Academics', entity: 'academics', permissions: ['create'] }],
  table_rules_permissions: [{ label: 'Academics', entity: 'academics', permissions: ['create'] }],
}

export const permissionsConfig = [
  {
    title: 'Create data',
    label: 'create',
    source_field_label: 'form_data_permissions',
    isCheckboxes: true,
    advanced: true,
  },
  {
    title: 'Can delete own data',
    label: 'delete_own',
    source_field_label: 'form_data_permissions',
    isCheckboxes: true,
    advanced: true,
  },
  {
    title: 'Can delete other user data',
    label: 'delete_other',
    source_field_label: 'form_data_permissions',
    isCheckboxes: true,
    advanced: true,
  },
  {
    title: 'Can view own data',
    label: 'view_own',
  },
  {
    title: 'Can view other user data',
    label: 'view_other',
  },
  {
    title: 'Can edit own data',
    label: 'edit_own',
    skipTableExceptions: true,
  },
  {
    title: 'Can edit other user data',
    label: 'edit_other',
    skipTableExceptions: true,
  },
  {
    title: 'Create rules',
    label: 'rule_create',
    source_field_label: 'table_rules_permissions',
    isCheckboxes: true,
    isUsedPermissionArray: true,
  },
  {
    title: 'Edit rules',
    label: 'rule_edit',
    source_field_label: 'table_rules_permissions',
    isCheckboxes: true,
    isUsedPermissionArray: true,
  },
]

export const additionalPermissionsConfig = [
  { title: 'All Contacts CSV Export', entity: 'allContacts', sub_entity: 'csv_export', path: 'all-contacts' },
  { title: 'Students CSV Import', entity: 'students', sub_entity: 'csv_import', path: 'students' },
  { title: 'Students CSV Export', entity: 'students', sub_entity: 'csv_export', path: 'students' },
  { title: 'Students XML Export', entity: 'students', sub_entity: 'xml_export', path: 'students' },
  { title: 'Moodle data export', entity: 'students', sub_entity: 'moodle_export', path: 'students' },
  { title: 'Module CSV Export', entity: 'modules', sub_entity: 'csv_export', path: 'modules' },
  { title: 'Module CSV Import', entity: 'modules', sub_entity: 'csv_import', path: 'modules' },
  { title: 'Push programmes', entity: 'programmes', sub_entity: 'push_to_application', path: 'programmes' },
  { title: 'Programmes CSV Export', entity: 'programmes', sub_entity: 'csv_export', path: 'programmes' },
  { title: 'Programmes CSV Import', entity: 'programmes', sub_entity: 'csv_import', path: 'programmes' },
  { title: 'Duplicate study records', entity: 'student_course_session', sub_entity: 'duplicate', path: 'records-of-studies' },
  { title: 'View trashed contacts', entity: 'global', sub_entity: 'view_trashed_rule', path: '' },
  { title: 'Academics CSV Export', entity: 'academics', sub_entity: 'csv_export', path: 'academics' },
  { title: 'Staff records CSV Export', entity: 'staff', sub_entity: 'csv_export', path: 'staff' },
  { title: 'Clergy CSV Export', entity: 'clergy', sub_entity: 'csv_export', path: 'clergy' },
  { title: 'Associations CSV Export', entity: 'associations', sub_entity: 'csv_export', path: 'associations' },
  { title: 'Churches CSV Export', entity: 'churches', sub_entity: 'csv_export', path: 'churches' },
  { title: 'Dioceses CSV Export', entity: 'dioceses', sub_entity: 'csv_export', path: 'dioceses' },
  { title: 'Other CSV Export', entity: 'other', sub_entity: 'csv_export', path: 'other' },
  { title: 'Engagements CSV Export', entity: 'engagements', sub_entity: 'csv_export', path: 'engagements' },
  { title: 'Student Course Session CSV Export', entity: 'student_course_session', sub_entity: 'csv_export', path: 'records-of-studies' },
  { title: 'Modules-instances CSV Export', entity: 'modulesInstances', sub_entity: 'csv_export', path: 'module-instances' },
  { title: 'Modules-instances CSV Import', entity: 'modulesInstances', sub_entity: 'csv_import', path: 'module-instances' },
  { title: 'Postgrad Student Details CSV Export', entity: 'postgradStudentDetails', sub_entity: 'csv_export', path: 'postgrad-student-details' },
  { title: 'Non Active CSV Export', entity: 'nonActive', sub_entity: 'csv_export', path: 'non-active' },
  { title: 'Admissions CSV Export', entity: 'admissions', sub_entity: 'csv_export', path: 'admissions-details' },
  { title: 'Applications CSV Export', entity: 'applications', sub_entity: 'csv_export', path: 'applications' },
  { title: 'Training Facilities CSV Export', entity: 'training_facilities', sub_entity: 'csv_export', path: 'training-facilities' },
]

import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { getFromLocalStorage, getFromSessionStorage } from '@Root/helpers'
import { authActions } from '@Store/auth'
import { useDispatch } from 'react-redux'
import { axios } from '@Root/API'
import { permissionsActions } from '@Store/permissions'

export const RedirectUnAuthUser = ({ children }) => {
	const dispatch = useDispatch()
	const [isAuth, setAuth] = useState(true)
	useEffect(() => {
		let timeout = setTimeout(() => {
			const token = getFromLocalStorage('token', true) || getFromSessionStorage('token', true)
			setAuth(Boolean(token))
			if (token) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
				dispatch(authActions.getUser())
				dispatch(permissionsActions.getTabs())
			}
		}, 0)

		return () => {
			clearTimeout(timeout)
		}
	}, [dispatch])

	if (!isAuth) return <Redirect to={'/login'} />
	return <>{children}</>
}

import React from 'react'
import { ReferencePeriodForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { referencePeriodSelectors, referencePeriodActions } from '@Store/studies'

export const CreateReferencePeriod = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const contactId = pathname.split('/')[5]
  const studyProgrammeId = pathname.split('/')[6]
  const referencePeriodActivity = useSelector(referencePeriodSelectors.referencePeriod)
  const options = useSelector(referencePeriodSelectors.options)
  const isLoading = useSelector(referencePeriodSelectors.isLoading)
  const errors = useSelector(referencePeriodSelectors.errors)

  const onCreate = data => {
    dispatch(referencePeriodActions.create({ data, history, studentCourseSessionId: programmeRecord.student_course_session_id, studyProgrammeId, contactId }))
  }

  const onCancel = () => {
    handleBack()
  }

  return (
    <ReferencePeriodForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      errorsFields={errors}
      isNew
      initialValue={referencePeriodActivity}
      options={options}
      isLoading={isLoading}
    />
  )
}

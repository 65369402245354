export const TOGGLE_LOADING = 'qualificationAward/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'qualificationAward/TOGGLE_SUCCESS'
export const GET_QUALIFICATION_AWARD = 'qualificationAward/GET_QUALIFICATION_AWARD'
export const SET_QUALIFICATION_AWARD = 'qualificationAward/SET_QUALIFICATION_AWARD'
export const CREATE_QUALIFICATION_AWARD = 'qualificationAward/CREATE_QUALIFICATION_AWARD'
export const DELETE_QUALIFICATION_AWARD = 'qualificationAward/DELETE_QUALIFICATION_AWARD'
export const EDIT_QUALIFICATION_AWARD = 'qualificationAward/EDIT_QUALIFICATION_AWARD'
export const GET_OPTIONS = 'qualificationAward/GET_OPTIONS'
export const SET_OPTIONS = 'qualificationAward/SET_OPTIONS'
export const SET_ERRORS = 'qualificationAward/SET_ERRORS'
export const CLEAR_DATA = 'qualificationAward/CLEAR_DATA'
export const SET_PERMISSIONS = 'qualificationAward/SET_PERMISSIONS'

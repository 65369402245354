import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessage } from '@Root/helpers'
import { snackbarActions, modalActions } from '@Root/store'
import { API } from '@Root/API'
import { ActionsDropdown, Table } from '@Root/components'
import { admissionsMandatoryRules, createDataConfig, trashInformationMandatoryRules } from '@Root/configs'
import { useHistory, useLocation } from 'react-router'
import { admissionActions, admissionSelectors } from '@Store/admission'
import { permissionsSelectors, permissionsActions } from '@Store/permissions'
import { usePermissions } from '@Hooks'

export const AdmissionsTable = ({ styleConfig }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const tab = location.pathname.split('/')[3]
  const [shouldBeUpdated, setShouldBeUpdated] = useState(false)
  const [initialDataWasFetched, setInitialDataWasFetched] = useState(false)
  const [savedCreatePdfMethod, setSaveCreatePdfMethod] = useState(null)
  const admissionOptions = useSelector(admissionSelectors.options).static
  const additionalPermissions = useSelector(permissionsSelectors.additionalPermissions)
  const rulePermissions = useSelector(permissionsSelectors.rulePermissions)
  const tableRef = useRef(null)
  const isEmptyPermissions = usePermissions({ additionalPermissions, tab })

  useEffect(() => {
    if (isEmptyPermissions) {
      dispatch(permissionsActions.getAdditional(tab))
    }
  }, [dispatch, isEmptyPermissions, tab])

  const onSuccess = text => {
    dispatch(snackbarActions.setSuccessNotification({ text }))
  }
  const exportHandlers = format => {
    const handlers = {
      csv: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      xml: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      moodle_csv: { handler: API.contact.exports.moodle, useCustomCollumns: false },
    }
    return handlers[format]
  }
  const onError = useCallback(
    message => {
      dispatch(snackbarActions.setSnackbar({ text: errorMessage(message), isError: true }))
    },
    [dispatch]
  )

  const actions = () => {
    return initialDataWasFetched
      ? [
          {
            name: 'Get PDF',
            handler: () => {
              if (savedCreatePdfMethod) savedCreatePdfMethod()
            },
          },
        ]
      : []
  }

  const admissionsDetailsConfig = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const hasApplicationOptions = Object.keys(admissionOptions).length > 0
        if (!hasApplicationOptions) dispatch(admissionActions.getStaticOptions())
      },
      table: () => {
        const {
          applications_status = [],
          statuses = [],
          ordinand = [],
          academic_years = [],
          study_years = [],
          bap_statuses = [],
          managed_by = [],
        } = admissionOptions

        const options = {
          status: statuses.map(x => x.label),
          academic_years,
          ordinand: ordinand.map(x => x.label),
          study_year: study_years.map(x => x.label),
          managed_by: managed_by.map(x => x.label),
          bap_status: bap_statuses,
          application_status: applications_status,
        }
        return {
          ...createDataConfig('admissions-details', trashInformationMandatoryRules, [], 'admissions-details', 'admissions-details', true, true, true),
          fetchDataHandler: params => API.admissions.get(params),
          fetchExportedDataHander: format => exportHandlers(format),
          clickLinkHandlers: {},
          exportTypeName: 'admissions',
          options,
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
                rule: rulePermissions['admissions-details'],
                hasExport:
                  additionalPermissions?.admissions?.xml_export ||
                  additionalPermissions?.admissions?.csv_export ||
                  additionalPermissions?.admissions?.moodle_export,
                hasCSVExport: additionalPermissions?.admissions?.csv_export,
              }
            : null,
        }
      },
    }
  }, [admissionOptions, dispatch, additionalPermissions, rulePermissions])

  const applicationsConfig = useCallback(
    () => ({
      fetchInitialDataHandler: () => {
        const hasApplicationOptions = Object.keys(admissionOptions).length > 0
        if (!hasApplicationOptions) dispatch(admissionActions.getStaticOptions())
      },
      table: () => {
        const { applications_status = [], forms_status = [] } = admissionOptions
        const options = {
          ordinand: ['Y', 'N'],
          attend: ['FT', 'PT'],
          mode: ['Residential', 'Context'],
          actioned: ['unactioned', 'actioned'],
          application_status: applications_status,
          form_status: forms_status,
          applied_before: ['Yes', 'No'],
          removed: ['untrashed', 'trashed'],
        }
        return {
          ...createDataConfig('applications', admissionsMandatoryRules, [], 'getAdmissionsApplicationsTable', 'applications', true, true, true),
          fetchDataHandler: params => API.admissions.application.getNotConfirmed(params),
          fetchExportedDataHander: format => exportHandlers(format),
          convertDataHandler: initialData => {
            return initialData.map(row => {
              return { ...row, actioned: row.date_finished ? row.actioned : undefined, form_status: row.form_status !== null ? row.form_status : 'none' }
            })
          },
          options,
          clickLinkHandlers: {
            application_status: ({ id: applicationId }) => {
              new Promise((resolve, reject) => {
                dispatch(
                  modalActions.showModal('InputModal', {
                    title: 'Change the Application Status',
                    inputType: 'select',
                    options: applications_status,
                    valueIsRequired: true,
                    placeholder: 'Select a Status',
                    resolveButtonText: 'Save',
                    clickRejectButtonHandler: reject,
                    clickResolveButtonHandler: resolve,
                  })
                )
              })
                .then(
                  async applicationStatusId => {
                    try {
                      await API.admissions.application.changeFormStatus(applicationId, applicationStatusId)
                      setShouldBeUpdated(true)
                    } catch (error) {
                      onError(error.response.data)
                    }
                  },
                  () => {}
                )
                .finally(() => dispatch(modalActions.hideModal()))
            },
            form_status: ({ id: applicationId }) => {
              new Promise((resolve, reject) => {
                dispatch(
                  modalActions.showModal('InputModal', {
                    title: 'Change the Form Status',
                    inputType: 'select',
                    options: forms_status,
                    valueIsRequired: true,
                    placeholder: 'Select a Status',
                    resolveButtonText: 'Save',
                    clickRejectButtonHandler: reject,
                    clickResolveButtonHandler: resolve,
                  })
                )
              })
                .then(async formStatusId => {
                  try {
                    await API.admissions.application.changeStatus(applicationId, formStatusId)
                    setShouldBeUpdated(true)
                  } catch (error) {
                    onError(error.response.data)
                  }
                })
                .finally(() => dispatch(modalActions.hideModal()))
            },
          },
          checkboxes: {
            'Actioned?': {
              values: {
                unactioned: false,
                actioned: true,
              },
              isDisabledWhenTrue: true,
              changeHandler: row => {
                new Promise((resolve, reject) => {
                  dispatch(
                    modalActions.showModal('ConfirmationModal', {
                      text: `Are you sure you want to convert this applicant into an accepted student?`,
                      clickRejectButtonHandler: reject,
                      clickResolveButtonHandler: resolve,
                    })
                  )
                })
                  .then(async () => {
                    try {
                      await API.admissions.application.confirmStudent(row.id)
                      setShouldBeUpdated(true)
                    } catch (error) {
                      onError(error)
                    }
                  })
                  .finally(() => dispatch(modalActions.hideModal()))
              },
            },
            'Removed?': {
              values: {
                untrashed: false,
                trashed: true,
              },
              changeHandler: (row, callback) => {
                const config = {
                  untrashed: {
                    text: 'trashed',
                    method: () => API.admissions.application.getTrashed(row.id),
                  },
                  trashed: {
                    text: 'untrashed',
                    method: () => API.admissions.application.getNotTrashed(row.id),
                  },
                }[row.removed]
                new Promise((resolve, reject) => {
                  dispatch(
                    modalActions.showModal('ConfirmationModal', {
                      text: `This application will be ${config.text}?`,
                      clickRejectButtonHandler: reject,
                      clickResolveButtonHandler: resolve,
                    })
                  )
                })
                  .then(async () => {
                    try {
                      await config.method()
                      callback()
                    } catch (error) {
                      onError(error)
                    }
                  })
                  .finally(() => dispatch(modalActions.hideModal()))
              },
            },
          },
          exportTypeName: 'applications',
          hasExportConfiguration: true,
          permissions: additionalPermissions
            ? {
                rule: rulePermissions.applications,
                hasExport:
                  additionalPermissions?.applications?.xml_export ||
                  additionalPermissions?.applications?.csv_export ||
                  additionalPermissions?.applications?.moodle_export,
                hasCSVExport: additionalPermissions?.applications?.csv_export,
              }
            : null,
        }
      },
    }),
    [additionalPermissions, admissionOptions, dispatch, onError, rulePermissions.applications]
  )

  const tableData = useCallback(
    () => ({
      'admissions-details': admissionsDetailsConfig(),
      applications: applicationsConfig(),
    }),
    [admissionsDetailsConfig, applicationsConfig]
  )

  useEffect(() => {
    shouldBeUpdated && setShouldBeUpdated(false)
  }, [shouldBeUpdated])

  const { table, fetchInitialDataHandler } = useMemo(() => {
    const data = tableData()[tab]
    return {
      ...data,
      table: data.table(),
    }
  }, [tab, tableData])

  useEffect(() => {
    fetchInitialDataHandler()
  }, [fetchInitialDataHandler])
  return (
    <div className={classes.wrapper}>
      {!!actions().length && (
        <ActionsDropdown
          actions={actions()}
          clickHandler={actionName =>
            actions()
              .find(action => action.name === actionName)
              .handler()
          }
          style={{ position: 'fixed', right: 20, top: 122, zIndex: 11 }}
        />
      )}
      <Table
        ref={tableRef}
        name={table.name}
        mandatoryRules={table.mandatoryRules}
        options={table.options}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        finishFetchDataHandler={() => setInitialDataWasFetched(true)}
        convertDataHandler={table.convertDataHandler}
        clickLinkHandlers={table.clickLinkHandlers}
        hasRules={table.hasRules}
        hasFilters={table.hasFilters}
        hasSorting={table.hasSorting}
        checkboxes={table.checkboxes}
        errorHandler={onError}
        successHandler={onSuccess}
        shouldBeUpdated={shouldBeUpdated}
        styleConfig={{ ...styleConfig.table, tableBar: styleConfig.tableBar }}
        saveCreatePdfMethod={method => {
          setSaveCreatePdfMethod(() => method)
        }}
        permissions={table.permissions}
        hasExportConfiguration={table.hasExportConfiguration}
        exportTypeName={table.exportTypeName}
        fetchExportedDataHander={table.fetchExportedDataHander}
        defaultExportFields={table.defaultExportFields}
      />
    </div>
  )
}

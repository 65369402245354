import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './modules.types'
import { actions } from './modules.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'
import { subjectRootSaga } from './subject'
import { instanceRootSaga } from './instance'
import { costCenterRootSaga } from './costCenter'
import { roleRootSaga } from './role'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { moduleId, history } = payload
  try {
    const { data } = yield call(API.studies.module.get, moduleId)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    history.push('/home/studies/modules')
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, data } = payload
  try {
    const response = yield call(API.studies.module.create, data)
    history.push(`/home/studies/modules/${response.data.data.module_id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The module has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.studies.module.edit, payload)
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The module has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, moduleId } = payload
  try {
    yield call(API.studies.module.remove, moduleId)
    history.push('/home/studies/modules')
    yield put(snackbarActions.setSuccessNotification({ text: 'The module has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  yield put(actions.toggleLoading(true))
  try {
    const {
      data: { credit_transfer_scheme = [], level_of_credit_points = [], random_number = null, language_identifiers, programmers_courses = [] },
    } = yield call(API.studies.module.options.getStatic)
    const formattedData = {
      credit_transfer_scheme: credit_transfer_scheme.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      level_of_credit_points: level_of_credit_points.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      language_identifiers: language_identifiers.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      random_number: random_number,
      programmers_courses: programmers_courses.map(obj => ({ value: obj.id, label: `${obj.programme_name}` })),
    }
    yield put(
      actions.setOptions({
        name: 'static',
        data: formattedData,
      })
    )
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_MODULE, get),
    takeEvery(types.CREATE_MODULE, create),
    takeEvery(types.DELETE_MODULE, remove),
    takeEvery(types.EDIT_MODULE, edit),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    subjectRootSaga(),
    instanceRootSaga(),
    costCenterRootSaga(),
    roleRootSaga(),
  ])
}

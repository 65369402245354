export const generateStyleConfig = (isNotTable, isSnapshot, isMoodleLinks) => {
	return {
		navLinksStyles: {
			position: isNotTable ? 'static' : 'fixed',
			width: '100%',
			top: '103px',
			right: 0,
			zIndex: 10,
			marginBottom: 30,
		},
		subPageNavLinksStyles: {
			position: 'fixed',
			top: '175px',
			width: '99%',
			zIndex: 10,
			marginBottom: 30,
		},
		actionsDropdown: {
			position: isNotTable ? 'absolute' : 'fixed',
			right: isNotTable ? 10 : 20,
			top: isNotTable ? -60 : 122,
			zIndex: 12,
		},
		pageTitle: {
			position: isNotTable ? 'static' : 'fixed',
			top: 37,
			zIndex: 10,
			marginTop: isNotTable ? 50 : 10,
		},
		tableBar: {
			position: isNotTable ? 'static' : 'fixed',
			width: '100%',
			top: isSnapshot || isMoodleLinks ? 205 : 272,
			padding: '0 10px',
			zIndex: 150,
		},
		table: {
			datePeriod: {
				top: 62,
			},
			datePeriodDropdownStyle: {
				position: isNotTable ? 'absolute' : 'fixed',
				top: isNotTable ? -140 : 62,
				zIndex: 50,
				right: isNotTable ? 0 : 10,
			},
			cleanFilters: {
				position: isNotTable ? 'static' : 'fixed',
				top: isSnapshot || isMoodleLinks ? 170 : 234,
				background: isNotTable ? '#ffffff' : '#f5f5f5',
			},
			upperPaginationContainerStyles: {
				marginTop: isSnapshot || isMoodleLinks ? 250 : 315,
			},
			tableData: {
				height: 555,
			},
			tablePositions: {
				height: 545,
			},
			isFixedTableHeader: true,
			isHideScrollAtBody: true,
		},
	}
}

import * as types from './fieldConstructor.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  getFields: payload => ({
    type: types.GET_FIELDS,
    payload,
  }),
  setFields: payload => ({
    type: types.SET_FIELDS,
    payload,
  }),
  setPaginationInformation: payload => ({
    type: types.SET_PAGINATION_INFORMATION,
    payload,
  }),
  createField: payload => ({
    type: types.CREATE_FIELD,
    payload,
  }),
  getField: payload => ({
    type: types.GET_FIELD,
    payload,
  }),
  setField: payload => ({
    type: types.SET_FIELD,
    payload,
  }),
  editField: payload => ({
    type: types.EDIT_FIELD,
    payload,
  }),
  removeField: payload => ({
    type: types.REMOVE_FIELD,
    payload,
  }),
  getFieldsForContact: payload => ({
    type: types.GET_FIELDS_FOR_CONTACT,
    payload,
  }),
  setFieldsForContact: payload => ({
    type: types.SET_FIELDS_FOR_CONTACT,
    payload,
  }),
  setErrors: payload => ({
    type: types.SET_ERRORS,
    payload,
  }),
  saveOrder: payload => ({
    type: types.SAVE_ORDER,
    payload,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
}

export const TOGGLE_LOADING = 'studentCourseSession/referencePeriod/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/referencePeriod/TOGGLE_SUCCESS'
export const GET_REFERENCEPERIOD = 'studentCourseSession/referencePeriod/GET_REFERENCEPERIOD'
export const SET_REFERENCEPERIOD = 'studentCourseSession/referencePeriod/SET_REFERENCEPERIOD'
export const CREATE_REFERENCEPERIOD = 'studentCourseSession/referencePeriod/CREATE_REFERENCEPERIOD'
export const DELETE_REFERENCEPERIOD = 'studentCourseSession/referencePeriod/DELETE_REFERENCEPERIOD'
export const EDIT_REFERENCEPERIOD = 'studentCourseSession/referencePeriod/EDIT_REFERENCEPERIOD'
export const GET_OPTIONS = 'studentCourseSession/referencePeriod/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/referencePeriod/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/referencePeriod/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/referencePeriod/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/referencePeriod/SET_CAN_BE_CREATED'
export const SET_ERRORS = 'studentCourseSession/referencePeriod/SET_ERRORS'

import React, { useState, useEffect, useRef } from 'react'
import classes from './style.module.scss'
import { useDispatch } from 'react-redux'
import { snackbarActions } from '@Root/store'
import { API } from '@Root/API'
import { all, errorMessage } from '@Root/helpers'
import { SectionWrapper, FormWrapper } from '@Root/HOCs'
import { Spinner, Editor } from '@Root/components'
import { useHistory } from 'react-router'
import { useToggle } from '@Root/hooks'

const titles = { onRead: 'Application Form Instruction', onEdit: 'Edit Application Form Instruction' }
export const ApplicationFormInstructions = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [initialData, setInitialData] = useState('<p></p>')
  const [data, setData] = useState('<p></p>')
  const [isShown, setIsShown] = useState(false)
  const [isEditable, toggleEditable] = useToggle(false)
  const [isSpinning, setIsSpinning] = useState(false)
  const [permissions, setPermissions] = useState({})
  const editorRef = useRef()

  const onError = error => {
    dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }

  const fetchData = async () => {
    try {
      const { data } = await API.admin.applicationFormInstructions.get()
      setInitialData(data.html.header)
      setData(data.html.header)
      setPermissions(data.permissions)
    } catch (error) {
      onError(error)
      history.push('/page-not-found')
    }
  }

  const updateData = async () => {
    try {
      setIsSpinning(true)
      await API.admin.applicationFormInstructions.edit(data)
      toggleEditable()
      dispatch(snackbarActions.setSuccessNotification({ text: 'The application form instruction has been saved successfully!' }))
    } catch (error) {
      onError(error)
    }
    setIsSpinning(false)
  }

  useEffect(() => {
    ;(async () => {
      await fetchData()
      setIsShown(true)
    })()
  }, [])

  const { onRead, onEdit } = titles
  return (
    <div className={classes.wrapper} style={{ marginTop: 235 }}>
      {isShown ? (
        <SectionWrapper>
          <FormWrapper
            buttons={isEditable ? ['cancel', 'save'] : []}
            buttonsAreDisabled={isSpinning}
            isSpinning={isSpinning}
            clickCancelButtonHandler={() =>
              all(
                () => setData(initialData),
                () => toggleEditable(),
                () => editorRef.current.setEditorState(initialData)
              )
            }
            clickSaveButtonHandler={updateData}
            canEdit={permissions?.edit}
            isEditable={isEditable}
            clickToggleEditModeButtonHandler={toggleEditable}
            readTitle={onRead}
            editTitle={onEdit}
            idForm={`application_form_instructions`}
            isSuccess={isSpinning && !isEditable}
          >
            <Editor ref={editorRef} value={data} changeHandler={value => setData(value)} isDisabled={!isEditable} />
          </FormWrapper>
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: 'absolute' }} />
      )}
    </div>
  )
}

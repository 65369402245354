import React from 'react'
import classes from './style.module.scss'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { modalActions, snackbarActions } from '@Root/store'
import { API } from '@Root/API'
import { errorMessage, onOpenEmail } from '@Root/helpers'
import { ActionsDropdown, Table } from '@Root/components'
import { trashInformationMandatoryRules, createDataConfig, booleanOptions } from '@Root/configs'
import { optionsActions } from '@Store/options'
import { studentActions } from '@Store/contact/person'
import { permissionsActions } from '@Store/permissions'

class Component extends React.Component {
  state = {
    shouldBeUpdated: false,
  }

  generateActions = () => {
    const { dataCreationPermissions, history } = this.props
    let array = []
    if (dataCreationPermissions?.person) {
      array.push({
        name: 'Add a person',
        path: '/home/contacts/all-contacts/new-person',
      })
    }
    if (dataCreationPermissions?.organisation) {
      array.push({
        name: 'Add an organisation',
        path: '/home/contacts/all-contacts/new-organisation',
      })
    }
    return array
  }

  tableOptions = () => {
    const {
      dataForCreateContact: {
        all_program_type = [],
        institutions = [],
        student_types = [],
        genders = [],
        person_relationships = [],
        organisation_relationships = [],
        academic_year_list = [],
      },
      studentStaticOptions: { marital_statuses = [], nationalities = [], ethnicities = [], disabilities = [] },
    } = this.props

    return {
      gender: genders.map(x => x.label),
      relationship: [...person_relationships, ...organisation_relationships].map(x => x.label),
      institution: institutions.map(x => x.label),
      student_types: student_types.map(x => x.label),
      programme_types: all_program_type.map(x => x.label),
      cohort_group: academic_year_list,
      university_card_date: academic_year_list,
      marital_status_type: marital_statuses.map(x => x.label),
      has_learning_support: booleanOptions.map(x => x.label),
      has_disability: booleanOptions.map(x => x.label),
      is_international: booleanOptions.map(x => x.label),
      nationality_type: nationalities.map(x => x.label),
      disability_type: disabilities.map(x => x.label),
      ethnicity_type: ethnicities.map(x => x.label),
      studied_at: booleanOptions.map(x => x.label),
      is_community_member: booleanOptions.map(x => x.label),
      include_in_hesa: booleanOptions.map(x => x.label),
    }
  }

  exportHandlers = format => {
    const handlers = {
      csv: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      xml: { handler: API.contact.exports.byFormat, useCustomCollumns: true },
      moodle_csv: { handler: API.contact.exports.moodle, useCustomCollumns: false },
    }
    return handlers[format]
  }

  configureExportColumns = (columns, hiddenColumns, params) => {
    const { showModal, hideModal, setNotification } = this.props
    new Promise((resolve, reject) => {
      showModal('ConfigureExportModal', {
        clickRejectButtonHandler: reject,
        title: 'Choose exported columns',
        columns: columns,
        resolveButtonText: 'Submit',
        hiddenColumns: hiddenColumns,
        clickResolveButtonHandler: resolve,
      })
    })
      .then(data => API.contact.exports.configureExportColumns(data, params))
      .then(() => {
        setNotification({ text: 'Changes has been saved successfully!' })
      })
      .finally(() => hideModal())
  }

  tableData = () => {
    const { additionalPermissions, rulePermissions } = this.props

    const mandatoryRules = trashInformationMandatoryRules
    return {
      'all-contacts': {
        ...createDataConfig('all-contacts', mandatoryRules, [], 'getContacts', 'all-contacts', true, true, true, true),
        options: this.tableOptions(),
        exportTypeName: 'all-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {
          Email: onOpenEmail,
        },
        permissions: additionalPermissions
          ? {
              rule: rulePermissions['all-contacts'],
              hasExport:
                additionalPermissions?.allContacts?.xml_export ||
                additionalPermissions?.allContacts?.csv_export ||
                additionalPermissions?.allContacts?.moodle_export,
              hasCSVExport: additionalPermissions?.allContacts?.csv_export,
            }
          : null,
      },
      students: {
        ...createDataConfig('students', mandatoryRules, [], 'getContacts', 'student', true, true, true, true, false, true),
        fetchExportedDataHander: format => this.exportHandlers(format),
        exportTypeName: 'contacts',
        clickImportButtonHandler: this.importCSV,
        options: this.tableOptions(),
        clickLinkHandlers: {},
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        permissions: additionalPermissions
          ? {
              hasExport:
                additionalPermissions?.students?.xml_export || additionalPermissions?.students?.csv_export || additionalPermissions?.students?.moodle_export,
              hasCSVImport: additionalPermissions?.students?.csv_import,
              hasXMLExport: additionalPermissions?.students?.xml_export,
              hasCSVExport: additionalPermissions?.students?.csv_export,
              hasMoodleExport: additionalPermissions?.students?.moodle_export,
              rule: rulePermissions?.students,
            }
          : null,
      },
      academics: {
        ...createDataConfig('academics', mandatoryRules, [], 'getContacts', 'academic', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'academics-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions.academics,
              hasExport:
                additionalPermissions?.academics?.xml_export || additionalPermissions?.academics?.csv_export || additionalPermissions?.academics?.moodle_export,
              hasCSVExport: additionalPermissions?.academics?.csv_export,
            }
          : null,
      },
      staff: {
        ...createDataConfig('staff', mandatoryRules, [], 'getContacts', 'staff', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'staff-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions.staff,
              hasExport: additionalPermissions?.staff?.xml_export || additionalPermissions?.staff?.csv_export || additionalPermissions?.staff?.moodle_export,
              hasCSVExport: additionalPermissions?.staff?.csv_export,
            }
          : null,
      },
      clergy: {
        ...createDataConfig('clergy', mandatoryRules, [], 'getContacts', 'clergy', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'clergy-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions.clergy,
              hasExport: additionalPermissions?.clergy?.xml_export || additionalPermissions?.clergy?.csv_export || additionalPermissions?.clergy?.moodle_export,
              hasCSVExport: additionalPermissions?.clergy?.csv_export,
            }
          : null,
      },
      associations: {
        ...createDataConfig('associations', mandatoryRules, [], 'getContacts', 'association', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'association-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions.associations,
              hasExport:
                additionalPermissions?.associations?.xml_export ||
                additionalPermissions?.associations?.csv_export ||
                additionalPermissions?.associations?.moodle_export,
              hasCSVExport: additionalPermissions?.associations?.csv_export,
            }
          : null,
      },
      churches: {
        ...createDataConfig('churches', mandatoryRules, [], 'getContacts', 'church', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'church-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions?.churches,
              hasExport:
                additionalPermissions?.churches?.xml_export || additionalPermissions?.churches?.csv_export || additionalPermissions?.churches?.moodle_export,
              hasCSVExport: additionalPermissions?.churches?.csv_export,
            }
          : null,
      },
      dioceses: {
        ...createDataConfig('dioceses', mandatoryRules, [], 'getContacts', 'diocese', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'diocese-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions.dioceses,
              hasExport:
                additionalPermissions?.dioceses?.xml_export || additionalPermissions?.dioceses?.csv_export || additionalPermissions?.dioceses?.moodle_export,
              hasCSVExport: additionalPermissions?.dioceses?.csv_export,
            }
          : null,
      },
      'training-facilities': {
        ...createDataConfig('training-facilities', mandatoryRules, [], 'getContacts', 'training-facilities', true, true, true, true, false, true),
        fetchDataHandler: params => API.getContacts(params, 'trainingfacility'),
        exportTypeName: 'training-facility-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        options: this.tableOptions(),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions['training-facilities'],
              hasExport:
                additionalPermissions?.training_facilities?.xml_export ||
                additionalPermissions?.training_facilities?.csv_export ||
                additionalPermissions?.training_facilities?.moodle_export,
              hasCSVExport: additionalPermissions?.training_facilities?.csv_export,
            }
          : null,
      },
      other: {
        ...createDataConfig('other', mandatoryRules, [], 'getContacts', 'other', true, true, true, true, false, true),
        options: this.tableOptions(),
        exportTypeName: 'other-contacts',
        fetchExportedDataHander: format => this.exportHandlers(format),
        hasExportConfiguration: true,
        clickExportConfigurationButtonHandler: (columns, hiddenColumns) => this.configureExportColumns(columns, hiddenColumns),
        clickLinkHandlers: {},
        permissions: additionalPermissions
          ? {
              rule: rulePermissions.other,
              hasExport: additionalPermissions?.other?.xml_export || additionalPermissions?.other?.csv_export || additionalPermissions?.other?.moodle_export,
              hasCSVExport: additionalPermissions?.other?.csv_export,
            }
          : null,
      },
    }
  }

  fetchOptions = () => {
    const { getDataForCreateContact, dataForCreateContact, getDataForCreateStudent } = this.props
    const hasOptions = Object.keys(dataForCreateContact).length > 0
    if (!hasOptions) {
      getDataForCreateContact()
      getDataForCreateStudent()
    }
  }

  refreshTable = () => {
    this.setState({ shouldBeUpdated: true }, () => this.setState({ shouldBeUpdated: false }))
  }

  importCSV = () => {
    const { showModal, hideModal, setSnackbar, setNotification } = this.props
    const { refreshTable } = this
    let importData = {}
    new Promise((resolve, reject) => {
      showModal('UploadingModal', {
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve,
      })
    })
      .then(
        file => API.contact.imports.csv(file),
        () => {
          hideModal()
          Promise.resolve()
        }
      )
      .then(({ data: { data } }) => {
        const processId = data.job_id
        const importType = data.import_type
        const payload = {
          job_id: processId,
          import_type: importType,
        }
        return new Promise((resolve, reject) => {
          showModal('ProcessModal', {
            headerText: 'Results',
            text: `Data process is in progress. Please, don't close this window`,
            title: 'Process data',
            fetchDataHandler: () => API.contact.imports.trackProcess(payload),
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve,
          })
        })
      })
      .then(
        data => {
          importData = data
          return new Promise((resolve, reject) => {
            showModal('ImportModal', {
              headerText: 'Results',
              importDescription: data.contacts.length ? 'The following student records were imported:' : 'No students were imported.',
              importData: { data: data.contacts, fields: ['sid', 'name', 'email'] },
              invalidImportDescription: data.invalid_students.length ? '' : 'No import conflicts were detected.',
              invalidImportData: { data: data.invalid_students, fields: ['sid', 'name', 'email', 'error_msg'] },
              leftButtonText: data.contacts.length ? 'Roll back' : null,
              rightButtonText: 'Ok',
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve,
            })
          })
        },
        error => {
          const convertErrors = serverError => {
            try {
              const errorsObj = serverError.response.data.errors
              return Object.keys(errorsObj)
                .sort((a, b) => {
                  const x = +a.slice(11)
                  const y = +b.slice(11)
                  if (x > y) return 1
                  if (x < y) return -1
                  return 0
                })
                .map(key => ({
                  title: key,
                  rows: errorsObj[key],
                }))
            } catch (error) {
              console.log('Unable to convert errors')
              let message = serverError.response.data.message
              return [{ title: 'Error Message', rows: [message] }]
            }
          }

          const errors = convertErrors(error)

          new Promise((resolve, reject) => {
            showModal('ImportModal', {
              headerText: 'Results',
              importDescription: `Unable to process the data. Please check your template.${errors?.length ? ' Errors list:' : ''}`,
              errors,
              rightButtonText: 'Ok',
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve,
            })
          }).then(
            () => {
              hideModal()
            },
            () => {
              hideModal()
            }
          )
        }
      )
      .then(
        button => {
          if (button === 'left') {
            new Promise((resolve, reject) => {
              showModal('ImportModal', {
                headerText: 'Warning',
                importDescription: 'The student records from the most recent import will be deleted. Click the delete button to delete the records:',
                importData: { data: importData.contacts, fields: ['sid', 'name', 'email'] },
                leftButtonText: 'Cancel Rollback',
                rightButtonText: 'Delete',
                clickRejectButtonHandler: reject,
                clickResolveButtonHandler: resolve,
              })
            }).then(
              button => {
                if (button === 'left') {
                  hideModal()
                  refreshTable()
                } else if (button === 'right') {
                  API.contact.imports.rollback({ rollback: importData.rollback }).then(
                    () => {
                      new Promise((resolve, reject) => {
                        showModal('NotificationModal', {
                          text: 'Your records were deleted.',
                          clickRejectButtonHandler: reject,
                          clickResolveButtonHandler: resolve,
                        })
                      }).then(
                        () => {
                          hideModal()
                        },
                        () => {
                          hideModal()
                        }
                      )
                    },
                    error => {
                      setSnackbar({ text: `Unable to roll back. Error message: ${errorMessage(error)}`, isError: true })
                      hideModal()
                      refreshTable()
                    }
                  )
                }
              },
              () => {
                hideModal()
                Promise.resolve()
              }
            )
          } else if (button === 'right') {
            hideModal()
            setNotification({ text: 'Students have been imported successfully!' })
            refreshTable()
          }
        },
        () => {
          hideModal()
          Promise.resolve()
        }
      )
  }

  componentDidMount() {
    const { location, getAdditionalPermissions, isEmptyPermissions } = this.props
    const tab = location.pathname.split('/')[3]
    this.fetchOptions()
    if (isEmptyPermissions) {
      getAdditionalPermissions(tab)
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  render() {
    const { shouldBeUpdated } = this.state
    const { location, setSnackbar, styleConfig } = this.props
    const { generateActions, tableData } = this
    const tab = location.pathname.split('/')[3]
    const actions = generateActions()

    return (
      <div className={classes.wrapper}>
        {actions.length > 0 && <ActionsDropdown actions={actions} style={styleConfig.actionsDropdown} />}
        <Table
          key={tableData()[tab].name}
          name={tableData()[tab].name}
          mandatoryRules={tableData()[tab].mandatoryRules}
          options={tableData()[tab].options}
          fetchDataHandler={tableData()[tab].fetchDataHandler}
          fetchSaveColumnOptions={tableData()[tab].fetchSaveColumnOptions}
          fetchColumnOptions={tableData()[tab].fetchColumnOptions}
          fetchExportedDataHander={tableData()[tab].fetchExportedDataHander}
          clickLinkHandlers={tableData()[tab].clickLinkHandlers}
          hasRules={tableData()[tab].hasRules}
          hasExport={tableData()[tab].hasExport}
          hasImport={tableData()[tab].hasImport}
          hasFilters={tableData()[tab].hasFilters}
          hasSorting={tableData()[tab].hasSorting}
          exportTypeName={tableData()[tab].exportTypeName}
          clickImportButtonHandler={tableData()[tab].clickImportButtonHandler}
          errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
          shouldBeUpdated={shouldBeUpdated}
          datePeriodTop={styleConfig.table.datePeriod.top}
          styleConfig={{
            ...styleConfig.table,
            tableBar: styleConfig.tableBar,
          }}
          defaultExportFields={tableData()[tab].defaultExportFields}
          hasExportConfiguration={tableData()[tab].hasExportConfiguration}
          clickExportConfigurationButtonHandler={tableData()[tab].clickExportConfigurationButtonHandler}
          permissions={tableData()[tab].permissions}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ authReducer, optionsReducer, contactReducer, permissionsReducer }) => {
  return {
    permissions: authReducer.user.permissions,
    dataForCreateContact: optionsReducer.dataForCreateContact,
    studentStaticOptions: contactReducer.person.student.reducer.options.static,
    additionalPermissions: permissionsReducer.additionalPermissions,
    dataCreationPermissions: permissionsReducer.dataCreationPermissions,
    rulePermissions: permissionsReducer.rulePermissions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showModal: (component, props) => dispatch(modalActions.showModal(component, props)),
    hideModal: () => dispatch(modalActions.hideModal()),
    setSnackbar: data => dispatch(snackbarActions.setSnackbar(data)),
    setNotification: data => dispatch(snackbarActions.setSuccessNotification(data)),
    getDataForCreateContact: () => dispatch(optionsActions.getDataForCreateContact()),
    getDataForCreateStudent: () => dispatch(studentActions.getStaticOptions()),
    getAdditionalPermissions: tab => dispatch(permissionsActions.getAdditional(tab)),
  }
}

export const ContactsTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))

export const TOGGLE_LOADING = 'studentCourseSession/studentFinancialSupport/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/studentFinancialSupport/TOGGLE_SUCCESS'
export const GET_STUDENT_FINANCIAL_SUPPORT = 'studentCourseSession/studentFinancialSupport/GET_STUDENT_FINANCIAL_SUPPORT'
export const SET_STUDENT_FINANCIAL_SUPPORT = 'studentCourseSession/studentFinancialSupport/SET_STUDENT_FINANCIAL_SUPPORT'
export const CREATE_STUDENT_FINANCIAL_SUPPORT = 'studentCourseSession/studentFinancialSupport/CREATE_STUDENT_FINANCIAL_SUPPORT'
export const DELETE_STUDENT_FINANCIAL_SUPPORT = 'studentCourseSession/studentFinancialSupport/DELETE_STUDENT_FINANCIAL_SUPPORT'
export const EDIT_STUDENT_FINANCIAL_SUPPORT = 'studentCourseSession/studentFinancialSupport/EDIT_STUDENT_FINANCIAL_SUPPORT'
export const GET_OPTIONS = 'studentCourseSession/studentFinancialSupport/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/studentFinancialSupport/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/studentFinancialSupport/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/studentFinancialSupport/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/studentFinancialSupport/SET_CAN_BE_CREATED'
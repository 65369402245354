import React, { useEffect } from 'react'
import { AccreditationAimForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { accreditationAimActions, accreditationAimSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditAccreditationAim = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, accreditationAimId, contactId } = useParams()
  const accreditationAim = useSelector(accreditationAimSelectors.accreditationAim)
  const permissions = useSelector(accreditationAimSelectors.permissions)
  const isSuccess = useSelector(accreditationAimSelectors.isSuccess)
  const isLoading = useSelector(accreditationAimSelectors.isLoading)
  const options = useSelector(accreditationAimSelectors.options)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_accreditation_aim

  useEffect(() => {
    dispatch(accreditationAimActions.get({ data: { engagementId, contactId, accreditationAimId }, history }))
    return () => {
      dispatch(accreditationAimActions.clearData())
    }
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This accreditation aim will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(accreditationAimActions.delete({ data: { engagementId, contactId, accreditationAimId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(accreditationAimActions.edit({ data, engagementId, accreditationAimId }))
  }

  return (
    <div>
      <AccreditationAimForm
        onSave={onSave}
        onDelete={onDelete}
        canEdit={permissions.form?.edit}
        canDelete={permissions.form?.delete}
        initialValue={accreditationAim}
        isLoading={isLoading}
        isSuccess={isSuccess}
        options={options}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import moment from 'moment'
import { FormWrapper } from '@Root/HOCs'
import { RoundPlusButton, SectionTitle, TextArea, FileInput, LoadMoreButton } from '@Root/components'
import { useDispatch } from 'react-redux'
import { modalActions } from '@Store/modal'
import { useError } from '@Root/hooks'
import { UserIcon } from '@Root/assets'

export const AttachmentsForm = ({
  canManipulate,
  isLoading,
  isSuccess,
  initialValues,
  canCreate,
  onCreate,
  onEdit,
  onDelete,
  history,
  totalHistory,
  handelPaginationAttachmentAction,
  totalAttachments,
  onOpenHistoryOfChanges,
  clearHistory,
  historyPaginate,
}) => {
  const [inputsAreShown, setInputsAreShown] = useState(false)
  const [newAttachmentValue, setNewAttachmentValue] = useState(null)
  const [newAttachmentFile, setNewAttachmentFile] = useState(null)
  const [editAttachmentId, setEditAttachmentId] = useState(null)
  const [editAttachmentFile, setEditAttachmentFile] = useState(null)
  const [editAttachmentValue, setEditAttachmentValue] = useState(null)
  const [editAttachmentDescription, setEditAttachmentDescription] = useState(null)
  const [newAttachmentDescription, setNewAttachmentDescription] = useState('')
  const [data, setData] = useState([])
  const { errors, setError } = useError()
  const dispatch = useDispatch()

  useEffect(() => {
    if (initialValues) setData(initialValues)
  }, [initialValues])

  useEffect(() => {
    if (history.length > 0) {
      dispatch(
        modalActions.showModal('HistoryModal', {
          history,
          historyLength: totalHistory,
          moment,
          historyPaginate,
          clearHistory,
        })
      )
    }
  }, [history])

  const cancelCreate = () => {
    setInputsAreShown(false)
    setNewAttachmentValue(null)
    setNewAttachmentFile(null)
    setNewAttachmentDescription('')
  }

  const validateForm = () => {
    if (!newAttachmentFile) {
      setError('file', 'File is not chosen')
      return
    }
    return true
  }

  const onCreateNote = () => {
    if (!validateForm()) return
    onCreate({ file: newAttachmentFile, description: newAttachmentDescription })
  }

  const onEditNote = () => {
    onEdit(editAttachmentId, { file: editAttachmentFile, description: editAttachmentDescription })
  }

  const handleClickDeleteButton = id => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This attachment will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        onDelete({ id })
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  useEffect(() => {
    if (isSuccess) {
      cancelCreate()
      onCancelEdit()
    }
  }, [isSuccess])

  const onEditAttachment = (id, file_url, description) => {
    setEditAttachmentId(id)
    setEditAttachmentValue(file_url)
    setEditAttachmentDescription(description)
  }

  const onCancelEdit = () => {
    setEditAttachmentId(null)
    setEditAttachmentValue(null)
    setEditAttachmentFile(null)
    setEditAttachmentDescription(null)
  }

  const onChangeEditFile = (value, file) => {
    setEditAttachmentValue(value)
    setEditAttachmentFile(file)
  }

  const onChangeNewFile = (value, file) => {
    setNewAttachmentValue(value)
    setNewAttachmentFile(file)
  }

  const renderAttachment = (data, index) => {
    const { author_name, created_at, file_url, file_name, description, id, avatar, edited_author_name, updated_at } = data

    const isDeletable = data.delete && canManipulate
    const isEditable = data.edit && canManipulate
    return (
      <div key={index} className={classes.attachment}>
        <div className={classes.main}>
          <div className={classes.left}>
            <div className={classes.photo} style={{ backgroundImage: `url(${avatar || UserIcon})` }} />
          </div>
          <div className={classes.right}>
            <div className={classes.author}>
              <div className={classes.infoTitle}>
                <p>
                  {author_name}, {created_at}
                </p>
                ,
                {edited_author_name && (
                  <p>
                    ( Edited by {edited_author_name}, {updated_at} )
                  </p>
                )}
                <p className={classes.historyOfChanges}>
                  <span onClick={() => onOpenHistoryOfChanges(id)} className={classes.historyOfChangesBtn}>
                    history of changes
                  </span>
                </p>
              </div>
              {isEditable && (
                <div className={classes.edit_button} onClick={() => onEditAttachment(id, file_url, description)}>
                  Edit
                </div>
              )}
            </div>
            <a className={classes.link} href={file_url} target='_blank' rel='noopener noreferrer'>
              {file_name}
            </a>
            <div className={classes.description}>{description}</div>
          </div>
        </div>
        {isDeletable && (
          <div className={classes.buttons}>
            <button className={classes.delete} onClick={() => handleClickDeleteButton(id)}>
              Delete
            </button>
          </div>
        )}
      </div>
    )
  }

  const renderEditAttachment = (cancel, save, description, setDescription, onChangeFile, file, value, buttonSaveName, file_name, id) => {
    return (
      <div className={classes.newAttachment} key={id}>
        <div className={classes.inputs}>
          <FileInput
            style={{ width: 84, height: 84 }}
            circleStyle={{ width: 30, height: 30 }}
            value={value}
            file={file || { name: file_name, type: 'video/custom' }}
            changeHandler={(value, file) => onChangeFile(value, file)}
            removeHandler={() => onChangeFile(null, null)}
            hasText={false}
            error={errors.file}
          />
          <TextArea style={{ width: 796, height: 84 }} value={description} changeHandler={value => setDescription(value)} />
        </div>
        <div className={classes.buttons}>
          <button className={classes.buttonSecondary} onClick={cancel}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={save}>
            {buttonSaveName}
          </button>
        </div>
      </div>
    )
  }

  const isCreatable = canCreate && canManipulate

  return (
    <FormWrapper isSpinning={isLoading}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <SectionTitle title='Attachments (Maximum file size is 20 MB)' />
          {isCreatable && !inputsAreShown && <RoundPlusButton clickHandler={() => setInputsAreShown(true)} />}
        </div>
        {inputsAreShown &&
          renderEditAttachment(
            cancelCreate,
            onCreateNote,
            newAttachmentDescription,
            setNewAttachmentDescription,
            onChangeNewFile,
            newAttachmentFile,
            newAttachmentValue,
            'Add'
          )}
        <div className={classes.attachments}>
          {data.map((data, i) =>
            editAttachmentId !== data.id
              ? renderAttachment(data, i)
              : renderEditAttachment(
                  onCancelEdit,
                  onEditNote,
                  editAttachmentDescription,
                  setEditAttachmentDescription,
                  onChangeEditFile,
                  editAttachmentFile,
                  editAttachmentValue,
                  'Save',
                  data.file_name,
                  data.id
                )
          )}
        </div>
        {!data.length && !inputsAreShown && <div className={classes.message}>There are currently no attachments for this contact</div>}
        {data.length >= 10 && totalAttachments !== data.length && (
          <LoadMoreButton article={'Attachment'} handelPaginationAction={handelPaginationAttachmentAction} />
        )}
      </div>
    </FormWrapper>
  )
}

import * as types from './institution.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './institution.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admin.institution.get, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.admin.institution.create, data)
    history.push(`/home/admin/data/institutions/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The institution has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.institution.edit, payload)
    yield put(actions.set(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The institution has been saved successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, history } = payload
  try {
    yield call(API.admin.institution.remove, id)
    history.push('/home/admin/data/institutions')
    yield put(snackbarActions.setSuccessNotification({ text: 'The institution has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.admin.institution.options.getAccommodation)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: obj.title }))

    yield put(actions.setOptions({ accomodation: generateObject(data.data) }))
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_INSTITUTION, get),
    takeEvery(types.CREATE_INSTITUTION, create),
    takeEvery(types.EDIT_INSTITUTION, edit),
    takeEvery(types.DELETE_INSTITUTION, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

import { axios } from '@Root/API'
import { replaceNullValueInObj, validateCustomIdToEmpty } from '@Root/helpers'
import { log } from '@craco/craco/lib/logger'

export const edit = (contactType, data) => {
	const { addresses, dbsses, relationship_ids, ...rest } = data

	const validateData = payload => {
		const data = replaceNullValueInObj(payload)
		return (array, fieldName) => {
			const newArray = validateCustomIdToEmpty(array)
			newArray.forEach(address => replaceNullValueInObj(address))
			return { ...data, [fieldName]: newArray }
		}
	}

	const formData = new FormData()
	const newData = validateData(rest)(addresses, 'addresses')
	Object.keys(newData).forEach(key => formData.append(key, newData[key]))
	newData?.addresses?.forEach((address, index) => {
		for (let key in address) {
			formData.append(`addresses[${index}][${key}]`, address[key])
		}
	})

	if (contactType !== 'organisation') {
		relationship_ids.forEach(relationship_id => {
			formData.append(`relationship_ids[]`, relationship_id)
		})
		dbsses.forEach((dbs, index) => {
			for (let key in dbs) {
				formData.append(`dbsses[${index}][${key}]`, dbs[key])
			}
		})
	}

	const url = {
		person: 'persons',
		organisation: 'organisations',
	}
	return axios.post(`${url[contactType]}/edit`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

import React from 'react'
import { SnapshotForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { snapshotActions, snapshotSelectors } from '@Store/admin'
import { useHistory } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateSnapshot = ({ onOpenDescriptionModal, parentURL }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const snapshot = useSelector(snapshotSelectors.snapshot)
  const isLoading = useSelector(snapshotSelectors.isLoading)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).snapshots
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  const onSubmit = data => {
    dispatch(snapshotActions.create({ data, history }))
  }

  const onCancel = () => {
    history.goBack()
  }

  if (!dataCreationPermissions.snapshots) return <Redirect to={parentURL} />
  return (
    <SnapshotForm
      canEdit
      isNew
      initialValue={snapshot}
      onSave={onSubmit}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
      onCancel={onCancel}
    />
  )
}

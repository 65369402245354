import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { booleanOptions } from '@Root/configs'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, TextInput, Select, TextArea, DatePicker } from '@Root/components'
import { validateData } from '@Root/helpers'

export class StudiesProgrammeForm extends Component {
  constructor(props) {
    super(props)
    const { initialIsEditable, initialData } = props
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      id: initialData.id,
      programme_name: initialData.programme_name,
      programme_type: initialData.programme_type,
      programme_mode: initialData.programme_mode,
      subject_code: initialData.subject_code,
      programme_years: initialData.programme_years,
      course_delivery_mode_type: initialData.course_delivery_mode_type,
      course_delivery_type: initialData.course_delivery_type,
      HEFCE_code: initialData.HEFCE_code,
      HEFCE_code_Courseaim: initialData.HEFCE_code_Courseaim,
      available: initialData.available,
      description: initialData.description,
      course_delivery_start_date: initialData.course_delivery_start_date,
      designated_course: initialData.designated_course,
      expected_end_date: initialData.expected_end_date,
      course_delivery_reference: initialData.course_delivery_reference,
      course_delivery_title: initialData.course_delivery_title,
      course_delivery_role: initialData.course_delivery_role,
      course_delivery_venue: initialData.course_delivery_venue,
      //course_session: initialData.course_session, //??
      //module_id: initialData.course_session, //??
      credit_value_of_module: initialData.credit_value_of_module,
      uuid: initialData.uuid,
      program_awarding_body_id: initialData.program_awarding_body_id,
      course_identifier: initialData.course_identifier,
      bittm_id: initialData.bittm_id,
      clsdcrs_id: initialData.clsdcrs_id,
      flexible_indicator_id: initialData.flexible_indicator_id,
      pre_requisite_id: initialData.pre_requisite_id,
      sandwich_id: initialData.sandwich_id,
      ttcid_id: initialData.ttcid_id,
      qualification_id: initialData.qualification_id,
    }
  }

  formIsValid = () => {
    const { programme_name, programme_type, course_identifier, course_delivery_title, pre_requisite_id, qualification_id, expected_end_date } = this.state
    if (!programme_name) {
      this.showError('programme_name', 'Required')
      return false
    }
    if (!programme_type) {
      this.showError('programme_type', 'Required')
      return false
    }
    if (!course_identifier) {
      this.showError('course_identifier', 'Required')
      return false
    }
    if (!course_delivery_title) {
      this.showError('course_delivery_title', 'Required')
      return false
    }
    if (!pre_requisite_id) {
      this.showError('pre_requisite_id', 'Required')
      return false
    }
    if (!qualification_id) {
      this.showError('qualification_id', 'Required')
      return false
    }
    if (validateData([expected_end_date])) {
      return false
    }

    return true
  }

  showError = (input, message) => {
    this.setState({ error: { input, message } })
  }

  errorMessage = input => {
    const { error } = this.state
    return error && error.input === input ? error.message : null
  }

  setInitialState = () => {
    const { initialData } = this.props
    this.setState({
      isEditable: false,
      error: null,
      id: initialData.id,
      programme_name: initialData.programme_name,
      programme_type: initialData.programme_type,
      programme_mode: initialData.programme_mode,
      //subject_code: initialData.subject_code,
      programme_years: initialData.programme_years,
      //course_delivery_mode_type: initialData.course_delivery_mode_type,
      //course_delivery_type: initialData.course_delivery_type,
      //HEFCE_code: initialData.HEFCE_code,
      //HEFCE_code_Courseaim: initialData.HEFCE_code_Courseaim,
      available: initialData.available,
      description: initialData.description,
      //course_delivery_start_date: initialData.course_delivery_start_date,
      //designated_course: initialData.designated_course,
      expected_end_date: initialData.expected_end_date,
      //course_delivery_reference: initialData.course_delivery_reference,
      course_delivery_title: initialData.course_delivery_title,
      //course_delivery_role: initialData.course_delivery_role,
      //course_delivery_venue: initialData.course_delivery_venue,
      // course_session: initialData.course_session, //??
      // module_id: initialData.course_session,//??
      credit_value_of_module: initialData.credit_value_of_module,
      uuid: initialData.uuid,
      program_awarding_body_id: initialData.program_awarding_body_id,
      course_identifier: initialData.course_identifier,
      bittm_id: initialData.bittm_id,
      clsdcrs_id: initialData.clsdcrs_id,
      flexible_indicator_id: initialData.flexible_indicator_id,
      pre_requisite_id: initialData.pre_requisite_id,
      sandwich_id: initialData.sandwich_id,
      ttcid_id: initialData.ttcid_id,
      qualification_id: initialData.qualification_id,
    })
  }

  handleClickCancelButton = () => {
    this.setInitialState()
  }

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true })
      },
      () => {
        !this.isUnmounted && this.setState({ isSaving: false })
      }
    )
  }

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return
    const { state } = this
    this.setState({ isSaving: true })
    await this.props.saveHandler(
      {
        programme_name: state.programme_name,
        programme_type: state.programme_type,
        programme_mode: state.programme_mode,
        //HEFCE_code: state.HEFCE_code,
        //subject_code: state.subject_code,
        //HEFCE_code_Courseaim: state.HEFCE_code_Courseaim,
        programme_years: state.programme_years,
        available: state.available,
        description: state.description,
        //course_delivery_mode_type: state.course_delivery_mode_type,
        //course_delivery_type: state.course_delivery_type,
        //course_delivery_start_date: state.course_delivery_start_date,
        //designated_course: state.designated_course,
        expected_end_date: state.expected_end_date,
        //course_delivery_reference: state.course_delivery_reference,
        course_delivery_title: state.course_delivery_title,
        //course_delivery_role: state.course_delivery_role,
        //course_delivery_venue: state.course_delivery_venue,
        //course_session: state.course_session,//??
        //module_id: state.course_session,//??
        program_awarding_body_id: state.program_awarding_body_id,
        course_identifier: state.course_identifier,
        bittm_id: state.bittm_id,
        clsdcrs_id: state.clsdcrs_id,
        flexible_indicator_id: state.flexible_indicator_id,
        pre_requisite_id: state.pre_requisite_id,
        sandwich_id: state.sandwich_id,
        ttcid_id: state.ttcid_id,
        qualification_id: state.qualification_id,
      },
      () => {
        !this.isUnmounted && this.setState({ isEditable: false })
      }
    )
    !this.isUnmounted && this.setState({ isSaving: false })
  }

  onOpenDescriptionModal = (description, title) => {
    const { openDescriptionModal } = this.props
    openDescriptionModal(description, title)
  }

  onToggleEditable = () => {
    this.setState(prevState => ({ isEditable: !prevState.isEditable }))
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  render() {
    const {
      isEditable,
      isSaving,
      programme_name,
      programme_mode,
      //subject_code,
      programme_years,
      programme_type,
      //HEFCE_code,
      //HEFCE_code_Courseaim,
      available,
      description,
      //course_delivery_mode_type,
      //course_delivery_type,
      //course_delivery_start_date,
      //designated_course,
      expected_end_date,
      //course_delivery_reference,
      course_delivery_title,
      //course_delivery_role,
      //course_delivery_venue,
      module_id,
      uuid,
      program_awarding_body_id,
      course_identifier,
      bittm_id,
      clsdcrs_id,
      flexible_indicator_id,
      pre_requisite_id,
      sandwich_id,
      ttcid_id,
      qualification_id,
      id,
    } = this.state

    const { title, options, descriptions, permissions, isSuccess } = this.props
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage, onOpenDescriptionModal, onToggleEditable } = this
    const selectedModule = options?.modules?.find(module => module.value === module_id)
    const { onRead: titleOnRead = '', onEdit: titleOnEdit = '' } = title

    const { form, fields } = permissions

    return (
      <div className={classes.StudiesProgrammeForm}>
        <FormWrapper
          buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
          buttonsNames={[{ button: 'delete', name: 'Delete this programme' }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
          canEdit={form?.edit}
          isEditable={isEditable}
          readTitle={titleOnRead}
          editTitle={titleOnEdit}
          clickToggleEditModeButtonHandler={onToggleEditable}
          idForm={id ? `studies_programme_${id}` : null}
          isSuccess={!isEditable && isSaving}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Programme Name'
                  hasAsterisk={isEditable}
                  description={descriptions.programme_name}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={programme_name}
                  changeHandler={programme_name => this.setState({ programme_name })}
                  placeholder='Programme Name'
                  isDisabled={!isEditable || !fields?.programme_name?.edit}
                  error={errorMessage('programme_name')}
                  isView={fields.programme_name?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Programme Type'
                  hasAsterisk={isEditable}
                  description={descriptions.programme_type}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.program_types}
                  value={programme_type}
                  changeHandler={value => this.setState({ programme_type: value })}
                  isDisabled={!isEditable || !fields?.programme_type?.edit}
                  error={errorMessage('programme_type')}
                  isView={fields.programme_type?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Programme Mode' description={descriptions.programme_mode} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.programme_modes}
                  value={programme_mode}
                  changeHandler={value => this.setState({ programme_mode: value })}
                  isDisabled={!isEditable || !fields?.programme_mode?.edit}
                  isView={fields.programme_mode?.view}
                />
              </div>
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='Subject Code' description={descriptions.subject_code} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={subject_code}
                  changeHandler={subject_code => this.setState({ subject_code })}
                  placeholder='Subject Code'
                  isDisabled={!isEditable || !fields?.subject_code?.edit}
                  isView={fields.subject_code?.view}
                />
              </div> */}
              <div className={classes.inputWrapper}>
                <InputLabel text='Programme Years' description={descriptions.programme_years} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.programme_years}
                  value={programme_years}
                  changeHandler={value => this.setState({ programme_years: value })}
                  isDisabled={!isEditable || !fields?.programme_years?.edit}
                  isView={fields.programme_years?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Available' description={descriptions.available} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={booleanOptions}
                  value={available}
                  changeHandler={available => this.setState({ available })}
                  isDisabled={!isEditable || !fields?.available?.edit}
                  isView={fields.available?.view}
                />
              </div>
              <div className={classes.textareaWrapper}>
                <InputLabel text='Description' description={descriptions.description} onOpenDescription={onOpenDescriptionModal} />
                <TextArea
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260, height: 90 }}
                  value={description}
                  changeHandler={value => this.setState({ description: value })}
                  isDisabled={!isEditable || !fields?.description?.edit}
                  isView={fields.description?.view}
                />
              </div>
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='Course Delivery Mode Type' description={descriptions.course_delivery_mode_type} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.course_delivery_mode_type}
                  value={course_delivery_mode_type}
                  changeHandler={value => this.setState({ course_delivery_mode_type: value })}
                  isDisabled={!isEditable || !fields?.course_delivery_mode_type?.edit}
                  isView={fields.course_delivery_mode_type?.view}
                />
              </div> */}
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='Course Delivery Reference' description={descriptions.course_delivery_reference} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={course_delivery_reference}
                  placeholder='Course Delivery Reference'
                  changeHandler={course_delivery_reference => this.setState({ course_delivery_reference })}
                  isDisabled={!isEditable || !fields?.course_delivery_reference?.edit}
                  isView={fields.course_delivery_reference?.view}
                />
              </div> */}
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Course Title'
                  hasAsterisk={isEditable}
                  description={descriptions.course_delivery_title}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={course_delivery_title}
                  placeholder='Course Title'
                  changeHandler={course_delivery_title => this.setState({ course_delivery_title })}
                  isDisabled={!isEditable || !fields?.course_delivery_title?.edit}
                  isView={fields.course_delivery_title?.view}
                  error={errorMessage('course_delivery_title')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Programme Import ID' description={descriptions.uuid} onOpenDescription={onOpenDescriptionModal} />
                <TextInput classNames={['borderless']} style={{ width: 260 }} value={uuid} isDisabled />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Pre-requisite'
                  hasAsterisk={isEditable}
                  description={descriptions.pre_requisite_id}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.pre_requisites}
                  value={pre_requisite_id}
                  changeHandler={value => this.setState({ pre_requisite_id: value })}
                  isDisabled={!isEditable || !fields?.pre_requisite_id?.edit}
                  isView={fields.pre_requisite_id?.view}
                  error={errorMessage('pre_requisite_id')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Sandwich' description={descriptions.sandwich_id} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.sandwichs}
                  value={sandwich_id}
                  changeHandler={value => this.setState({ sandwich_id: value })}
                  isDisabled={!isEditable || !fields?.sandwich_id?.edit}
                  isView={fields.sandwich_id?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Teacher training course ident.'
                  hasAsterisk={isEditable}
                  description={descriptions.ttcid_id}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.ttcids}
                  value={ttcid_id}
                  changeHandler={value => this.setState({ ttcid_id: value })}
                  isDisabled={!isEditable || !fields?.ttcid_id?.edit}
                  isView={fields.ttcid_id?.view}
                  error={errorMessage('ttcid_id')}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='Course Delivery Type' description={descriptions.course_delivery_type} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.course_delivery_type}
                  value={course_delivery_type}
                  changeHandler={value => this.setState({ course_delivery_type: value })}
                  isDisabled={!isEditable || !fields?.course_delivery_type?.edit}
                  isView={fields.course_delivery_type?.view}
                />
              </div> */}
              {/* <div className={classes.inputWrapper}>
                <InputLabel
                  text='Course delivery start date'
                  description={descriptions.course_delivery_start_date}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <DatePicker
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={course_delivery_start_date}
                  changeHandler={course_delivery_start_date => this.setState({ course_delivery_start_date })}
                  isDisabled={!isEditable || !fields?.course_delivery_start_date?.edit}
                  isView={fields.course_delivery_start_date?.view}
                />
              </div> */}
              {/* <div className={classes.inputWrapper}>
                <InputLabel
                  text='Designated course'
                  hasAsterisk={isEditable}
                  description={descriptions.designated_course}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.designated_course}
                  value={designated_course}
                  changeHandler={value => this.setState({ designated_course: value })}
                  isDisabled={!isEditable || !fields?.designated_course?.edit}
                  isView={fields.designated_course?.view}
                />
              </div> */}
              <div className={classes.inputWrapper}>
                <InputLabel text='Expected end date' description={descriptions.expected_end_date} onOpenDescription={onOpenDescriptionModal} />
                <DatePicker
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={expected_end_date}
                  changeHandler={expected_end_date => this.setState({ expected_end_date })}
                  isDisabled={!isEditable || !fields?.expected_end_date?.edit}
                  isView={fields.expected_end_date?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Qualification identifier'
                  hasAsterisk={isEditable}
                  description={descriptions.qualification_id}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.qualifications}
                  value={qualification_id}
                  changeHandler={value => this.setState({ qualification_id: value })}
                  isDisabled={!isEditable || !fields?.qualification_id?.edit}
                  isView={fields.qualification_id?.view}
                  error={errorMessage('qualification_id')}
                />
              </div>
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='Course delivery role' description={descriptions.course_delivery_role} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={course_delivery_role}
                  changeHandler={course_delivery_role => this.setState({ course_delivery_role })}
                  placeholder='Course delivery role'
                  isDisabled={!isEditable || !fields?.course_delivery_role?.edit}
                  isView={fields.course_delivery_role?.view}
                />
              </div> */}
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='Course delivery venue' description={descriptions.course_delivery_venue} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.venues}
                  value={course_delivery_venue}
                  changeHandler={value => this.setState({ course_delivery_venue: value })}
                  isDisabled={!isEditable || !fields?.course_delivery_venue?.edit}
                  isView={fields.course_delivery_venue?.view}
                />
              </div> */}
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='HEFCE Code' description={descriptions.HEFCE_code} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={HEFCE_code}
                  changeHandler={HEFCE_code => this.setState({ HEFCE_code })}
                  placeholder='HEFCE Code'
                  isDisabled={!isEditable || !fields?.HEFCE_code?.edit}
                  isView={fields.HEFCE_code?.view}
                />
              </div> */}
              {/* <div className={classes.inputWrapper}>
                <InputLabel text='HEFCE Code - COURSEAIM' description={descriptions.HEFCE_code_Courseaim} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={HEFCE_code_Courseaim}
                  changeHandler={HEFCE_code_Courseaim => this.setState({ HEFCE_code_Courseaim })}
                  placeholder='HEFCE Code - COURSEAIM'
                  isDisabled={!isEditable || !fields?.HEFCE_code_Courseaim?.edit}
                  isView={fields.HEFCE_code_Courseaim?.view}
                />
              </div>{' '} */}
              {selectedModule && selectedModule.credit && (
                <div className={classes.inputWrapper}>
                  <InputLabel text='Credit value of module' description={descriptions.credit} onOpenDescription={onOpenDescriptionModal} />
                  <TextInput classNames={!isEditable ? ['borderless'] : []} style={{ width: 260 }} value={String(selectedModule.credit)} isDisabled />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text='Awarding body' description={descriptions.program_awarding_body_id} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.program_awarding_bodies}
                  value={program_awarding_body_id}
                  changeHandler={value => this.setState({ program_awarding_body_id: value })}
                  isDisabled={!isEditable || !fields?.program_awarding_body_id?.edit}
                  isView={fields.program_awarding_body_id?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Course identifier'
                  hasAsterisk={isEditable}
                  description={descriptions.course_identifier}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={course_identifier}
                  changeHandler={course_identifier => this.setState({ course_identifier })}
                  placeholder='Course identifier'
                  isDisabled={!isEditable || !fields?.course_identifier?.edit}
                  isView={fields.course_identifier?.view}
                  error={errorMessage('course_identifier')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Bilingual ITT marker' description={descriptions.bittm_id} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.bittms}
                  value={bittm_id}
                  changeHandler={value => this.setState({ bittm_id: value })}
                  isDisabled={!isEditable || !fields?.bittm_id?.edit}
                  isView={fields.bittm_id?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Closed course' description={descriptions.clsdcrs_id} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.clsdcrses}
                  value={clsdcrs_id}
                  changeHandler={value => this.setState({ clsdcrs_id: value })}
                  isDisabled={!isEditable || !fields?.clsdcrs_id?.edit}
                  isView={fields.clsdcrs_id?.view}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Fully flexible indicator' description={descriptions.flexible_indicator_id} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options?.flexible_indicators}
                  value={flexible_indicator_id}
                  changeHandler={value => this.setState({ flexible_indicator_id: value })}
                  isDisabled={!isEditable || !fields?.flexible_indicator_id?.edit}
                  isView={fields.flexible_indicator_id?.view}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    )
  }
}

StudiesProgrammeForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string,
  }),
  initialData: PropTypes.shape({
    programme_name: PropTypes.string,
    programme_type: PropTypes.number,
    programme_mode: PropTypes.number,
    subject_code: PropTypes.string,
    programme_years: PropTypes.number,
    course_delivery_mode_type: PropTypes.number,
    course_delivery_type: PropTypes.number,
    HEFCE_code: PropTypes.string,
    HEFCE_code_Courseaim: PropTypes.string,
    available: PropTypes.bool,
    description: PropTypes.string,
    course_delivery_start_date: PropTypes.string,
    designated_course: PropTypes.number,
    expected_end_date: PropTypes.string,
    qualification_identifier: PropTypes.string,
    course_delivery_reference: PropTypes.string,
    course_delivery_title: PropTypes.string,
    course_delivery_role: PropTypes.string,
    course_delivery_venue: PropTypes.number,
    course_session: PropTypes.string,
    module_id: PropTypes.number,
    credit_value_of_module: PropTypes.number,
    uuid: PropTypes.string,
    awarding_body: PropTypes.string,
    ttcid_id: PropTypes.number,
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
}

StudiesProgrammeForm.defaultProps = {
  permissions: [],
  title: {
    onRead: '',
    onEdit: '',
  },
  initialData: {
    programme_name: null,
    programme_type: null,
    programme_mode: null,
    subject_code: null,
    programme_years: null,
    course_delivery_mode_type: null,
    course_delivery_type: null,
    HEFCE_code: null,
    HEFCE_code_Courseaim: null,
    available: true,
    description: null,
    course_delivery_start_date: null,
    designated_course: null,
    expected_end_date: null,
    qualification_identifier: null,
    course_delivery_reference: null,
    course_delivery_title: null,
    course_delivery_role: null,
    course_delivery_venue: null,
    course_session: null,
    module_id: null,
    credit_value_of_module: null,
    uuid: null,
    awarding_body: null,
    ttcid_id: 8,
  },
  initialIsEditable: false,
  options: null,
  buttons: ['cancel', 'delete', 'save'],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
}

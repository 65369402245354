import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './fieldConstructor.types'
import { actions } from './fieldConstructor.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* getFields({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.fieldConstructor.getAll, payload)
    const fields = data.data
    const permissions = data.permissions
    const { from, to, per_page: perPage, last_page: lastPage, total } = data.meta
    const paginationData = {
      from,
      to,
      perPage,
      lastPage,
      total,
    }
    yield put(actions.setFields(fields))
    yield put(actions.setPermissions(permissions))
    yield put(actions.setPaginationInformation(paginationData))
  } catch (error) {}
  yield put(actions.toggleLoading(false))
}

function* getField({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.fieldConstructor.get, payload.id)
    yield put(actions.setField(data.data))
  } catch (error) {}
  yield put(actions.toggleLoading(false))
}

function* createField({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, ...rest } = payload
  try {
    const { data } = yield call(API.admin.fieldConstructor.create, rest)
    yield put(snackbarActions.setSuccessNotification({ text: 'The field has been created successfully!' }))
    history.push(`/home/admin/additional-fields`)
  } catch (error) {
    const entities = Object.entries(error.response.data.errors)
    const errors = entities.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1][0] }), {})
    yield put(actions.setField(rest))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleLoading(false))
}

function* editField({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, ...rest } = payload
  try {
    const { data } = yield call(API.admin.fieldConstructor.edit, rest.id, rest)
    yield put(actions.setField(data))
    history.push('/home/admin/additional-fields')
    yield put(snackbarActions.setSuccessNotification({ text: 'The field has been saved successfully!' }))
  } catch (error) {
    const entities = Object.entries(error.response.data.errors)
    const errors = entities.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1][0] }), {})
    yield put(actions.setErrors(errors))
    yield put(actions.setField(payload))
  }
  yield put(actions.toggleLoading(false))
}

function* removeField({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, pagination } = payload
  try {
    yield call(API.admin.fieldConstructor.remove, id)
    yield put(actions.getFields(pagination))
    yield put(snackbarActions.setSuccessNotification({ text: 'The field has been deleted successfully!' }))
  } catch (error) {}
}

function* getFieldsForContact({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactId } = payload
  try {
    const { data } = yield call(API.admin.fieldConstructor.getFieldsForContact, contactId)
    const formattedData = data.data.map(item => {
      return {
        ...item,
        options: item.options.map(({ id, label }) => {
          return { value: id, label }
        }),
      }
    })
    yield put(actions.setFieldsForContact(formattedData))
  } catch (error) {}
  yield put(actions.toggleLoading(false))
}

function* saveOrder({ payload }) {
  try {
    yield call(API.admin.fieldConstructor.saveOrder, payload)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_FIELDS, getFields),
    takeEvery(types.GET_FIELD, getField),
    takeEvery(types.EDIT_FIELD, editField),
    takeEvery(types.CREATE_FIELD, createField),
    takeEvery(types.REMOVE_FIELD, removeField),
    takeEvery(types.GET_FIELDS_FOR_CONTACT, getFieldsForContact),
    takeEvery(types.SAVE_ORDER, saveOrder),
  ])
}

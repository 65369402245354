import React, { useEffect } from 'react'
import { EngagementDetailsForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { engagementActions, engagementSelectors, fieldTooltipManagerSelectors } from '@Root/store'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateEngagement = ({ contactId, onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const errors = useSelector(engagementSelectors.errors)
  const options = useSelector(engagementSelectors.options)
  const engagement = useSelector(engagementSelectors.engagement)
  const isLoading = useSelector(engagementSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const permissions = useSelector(engagementSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagements

  useEffect(() => {
    return () => {
      dispatch(engagementActions.set({}))
    }
  }, [dispatch])

  const onCreate = data => {
    const payload = { ...data, student_id: Number(contactId) }
    dispatch(engagementActions.create({ data: payload, history }))
  }

  const onCancel = () => {
    history.goBack()
    dispatch(engagementActions.clearData())
  }

  if (!dataCreationPermissions.engagements) return <Redirect to={'/home/studies/engagements'} />

  return (
    <div>
      <EngagementDetailsForm
        isNew
        onSave={onCreate}
        onCancel={onCancel}
        errorsFields={errors}
        options={options}
        initialValue={engagement}
        isLoading={isLoading}
        permissions={permissions}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

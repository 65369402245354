import * as types from './programme.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	get: payload => ({
		type: types.GET_PROGRAMME,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_PROGRAMME,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_PROGRAMME,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_PROGRAMME,
		payload,
	}),
	set: payload => ({
		type: types.SET_PROGRAMME,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: () => ({
		type: types.GET_DYNAMIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	getProgrammesOptions: () => ({
		type: types.GET_PROGRAMMES_OPTIONS,
	}),
	setProgrammesOptions: payload => ({
		type: types.SET_PROGRAMMES_OPTIONS,
		payload,
	}),
	getProgrammesPostgardOptions: () => ({
		type: types.GET_PROGRAMMES_POSTGARD_OPTIONS
	}),
	setProgrammesPostgardOptions: payload => ({
		type: types.SET_PROGRAMMES_POSTGARD_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearPermissions: () => ({
		type: types.CLEAR_PERMISSIONS,
	}),
}

import React from 'react'
import styles from './style.module.scss'
import { createDataConfig, regExpressions } from '@Root/configs'
import { TableBlock, VerticalTabs } from '@Root/components'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { API } from '@Root/API'

export const DetailInformation = ({ engagementId, contactId, permissions }) => {
	const defaultURL = `/home/studies/engagements/${contactId}/${engagementId}`
	const history = useHistory()
	const collaborativeProvisionTableData = () => {
		const columns = () => [
			{
				name: 'Collaboration ID',
				field: 'id',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'id_link',
			},
			{
				name: 'Collaborative provision type',
				field: 'colprovtypeid',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Partner NUMHUS',
				field: 'partnernumhus',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Partner SID',
				field: 'partnersid',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Partner UKPRN',
				field: 'partnerukprn',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Collaborative Provision', [], columns(), 'getModules', 'collaborativeProvision', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.collaborativeProvision.getAll(engagementId, params),
			clickLinkHandlers: {
				'Collaboration ID': row => history.push(`${defaultURL}/collaborative-provision/${row.id}`),
			},
		}
	}

	const entryProfileTableData = () => {
		const columns = () => [
			{
				name: 'Entry Profile ID',
				field: 'id',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'id_link',
			},
			{
				name: 'Access programme',
				field: 'access_programme',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'APEL credits',
				field: 'apel_credits',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Care leaver',
				field: 'care_leaver',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Credits points counted towards study',
				field: 'crdptstu',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Credit scheme',
				field: 'credit_scheme',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Dependant',
				field: 'dependant',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Estranged student',
				field: 'estranged_student',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Highest qualification on entry',
				field: 'highestqualoe',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Marital status',
				field: 'marital_status',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Parental education',
				field: 'parental_education',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Country of permanent address',
				field: 'permanent_address',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Postcode of permanent home address',
				field: 'postcode_permanent_address',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Previous provider',
				field: 'previous_provider',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Religious background',
				field: 'religious_background',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Socio economic classification',
				field: 'socio_economic_classification',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Standard occupational classification 2010',
				field: 'socio_economic_classification',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'UCAS scheme code',
				field: 'ucas_scheme_code',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Year left last provider',
				field: 'year_left_last_provider',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Entry Profile', [], columns(), 'getModules', 'collaborativeProvision', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.entryProfile.getAll(engagementId, params),
			clickLinkHandlers: {
				'Entry Profile ID': row => history.push(`${defaultURL}/entry-profile/${row.id}`),
			},
		}
	}

	const leaverTableData = () => {
		const columns = () => [
			{
				name: 'Leaver ID',
				field: 'id',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'id_link',
			},
			{
				name: 'Engagement end date',
				field: 'engagement_end_date',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Intended destination',
				field: 'intended_destination',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Reason for Engagement ending',
				field: 'ending_reason',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Leaver', [], columns(), 'getModules', 'engagementLeaver', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.leaver.getAll(engagementId, params),
			clickLinkHandlers: {
				'Leaver ID': row => history.push(`${defaultURL}/leaver/${row.id}`),
			},
		}
	}

	const qualificationAwardedTableData = () => {
		const columns = () => [
			{
				name: 'Qualification awarded identifier',
				field: 'qualification_awarded_identifier',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'qualification_awarded_identifier_link',
			},
			{
				name: 'Qualification identifier',
				field: 'qualification_identifier',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Qualification result',
				field: 'qualification_result',
				searchInputType: 'textInput',
				cellType: 'link',
			},
			{
				name: 'Thesis title',
				field: 'thesis_title',
				searchInputType: 'textInput',
				cellType: 'link',
			},
		]
		return {
			name: 'Module Delivery Roles',
			...createDataConfig('Qualification awarded', [], columns(), 'getModules', 'qualificationAwarded', false, false, false, false, false, false),
			fetchDataHandler: async params => API.studies.engagement.qualificationAward.getAll(engagementId, params),
			clickLinkHandlers: {
				'Qualification awarded identifier': row => history.push(`${defaultURL}/qualification-award/${row.id}`),
			},
		}
	}

	const studentAccreditationAimTableData = () => {
		const columns = () => [
			{
				name: 'Accreditation ID',
				field: 'id',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'id_link',
			},
			{
				name: 'Accreditation identifier',
				field: 'accreditation',
				searchInputType: 'textInput',
				cellType: 'link',
			},
		]
		return {
			...createDataConfig('Student accreditation aim', [], columns(), 'getModules', 'studentAccreditationAim', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.accreditationAim.getAll(engagementId, params),
			clickLinkHandlers: {
				'Accreditation ID': row => history.push(`${defaultURL}/student-accreditation-aim/${row.id}`),
			},
		}
	}

	const studentCourseSession = () => {
		const columns = () => [
			{
				name: 'Name',
				field: 'name',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'name_link',
			},
			{
				name: 'Student ID',
				field: 'student_id',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Programme',
				field: 'programme_name',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'programme_name_link',
			},
			{
				name: 'Academic year',
				field: 'academic_year',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
				rule_suggest: regExpressions.academic_year,
			},
			{
				name: 'Status',
				field: 'status',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Context',
				field: 'context',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Sponsor',
				field: 'sponsor',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Accomm',
				field: 'accommodation',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Programme Manager',
				field: 'management_by',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Ordinand',
				field: 'ordinand',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Institution',
				field: 'institution',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Learning Centre',
				field: 'training_mode',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Diocese',
				field: 'diocese',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Start date (help)',
				field: 'start_date',
				searchInputType: 'textInput',
				cellType: 'text',
				rule_suggest: regExpressions.date,
			},
			{
				name: 'Leaving (help)',
				field: 'leaving_date',
				searchInputType: 'textInput',
				cellType: 'text',
				rule_suggest: regExpressions.date,
			},
			{
				name: 'Finish (help)',
				field: 'finishing_date',
				searchInputType: 'textInput',
				cellType: 'text',
				rule_suggest: regExpressions.date,
			},
			{
				name: 'Credits',
				field: 'credits',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Support',
				field: 'support',
				searchInputType: 'select',
				options: ['Yes', 'No'],
				cellType: 'text',
			},
			{
				name: 'SLC this year',
				field: 'SLC_this_year',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Heapes help',
				field: 'heapes',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Supervisor 1',
				field: 'supervisor_1',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Supervisor 2',
				field: 'supervisor_2',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Supervisor 3',
				field: 'supervisor_3',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Examiner',
				field: 'examiner',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Pastoral Tutor',
				field: 'pastoral_tutor',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Session Year',
				field: 'session_year',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
			{
				name: 'Awarding body',
				field: 'awarding_body',
				searchInputType: 'select',
				options: [],
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Student Course Session', [], columns(), 'getModules', 'engagementStudentCourseSession', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.studentCourseSession.getCourseSessions(engagementId, params),
			clickLinkHandlers: {
				Name: row => history.push(`/home/contacts/students/${row.student_id}`),
				Programme: row => history.push(`/home/studies/programmes/study/${row.student_id}/${row.id}`),
			},
		}
	}

	const studentInitiatives = () => {
		const columns = () => [
			{
				name: 'Student initiative ID',
				field: 'id',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'id_link',
			},
			{
				name: 'Student initiative',
				field: 'type',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Student Initiatives', [], columns(), 'getModules', 'studentInitiatives', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.studentInitiatives.getAll(engagementId, params),
			clickLinkHandlers: {
				'Student initiative ID': row => history.push(`${defaultURL}/student-initiatives/${row.id}`),
			},
		}
	}

	const tables = [
		...(permissions?.collaborative_provision
			? [{ name: 'Collaborative provision', label: 'collaborative-provision', tableInformation: collaborativeProvisionTableData() }]
			: []),
		...(permissions?.student_registration ? [{ name: 'Entry Profile', label: 'entry-profile', tableInformation: entryProfileTableData() }] : []),
		...(permissions?.leaver ? [{ name: 'Leaver ', label: 'leaver', tableInformation: leaverTableData() }] : []),
		...(permissions?.qualification_awarded
			? [{ name: 'Qualification awarded', label: 'qualification-award', tableInformation: qualificationAwardedTableData() }]
			: []),
		...(permissions?.student_accreditation_aim
			? [{ name: 'Student accreditation aim', label: 'student-accreditation-aim', tableInformation: studentAccreditationAimTableData() }]
			: []),
		...(permissions?.student_course_session
			? [{ name: 'Student course session', label: 'student-course-session', tableInformation: studentCourseSession() }]
			: []),
		...(permissions?.student_initiatives ? [{ name: 'Student initiatives', label: 'student-initiatives', tableInformation: studentInitiatives() }] : []),
	]

	return (
		<div className={styles.wrapper}>
			<VerticalTabs tabs={tables} isLink defaultURL={defaultURL} />
			<Switch>
				{tables.map(({ name, tableInformation, label }) => (
					<Route exact={true} key={name} path={`${defaultURL}/${label}`} render={() => <TableBlock title={name} config={tableInformation} />} />
				))}
				<Redirect to={`${defaultURL}/${tables[0]?.label || ''}`} />
			</Switch>
		</div>
	)
}

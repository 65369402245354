import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { roleSelectors, roleActions } from '@Store/studies'
import { StudiesModuleDeliveryRoleForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateCostCenter = ({ onOpenDescriptionModal, defaultURL }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { moduleId, moduleDelId: moduleDeliveryId } = useParams()
	const isLoading = useSelector(roleSelectors.isLoading)
	const staticOptions = useSelector(roleSelectors.options).static
	const role = useSelector(roleSelectors.role)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['module-role']
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

	const onSubmit = payload => {
		dispatch(roleActions.create({ data: { ...payload, module_delivery_id: moduleDeliveryId }, history, moduleId }))
	}

	const onCancel = () => {
		history.push(defaultURL)
	}

	if (!dataCreationPermissions.modules_sub) return <Redirect to={defaultURL} />
	return (
		<StudiesModuleDeliveryRoleForm
			options={staticOptions}
			onSave={onSubmit}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			isLoading={isLoading}
			initialValue={role}
			onCancel={onCancel}
			canEdit
			isNew
			generateId
		/>
	)
}

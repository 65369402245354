import { all, call, put, takeEvery } from 'redux-saga/effects'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import * as types from './additionalInformation.types'
import { API } from '@Root/API'
import { actions } from './additionalInformation.actions'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { contactId } = payload
	try {
		const { data } = yield call(API.contact.additionalInformation.get, contactId)
		yield put(actions.set(data.data))
		yield put(actions.setPermissions(data.permissions))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { contactId, ...rest } = payload
	const data = Object.entries(rest)
	const formattedData = data.map(([id, obj]) => ({ id: Number(id), value: obj.value, type: obj.type }))
	try {
		yield call(API.contact.additionalInformation.edit, contactId, { additional_field: formattedData })
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The additional information has been saved successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
	yield all([takeEvery(types.GET_ADDITIONAL_INFORMATION, get), takeEvery(types.EDIT_ADDITIONAL_INFORMATION, edit)])
}

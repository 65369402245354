import * as types from './admission.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	getApplication: payload => ({
		type: types.GET_APPLICATION,
		payload,
	}),
	setApplication: payload => ({
		type: types.SET_APPLICATION,
		payload,
	}),
	getStaticOptions: payload => ({
		type: types.GET_STATIC_OPTIONS,
		payload,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { switchEmptyArraysToNullsInObjectRecursively } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, MultiSelect } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useToggle } from '@Root/hooks'
import { churchActions, organisationSelectors, trainingFacilityActions, trainingFacilitySelectors } from '@Store/contact/organisation'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Store/index'

const PARENT_URL = '/home/contacts/all-contacts'
const relationShip = 'Training Facility'
const titles = { onRead: 'Training Facility Details', onEdit: 'Edit Training Facility Details' }
export const TrainingFacilityForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, toggleEditable] = useToggle(false)
  const [data, setData] = useState({})
  const relationships = useSelector(organisationSelectors.relationships)
  const trainingFacility = useSelector(trainingFacilitySelectors.trainingFacility)
  const isLoading = useSelector(trainingFacilitySelectors.isLoading)
  const isSuccess = useSelector(trainingFacilitySelectors.isSuccess)
  const permissions = useSelector(trainingFacilitySelectors.permissions)
  const isPermissionsLoaded = useSelector(trainingFacilitySelectors.isPermissionsLoaded)
  const staticOptions = useSelector(trainingFacilitySelectors.options).static
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).organisation['training-facilities']
  const contactPermissions = useSelector(contactSelectors.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(trainingFacilityActions.getStaticOptions())
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'organisation', entity_sub_type: 'training-facilities' }))
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      dispatch(trainingFacilityActions.get({ contactId }))
      dispatch(permissionsActions.getMain({ entity: 'training_facility', contactId: Number(contactId) }))
    }
  }, [dispatch, contactId])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (trainingFacility) setData(trainingFacility)
  }, [trainingFacility])

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onCancel = () => {
    setData(trainingFacility)
    toggleEditable()
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationShip}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationShip).value
        dispatch(churchActions.remove({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = () => {
    const modifiedData = switchEmptyArraysToNullsInObjectRecursively(data)
    dispatch(trainingFacilityActions.edit({ ...modifiedData, id: contactId }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!contactPermissions.tabs.training_facility) return <Redirect to={PARENT_URL} />
  const { form, fields } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', 'save'] : []} //...(form?.delete ? ['delete'] : [])
      //buttonsNames={[{ button: 'delete', name: 'Delete this training facility' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={data?.id ? `training_facility_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Owners' description={descriptions.organisation_id} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 150 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ['borderless'] : []}
              inputStyle={{ width: 260 }}
              options={staticOptions.users}
              values={data.user_ids}
              changeHandler={value => handleChangeInput('user_ids', value)}
              isDisabled={!isEditable || !fields.user_ids?.edit}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  )
}

TrainingFacilityForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    user_ids: PropTypes.arrayOf(PropTypes.number),
    institution_ids: PropTypes.arrayOf(PropTypes.number),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

TrainingFacilityForm.defaultProps = {
  permissions: [],
  initialData: {
    user_ids: [],
    institution_ids: [],
  },
  options: [],
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
}

import React from 'react'
import styles from './style.module.scss'
import { UserIcon } from '@Root/assets'
import { Link } from 'react-router-dom'

export const UserAvatar = ({ src, style, path }) => {
	return (
		<Link to={path}>
			<img src={src || UserIcon} alt='' className={styles.img} style={style} />
		</Link>
	)
}

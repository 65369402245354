import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './rule.types'
import { errorMessage } from '@Root/helpers'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* create({ payload }) {
	try {
		yield call(API.table.rule.create, payload)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* edit({ payload }) {
	try {
		yield call(API.table.rule.edit, payload)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* remove({ payload }) {
	try {
		yield call(API.table.rule.remove, payload)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

export function* rootSaga() {
	yield all([takeEvery(types.CREATE_RULE, create), takeEvery(types.EDIT_RULE, edit), takeEvery(types.DELETE_RULE, remove)])
}

import * as types from './contact.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_CONTACT,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_CONTACT,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_CONTACT,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_CONTACT,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: () => ({
		type: types.GET_DYNAMIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setContactType: payload => ({
		type: types.SET_CONTACT_TYPE,
		payload,
	}),
	setError: payload => ({
		type: types.SET_ERROR,
		payload,
	}),
	setMoodleLinks: payload => ({
		type: types.SET_MOODLE_LINKS,
		payload,
	}),
	setPerson: payload => ({
		type: types.SET_PERSON,
		payload,
	}),
	setOrganisation: payload => ({
		type: types.SET_ORGANISATION,
		payload,
	}),
	setInformation: payload => ({
		type: types.SET_INFORMATION,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearPermissions: () => ({
		type: types.CLEAR_PERMISSIONS,
	}),
}

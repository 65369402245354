import React, { useEffect, useState } from 'react'
import { Error, FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from '@Root/store'
import { fieldConstructorActions, fieldConstructorSelectors } from '@Store/admin'
import { useError } from '@Root/hooks'
import { additionalInformationActions, additionalInformationSelectors } from '@Store/contact/additionalInformation'
import { Redirect } from 'react-router'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
const titles = { onRead: 'Additional fields Details', onEdit: 'Edit Additional fields' }
export const AdditionalFieldsForm = ({ contactId }) => {
  const dispatch = useDispatch()
  const [isEditable, setIsEditable] = useState(false)
  const [data, setData] = useState(null)
  const { errors, setError, onClear: onClearError } = useError()
  const additionalInformation = useSelector(additionalInformationSelectors.formattedAdditionalInformation)
  const fields = useSelector(fieldConstructorSelectors.contactFields)
  const isLoading = useSelector(additionalInformationSelectors.isLoading)
  const permissions = useSelector(additionalInformationSelectors.permissions)
  const isSuccess = useSelector(additionalInformationSelectors.isSuccess)
  const contactPermissions = useSelector(contactSelectors.permissions)

  const onChangeValue = field => value => {
    setData({ ...data, [field]: { ...data[field], value } })
  }

  const validateForm = () => {
    return fields.some(({ is_required, id }) => {
      const typeById = data[id].type
      const valueById = data[id].value
      if (typeById === 'multiCheckbox') return false
      if (!Array.isArray(valueById)) {
        if (is_required && !valueById) {
          setError(id, 'Required')
          return true
        }
      }
      if (Array.isArray(valueById)) {
        if (is_required && valueById.length <= 0) {
          setError(id, 'Required')
          return true
        }
      }
      onClearError()
      return false
    })
  }

  const onSave = () => {
    if (!validateForm()) {
      dispatch(additionalInformationActions.edit({ ...data, contactId }))
    }
  }

  const onCancel = () => {
    setData(additionalInformation)
    onClearError()
    setIsEditable(false)
  }

  const toggleEditMode = () => {
    setIsEditable(prevState => !prevState)
  }

  useEffect(() => {
    if (contactId) {
      dispatch(fieldConstructorActions.getFieldsForContact({ contactId }))
      dispatch(additionalInformationActions.get({ contactId }))
    }
  }, [contactId, dispatch])

  useEffect(() => {
    if (additionalInformation) setData(additionalInformation)
  }, [additionalInformation])

  useEffect(() => {
    if (isSuccess) setIsEditable(false)
  }, [isSuccess])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const { form } = permissions
  if (!contactPermissions.tabs?.additional_field) return <Redirect to={PARENT_URL} />
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', 'save'] : []}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={onCancel}
      clickSaveButtonHandler={onSave}
      canEdit={fields.length > 0 ? form?.edit : false}
      clickToggleEditModeButtonHandler={toggleEditMode}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={contactId ? `additional_fields_details_${contactId}` : null}
      isSuccess={isSuccess}
    >
      <div className={styles.grid}>
        <div className={styles.row}>
          {fields.length > 0 && data ? (
            fields.map(({ name, options, description, type, id, is_in_help_text, is_required, edit }) => {
              return (
                <Field
                  label={name}
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: '100%' }}
                  optionsStyle={{ width: '100%' }}
                  value={data[id]?.value}
                  onChange={onChangeValue(id)}
                  placeholder={name}
                  isDisabled={!isEditable ? true : !edit}
                  isRequired={isEditable ? is_required : false}
                  options={options}
                  description={is_in_help_text ? description : null}
                  onOpenDescriptionModal={onOpenDescriptionModal}
                  error={errors[id]}
                  field={type}
                  maxLength={2000}
                  key={id}
                  autoHeight
                />
              )
            })
          ) : (
            <Error>There is no data to manage</Error>
          )}
        </div>
      </div>
    </FormWrapper>
  )
}

AdditionalFieldsForm.propTypes = {
  contactId: PropTypes.number,
}

AdditionalFieldsForm.defaultProps = {
  contact_id: null,
}

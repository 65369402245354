import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isDecimal from "validator/lib/isDecimal";
import { FormWrapper } from "@Root/HOCs";
import { SectionTitle, InputLabel, TextInput, DataListAsync, Select } from "@Root/components";

export class TuitionFeesForm extends Component {
  state = {
    error: null,
    isSaving: false,
    student: null,
    financialSupportAmount: null,
    financialSupportSchemeIdentifierId: null,
    studentRegistrationNumberId: null
  };

  formIsValid = () => {
    const { student, financialSupportAmount, financialSupportSchemeIdentifierId, studentRegistrationNumberId } = this.state;
    if (!student) {
      this.showError("student", "Required");
      return false;
    }
    if (!financialSupportAmount) {
      this.showError("financialSupportAmount", "Required");
      return false;
    }
    if (financialSupportAmount && !isDecimal(financialSupportAmount)) {
      this.showError("financialSupportAmount", "Invalid value. Should be a decimal");
      return false;
    }
    if (!financialSupportSchemeIdentifierId) {
      this.showError("financialSupportSchemeIdentifierId", "Required");
      return false;
    }
    if (!studentRegistrationNumberId) {
      this.showError("studentRegistrationNumberId", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  fetchStudentOptions = async value => {
    const { data } = await this.props.fetchStudentHandler({ name: value });
    return data.map(option => ({ value: option, label: `${option.name} ${option.surname}` }));
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    this.setState({ isSaving: true });
    await this.props.saveHandler({
      student_id: state.student.id,
      financial_support_type: state.financialSupportSchemeIdentifierId,
      financial_support_amount: state.financialSupportAmount,
      student_registration_id: state.studentRegistrationNumberId
    });
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isSaving, student, financialSupportAmount, financialSupportSchemeIdentifierId, studentRegistrationNumberId } = this.state;
    const { options } = this.props;
    const { handleClickSaveButton, fetchStudentOptions, errorMessage } = this;
    const studentRegistrationNumberOptions = student
      ? student.student.student_registrations.map(x => ({ value: x.id, label: x.student_registration_number }))
      : [];

    return (
      <div className={classes.TuitionFeesForm}>
        <FormWrapper buttons={["save"]} buttonsAreDisabled={isSaving} isSpinning={isSaving} clickSaveButtonHandler={handleClickSaveButton}>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <SectionTitle title={"Select Student"} />
              <div className={classes.inputWrapper}>
                <InputLabel text="Student" />
                <DataListAsync
                  inputStyle={{ width: 300 }}
                  value={student}
                  fetchOptionsHandler={value => fetchStudentOptions(value)}
                  changeHandler={value => this.setState({ student: value })}
                  error={errorMessage("student")}
                />
              </div>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <SectionTitle title={"Student financial support"} />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Financial support amount" />
                <TextInput
                  style={{ width: 300 }}
                  value={financialSupportAmount}
                  changeHandler={financialSupportAmount => this.setState({ financialSupportAmount })}
                  error={errorMessage("financialSupportAmount")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Financial support scheme identifier" />
                <Select
                  inputStyle={{ width: 300 }}
                  options={options.financial_support_type}
                  value={financialSupportSchemeIdentifierId}
                  changeHandler={value => this.setState({ financialSupportSchemeIdentifierId: value })}
                  error={errorMessage("financialSupportSchemeIdentifierId")}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <SectionTitle title={"Financial support scheme"} />
              <div className={classes.inputWrapper}>
                <InputLabel text="Student registration number" />
                <Select
                  inputClassNames={!student ? ["borderless"] : []}
                  inputStyle={{ width: 300 }}
                  options={studentRegistrationNumberOptions}
                  value={studentRegistrationNumberId}
                  changeHandler={value => this.setState({ studentRegistrationNumberId: value })}
                  isDisabled={!student}
                  error={errorMessage("studentRegistrationNumberId")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Student identifier (SID)" />
                <TextInput classNames={["borderless"]} style={{ width: 300 }} value={student ? student.student.sid : ""} isDisabled />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

TuitionFeesForm.propTypes = {
  options: PropTypes.shape({
    financial_support_type: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string
      })
    )
  }),
  fetchStudentHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

TuitionFeesForm.defaultProps = {
  options: null,
  fetchStudentHandler: () => {},
  saveHandler: () => {}
};

export const TOGGLE_LOADING = 'admission/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admission/TOGGLE_SUCCESS'
export const CREATE_ADMISSION = 'admission/CREATE_ADMISSION'
export const GET_ADMISSION = 'admission/GET_ADMISSION'
export const SET_ADMISSION = 'admission/SET_ADMISSION'
export const EDIT_ADMISSION = 'admission/EDIT_ADMISSION'
export const DELETE_ADMISSION = 'admission/DELETE_ADMISSION'
export const GET_OPTIONS = 'admission/GET_OPTIONS'
export const SET_OPTIONS = 'admission/SET_OPTIONS'
export const SET_PERMISSIONS = 'admission/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'admission/CLEAR_PERMISSIONS'

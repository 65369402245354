import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './consents.types'
import { snackbarActions } from '../snackbar'
import { errorMessage } from '@Root/helpers'
import { API } from '@Root/API'
import { actions } from './consents.actions'

function* updateConsents({ payload }) {
	yield put(actions.toggleLoading(true))
	const { contactId, ...rest } = payload
	try {
		const { data } = yield call(API.contact.consent.edit, contactId, rest)
		yield put(actions.setConsents(data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The consents information has been saved successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

export function* rootSaga() {
	yield all([takeEvery(types.UPDATE_CONSENTS, updateConsents)])
}

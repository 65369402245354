import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './collaborativeProvision.types'
import { actions } from './collaborativeProvision.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, data } = payload
  const { collaborativeProvisionId, contactId, engagementId } = data
  try {
    const response = yield call(API.studies.engagement.collaborativeProvision.get, engagementId, collaborativeProvisionId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/leaver`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, engagementId, contactId } = payload
  try {
    const response = yield call(API.studies.engagement.collaborativeProvision.create, engagementId, data)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/collaborative-provision/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The collaborative provision has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, engagementId } = payload
  try {
    const response = yield call(API.studies.engagement.collaborativeProvision.edit, engagementId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The collaborative provision has been saved successfully!' }))
  } catch (error) {}
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { engagementId, contactId } = data
  try {
    yield call(API.studies.engagement.collaborativeProvision.remove, engagementId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/collaborative-provision`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The collaborative provision has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { colprovtypeid },
    } = yield call(API.studies.engagement.collaborativeProvision.options.getStatic)
    const formattedData = {
      colprovtypeid: colprovtypeid.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('collaborative_provision', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_COLLABORATIVE_PROVISION, get),
    takeEvery(types.CREATE_COLLABORATIVE_PROVISION, create),
    takeEvery(types.EDIT_COLLABORATIVE_PROVISION, edit),
    takeEvery(types.DELETE_COLLABORATIVE_PROVISION, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

import * as types from './auth.types'

export const actions = {
  logIn: (login, password, history, code) => ({
    type: types.LOG_IN,
    login,
    password,
    history,
    code,
  }),
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),

  logOut: history => ({
    type: types.LOG_OUT,
    history,
  }),
  removeToken: payload => ({
    type: types.REMOVE_TOKEN,
    payload,
  }),
  setToken: token => ({
    type: types.SET_TOKEN,
    token,
  }),
  setAuthError: error => ({
    type: types.SET_AUTH_ERROR,
    error,
  }),
  checkRecoveryToken: (token, history) => ({
    type: types.CHECK_RECOVERY_TOKEN,
    token,
    history,
  }),
  setRecoveryToken: token => ({
    type: types.SET_RECOVERY_TOKEN,
    token,
  }),
  sendNewPassword: (password, history) => ({
    type: types.SEND_NEW_PASSWORD,
    password,
    history,
  }),
  getUser: promise => ({
    type: types.GET_USER,
    promise,
  }),
  setUser: user => ({
    type: types.SET_USER,
    user,
  }),
  sendRecoveryEmail: (email, history) => ({
    type: types.SEND_RECOVERY_EMAIL,
    email,
    history,
  }),
  initTwoFactoreAuth: payload => ({
    type: types.INIT_2FA_CODE,
    payload,
  }),
  disableTwoFactoreAuth: payload => ({
    type: types.DISABLE_2FA,
    payload,
  }),
  setTwoFactoreAuth: payload => ({
    type: types.SET_2FA_CODE,
    payload,
  }),
}

import './style.scss'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'
import { DATE } from '@Root/configs'
import moment from 'moment'
import { enumerateDaysBetweenDates, isValidDataFormat } from '@Root/helpers'

const DatePickerPopup = ({ value, changeHandler, minDate, maxDate, onClose }) => {
  let config = {}
  const DATE_FORMAT = DATE.format
  if (minDate) config.minDate = moment(minDate, DATE_FORMAT).toDate()
  if (maxDate) config.maxDate = moment(maxDate, DATE_FORMAT).toDate()
  if (minDate && maxDate)
    config.disabledDates = [...enumerateDaysBetweenDates(minDate, maxDate), moment(minDate, DATE_FORMAT).toDate(), moment(maxDate, DATE_FORMAT).toDate()]

  return (
    <div className='DatePickerPopup'>
      <Calendar
        date={isValidDataFormat(value) ? moment(value, DATE_FORMAT).toDate() : new Date()}
        shownDate={isValidDataFormat(value) ? moment(value, DATE_FORMAT).toDate() : new Date()}
        onChange={value => {
          changeHandler(moment(value).format(DATE_FORMAT))
          onClose()
        }}
        showPreview={false}
        className={'calendar-date'}
        {...config}
        disabled
      />
    </div>
  )
}

DatePickerPopup.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
}

export default DatePickerPopup

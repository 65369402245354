import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { snapshotActions, snapshotSelectors } from '@Store/admin'
import { SnapshotForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { fieldTooltipManagerSelectors, modalActions } from '@Root/store'

export const EditSnapshot = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { snapshotId } = useParams()
  const snapshot = useSelector(snapshotSelectors.snapshot)
  const isLoading = useSelector(snapshotSelectors.isLoading)
  const permissions = useSelector(snapshotSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).snapshots

  useEffect(() => {
    dispatch(snapshotActions.get({ id: snapshotId }))
    return () => {
      dispatch(snapshotActions.set({}))
    }
  }, [dispatch, snapshotId])

  const onDelete = id => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This snapshot will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(snapshotActions.remove({ id, history }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onSubmit = data => {
    dispatch(snapshotActions.edit(data))
  }

  return (
    <SnapshotForm
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={snapshot}
      onDelete={onDelete}
      onSave={onSubmit}
      onOpenDescription={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
    />
  )
}

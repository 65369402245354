export const loadingConf = state => state.contactReducer.note.loadingConf

export const isSuccess = state => state.contactReducer.note.isSuccess

export const notes = state => state.contactReducer.note.notes

export const notesLength = state => state.contactReducer.note.notesLength

export const currentNotesPage = state => state.contactReducer.note.currentNotesPage

export const history = state => state.contactReducer.note.history

export const historyLength = state => state.contactReducer.note.historyLength

export const options = state => state.contactReducer.note.options

export const permissions = state => state.contactReducer.note.permissions

export const isOpenHistory = state => state.contactReducer.note.isOpenHistory

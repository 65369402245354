import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './qualificationAwardAccreditation.types'
import { actions } from './qualificationAwardAccreditation.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, contactId } = payload
  const { engagementId, qualificationAwardId, qualificationAwardAccreditationId } = data
  try {
    const response = yield call(
      API.studies.engagement.qualificationAward.qualificationAwardAccreditation.get,
      qualificationAwardId,
      qualificationAwardAccreditationId
    )
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${qualificationAwardId}/qualification-award-accreditation`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, contactId, engagementId, qualificationAwardId } = payload
  try {
    const response = yield call(API.studies.engagement.qualificationAward.qualificationAwardAccreditation.create, qualificationAwardId, data)
    history.push(
      `/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${qualificationAwardId}/qualification-award-accreditation/${response.data.data.id}`
    )
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification award accreditation has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, qualificationAwardId } = payload
  try {
    const response = yield call(API.studies.engagement.qualificationAward.qualificationAwardAccreditation.edit, qualificationAwardId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification award accreditation has been saved successfully!' }))
  } catch (error) {
    yield put(actions.set(payload))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, contactId } = payload
  const { engagementId, qualificationAwardId, qualificationAwardAccreditationId } = data
  try {
    yield call(API.studies.engagement.qualificationAward.qualificationAwardAccreditation.remove, qualificationAwardId, qualificationAwardAccreditationId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${qualificationAwardId}/qualification-award-accreditation`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification award accreditation has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { accreditationids },
    } = yield call(API.studies.engagement.qualificationAward.qualificationAwardAccreditation.options.getStatic)
    const generateObject = arr => arr?.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      accreditationids: generateObject(accreditationids),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('qualification_award_accreditation', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_QUALIFICATION_AWARD_ACCREDITATION, get),
    takeEvery(types.CREATE_QUALIFICATION_AWARD_ACCREDITATION, create),
    takeEvery(types.EDIT_QUALIFICATION_AWARD_ACCREDITATION, edit),
    takeEvery(types.DELETE_QUALIFICATION_AWARD_ACCREDITATION, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

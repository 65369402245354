import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { nanoid } from '@Root/../node_modules/nanoid/index'

const titles = { onEdit: 'Edit Module Role', onRead: 'Module Role', onCreate: 'Create Module Role' }
export const StudiesModuleDeliveryRoleForm = ({
  initialValue,
  isLoading,
  isSuccess,
  canEdit,
  canDelete,
  options,
  descriptions,
  onSave,
  isNew,
  onCancel,
  onDelete,
  onOpenDescriptionModal,
  maxProportion = 100,
  generateId,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(isNew)
  const { errors, setError, onClear: onClearError } = useError()
  const [customCode, setCustomCode] = useState({ label: '', value: '' })
  const [hesaIdentificatorsOptions, setHesaIdentificatorsrOptions] = useState([])

  useEffect(() => {
    if (options?.hesa_identificators) {
      if (options?.hesa_identificators.find(el => el.value === initialValue.hesa_identifier) || initialValue.hesa_identifier === null) {
        setHesaIdentificatorsrOptions([...options?.hesa_identificators])
        setData({
          ...initialValue,
          module_delivery_role_identifier: generateId ? Math.floor(Math.random() * 100000000000000) + 1 : initialValue.module_delivery_role_identifier,
        })
      } else {
        const id = nanoid()
        setHesaIdentificatorsrOptions([...options?.hesa_identificators, { label: initialValue.hesa_identifier, value: id }])
        setData({
          ...initialValue,
          module_delivery_role_identifier: generateId ? Math.floor(Math.random() * 100000000000000) + 1 : initialValue.module_delivery_role_identifier,
          hesa_identifier: id,
        })
      }
    }
  }, [generateId, initialValue, options?.hesa_identificators])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  const validateForm = () => {
    const { hesa_identifier, module_delivery_role_proportion } = data
    if (!hesa_identifier) {
      setError('hesa_identifier', 'Required')
      return false
    }
    if (!module_delivery_role_proportion) {
      setError('module_delivery_role_proportion', 'Required')
      return false
    }
    if (+module_delivery_role_proportion > maxProportion) {
      setError('module_delivery_role_proportion', 'Should be no more than 100%')
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    if (options?.hesa_identificators.find(el => el.value === data.hesa_identifier)) {
      onSave(data)
    } else {
      onSave({ ...data, hesa_identifier: hesaIdentificatorsOptions.find(el => el.value === data.hesa_identifier).label })
      setCustomCode({ label: '', value: '' })
    }
  }

  const onCancelEdit = () => {
    onClearError()
    if (!isNew) {
      onCancelEditMode()
      return
    }
    onCancel()
  }

  const onChangeCustomCode = field => value => {
    setCustomCode({ [field]: `${value}`, value: nanoid() })
  }

  const onAddCustomCode = () => {
    setData(prevState => ({ ...prevState, hesa_identifier: customCode.value }))
    setHesaIdentificatorsrOptions([...hesaIdentificatorsOptions, customCode])
  }

  const { hesa_identifier, franchise_indicator_id, module_delivery_role_proportion, module_delivery_role_id } = data

  const { onRead, onEdit, onCreate } = titles

  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this module Role' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={onCancelEdit}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSubmitForm}
      canEdit={canEdit}
      isEditable={isEditable}
      editTitle={onEdit}
      readTitle={onRead}
      createTitle={onCreate}
      clickToggleEditModeButtonHandler={toggleEditable}
      isCreate={isNew}
      idForm={module_delivery_role_id ? `module_role_${module_delivery_role_id}` : null}
      isSuccess={isSuccess}
    >
      <div className={styles.grid}>
        <div className={styles.column}>
          <Field
            label={'HESA identifier'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260, overflow: 'inherit' }}
            value={hesa_identifier}
            onChange={onChangeValue('hesa_identifier')}
            placeholder='HESA identifier'
            isDisabled={!isEditable}
            maxLength={2}
            isRequired={isEditable}
            error={errors.hesa_identifier}
            description={descriptions.hesa_identifier_id}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
            options={hesaIdentificatorsOptions}
            customComponents={
              <Field
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={customCode.label}
                onChange={onChangeCustomCode('label')}
                placeholder='Set custom code'
                isDisabled={!isEditable}
                field={'textField'}
                maxLength={8}
              />
            }
            callBack={onAddCustomCode}
          />
          <Field
            label={'Franchise indicator'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            value={franchise_indicator_id}
            onChange={onChangeValue('franchise_indicator_id')}
            placeholder='Franchise indicator'
            isDisabled={!isEditable}
            maxLength={2}
            error={errors.franchise_indicator_id}
            description={descriptions.franchise_indicator_id}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
            options={options.franchise_indicators}
          />
        </div>
        <div className={styles.columnWrapper}>
          <Field
            label={'Module delivery role proportion'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={module_delivery_role_proportion}
            onChange={onChangeValue('module_delivery_role_proportion')}
            placeholder='Module delivery role proportion'
            isDisabled={!isEditable}
            maxLength={4}
            isRequired={isEditable}
            error={errors.module_delivery_role_proportion}
            description={descriptions.module_delivery_role_proportion}
            onOpenDescriptionModal={onOpenDescriptionModal}
            restriction='digits'
          />
        </div>
      </div>
    </FormWrapper>
  )
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { CreateSnapshot } from './Create'
import { fieldTooltipManagerActions, modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import { EditSnapshot } from './Edit'
import { qualificationActions } from '@Store/admin'

const PARENT_URL = '/home/admin/snapshots'
export const SnapshotPage = () => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const pathname = location.pathname
	const isNew = pathname.includes('new')

	useEffect(() => {
		dispatch(qualificationActions.getStaticOptions())
		dispatch(
			fieldTooltipManagerActions.getDescriptions({
				entity: 'admin',
				entity_type: 'snapshots',
			})
		)
	}, [dispatch])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	return (
		<SectionWrapper hasBackButton backButtonText='Snapshots' clickBackButtonHandler={() => history.push(PARENT_URL)}>
			<div className={styles.wrapper}>
				{isNew ? (
					<CreateSnapshot onOpenDescriptionModal={onOpenDescriptionModal} parentURL={PARENT_URL} />
				) : (
					<EditSnapshot onOpenDescriptionModal={onOpenDescriptionModal} />
				)}
			</div>
		</SectionWrapper>
	)
}

import * as types from './permissions.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	get: payload => ({
		type: types.GET_PERMISSIONS,
		payload,
	}),
	set: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	save: payload => ({
		type: types.SAVE_PERMISSIONS,
		payload,
	}),
	rolesMerge: payload => ({
		type: types.ROLES_MERGE,
		payload,
	}),
}

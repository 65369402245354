import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './sessionStatus.types'
import { actions } from './sessionStatus.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, studentCourseSessionId, contactId, sessionStatusId } = data
	try {
		const response = yield call(API.studies.studentCourseSession.sessionStatus.get, studentCourseSessionId, sessionStatusId)
		yield put(actions.set(response.data.data))
		yield put(actions.setPermissions(response.data.permissions))
	} catch (error) {
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/session_status`)
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history, studyProgrammeId, contactId, studentCourseSessionId } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.sessionStatus.create, studentCourseSessionId, data)
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/session_status/${response.data.data.id}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Session Status has been created successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		if (errors['studyProgrammeId']) {
			history.push('/home/studies/programmes/study')
		} else {
			yield put(actions.set(data))
			yield put(actions.setErrors(errors))
		}
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { studentCourseSessionId, sessionStatusId, data } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.sessionStatus.edit, studentCourseSessionId, sessionStatusId, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The Session Status has been saved successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		yield put(actions.set(payload))
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { contactId, studyProgrammeId, studentCourseSessionId, sessionStatusId} = data
	try {
		yield call(API.studies.studentCourseSession.sessionStatus.remove, studentCourseSessionId, sessionStatusId)
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/session_status`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Session Status has been deleted successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* getOptions() {
	try {
		const {data} = yield call(API.studies.studentCourseSession.sessionStatus.options.getStatic)
		const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
		const formattedData = {
			status_changed_to: generateObject(data.status_changed_to) ,
		}
		yield put(actions.setOptions(formattedData))
		saveDataOptionsToLocalStorage('sessionStatus', formattedData)
	} catch (error) {
		console.log(error)
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_SESSIONSTATUS, get),
		takeEvery(types.CREATE_SESSIONSTATUS, create),
		takeEvery(types.EDIT_SESSIONSTATUS, edit),
		takeEvery(types.DELETE_SESSIONSTATUS, remove),
		takeEvery(types.GET_OPTIONS, getOptions),
	])
}

export const TOGGLE_LOADING = 'studentCourseSession/supervisorAllocation/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/supervisorAllocation/TOGGLE_SUCCESS'
export const GET_SUPERVISOR_ALLOCATION = 'studentCourseSession/supervisorAllocation/GET_SUPERVISOR_ALLOCATION'
export const SET_SUPERVISOR_ALLOCATION = 'studentCourseSession/supervisorAllocation/SET_SUPERVISOR_ALLOCATION'
export const CREATE_SUPERVISOR_ALLOCATION = 'studentCourseSession/supervisorAllocation/CREATE_SUPERVISOR_ALLOCATION'
export const DELETE_SUPERVISOR_ALLOCATION = 'studentCourseSession/supervisorAllocation/DELETE_SUPERVISOR_ALLOCATION'
export const EDIT_SUPERVISOR_ALLOCATION = 'studentCourseSession/supervisorAllocation/EDIT_SUPERVISOR_ALLOCATION'
export const GET_OPTIONS = 'studentCourseSession/supervisorAllocation/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/supervisorAllocation/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/supervisorAllocation/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/supervisorAllocation/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/supervisorAllocation/SET_CAN_BE_CREATED'
export const SET_ERRORS = 'studentCourseSession/supervisorAllocation/SET_ERRORS'

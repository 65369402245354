export const isLoading = state => state.studiesReducer.programme.courseRole.isLoading

export const isSuccess = state => state.studiesReducer.programme.courseRole.isSuccess

export const courseRole = state => state.studiesReducer.programme.courseRole.courseRole

export const errors = state => state.studiesReducer.programme.courseRole.errors

export const options = state => state.studiesReducer.programme.courseRole.options

export const permissions = state => state.studiesReducer.programme.courseRole.permissions

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './organisation.actions'
import { createInstance } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import * as types from './organisation.types'
import { dioceseRootSaga } from './diocese'
import { trainingFacilityRootSaga } from './trainingFacility'
import { churchRootSaga } from './church'

function* getRelationships() {
	try {
		const { data } = yield call(createInstance().get, 'contacts/organisation/relationships')
		const formattedData = data.data.map(({ id, display_name }) => ({ value: id, label: display_name }))
		yield put(actions.setRelationShips(formattedData))
		saveDataOptionsToLocalStorage('organisation_relationships', formattedData)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

export function* rootSaga() {
	yield all([takeEvery(types.GET_RELATIONSHIPS, getRelationships), churchRootSaga(), dioceseRootSaga(), trainingFacilityRootSaga()])
}

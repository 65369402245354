export const fee = state => state.studiesReducer.studentCourseSession.fee.fee

export const isLoading = state => state.studiesReducer.studentCourseSession.fee.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.fee.isSuccess

export const isNew = state => state.studiesReducer.studentCourseSession.fee.isNew

export const options = state => state.studiesReducer.studentCourseSession.fee.options

export const permissions = state => state.studiesReducer.studentCourseSession.fee.permissions

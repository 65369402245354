import * as types from './tableManager.types'

const initialState = {
	tablePermissions: null,
	isLoading: false,
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.SET_TABLE_PERMISSIONS:
			return {
				...state,
				tablePermissions: action.payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

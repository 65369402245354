import React, { useEffect } from 'react'
import {
  AdmissionsForm,
  FeesForm,
  FundingAndMonitoringForm,
  PostgradForm,
  VerticalTabs,
  StudentCourseSessionAttachmentForm,
  StudentCourseSessionNotesForm,
  TableBlock,
} from '@Root/components'
import { Redirect, Route, Switch, useParams, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { feeSelectors, fundingAndMonitoringSelectors, fieldTooltipManagerActions, studentCourseSessionPostgradSelectors } from '@Root/store'
import { permissionsSelectors } from '@Store/permissions'
import { studentCourseSessionSelectors } from '@Store/studies/studentCourseSession/index'
import {
  venueActivityTableData,
  referencePeriodStudentTableData,
  studyLocationTableData,
  studentFinancialSupportTableData,
  sessionStatusTableData,
  fundingBodyTableData,
  moduleInstanceTableData,
  supervisorAllocationTableData,
  fundingAndMonitoringTableData,
  postgradResearchTableData,
} from '../lib'
import styles from './style.module.scss'

export const DetailInformation = ({ canManipulateOtherForms, isDuplicate }) => {
  const dispatch = useDispatch()
  const { contactId, contactTab: studyProgrammeTab, studyProgrammeId } = useParams()
  const postgrad = useSelector(studentCourseSessionPostgradSelectors.postgrad)
  const fundingAndMonitoring = useSelector(fundingAndMonitoringSelectors.fundingAndMonitoring)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const permissions = useSelector(studentCourseSessionSelectors.permissions)
  const programmeRecord = useSelector(studentCourseSessionSelectors.programmeRecord)
  const fee = useSelector(feeSelectors.fee)
  const defaultPathURL = '/home/studies/programmes/study'
  const DEFAULT_URL = `${defaultPathURL}/${contactId}/${studyProgrammeId}`
  const history = useHistory()

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'records-of-studies' }))
  }, [dispatch])

  const forms = () => {
    const feesConfig = {
      createFeesLink: 'fee-details',
      editFeesLink: 'update/fee-details',
      deleteFeesLink: fee_id => `fee-details/${fee_id}`,
    }

    return [
      ...(permissions?.tabs?.off_venue_activity
        ? [
            {
              name: 'Off venue activity',
              label: !isDuplicate ? 'off_venue_activity' : 'off_venue_activity/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock title={'Off venue activity'} config={venueActivityTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)} />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.reference_period_student_load
        ? [
            {
              name: 'Ref. period stud. load',
              label: !isDuplicate ? 'reference_period_student_load' : 'reference_period_student_load/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock
                    title={'Reference period student load'}
                    config={referencePeriodStudentTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)}
                  />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.study_location
        ? [
            {
              name: 'Study location',
              label: !isDuplicate ? 'study_location' : 'study_location/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock title={'Study location'} config={studyLocationTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)} />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.student_financial_support
        ? [
            {
              name: 'Stud. fin. support',
              label: !isDuplicate ? 'student_financial_support' : 'student_financial_support/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock
                    title={'Student financial support'}
                    config={studentFinancialSupportTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)}
                  />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.session_status
        ? [
            {
              name: 'Session status',
              label: !isDuplicate ? 'session_status' : 'session_status/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock title={'Session status'} config={sessionStatusTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)} />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.module_instance
        ? [
            {
              name: 'Module instances',
              label: !isDuplicate ? 'module_instances' : 'module_instances/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock title={'Module instances'} config={moduleInstanceTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)} />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.supervisor_allocation
        ? [
            {
              name: 'Supervisor allocation',
              label: !isDuplicate ? 'supervisor_allocation' : 'supervisor_allocation/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock
                    title={'Supervisor allocation'}
                    config={supervisorAllocationTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)}
                  />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.funding_body
        ? [
            {
              name: 'Funding body',
              label: !isDuplicate ? 'funding_body' : 'funding_body/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock title={'Funding body'} config={fundingBodyTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)} />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.funding_and_monitoring
        ? [
            {
              name: 'Funding & Monitoring',
              label: !isDuplicate ? 'funding-and-monitoring' : 'funding-and-monitoring/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock
                    title={'Funding and Monitoring'}
                    config={fundingAndMonitoringTableData(programmeRecord.student_course_session_id, DEFAULT_URL, history)}
                  />
                </div>
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.note //add back-end if needed
        ? [
            {
              name: 'Notes',
              label: !isDuplicate ? 'notes' : 'notes/duplicate',
              Component: (
                <StudentCourseSessionNotesForm canManipulate={canManipulateOtherForms} contactId={contactId} studentCourseSessionId={studyProgrammeId} />
              ),
            },
          ]
        : []),
      ...(permissions?.tabs?.attachment //add back-end if needed
        ? [
            {
              name: 'Attachments',
              label: !isDuplicate ? 'attachments' : 'attachments/duplicate',
              Component: <StudentCourseSessionAttachmentForm canManipulate={canManipulateOtherForms} studentCourseSessionId={studyProgrammeId} />,
            },
          ]
        : []),
      ...(postgrad && permissions?.tabs?.post_grad
        ? [
            {
              name: 'Postgrad research',
              label: !isDuplicate ? 'post_grad' : 'post_grad/duplicate',
              Component: (
                <div className={styles.table_padding}>
                  <TableBlock title={'Postgrad research'} config={postgradResearchTableData(studyProgrammeId, DEFAULT_URL, history)} />
                </div>
              ),
            },
          ]
        : []),
      ...((studyProgrammeTab === 'fees' && !fee) || permissions?.tabs?.fees //add back-end if needed
        ? [
            {
              name: 'Fees',
              label: 'fees/new',
              Component: (
                <FeesForm
                  isNew
                  config={feesConfig}
                  contactId={contactId}
                  studyProgrammeId={studyProgrammeId}
                  canCreate={dataCreationPermissions.student_course_session_sub}
                />
              ),
            },
          ]
        : []),
      ...(fee && permissions?.tabs?.fees //add back-end if needed
        ? [
            {
              name: 'Fees',
              label: !isDuplicate ? 'fees' : 'fees/duplicate',
              Component: <FeesForm canManipulate={canManipulateOtherForms} config={feesConfig} contactId={contactId} studyProgrammeId={studyProgrammeId} />,
            },
          ]
        : []),
    ]
  }

  return permissions.tabs && programmeRecord?.id && forms()?.length > 0 ? (
    <div className={styles.wrapper_container}>
      <div className={styles.scroll_bar}>
        <VerticalTabs tabs={forms()} isLink={true} defaultURL={`${defaultPathURL}/${contactId}/${studyProgrammeId}`} />
      </div>

      <Switch>
        {forms().map(({ label, Component }) => {
          return <Route exact path={`${defaultPathURL}/${contactId}/${studyProgrammeId}/${label}`} component={() => Component} key={label} />
        })}
        <Redirect to={`${defaultPathURL}/${contactId}/${studyProgrammeId}/off_venue_activity`} />
      </Switch>
    </div>
  ) : (
    <></>
  )
}

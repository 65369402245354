import React, { useEffect } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions, programmeActions, programmeSelectors } from '@Root/store'
import { SectionWrapper } from '@Root/HOCs'
import { Spinner, StudiesProgrammeForm } from '@Root/components'
import { useHistory } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const StudiesNewProgramme = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const isLoading = useSelector(programmeSelectors.isLoading)
	const options = useSelector(programmeSelectors.options)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).programmes
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
	const permissions = useSelector(programmeSelectors.permissions)

	const saveProgramme = payload => {
		dispatch(programmeActions.create({ data: payload, history }))
	}

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'programmes' }))
		dispatch(programmeActions.getStaticOptions())
		dispatch(programmeActions.getDynamicOptions())
	}, [dispatch])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: description,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
					title,
				})
			)
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	if (!dataCreationPermissions.programmes) return <Redirect to={'/home/studies/programmes'} />
	return (
		<div className={classes.wrapper}>
			<div className={classes.sectionsWrapper}>
				{!isLoading ? (
					<SectionWrapper hasBackButton backButtonText='Programmes' clickBackButtonHandler={() => history.push('/home/studies/programmes')}>
						<StudiesProgrammeForm
							title={{ onEdit: 'Create Programme Delivery' }}
							initialIsEditable={true}
							options={{ ...options?.static, ...options?.dynamic }}
							buttons={['save']}
							saveHandler={saveProgramme}
							openDescriptionModal={onOpenDescriptionModal}
							descriptions={descriptions}
							permissions={permissions}
						/>
					</SectionWrapper>
				) : (
					<Spinner />
				)}
			</div>
		</div>
	)
}

export const checkAddressValidFields = addresses => {
	const invalidFields = []
	const addressesUnfull = addresses.slice(1, addresses.length)
	addressesUnfull.forEach(address => {
		const valuesLength = Object.values(address).filter(address => address).length
		const onlyFieldsValuesLength = valuesLength - 1
		if (!onlyFieldsValuesLength) invalidFields.push(address.id)
	})
	return invalidFields
}

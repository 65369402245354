import * as types from './fieldTooltipManager.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_MESSAGES,
		payload,
	}),
	set: payload => ({
		type: types.SET_MESSAGES,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_MESSAGES,
		payload,
	}),
	getContact: payload => ({
		type: types.GET_CONTACT_DESCRIPTIONS,
		payload,
	}),
	setContactDescriptions: payload => ({
		type: types.SET_CONTACT_DESCRIPTIONS,
		payload,
	}),
	getDescriptions: payload => ({
		type: types.GET_DESCRIPTIONS,
		payload,
	}),
	setDescriptions: payload => ({
		type: types.SET_DESCRIPTIONS,
		payload,
	}),
	getHesaDescriptions: payload => ({
		type: types.GET_HESA_DESCRIPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

export const TOGGLE_LOADING = 'collaborativeProvision/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'collaborativeProvision/TOGGLE_SUCCESS'
export const GET_COLLABORATIVE_PROVISION = 'collaborativeProvision/GET_COLLABORATIVE_PROVISION'
export const SET_COLLABORATIVE_PROVISION = 'collaborativeProvision/SET_COLLABORATIVE_PROVISION'
export const CREATE_COLLABORATIVE_PROVISION = 'collaborativeProvision/CREATE_COLLABORATIVE_PROVISION'
export const DELETE_COLLABORATIVE_PROVISION = 'collaborativeProvision/DELETE_COLLABORATIVE_PROVISION'
export const EDIT_COLLABORATIVE_PROVISION = 'collaborativeProvision/EDIT_COLLABORATIVE_PROVISION'
export const GET_OPTIONS = 'collaborativeProvision/GET_OPTIONS'
export const SET_OPTIONS = 'collaborativeProvision/SET_OPTIONS'
export const CLEAR_DATA = 'collaborativeProvision/CLEAR_DATA'
export const SET_PERMISSIONS = 'collaborativeProvision/SET_PERMISSIONS'

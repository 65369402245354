import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Route, Switch, useHistory, useParams } from 'react-router'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { CollaborativeProvisionPage } from './CollaborativeProvisionPage'
import { EngagementDetailsPage } from './Page'
import { EntryProfileDetails } from './EntryProfilePage'
import { LeaverPage } from './LeaverPage'
import { QualificationAwardDetails } from './QualificationAwardPage'
import { AccreditationAimPage } from './AccreditationAimPage'
import { StudentInitiativesPage } from './StudentInitiativesPage'
import { useDispatch } from 'react-redux'
import { engagementActions } from '@Store/studies'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'

export const EngagementDetails = () => {
  const history = useHistory()
  const { id: engagementId, tab: otherTabId, tabId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    getDataOptionsFromLocalStorage(['engagements'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'engagements' }))
  }, [])

  useEffect(() => {
    if (engagementId && otherTabId) {
      dispatch(engagementActions.get({ data: engagementId, history }))
      return () => {
        dispatch(engagementActions.clearData())
      }
    }
    //Note: Don't add otherTabId as dependency. I remove that to skip extra request during switching engagement tabs.
  }, [dispatch, engagementId, history, tabId])

  return (
    <div className={styles.wrapper}>
      <div className={styles.sub_page}>
        <Switch>
          <Route path={'/home/studies/engagements/:contactId/:id/collaborative-provision/new'} component={CollaborativeProvisionPage} exact />
          <Route path={'/home/studies/engagements/:contactId/:id/collaborative-provision/:collaborationId'} component={CollaborativeProvisionPage} exact />{' '}
          <Route path={'/home/studies/engagements/:contactId/:id/leaver/new'} component={LeaverPage} exact />
          <Route path={'/home/studies/engagements/:contactId/:id/leaver/:leaverId'} component={LeaverPage} exact />{' '}
          <Route path={'/home/studies/engagements/:contactId/:id/student-accreditation-aim/new'} component={AccreditationAimPage} exact />
          <Route path={'/home/studies/engagements/:contactId/:id/student-accreditation-aim/:accreditationAimId'} component={AccreditationAimPage} exact />{' '}
          <Route path={'/home/studies/engagements/:contactId/:id/student-initiatives/new'} component={StudentInitiativesPage} exact />
          <Route path={'/home/studies/engagements/:contactId/:id/student-initiatives/:studentInitiativesId'} component={StudentInitiativesPage} exact />
          <Route path={'/home/studies/engagements/:contactId/:id/qualification-award/new'} component={QualificationAwardDetails} exact />
          <Route
            path={
              '/home/studies/engagements/:contactId/:id/qualification-award/:qualificationAwardId/:qualificationAwardAccreditation?/:qualificationAwardAccreditationId?'
            }
            component={QualificationAwardDetails}
            exact
          />{' '}
          <Route path={'/home/studies/engagements/:contactId/:id/entry-profile/new'} component={EntryProfileDetails} exact />
          <Route
            path={
              '/home/studies/engagements/:contactId/:id/entry-profile/:entryProfileId/:entryQualification?/:entryQualificationId?/:entryQualificationSubject?/:entryQualificationSubjectId?'
            }
            component={EntryProfileDetails}
            exact
          />
          <Route component={EngagementDetailsPage} />
        </Switch>
      </div>
    </div>
  )
}

export const saveDataOptionsToLocalStorage = (optionName, options) => {
	const data = localStorage.getItem('dataOptions')
	if (data) {
		const parsedData = JSON.parse(data)
		const isAvailableInData = parsedData[optionName]
		if (!isAvailableInData) {
			const stringifyOptions = JSON.stringify(options)
			const newData = { ...parsedData, [optionName]: stringifyOptions }
			const stringifyData = JSON.stringify(newData)
			localStorage.setItem('dataOptions', stringifyData)
		}
	} else {
		const stringifyData = JSON.stringify({})
		localStorage.setItem('dataOptions', stringifyData)
	}
}

export const TOGGLE_LOADING = 'contact/studentCourseSession/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'contact/studentCourseSession/TOGGLE_SUCCESS'
export const GET_ATTACHMENT = 'contact/studentCourseSession/GET_ATTACHMENT'
export const SET_ATTACHMENTS = 'contact/studentCourseSession/SET_ATTACHMENTS'
export const CREATE_ATTACHMENT = 'contact/studentCourseSession/CREATE_ATTACHMENT'
export const SET_CREATED_ATTACHMENT = 'contact/studentCourseSession/SET_CREATED_ATTACHMENT'
export const EDIT_ATTACHMENT = 'contact/studentCourseSession/EDIT_ATTACHMENT'
export const SET_EDITED_ATTACHMENT = 'contact/studentCourseSession/SET_EDITED_ATTACHMENT'
export const DELETE_ATTACHMENT = 'contact/studentCourseSession/DELETE_ATTACHMENT'
export const SET_DELETED_ATTACHMENT = 'contact/studentCourseSession/SET_DELETED_ATTACHMENT'
export const SET_PERMISSIONS = 'contact/studentCourseSession/SET_PERMISSIONS'

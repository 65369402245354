export const TOGGLE_LOADING = 'qualificationAwardAccreditation/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'qualificationAwardAccreditation/TOGGLE_SUCCESS'
export const GET_QUALIFICATION_AWARD_ACCREDITATION = 'qualificationAwardAccreditation/GET_QUALIFICATION_AWARD_ACCREDITATION'
export const SET_QUALIFICATION_AWARD_ACCREDITATION = 'qualificationAwardAccreditation/SET_QUALIFICATION_AWARD_ACCREDITATION'
export const CREATE_QUALIFICATION_AWARD_ACCREDITATION = 'qualificationAwardAccreditation/CREATE_QUALIFICATION_AWARD_ACCREDITATION'
export const DELETE_QUALIFICATION_AWARD_ACCREDITATION = 'qualificationAwardAccreditation/DELETE_QUALIFICATION_AWARD_ACCREDITATION'
export const EDIT_QUALIFICATION_AWARD_ACCREDITATION = 'qualificationAwardAccreditation/EDIT_QUALIFICATION_AWARD_ACCREDITATION'
export const GET_OPTIONS = 'qualificationAwardAccreditation/GET_OPTIONS'
export const SET_OPTIONS = 'qualificationAwardAccreditation/SET_OPTIONS'
export const CLEAR_DATA = 'qualificationAwardAccreditation/CLEAR_DATA'
export const SET_PERMISSIONS = 'qualificationAwardAccreditation/SET_PERMISSIONS'

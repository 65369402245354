import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import { CheckboxInput, InputLabel } from '@Root/components'
import { additionalPermissionsConfig } from '@Root/configs'

export const PermissionsCheckboxes = ({ initialData, onChangeHandler, isEditable }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    if (initialData) setData(initialData)
  }, [initialData])

  const onChangeValue = (name, field) => value => {
    setData(prevState => {
      onChangeHandler({ ...prevState, [name]: { ...prevState[name], [field]: value } })
      return { ...prevState, [name]: { ...prevState[name], [field]: value } }
    })
  }
  return (
    <div>
      <h3 className={classes.title}>Additional functions:</h3>
      <div className={classes.rows}>
        {additionalPermissionsConfig.map(({ title, entity, sub_entity }) => (
          <div className={classes.row} key={`${entity}-${sub_entity}`}>
            <InputLabel text={title} />
            <CheckboxInput
              isChecked={data?.[entity]?.[sub_entity]}
              changeHandler={value => onChangeValue(entity, sub_entity)(value)}
              isDisabled={!isEditable}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

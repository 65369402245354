import React from 'react'
import { FundingBodyForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { fundingBodySelectors, fundingBodyActions } from '@Store/studies'

export const CreateFundingBody = ({ programmeRecord, onOpenDescriptionModal }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname
	const contactId = pathname.split('/')[5]
	const studyProgrammeId = pathname.split('/')[6]
	const fundingBody = useSelector(fundingBodySelectors.fundingBody)
	const options = useSelector(fundingBodySelectors.options)
	const isLoading = useSelector(fundingBodySelectors.isLoading)
	const DEFAULT_URL = `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_body`

	const onCreate = data => {
		dispatch(fundingBodyActions.create({ data, history, studentCourseSessionId: programmeRecord.student_course_session_id, studyProgrammeId, contactId }))
	}

	const onCancel = () => {
		history.push(DEFAULT_URL)
	}

	return (
		<FundingBodyForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onCreate}
			onCancel={onCancel}
			canEdit={true}
			isNew
			initialValue={fundingBody}
			options={options}
			isLoading={isLoading}
		/>
	)
}

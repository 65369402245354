import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { switchEmptyStringsToNullsInObject, validateData } from '@Root/helpers'
import { booleanOptions } from '@Root/configs'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, TextInput, Select, MultiSelect, DatePicker, TextArea } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { personSelectors, studentActions, studentSelectors } from '@Store/contact/person'
import { useHistory } from 'react-router'
import { useToggle } from '@Root/hooks'

const relationship = 'Student'
const titles = { onRead: 'Student Details', onEdit: 'Edit Student Details' }
export const ContactsStudentForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, toggleEditable] = useToggle(false)
  const [data, setData] = useState({})
  const relationships = useSelector(personSelectors.relationships)
  const dynamicOptions = useSelector(studentSelectors.options).dynamic
  const staticOptions = useSelector(studentSelectors.options).static
  const isLoading = useSelector(studentSelectors.isLoading)
  const isSuccess = useSelector(studentSelectors.isSuccess)
  const student = useSelector(studentSelectors.student)
  const permissions = useSelector(studentSelectors.permissions)
  const isPermissionsLoaded = useSelector(studentSelectors.isPermissionsLoaded)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.students
  const dispatch = useDispatch()

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const validateForm = () => {
    const { passport_expiry, visa_expiry } = data
    if (validateData([passport_expiry, visa_expiry])) return false
    return true
  }

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (student) setData(student)
  }, [student])

  useEffect(() => {
    const hasOptions = Object.keys({ ...staticOptions, ...dynamicOptions }).length > 0
    if (!hasOptions) {
      dispatch(studentActions.getStaticOptions())
      dispatch(studentActions.getDynamicOptions())
    }
  }, [dispatch, dynamicOptions, staticOptions])

  useEffect(() => {
    if (contactId) dispatch(studentActions.get({ contactId }))
  }, [contactId, dispatch])

  useEffect(() => {
    dispatch(studentActions.getDynamicOptions())
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'person', entity_sub_type: 'students' }))
  }, [dispatch])

  const onSave = () => {
    if (!validateForm()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    dispatch(studentActions.edit({ data: modifiedData, contactId }))
  }

  const onCancel = () => {
    setData(student)
    toggleEditable()
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationship}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationship).value
        dispatch(studentActions.remove({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const toggleEditMode = () => {
    toggleEditable()
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const { fields, form } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this student' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditMode}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={contactId ? `student_details_${contactId}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Student ID' description={descriptions.student_id} onOpenDescription={onOpenDescriptionModal} />
          <TextInput classNames={['borderless']} style={{ position: 'absolute', left: 275, width: 260 }} value={data.student_id} isDisabled />
          {data.contact_id && (
            <>
              <InputLabel style={{ position: 'absolute', left: 610 }} text='ID (from import)' />
              <TextInput classNames={['borderless']} style={{ position: 'absolute', left: 765, width: 260 }} value={data.contact_id} isDisabled />
            </>
          )}
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Community Member ?' description={descriptions.is_community_member} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.is_community_member}
            changeHandler={value => handleChangeInput('is_community_member', value)}
            isDisabled={!isEditable || !fields.is_community_member?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Student Types' description={descriptions.student_type_ids} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 275 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ['borderless'] : []}
              inputStyle={{ width: 260 }}
              options={dynamicOptions.student_types}
              values={data.student_type_ids}
              changeHandler={values => handleChangeInput('student_type_ids', values)}
              isDisabled={!isEditable || !fields.student_type_ids?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='University Code/BannerID' description={descriptions.banner_id} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 260 }}
            value={data.banner_id}
            changeHandler={value => handleChangeInput('banner_id', value)}
            isDisabled={!isEditable || !fields.banner_id?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='SLC Number' description={descriptions.slc_number} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 260 }}
            value={data.slc_number}
            changeHandler={value => handleChangeInput('slc_number', value)}
            isDisabled={!isEditable || !fields.slc_number?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Eligible for Support?' />
          <div className={classes.text} style={{ position: 'absolute', left: 275 }}>
            <span style={{ color: '#4A4A4A' }}>Note:</span> Recorded in Study Records - see Programmes tab
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='HEAPES?' />
          <div className={classes.text} style={{ position: 'absolute', left: 275 }}>
            <span style={{ color: '#4A4A4A' }}>Note:</span> Recorded in Study Records - see Programmes tab
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text={`Previously studied with TEI`} description={descriptions.studied_at} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.studied_at}
            changeHandler={value => handleChangeInput('studied_at', value)}
            isDisabled={!isEditable || !fields.studied_at?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text={`TEI code`} description={descriptions.tei_code} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', width: 260, left: 275 }}
            value={data.tei_code}
            changeHandler={value => handleChangeInput('tei_code', value)}
            isDisabled={!isEditable || !fields.tei_code?.edit}
            maxLength={4}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <InputLabel text='Ethnicity HESA Codes' description={descriptions.ethnicity_type_id} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={staticOptions.ethnicities}
            value={data.ethnicity_type_id}
            changeHandler={value => handleChangeInput('ethnicity_type_id', value)}
            isDisabled={!isEditable || !fields.ethnicity_type_id?.edit}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <InputLabel text='International' description={descriptions.is_international} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.is_international}
            changeHandler={value => handleChangeInput('is_international', value)}
            isDisabled={!isEditable || !fields.is_international?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Nationality' description={descriptions.nationality_type_id} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={staticOptions.nationalities}
            value={data.nationality_type_id}
            changeHandler={value => handleChangeInput('nationality_type_id', value)}
            isDisabled={!isEditable || !fields.nationality_type_id?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Disability' description={descriptions.has_disability} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.has_disability}
            changeHandler={value => handleChangeInput('has_disability', value)}
            isDisabled={!isEditable || !fields.has_disability?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Disability Detail' description={descriptions.disability_details} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.disability_details}
            changeHandler={value => handleChangeInput('disability_details', value)}
            isDisabled={!isEditable || !fields.disability_details?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Learning Support' description={descriptions.has_learning_support} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.has_learning_support}
            changeHandler={value => handleChangeInput('has_learning_support', value)}
            isDisabled={!isEditable || !fields.has_learning_support?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Learning Support Details' description={descriptions.learning_support_details} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.learning_support_details}
            changeHandler={value => handleChangeInput('learning_support_details', value)}
            isDisabled={!isEditable || !fields.learning_support_details?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Academic Qualifications' description={descriptions.academic_qualifications} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.academic_qualifications}
            changeHandler={value => handleChangeInput('academic_qualifications', value)}
            isDisabled={!isEditable || !fields.academic_qualifications?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Other Qualifications' description={descriptions.other_qualifications} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.other_qualifications}
            changeHandler={value => handleChangeInput('other_qualifications', value)}
            isDisabled={!isEditable || !fields.other_qualifications?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Work Experience' description={descriptions.work_experience} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.work_experience}
            changeHandler={value => handleChangeInput('work_experience', value)}
            isDisabled={!isEditable || !fields.work_experience?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Marital Status' description={descriptions.marital_status_type_id} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 275 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={staticOptions.marital_statuses}
            value={data.marital_status_type_id}
            changeHandler={value => handleChangeInput('marital_status_type_id', value)}
            isDisabled={!isEditable || !fields.marital_status_type_id?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Spouse Name' description={descriptions.spouse_name} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 260 }}
            value={data.spouse_name}
            changeHandler={value => handleChangeInput('spouse_name', value)}
            isDisabled={!isEditable || !fields.spouse_name?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 610 }}
            text='Spouse Surname'
            description={descriptions.spouse_surname}
            onOpenDescription={onOpenDescriptionModal}
          />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 765, width: 260 }}
            value={data.spouse_surname}
            changeHandler={value => handleChangeInput('spouse_surname', value)}
            isDisabled={!isEditable || !fields.spouse_surname?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Notes about children' description={descriptions.children_notes} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.children_notes}
            changeHandler={value => handleChangeInput('children_notes', value)}
            isDisabled={!isEditable || !fields.children_notes?.edit}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Brief Notes (use Notes Tab For Detailed)' description={descriptions.brief_notes} onOpenDescription={onOpenDescriptionModal} />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 275, width: 750, height: 90 }}
            value={data.brief_notes}
            changeHandler={value => handleChangeInput('brief_notes', value)}
            isDisabled={!isEditable || !fields.brief_notes?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Passport Expiry' description={descriptions.passport_expiry} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 275 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.passport_expiry}
              changeHandler={value => handleChangeInput('passport_expiry', value)}
              isDisabled={!isEditable || !fields.passport_expiry?.edit}
            />
          </div>
          <InputLabel
            style={{ position: 'absolute', left: 610 }}
            text='Visa Expiry'
            description={descriptions.visa_expiry}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 765 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.visa_expiry}
              changeHandler={value => handleChangeInput('visa_expiry', value)}
              isDisabled={!isEditable || !fields.visa_expiry?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper} style={{ marginBottom: '30px', marginTop: '30px' }}>
          <InputLabel
            style={{ position: 'absolute', left: 610 }}
            text='Visa Notes'
            description={descriptions.visa_notes}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 765 }}>
            <TextArea
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260, height: 90 }}
              maxLength={255}
              value={data.visa_notes}
              changeHandler={value => handleChangeInput('visa_notes', value)}
              isDisabled={!isEditable || !fields.visa_notes?.edit}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  )
}

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })
)

ContactsStudentForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    student_id: PropTypes.string,
    is_community_member: PropTypes.bool,
    student_type_ids: PropTypes.arrayOf(PropTypes.number),
    institution_ids: PropTypes.arrayOf(PropTypes.number),
    banner_id: PropTypes.string,
    slc_number: PropTypes.string,
    ethnicity_type_id: PropTypes.number,
    disability_type_id: PropTypes.number,
    is_international: PropTypes.bool,
    nationality_type_id: PropTypes.number,
    has_disability: PropTypes.bool,
    disability_details: PropTypes.string,
    has_learning_support: PropTypes.bool,
    learning_support_details: PropTypes.string,
    academic_qualifications: PropTypes.string,
    other_qualifications: PropTypes.string,
    work_experience: PropTypes.string,
    marital_status_type_id: PropTypes.number,
    spouse_name: PropTypes.string,
    spouse_surname: PropTypes.string,
    children_notes: PropTypes.string,
    brief_notes: PropTypes.string,
    passport_expiry: PropTypes.string,
    visa_expiry: PropTypes.string,
    visa_notes: PropTypes.string,
    studied_at: PropTypes.string,
    institutions: PropTypes.string,
    qual_ent_3_id: PropTypes.number,
    qual_sbj_1_id: PropTypes.number,
    qual_type_1_id: PropTypes.number,
    qual_sbj_2_id: PropTypes.number,
    qual_type_2_id: PropTypes.number,
    qual_sbj_3_id: PropTypes.number,
    qual_type_3_id: PropTypes.number,
  }),
  studentTypeOptions: arrayOfValueLabelShape,
  studiedAtOptions: arrayOfValueLabelShape,
  deleteHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

ContactsStudentForm.defaultProps = {
  permissions: [],
  initialData: {
    student_id: null,
    is_community_member: null,
    student_type_ids: null,
    banner_id: null,
    slc_number: null,
    ethnicity_type_id: null,
    disability_type_id: null,
    is_international: null,
    nationality_type_id: null,
    has_disability: null,
    disability_details: null,
    has_learning_support: null,
    learning_support_details: null,
    academic_qualifications: null,
    other_qualifications: null,
    work_experience: null,
    marital_status_type_id: null,
    spouse_name: null,
    spouse_surname: null,
    children_notes: null,
    brief_notes: null,
    passport_expiry: null,
    visa_expiry: null,
    visa_notes: null,
    studied_at: null,
    qual_ent_3_id: null,
    qual_sbj_1_id: null,
    qual_type_1_id: null,
    qual_sbj_2_id: null,
    qual_type_2_id: null,
    qual_sbj_3_id: null,
    qual_type_3_id: null,
  },
  studentTypeOptions: [],
  studiedAtOptions: [],
  deleteHandler: () => {},
  errorHandler: () => {},
}

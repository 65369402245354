import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from '@Store/auth'
import { getFromLocalStorage, getFromSessionStorage } from '@Root/helpers'
import { axios } from '@Root/API'

export const CheckUserAuthentication = ({ children }) => {
	const dispatch = useDispatch()

	useEffect(() => {
		const token = getFromLocalStorage('token', true) || getFromSessionStorage('token', true)
		if (token) {
			dispatch(authActions.setToken(token))
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
		}
	}, [dispatch])

	return <>{children}</>
}

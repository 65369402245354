import { useState } from 'react'
import classes from './style.module.scss'
import TEIDLogo from '../../../icons/TEIDLogo/TEIDLogo'

export const SecurityForm = ({ handleSubmit, handleCancel, error }) => {
  const [code, setCode] = useState(null)

  return (
    <>
      <form
        className={classes.SecurityForm}
        onSubmit={e => {
          e.preventDefault()
          handleSubmit(code)
        }}
      >
        <TEIDLogo style={{ marginBottom: 20 }} />
        <label className={classes.Wrapper}>
          <span className={classes.Info}>Authentication code</span>
          <input
            type='text'
            className={error ? classes.Invalid : ''}
            placeholder={'Authentication code'}
            minLength='5'
            value={code}
            onChange={e => setCode(e.target.value)}
            required
          />
          {error && <div className={classes.Error}>{error}</div>}
        </label>
        <div className={classes.WrapperBtn}>
          <button type='button' className={classes.CancelBtn} onClick={handleCancel}>
            Cancel
          </button>
          <button type='submit' className={classes.SubmitBtn}>
            Send
          </button>
        </div>
      </form>
    </>
  )
}

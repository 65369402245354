import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onEdit: 'Edit the Entry Qualification Award', onRead: 'Entry Qualification Award Details', onCreate: 'Create Entry Qualification Award' }
export const EntryQualificationAwardForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  initialErrors,
  descriptions,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const memoData = useRef({})

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.qualification_result_id) memoData.current = initialValue
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  useEffect(() => {
    if (initialErrors.includes('qualification_year')) {
      setError('qualification_year', 'The qualification year must be between 1900 and 2155')
    }
    if (initialErrors.includes('qualification_award_identifier')) {
      setError('qualification_award_identifier', 'The qualification award identifier has already been taken')
    }
  }, [initialErrors])

  const validateForm = () => {
    const { qualification_award_identifier, qualification_result_id, qualification_type_id } = data
    if (!qualification_award_identifier) {
      setError('qualification_award_identifier', 'Required')
      return false
    }
    if (!qualification_result_id) {
      setError('qualification_result_id', 'Required')
      return false
    }
    if (!qualification_type_id) {
      setError('qualification_type_id', 'Required')
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const { qualification_award_identifier, own_award_identifier, qualification_result_id, qualification_type_id, qualification_year, id } = data
  const { qualification_types, qualification_results } = options
  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this entry qualification award' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={toggleEditable}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={id ? `entry_qualification_award_details_${id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Entry qualification awarded identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_award_identifier}
                onChange={onChangeValue('qualification_award_identifier')}
                placeholder='Entry qualification awarded identifier'
                isDisabled={!isEditable}
                maxLength={50}
                error={errors.qualification_award_identifier}
                description={descriptions?.qualification_award_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                isRequired
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Own qualification identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={own_award_identifier}
                onChange={onChangeValue('own_award_identifier')}
                placeholder='Own qualification identifier'
                isDisabled={!isEditable}
                maxLength={50}
                description={descriptions?.own_award_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Qualification result'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_result_id}
                field={'select'}
                onChange={onChangeValue('qualification_result_id')}
                placeholder='Qualification result'
                isDisabled={!isEditable}
                options={qualification_results}
                maxLength={11}
                optionsStyle={{ width: 260, overflow: 'inherit' }}
                error={errors.qualification_result_id}
                description={descriptions?.qualification_result_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                isRequired
                search={true}
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Qualification type identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_type_id}
                field={'select'}
                onChange={onChangeValue('qualification_type_id')}
                placeholder='Qualification result'
                isDisabled={!isEditable}
                options={qualification_types}
                maxLength={5}
                optionsStyle={{ width: 260, overflow: 'inherit' }}
                error={errors.qualification_type_id}
                description={descriptions?.qualification_type_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                isRequired
                search={true}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Qualification year'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_year}
                onChange={value => onChangeValue('qualification_year')(Number(value))}
                placeholder='Qualification year'
                isDisabled={!isEditable}
                error={errors.qualification_year}
                description={descriptions?.qualification_year}
                onOpenDescriptionModal={onOpenDescriptionModal}
                maxLength={4}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

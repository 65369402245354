import * as types from './postgrad.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleCanBeAttached: payload => ({
		type: types.TOGGLE_CAN_BE_ATTACHED,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_POSTGRAD,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_POSTGRAD,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_POSTGRAD,
		payload,
	}),
	set: payload => ({
		type: types.SET_POSTGRAD,
		payload,
	}),
	getStaticOptions: payload => ({
		type: types.GET_STATIC_OPTIONS,
		payload,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearPermissions: payload => ({
		type: types.CLEAR_PERMISSIONS,
		payload,
	}),
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { API } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { actions } from './attachment.actions'
import * as types from './attachment.types'
import objectToFormData from 'object-to-formdata'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, page } = payload
  console.log(id)
  try {
    const { data } = yield call(API.admissions.application.attachment.get, id, page)
    yield put(actions.set(data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.admissions.application.attachment.create, objectToFormData(payload))
    yield put(actions.setCreated(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The attachment has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, data } = payload
  try {
    const response = yield call(API.admissions.application.attachment.edit, id, objectToFormData(data))
    yield put(actions.setEdited(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The attachment has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  const { id } = payload
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.admissions.application.attachment.remove, id)
    yield put(actions.setDeleted({ id }))
    yield put(snackbarActions.setSuccessNotification({ text: 'The attachment has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getHistory({ payload }) {
  const { id, page } = payload
  try {
    const { data } = yield call(API.admissions.application.attachment.historyList.get, id, page)
    yield put(actions.setHistory(data))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ATTACHMENT, get),
    takeEvery(types.EDIT_ATTACHMENT, edit),
    takeEvery(types.DELETE_ATTACHMENT, remove),
    takeEvery(types.CREATE_ATTACHMENT, create),
    takeEvery(types.GET_HISTORY, getHistory),
  ])
}

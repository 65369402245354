import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import isEmail from 'validator/lib/isEmail'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onRead: 'Institution', onEdit: 'Edit Institution', onCreate: 'Create Institution' }
export const InstitutionForm = ({
  canEdit,
  isNew = false,
  initialValue,
  onDelete,
  onSave,
  onOpenDescriptionModal,
  descriptions,
  isLoading,
  onCancel,
  isSuccess,
  canDelete,
  options,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const { errors, setError, onClear: onClearError } = useError()
  const [data, setData] = useState({})

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  const onChangeValue = field => value => {
    setData({ ...data, [field]: value })
  }

  const formIsValid = () => {
    const { name, contact_email } = data
    if (!name) {
      setError('name', 'Required')
      return false
    }
    if (contact_email && !isEmail(contact_email)) {
      setError('contact_email', 'Invalid email')
      return false
    }
    onClearError()
    return true
  }

  const onCancelEditForm = () => {
    if (!isNew) {
      setData(initialValue)
      onClearError()
      toggleEditable()
      return
    }
    onCancel()
  }

  const onSubmit = async () => {
    if (!formIsValid()) return
    onSave(data)
  }

  const { onRead, onCreate, onEdit } = titles
  return (
    <div className={styles.wrapper}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Institution' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEditForm}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmit}
        canEdit={canEdit}
        isEditable={isEditable}
        readTitle={onRead}
        editTitle={onEdit}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={toggleEditable}
        isCreate={isNew}
        idForm={data?.id ? `institution_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <Field
              label={'Name'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.name}
              onChange={onChangeValue('name')}
              placeholder='Name'
              isDisabled={!isEditable}
              description={descriptions.name}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.name}
              isRequired={isEditable}
            />
            <Field
              label={'Address'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.address}
              onChange={onChangeValue('address')}
              placeholder='Address'
              isDisabled={!isEditable}
              description={descriptions.address}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
            />
            <Field
              label={'Contact Name'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.contact_name}
              onChange={onChangeValue('contact_name')}
              placeholder='Contact Name'
              isDisabled={!isEditable}
              description={descriptions.contact_name}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
            />
            <Field
              label={'Contact Phone'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.contact_phone}
              onChange={onChangeValue('contact_phone')}
              placeholder='Contact Phone'
              isDisabled={!isEditable}
              description={descriptions.contact_phone}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
            />
            <Field
              label={'Contact Email'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.contact_email}
              onChange={onChangeValue('contact_email')}
              placeholder='Contact Email'
              isDisabled={!isEditable}
              description={descriptions.contact_email}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.contact_email}
            />
          </div>
          <div className={styles.column}>
            <Field
              label={'Accommodation'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={data.accommodation_ids}
              options={options?.accomodation}
              onChange={onChangeValue('accommodation_ids')}
              placeholder={'Accommodation'}
              isDisabled={!isEditable}
              description={descriptions.accommodation_ids}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'multiSelect'}
            />
            <Field
              label={'Venue UKPRN'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.venue_ukprn}
              onChange={onChangeValue('venue_ukprn')}
              placeholder='Venue UKPRN'
              isDisabled={!isEditable}
              description={descriptions.venue_ukprn}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
            />
            <Field
              label={'Notes'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.notes}
              onChange={onChangeValue('notes')}
              placeholder='Notes'
              isDisabled={!isEditable}
              description={descriptions.notes}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textArea'}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

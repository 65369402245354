import React from 'react'
import classes from './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { CreateRole } from './Create'
import { EditRole } from './Edit'

const PARENT_URL = '/home/admin/manage-system-team/user-roles'
export const AdminRolePage = () => {
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname

	const isNew = pathname.includes('new')

	return (
		<div className={classes.wrapper} style={{ marginTop: 235 }}>
			<SectionWrapper hasBackButton backButtonText='User Roles' clickBackButtonHandler={() => history.push(PARENT_URL)}>
				{isNew ? <CreateRole /> : <EditRole />}
			</SectionWrapper>
		</div>
	)
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { QualificationAwardPage } from './Page'
import { QualificationAwardAccreditationPage } from './QualificationAwardAccreditation'
import { useDispatch } from 'react-redux'
import { qualificationAwardActions } from '@Store/studies'
import { modalActions } from '@Store/modal'

export const QualificationAwardDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const { id: engagementId, contactId, qualificationAwardAccreditationId, qualificationAwardId } = useParams()
  const otherTabId = pathname.split('/')[9]

  useEffect(() => {
    getDataOptionsFromLocalStorage(['qualification_award'])
  }, [])

  useEffect(() => {
    if (qualificationAwardId && qualificationAwardId !== 'new' && otherTabId) {
      dispatch(qualificationAwardActions.get({ data: { qualificationAwardId, engagementId, contactId }, history }))
      return () => {
        dispatch(qualificationAwardActions.clearData())
      }
    }
  }, [qualificationAwardAccreditationId, otherTabId])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
          title,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.sub_page}>
        <Switch>
          <Route
            path={'/home/studies/engagements/:contactId/:id/qualification-award/:qualificationAwardId?/qualification-award-accreditation/new'}
            component={() => QualificationAwardAccreditationPage({ onOpenDescriptionModal })}
            exact
          />
          <Route
            path={
              '/home/studies/engagements/:contactId/:id/qualification-award/:qualificationAwardId?/qualification-award-accreditation/:qualificationAwardAccreditationId'
            }
            component={() => QualificationAwardAccreditationPage({ onOpenDescriptionModal })}
            exact
          />
          <Route component={() => QualificationAwardPage({ onOpenDescriptionModal })} />
        </Switch>
      </div>
    </div>
  )
}

import React from 'react'
import { Redirect } from 'react-router-dom'
import { FundingAndMonitoringForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { fundingAndMonitoringSelectors, fundingAndMonitoringActions } from '@Store/studies'
import { permissionsSelectors } from '@Store/permissions'

export const CreateFundingMonitoring = ({ onOpenDescriptionModal, parentURL, isNew }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { contactId, studyProgrammeId } = useParams()
  const fundingAndMonitoring = useSelector(fundingAndMonitoringSelectors.fundingAndMonitoring)
  const options = useSelector(fundingAndMonitoringSelectors.options)
  const isLoading = useSelector(fundingAndMonitoringSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  const onCreate = data => {
    dispatch(fundingAndMonitoringActions.create({ studyProgrammeId, data, contactId, history }))
  }
  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }
  if (isNew && !dataCreationPermissions.student_course_session_sub) return <Redirect to={parentURL} />
  return (
    <FundingAndMonitoringForm
      isNew
      onSave={onCreate}
      onCancel={onCancel}
      initialValue={fundingAndMonitoring}
      options={options}
      isLoading={isLoading}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

export const TOGGLE_LOADING = 'fee/TOGGLE_LOADING'
export const TOGGLE_NEW_FEE = 'fee/TOGGLE_NEW_FEE'
export const TOGGLE_SUCCESS = 'fee/TOGGLE_SUCCESS'
export const CREATE_FEE = 'fee/CREATE_FEE'
export const SET_FEE = 'fee/SET_FEE'
export const EDIT_FEE = 'fee/EDIT_FEE'
export const DELETE_FEE = 'fee/DELETE_FEE'
export const GET_OPTIONS = 'fee/GET_OPTIONS'
export const SET_OPTIONS = 'fee/SET_OPTIONS'
export const SET_PERMISSIONS = 'fee/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'fee/CLEAR_PERMISSIONS'

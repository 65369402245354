import React from 'react'
import classes from './style.module.scss'
import { permissionsConfig } from '@Root/configs'
import { MainAccordionManager } from './MainManager'
import { AdditionalAccordionManager } from './AdditionalManager'
import { AdvancedAdditionalAccordionManager } from './AdvancedAdditionalManager'

export class Accordion extends React.Component {
	render() {
		const {
			onDataChange,
			mainData,
			isEditable,
			additionalData,
			roleId,
			initialMainCategoryId,
			initialCategoryId,
			initialSubCategoryId,
			initialMainAccordion,
			roleName,
			saveRoleIntoStorage,
			isSuccess,
		} = this.props

		return (
			<div className={classes.wrapper}>
				<ul className={classes.accordion_list}>
					{permissionsConfig.map(config => {
						return (
							<li className={classes.accordion_list__item} key={config.label} id={config.label}>
								{!config.isCheckboxes ? (
									<MainAccordionManager
										permissionType={config}
										data={mainData}
										onDataChange={onDataChange('permissions')}
										isEditable={isEditable}
										roleId={roleId}
										initialMainCategoryId={initialMainCategoryId}
										initialCategoryId={initialCategoryId}
										initialSubCategoryId={initialSubCategoryId}
										initialMainAccordion={initialMainAccordion}
										roleName={roleName}
										saveRoleIntoStorage={saveRoleIntoStorage}
										isSuccess={isSuccess}
									/>
								) : config.isCheckboxes && !config.advanced ? (
									<AdditionalAccordionManager
										permissionType={config}
										data={additionalData[config.source_field_label]}
										onDataChange={onDataChange(config.source_field_label)}
										isEditable={isEditable}
										isSuccess={isSuccess}
									/>
								) : (
									<AdvancedAdditionalAccordionManager
										permissionType={config}
										data={additionalData[config.source_field_label]}
										onDataChange={onDataChange(config.source_field_label)}
										isEditable={isEditable}
										isSuccess={isSuccess}
									/>
								)}
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
}

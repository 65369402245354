import { all } from 'redux-saga/effects'
import { userRootSaga } from './user'
import { qualificationRootSaga } from './qualification'
import { venueRootSaga } from './venue'
import { institutionRootSaga } from './institution'
import { roleRootSaga } from './role'
import { fieldConstructorRootSaga } from './fieldConstuctor'
import { moodleRootSaga } from './moodle'
import { snapshotRootSaga } from './snapshot'
import { studentTypesRootSaga } from './studentTypes'
import { tableManagerRootSaga } from './tableManager'
import { sessionYearRootSaga } from './sessionYear'

export function* rootSaga() {
	yield all([
		userRootSaga(),
		qualificationRootSaga(),
		venueRootSaga(),
		institutionRootSaga(),
		roleRootSaga(),
		fieldConstructorRootSaga(),
		moodleRootSaga(),
		snapshotRootSaga(),
		studentTypesRootSaga(),
		tableManagerRootSaga(),
		sessionYearRootSaga(),
	])
}

import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { popupHeight, all } from '@Root/helpers'
import { CustomScrollbar, InputError, PositionWrapper } from '@Root/HOCs'
import { TextInput } from '../TextInput'
import { CrossIcon, PlusIcon } from '@Root/assets'
import { SaveButton, Spinner } from '@Root/components'
import { ArrowIcon } from '@Root/assets'
import { NO_DATA_AVAILABLE } from '@Root/configs'
import { Link } from 'react-router-dom'
export class List extends Component {
  state = {
    popupIsShown: false,
    filter: '',
    options: [],
    selectedOptions: [],
    initialOptionIsFetching: false,
    optionsAreFetching: false,
    error: null,
  }

  handleClickOutside = () => {
    this.setState({ popupIsShown: false })
  }

  showError = async error => {
    clearTimeout(this.timeout)
    this.setState({ error })
  }

  fetchOptions = () => {
    this.timeout = setTimeout(async () => {
      this.setState({ optionsAreFetching: true })
      try {
        const options = (await this.props.fetchOptionsHandler(this.state.filter)) || []
        !this.isUnmounted && this.state.filter && this.setState({ options })
      } catch (error) {
        this.props.errorHandler(error)
      }
      !this.isUnmounted && this.setState({ optionsAreFetching: false })
    }, 1000)
  }

  handleAdd = option => {
    const { selectedOptions } = this.state
    if (selectedOptions.find(selectedOption => selectedOption.value === option.value)) return
    this.setState({ selectedOptions: [...selectedOptions, option] })
  }

  handleRemove = option => {
    const { selectedOptions } = this.state
    this.setState({ selectedOptions: selectedOptions.filter(selectedOption => selectedOption.value !== option.value) })
  }

  getLabels = async () => {
    const { values, fetchLabelHandler } = this.props
    if (values && values !== NO_DATA_AVAILABLE.label) {
      this.setState({ initialOptionIsFetching: true })
      try {
        const labels = await Promise.all(values.map(value => fetchLabelHandler(value)))
        !this.isUnmounted && this.setState({ selectedOptions: values.map((value, i) => ({ value, label: labels[i] })) })
      } catch (error) {
        this.props.errorHandler(error)
      }
      !this.isUnmounted && this.setState({ initialOptionIsFetching: false })
    }
  }

  getInitialItems = async () => {
    const { fetchInitialHandler } = this.props
    try {
      const options = (await fetchInitialHandler(this.state.filter)) || []
      this.setState({ options })
    } catch (error) {
      this.props.errorHandler(error)
    }
  }

  onSearch = filter => {
    this.setState({ filter })
  }

  onTogglePopup = () => {
    this.setState(prevState => ({ popupIsShown: !prevState.popupIsShown }))
  }

  async componentDidMount() {
    const { withInitialList } = this.props
    if (withInitialList) await this.getInitialItems()
    await this.getLabels()
  }

  async componentDidUpdate(prevProps, prevState) {
    const { error, changeHandler, withInitialList } = this.props
    const { filter, selectedOptions } = this.state
    if (error !== prevProps.error) {
      error ? this.showError(error) : this.showError(null)
    }
    if (filter !== prevState.filter) {
      clearTimeout(this.timeout)
      filter ? this.fetchOptions() : withInitialList ? await this.getInitialItems() : this.setState({ options: [], optionsAreFetching: false })
    }
    if (selectedOptions && selectedOptions !== prevState.selectedOptions) {
      const newValues = selectedOptions.length ? selectedOptions.map(option => option.value) : null
      changeHandler(newValues)
    }
    if (prevProps.values === null && !!this.props.values) {
      await this.getLabels()
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearTimeout(this.timeout)
  }

  render() {
    const { popupIsShown, filter, options, selectedOptions, initialOptionIsFetching, optionsAreFetching, error } = this.state
    const {
      values,
      searchPlaceholder,
      changeHandler,
      inputClassNames,
      inputStyle,
      maxVisibleOptionsQuantity,
      isDisabled,
      listStyle,
      useModal,
      isOrganisations = false,
      isAllContact = false,
    } = this.props

    const { handleAdd, handleRemove } = this
    const wasSelected = option => !!selectedOptions.find(selectedOption => selectedOption.value === option.value)
    const labels = selectedOptions.map(option => option.label).join(', ')
    const isNotDataAvailable = values === NO_DATA_AVAILABLE.label
    return (
      <div className={classes.wrapper}>
        <InputError error={error}>
          <div
            className={`${classes.input} ${isDisabled ? classes.disabled : ''} 
                        ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
            style={inputStyle}
            onClick={() =>
              all(
                () => !isDisabled && this.setState({ popupIsShown: !popupIsShown, filter: '' }),
                () => this.setState({ error: null })
              )
            }
          >
            {!isNotDataAvailable ? (
              values ? (
                isOrganisations || isAllContact ? (
                  <CustomScrollbar horizontalOnly>
                    <div className={`${classes.linkWrapper} ${!isDisabled ? classes.disabled : ''}`}>
                      {selectedOptions.map((option, idx, arr) => (
                        <Link key={idx} className={classes.value} to={isDisabled && `/home/contacts/all-contacts/${option.value}`} style={{ padding: '0 3px' }}>
                          {option.label}
                          {arr.length > 1 && arr.length - 1 !== idx && ','}
                        </Link>
                      ))}
                    </div>{' '}
                  </CustomScrollbar>
                ) : (
                  <div className={classes.valueOther}>{labels}</div>
                )
              ) : (
                <div className={`${classes.value} ${classes.empty}`} />
              )
            ) : (
              <div className={classes.value}>{NO_DATA_AVAILABLE.value}</div>
            )}
            {initialOptionIsFetching && (
              <div className={classes.spinner}>
                <Spinner size='extra-small' color='dark' />
              </div>
            )}
            {!isDisabled && !isNotDataAvailable && (
              <>
                {!initialOptionIsFetching && values && !isAllContact && (
                  <div className={classes.xIcon}>
                    <img
                      src={CrossIcon}
                      alt=''
                      onClick={event =>
                        all(
                          () => event.stopPropagation(),
                          () => changeHandler(null),
                          () => this.setState({ selectedOptions: [] })
                        )
                      }
                    />
                  </div>
                )}
                <div className={classes.icon}>
                  <img style={popupIsShown ? { transform: 'rotate(180deg)' } : null} src={ArrowIcon} alt='' />
                </div>
              </>
            )}
          </div>
        </InputError>
        {popupIsShown && !useModal ? (
          <div className={classes.popup} style={listStyle}>
            <div style={{ height: popupHeight(selectedOptions.length, maxVisibleOptionsQuantity, 38) }}>
              <CustomScrollbar verticalOnly>
                {selectedOptions.map((option, i) => (
                  <div className={`${classes.option} ${classes.hover}`} key={i}>
                    {option.label}
                    {!isAllContact && (
                      <div className={classes.xIcon}>
                        <img src={CrossIcon} alt='' onClick={() => handleRemove(option)} />
                      </div>
                    )}
                  </div>
                ))}
              </CustomScrollbar>
            </div>

            {!isAllContact && (
              <div className={classes.search}>
                <TextInput classNames={['transparent']} value={filter} changeHandler={this.onSearch} placeholder={searchPlaceholder} />
                {optionsAreFetching && (
                  <div className={classes.spinner}>
                    <Spinner size='extra-small' color='dark' />
                  </div>
                )}
              </div>
            )}
            <div style={{ height: popupHeight(options.length, maxVisibleOptionsQuantity, 38) }}>
              <CustomScrollbar verticalOnly>
                {options.map((option, i) => (
                  <div className={`${classes.option} ${!wasSelected(option) ? classes.hover : ''}`} onClick={() => handleAdd(option)} key={i}>
                    {option.label}
                    {!wasSelected(option) && (
                      <div className={classes.xIcon}>
                        <img src={PlusIcon} alt='' />
                      </div>
                    )}
                  </div>
                ))}
              </CustomScrollbar>
            </div>
          </div>
        ) : (
          popupIsShown && (
            <ModalDataManipulation
              data={selectedOptions}
              maxVisibleOptionsQuantity={maxVisibleOptionsQuantity}
              onRemove={handleRemove}
              onAdd={handleAdd}
              filter={filter}
              optionsAreFetching={optionsAreFetching}
              searchPlaceholder={searchPlaceholder}
              wasSelected={wasSelected}
              options={options}
              onSearch={this.onSearch}
              onSubmit={this.onTogglePopup}
            />
          )
        )}
      </div>
    )
  }
}

const ModalDataManipulation = ({
  data,
  onRemove,
  maxVisibleOptionsQuantity,
  filter,
  optionsAreFetching,
  searchPlaceholder,
  options,
  onAdd,
  wasSelected,
  onSearch,
  onSubmit,
}) => {
  return (
    <PositionWrapper>
      <div className={classes.modal}>
        <div>
          <p>Add: </p>
          <div style={{ height: popupHeight(data.length, maxVisibleOptionsQuantity, 48) }}>
            <CustomScrollbar verticalOnly>
              <ul className={classes.items}>
                {data.map((option, i) => (
                  <li className={`${classes.item} ${classes.hover}`} onClick={() => onRemove(option)} key={i}>
                    {option.label}
                    <div className={classes.xIcon}>
                      <img src={CrossIcon} alt='' />
                    </div>
                  </li>
                ))}
              </ul>
            </CustomScrollbar>
          </div>
        </div>
        <div className={classes.search}>
          <TextInput classNames={['transparent']} value={filter} changeHandler={onSearch} placeholder={searchPlaceholder} />
          {optionsAreFetching && (
            <div className={classes.spinner}>
              <Spinner size='extra-small' color='dark' />
            </div>
          )}
        </div>
        <div style={{ height: popupHeight(options.length, maxVisibleOptionsQuantity, 38) }}>
          <CustomScrollbar verticalOnly>
            <ul className={classes.popup}>
              {options.map((option, i) => (
                <li className={`${classes.option} ${!wasSelected(option) ? classes.hover : ''}`} onClick={() => onAdd(option)} key={i}>
                  {option.label}
                  {!wasSelected(option) && (
                    <div className={classes.xIcon}>
                      <img src={PlusIcon} alt='' />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </CustomScrollbar>
        </div>
        <div className={classes.actions}>
          <SaveButton onClick={onSubmit}>Submit</SaveButton>
        </div>
      </div>
    </PositionWrapper>
  )
}

List.propTypes = {
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
  inputStyle: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])),
  fetchLabelHandler: PropTypes.func,
  fetchOptionsHandler: PropTypes.func,
  fetchInitialHandler: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  maxVisibleOptionsQuantity: PropTypes.number,
  error: PropTypes.string,
  errorHandler: PropTypes.func,
  withInitialList: PropTypes.bool,
  useModal: PropTypes.bool,
}

List.defaultProps = {
  inputClassNames: [],
  inputStyle: {},
  listStyle: {},
  values: null,
  fetchLabelHandler: () => {},
  fetchOptionsHandler: () => {},
  fetchInitialHandler: () => {},
  searchPlaceholder: 'Type to search',
  changeHandler: () => {},
  isDisabled: false,
  maxVisibleOptionsQuantity: 3,
  error: null,
  errorHandler: error => {
    console.log(error)
  },
  withInitialList: false,
  useModal: false,
}
export const MultiDataListAsync = onClickOutside(List)

import * as types from './modal.types'

export const showModal = (component, props) => {
	return {
		type: types.SHOW_MODAL,
		component,
		props,
	}
}

export const hideModal = () => {
	return {
		type: types.HIDE_MODAL,
	}
}

export const TOGGLE_LOADING = 'studentCourseSession/note/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/note/TOGGLE_SUCCESS'
export const GET_NOTE = 'studentCourseSession/note/GET_NOTE'
export const SET_NOTES = 'studentCourseSession/note/SET_NOTES'
export const CREATE_NOTE = 'studentCourseSession/note/CREATE_NOTE'
export const SET_CREATED_NOTE = 'studentCourseSession/note/SET_CREATED_NOTE'
export const EDIT_NOTE = 'studentCourseSession/note/EDIT_NOTE'
export const SET_EDITED_NOTE = 'studentCourseSession/note/SET_EDITED_NOTE'
export const DELETE_NOTE = 'studentCourseSession/note/DELETE_NOTE'
export const SET_DELETED_NOTE = 'studentCourseSession/note/SET_DELETED_NOTE'
export const SET_PERMISSIONS = 'studentCourseSession/note/SET_PERMISSIONS'

export const isLoading = state => state.studiesReducer.engagement.accreditationAim.isLoading

export const isSuccess = state => state.studiesReducer.engagement.accreditationAim.isSuccess

export const accreditationAim = state => state.studiesReducer.engagement.accreditationAim.accreditationAim

export const errors = state => state.studiesReducer.engagement.accreditationAim.errors

export const permissions = state => state.studiesReducer.engagement.accreditationAim.permissions

export const options = state => state.studiesReducer.engagement.accreditationAim.options

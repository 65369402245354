import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './leaver.types'
import { actions } from './leaver.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { engagementId, contactId, leaverId } = data
  try {
    const response = yield call(API.studies.engagement.leaver.get, engagementId, leaverId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/leaver`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, engagementId, contactId } = payload
  try {
    const response = yield call(API.studies.engagement.leaver.create, engagementId, data)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/leaver/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The leaver has been created successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    if (errors['student_id']) {
      history.push('/home/studies/engagements')
    } else {
      yield put(actions.set(data))
      yield put(actions.setErrors(errors))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { engagementId, data } = payload
  try {
    const response = yield call(API.studies.engagement.leaver.edit, engagementId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The leaver has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId } = data
  try {
    yield call(API.studies.engagement.leaver.remove, engagementId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/leaver`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The leaver has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { reasons },
    } = yield call(API.studies.engagement.leaver.options.getStatic)
    const formattedData = {
      reasons: reasons.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('engagement_leaver', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_LEAVER, get),
    takeEvery(types.CREATE_LEAVER, create),
    takeEvery(types.EDIT_LEAVER, edit),
    takeEvery(types.DELETE_LEAVER, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

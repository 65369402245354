import { axios } from '@Root/API'
import objectToFormdata from 'object-to-formdata'

export const check = file => {
  return axios.post('check-instance-modules-csv', objectToFormdata({ file }), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

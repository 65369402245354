import * as types from './student.types'
import { SET_PERMISSIONS } from './student.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  student: {},
  information: {},
  options: {
    static: {},
    dynamic: {},
  },
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_STUDENT:
      return {
        ...state,
        student: {
          ...state.student,
          ...action.payload,
        },
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_INFORMATION:
      return {
        ...state,
        information: action.payload,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    default:
      return state
  }
}

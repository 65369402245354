export const isLoading = state => state.studiesReducer.engagement.reducer.isLoading

export const isSuccess = state => state.studiesReducer.engagement.reducer.isSuccess

export const engagement = state => state.studiesReducer.engagement.reducer.engagement

export const errors = state => state.studiesReducer.engagement.reducer.errors

export const permissions = state => state.studiesReducer.engagement.reducer.permissions

export const options = state => state.studiesReducer.engagement.reducer.options

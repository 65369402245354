import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './costCenter.types'
import { actions } from './costCenter.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { id, history } = payload
	try {
		const { data } = yield call(API.studies.module.costCenter.get, id)
		yield put(actions.set(data.data))
		yield put(actions.setPermissions({ ...data.permissions, edit: true }))
	} catch (error) {
		history.push('/home/studies/engagements')
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { history, data, moduleId } = payload
	try {
		yield call(API.module.costCenter.create, data)
		history.push(`/home/studies/modules/${moduleId}/module-cost-center`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The module cost center has been created successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(API.module.costCenter.edit, payload)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The module cost center has been saved successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { history, id, moduleId } = payload
	try {
		yield call(API.module.costCenter.remove, id)
		history.push(`/home/studies/modules/${moduleId}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The module cost center has been deleted successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
	yield put(actions.toggleLoading(true))
	try {
		const {
			data: { hesa_cost_centres },
		} = yield call(API.module.costCenter.options.getStatic)
		yield put(
			actions.setOptions({
				name: 'static',
				data: {
					hesa_cost_centres: hesa_cost_centres.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
				},
			})
		)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_COST_CENTER, get),
		takeEvery(types.CREATE_COST_CENTER, create),
		takeEvery(types.EDIT_COST_CENTER, edit),
		takeEvery(types.DELETE_COST_CENTER, remove),
		takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
	])
}

import { axios } from "@Root/API";
import objectToFormdata from "object-to-formdata";

export const putNewAttachment = (contact_id, file, description, id) => {
  return axios.post(`upload/attachment/${id}`, objectToFormdata({ contact_id, file, description }), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './permissions.types'
import { actions } from './permissions.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* getAdditionalPermissions({ payload }) {
	yield put(actions.toggleLoading(true))

	try {
		const { data } = yield call(API.permissions.getAdditionalPermissions, payload)
		yield put(actions.setAdditional(data))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* getDataCreationPermissions() {
	yield put(actions.toggleLoading(true))
	try {
		const { data } = yield call(API.permissions.getDataCreationPermissions)
		yield put(actions.setDataCreation(data))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* getDataDeletingPermissions() {
	yield put(actions.toggleLoading(true))
	try {
		const { data } = yield call(API.permissions.getDataCreationPermissions)
		yield put(actions.setDataDeleting(data))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* getRulePermissions() {
	yield put(actions.toggleLoading(true))
	try {
		const { data } = yield call(API.permissions.getRulesPermissions)
		yield put(actions.setRule(data))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* getMainPermissions({ payload }) {
	try {
		const data = { entity: 'note_student_course_session', contactId: '12312' }
		// const { data } = yield call(API.permissions.getRulesPermissions)
		// console.log('data', data)
		//Example setting data
		// yield put(actions.setMain({ [payload.entity]: data }))

		yield put(
			actions.setMain({
				person: { edit: true, delete: true, view: true },
				organisation: { edit: true, delete: true, view: true },
				consent: { edit: true, delete: true, view: true },
				diocese: { edit: true, delete: true, view: true },
				training_facility: { edit: true, delete: true, view: true },
				church: { edit: true, delete: true, view: true },
				association: { edit: true, delete: true, view: true },
				cleric: { edit: true, delete: true, view: true },
				edit: { edit: true, delete: true, view: true },
				academic: { edit: true, delete: true, view: true },
			})
		)
	} catch (error) {
		console.log(error)
	}
}

function* getTabsPermissions() {
	yield put(actions.toggleLoading(true))
	try {
		const { data } = yield call(API.permissions.getMainTabsPermissions)
		yield put(actions.setTabs(data))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_ADDITIONAL_PERMISSIONS, getAdditionalPermissions),
		takeEvery(types.GET_DATA_CREATION_PERMISSIONS, getDataCreationPermissions),
		takeEvery(types.GET_DATA_DELETING_PERMISSIONS, getDataDeletingPermissions),
		takeEvery(types.GET_RULE_PERMISSIONS, getRulePermissions),
		takeEvery(types.GET_MAIN_PERMISSIONS, getMainPermissions),
		takeEvery(types.GET_TABS_PERMISSIONS, getTabsPermissions),
	])
}

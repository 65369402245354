import React from 'react'
import styles from './style.module.scss'
import { Route, Switch } from 'react-router'
import { StudentCourseSessionPage } from './Page'
import { VenueActivityPage } from './venueActivityPage'
import { ReferencePeriodPage } from './referencePeriodPage'
import { StudyLocationPage } from './studyLocationPage'
import { StudentFinancialSupportPage } from './studentFinancialSupportPage'
import { SessionStatusPage } from './sessionStatusPage'
import { FundingBodyPage } from './fundingBodyPage'
import { ModuleInstancePage } from './moduleInstancePage'
import { SupervisorAllocationPage } from './supervisorAllocationPage'
import { FundingAndMonitoringPage } from './fundingAndMonitoringPage'
import { PostgradPage } from './postgradPage'
import { useLocation } from 'react-router'

export const StudentCourseSessionDetails = () => {
  const location = useLocation()
  const duplicate = location.pathname.split('/')[8]
  const isDuplicate = duplicate === 'duplicate'
  return (
    <div className={styles.wrapper}>
      <div className={styles.sub_page}>
        <Switch>
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/off_venue_activity/new'} component={VenueActivityPage} exact />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/off_venue_activity/:venueActivityId'}
              component={VenueActivityPage}
              exact
            />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/reference_period_student_load/new'}
              component={ReferencePeriodPage}
              exact
            />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/reference_period_student_load/:referencePeriodId'}
              component={ReferencePeriodPage}
              exact
            />
          )}

          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/study_location/new'} component={StudyLocationPage} exact />
          )}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/study_location/:studyLocationId'} component={StudyLocationPage} exact />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/student_financial_support/new'}
              component={StudentFinancialSupportPage}
              exact
            />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/student_financial_support/:studentFinancialSupportId'}
              component={StudentFinancialSupportPage}
              exact
            />
          )}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/session_status/new'} component={SessionStatusPage} exact />
          )}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/session_status/:sessionStatusId'} component={SessionStatusPage} exact />
          )}
          {!isDuplicate && <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/funding_body/new'} component={FundingBodyPage} exact />}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/funding_body/:fundingBodyId'} component={FundingBodyPage} exact />
          )}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/module_instances/new'} component={ModuleInstancePage} exact />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/module_instances/:moduleInstancesId'}
              component={ModuleInstancePage}
              exact
            />
          )}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/supervisor_allocation/new'} component={SupervisorAllocationPage} exact />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/supervisor_allocation/:supervisorAllocationId'}
              component={SupervisorAllocationPage}
              exact
            />
          )}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/funding-and-monitoring/new'} component={FundingAndMonitoringPage} exact />
          )}
          {!isDuplicate && (
            <Route
              path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/funding-and-monitoring/:fundingMonitoringId'}
              component={FundingAndMonitoringPage}
              exact
            />
          )}
          {!isDuplicate && <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/post_grad/new'} component={PostgradPage} exact />}
          {!isDuplicate && (
            <Route path={'/home/studies/programmes/study/:contactId/:studyProgrammeId/post_grad/:postgradResearchId'} component={PostgradPage} exact />
          )}
          <Route component={StudentCourseSessionPage} />
        </Switch>
      </div>
    </div>
  )
}

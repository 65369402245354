import React from "react";
import "./style.scss";

export const PasswordRecoverySuccessForm = () => {
  return (
    <div className="PasswordRecoverySuccessForm">
      <div className="title">Congratulations</div>
      <div className="text">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem earum numquam facere, modi soluta sunt id voluptatem similique veritatis fuga rem ipsam
        distinctio non error est quisquam a beatae cum! Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </div>
    </div>
  );
};

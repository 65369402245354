import * as types from './auth.types'
import updateState from '../../helpers/updateState'

const initialState = {
  isLoading: false,
  token: null,
  error: null,
  recoveryToken: null,
  isGoogle2fa_active: false,
  isForce_activated: false,
  qr_code: null,
  google2fa_secret: null,
  user: {},
}

const toggleLoading = (state, action) => {
  return updateState(state, {
    isLoading: action.payload,
  })
}

const setToken = (state, action) => {
  return updateState(state, {
    token: action.token,
  })
}
const setTwoFactoreRedirect = (state, action) => {
  return updateState(state, {
    isGoogle2fa_active: action.payload.google2fa_active,
    isForce_activated: action.payload.is_force_activated,
    qr_code: action.payload.qr_code,
    google2fa_secret: action.payload.google2fa_secret,
  })
}

const setAuthError = (state, action) => {
  return updateState(state, {
    error: action.error,
  })
}

const setRecoveryToken = (state, action) => {
  return updateState(state, {
    recoveryToken: action.token,
  })
}

const setUser = (state, action) => {
  return updateState(state, {
    user: action.user,
  })
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return toggleLoading(state, action)
    case types.SET_TOKEN:
      return setToken(state, action)
    case types.SET_AUTH_ERROR:
      return setAuthError(state, action)
    case types.SET_RECOVERY_TOKEN:
      return setRecoveryToken(state, action)
    case types.SET_USER:
      return setUser(state, action)
    case types.SET_2FA_CODE:
      return setTwoFactoreRedirect(state, action)
    default:
      return state
  }
}

import * as types from './options.types'

export const actions = {
	getDataForCreateContact: () => ({
		type: types.GET_DATA_FOR_CREATE_CONTACT,
	}),
	setDataForCreateContact: payload => ({
		type: types.SET_DATA_FOR_CREATE_CONTACT,
		payload,
	}),
	getCountries: () => ({
		type: types.GET_COUNTRIES,
	}),
	setCountries: payload => ({
		type: types.SET_COUNTRIES,
		payload,
	}),
	getInstitutions: () => ({
		type: types.GET_INSTITUTIONS,
	}),
	setInstitutions: payload => ({
		type: types.SET_INSTITUTIONS,
		payload,
	}),
	getAdmissionBapStatuses: () => ({
		type: types.GET_ADMISSION_BAP_STATUSES,
	}),
	setAdmissionBapStatuses: payload => ({
		type: types.SET_ADMISSION_BAP_STATUSES,
		payload,
	}),
	getTCBResponse: () => ({
		type: types.GET_TCB_RESPONSE,
	}),
	setTCBResponse: payload => ({
		type: types.SET_TCB_RESPONSE,
		payload,
	}),
	getUOBResponse: () => ({
		type: types.GET_UOB_RESPONSE,
	}),
	setUOBResponse: payload => ({
		type: types.SET_UOB_RESPONSE,
		payload,
	}),
	getStatuses: () => ({
		type: types.GET_STATUSES,
	}),
	setStatuses: payload => ({
		type: types.SET_STATUSES,
		payload,
	}),
	getFinanceFundingStatic: () => ({
		type: types.GET_FINANCE_FUNDING_STATIC,
	}),
	setFinanceFunding: payload => ({
		type: types.SET_FINANCE_FUNDING,
		payload,
	}),
	getFinanceTuitionStatic: () => ({
		type: types.GET_FINANCE_TUITION_STATIC,
	}),
	setFinanceTuition: payload => ({
		type: types.SET_FINANCE_TUITION,
		payload,
	}),
	getStudiesSuspensions: () => ({
		type: types.GET_STUDIES_SUSPENSIONS_OPTIONS,
	}),
	setStudiesSuspensions: payload => ({
		type: types.SET_STUDIES_SUSPENSIONS_OPTIONS,
		payload,
	}),
	getRoles: payload => ({
		type: types.GET_ROLES,
		payload,
	}),
	setRoles: payload => ({
		type: types.SET_ROLES,
		payload,
	}),
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './programme.types'
import { API } from '@Root/API'
import { actions } from './programme.actions'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { courseInitiativeRootSaga } from './courseInitiative'
import { courseReferenceRootSaga } from './courseReference'
import { courseRoleRootSaga } from './courseRole'
import { curriculumAccreditationRootSaga } from './curriculumAccreditation'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { programmeId, history } = payload
  try {
    const { data } = yield call(API.studies.programme.get, programmeId)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    history.push('/page-not-found')
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { programmeId, data } = payload
  try {
    const response = yield call(API.studies.programme.edit, { program_id: programmeId, ...data })
    yield put(actions.set(response.data.data))
    yield put(snackbarActions.setSuccessNotification({ text: 'The programme has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { programmeId, history } = payload
  try {
    yield call(API.studies.programme.remove, programmeId)
    history.push('/home/studies/programmes')
    yield put(snackbarActions.setSuccessNotification({ text: 'The programme has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.studies.programme.create, data)
    yield put(actions.set(response.data.data))
    history.push(`/home/studies/programmes/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The programme has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const { data } = yield call(API.studies.options.getStatic)
    const {
      academic_years_list = [],
      course_delivery_mode_type = [],
      course_delivery_type = [],
      designated_course = [],
      programme_modes = [],
      programme_years = [],
      reason_for_student_course_session_ending = [],
      eacher_training_course_identifier = [],
      genders = [],
      study_years = [],
      sponsors = [],
      learning_centre = [],
      bittms = [],
      clsdcrses = [],
      course_pre_requisites = [],
      course_sandwiches = [],
      teacher_training_course_identifier = [],
      course_flexible_indicators = [],
      qualifications = [],
    } = data
    const formattedData = {
      reason_for_student_course_session_ending: reason_for_student_course_session_ending.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      academic_years_list: academic_years_list.map(obj => ({ value: obj.id, label: obj.name })),
      programme_modes: programme_modes.map(obj => ({ value: obj.id, label: obj.label })),
      course_delivery_type: course_delivery_type.map(obj => ({ value: obj.id, label: obj.label })),
      designated_course: designated_course.map(obj => ({ value: obj.id, label: obj.label })),
      programme_years: programme_years.map(obj => ({ value: obj.id, label: obj.label })),
      eacher_training_course_identifier: eacher_training_course_identifier.map(obj => ({ value: obj.id, label: obj.label })),
      course_delivery_mode_type: course_delivery_mode_type.map(obj => ({ value: obj.id, label: obj.label })),
      genders: genders.map(obj => ({ value: obj.id, label: obj.label })),
      study_years: study_years.map(obj => ({ value: obj.id, label: obj.name })),
      sponsors: sponsors.map(obj => ({ value: obj.id, label: obj.label })),
      learning_centre: learning_centre.map(obj => ({ value: obj.id, label: obj.name })),
      bittms: bittms.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      clsdcrses: clsdcrses.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      pre_requisites: course_pre_requisites.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      sandwichs: course_sandwiches.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      ttcids: teacher_training_course_identifier.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      flexible_indicators: course_flexible_indicators.map(obj => ({ value: obj.id, label: obj.label })),
      qualifications: qualifications.map(obj => ({ value: obj.id, label: obj.qualification_title })),
    }
    yield put(actions.setOptions({ name: 'static', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}
function* getDynamicOptions() {
  try {
    const { data } = yield call(API.studies.options.getDynamic)
    const { modules = [], program_awarding_bodies = [], program_types = [], venues = [], status = [], managed_by = [], ordinand = [], institution = [] } = data
    const formattedData = {
      program_types: program_types.map(obj => ({ value: obj.id, label: obj.name })),
      modules: modules.map(obj => ({ value: obj.id, label: obj.module_title, credit: obj.credit_value_of_module })),
      venues: venues.map(obj => ({ value: obj.id, label: obj.venue_name })),
      program_awarding_bodies: program_awarding_bodies.map(obj => ({ value: obj.id, label: obj.name })),
      status: status.map(obj => ({ value: obj.id, label: obj.label })),
      managed_by: managed_by.map(obj => ({ value: obj.id, label: obj.name })),
      ordinand: ordinand.map(obj => ({ value: obj.id, label: obj.name })),
      institutions: institution.map(obj => ({ value: obj.id, label: obj.name })),
    }
    yield put(actions.setOptions({ name: 'dynamic', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getProgrammesListOptions() {
  try {
    const { data } = yield call(API.getProgrammesListOptions)
    yield put(
      actions.setProgrammesOptions(
        data.map(obj => ({ value: obj.id, label: obj.programme_name, type: obj.programme_type, course_identifier: obj.course_identifier }))
      )
    )
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}
function* getPostgardSelectOptions() {
  try {
    const { data } = yield call(API.getPostgradStudentSelectOptions)
    const generateObject = arr =>
      arr.map(obj => {
        if (obj.name) return { value: obj.id, label: obj.name }
        if (obj.label && obj.code) {
          return { value: obj.id, label: `${obj.code} - ${obj.label}` }
        } else {
          return { value: obj.id, label: obj.label }
        }
      })
    const formattedData = {
      academic_years_list: generateObject(data.academic_years_list),
      managed_by: generateObject(data.managed_by),
      programme_modes: generateObject(data.programme_modes),
      status: generateObject(data.status),
      reason_course_session_ending: generateObject(data.reason_course_session_ending),
    }
    yield put(actions.setProgrammesPostgardOptions(formattedData))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}
export function* rootSaga() {
  yield all([
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS, getDynamicOptions),
    takeEvery(types.CREATE_PROGRAMME, create),
    takeEvery(types.GET_PROGRAMME, get),
    takeEvery(types.EDIT_PROGRAMME, edit),
    takeEvery(types.DELETE_PROGRAMME, remove),
    takeEvery(types.GET_PROGRAMMES_OPTIONS, getProgrammesListOptions),
    takeEvery(types.GET_PROGRAMMES_POSTGARD_OPTIONS, getPostgardSelectOptions),
    courseInitiativeRootSaga(),
    courseReferenceRootSaga(),
    courseRoleRootSaga(),
    curriculumAccreditationRootSaga(),
  ])
}

import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onEdit: 'Edit Module Cost Center', onRead: 'Module Cost Center', onCreate: 'Create Module Cost Center' }
export const StudiesModuleCostCenterForm = ({
  initialValue,
  isLoading,
  isSuccess,
  canEdit,
  canDelete,
  options,
  descriptions,
  onSave,
  isNew,
  onCancel,
  onDelete,
  onOpenDescriptionModal,
  maxProportion = 100,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(isNew)
  const { errors, setError, onClear: onClearError } = useError()

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (isNew && options.subject_identifiers) setData({ subject_id: options.subject_identifiers[0].value })
  }, [isNew, options])

  const validateForm = () => {
    const { hesa_cost_centre_id, hesa_cost_centre_proportion } = data
    if (!hesa_cost_centre_id) {
      setError('hesa_cost_centre_id', 'Required')
      return false
    }
    if (!hesa_cost_centre_proportion) {
      setError('hesa_cost_centre_proportion', 'Required')
      return false
    }
    if (+hesa_cost_centre_proportion > maxProportion) {
      setError('hesa_cost_centre_proportion', 'Should be less than 100%')
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    onClearError()
    if (!isNew) {
      onCancelEditMode()
      return
    }
    onCancel()
  }

  const { hesa_cost_centre_id, hesa_cost_centre_proportion, cost_centre_id } = data

  const { onRead, onEdit, onCreate } = titles
  return (
    <div className={classes.StudiesModuleSubjectForm}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Module Cost Center' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        canEdit={canEdit}
        isEditable={isEditable}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={toggleEditable}
        isCreate={isNew}
        idForm={cost_centre_id ? `module_cost_center_${cost_centre_id}` : null}
        isSuccess={isSuccess}
      >
        <div className={classes.grid}>
          <div className={classes.column}>
            <Field
              label={'HESA cost centre'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={hesa_cost_centre_id}
              onChange={onChangeValue('hesa_cost_centre_id')}
              placeholder='HESA cost centre'
              isDisabled={!isEditable}
              maxLength={3}
              isRequired={isEditable}
              error={errors.hesa_cost_centre_id}
              description={descriptions.hesa_cost_centre_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'select'}
              options={options.hesa_cost_centres}
            />
          </div>
          <div className={classes.column}>
            <Field
              label={'HESA cost centre proportion'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={hesa_cost_centre_proportion}
              onChange={onChangeValue('hesa_cost_centre_proportion')}
              placeholder='HESA cost centre proportion'
              isDisabled={!isEditable}
              maxLength={4}
              isRequired={isEditable}
              error={errors.hesa_cost_centre_proportion}
              description={descriptions.hesa_cost_centre_proportion}
              onOpenDescriptionModal={onOpenDescriptionModal}
              restriction='digits'
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

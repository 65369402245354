import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import styles from './style.module.scss'
import { CreateSubject } from './Create'
import { EditSubject } from './Edit'

const PARENT_URL = '/home/studies/modules'
export const SubjectPage = () => {
	const { moduleId } = useParams()
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname
	const isNew = pathname.includes('new')
	const DEFAULT_URL = `/home/studies/modules/${moduleId}`

	return (
		<SectionWrapper hasBackButton backButtonText={'Back to Modules'} clickBackButtonHandler={() => history.push(`${PARENT_URL}/${moduleId}`)}>
			<div className={styles.section}>{isNew ? <CreateSubject defaultURL={DEFAULT_URL} /> : <EditSubject defaultURL={DEFAULT_URL} />}</div>
		</SectionWrapper>
	)
}

import * as types from './snapshot.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './snapshot.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { id } = payload
	try {
		const { data } = yield call(API.admin.snapshot.get, id)
		yield put(actions.set(data.data))
		yield put(actions.setPermissions(data.permissions))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	try {
		yield call(API.admin.snapshot.create, data)
		history.push(`/home/admin/snapshots`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The snapshot has been created successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { id, history } = payload
	try {
		yield call(API.admin.snapshot.remove, id)
		history.push('/home/admin/other/user-roles')
		yield put(snackbarActions.setSuccessNotification({ text: 'The snapshot has been deleted successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
	yield all([takeEvery(types.GET_SNAPSHOT, get), takeEvery(types.CREATE_SNAPSHOT, create), takeEvery(types.DELETE_SNAPSHOT, remove)])
}

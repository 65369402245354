import { combineReducers } from 'redux'
import { reducer } from './admission.reducer'
import { noteReducer } from './note'
import { attachmentReducer } from './attachment'

export { actions as admissionActions } from './admission.actions'
export { rootSaga as admissionRootSaga } from './admission.saga'
export * as admissionSelectors from './admission.selectors'

export * from './note'
export * from './attachment'

export const admissionReducer = combineReducers({
  reducer,
  note: noteReducer,
  attachment: attachmentReducer,
})

import * as types from './programme.types'

const initialPermissions = {
  fields: {
    HEFCE_code: { view: true, edit: true },
    HEFCE_code_Courseaim: { view: true, edit: true },
    available: { view: true, edit: true },
    course_delivery_mode_type: { view: true, edit: true },
    course_delivery_reference: { view: true, edit: true },
    course_delivery_role: { view: true, edit: true },
    course_delivery_start_date: { view: true, edit: true },
    course_delivery_title: { view: true, edit: true },
    course_delivery_type: { view: true, edit: true },
    course_delivery_venue: { view: true, edit: true },
    description: { view: true, edit: true },
    designated_course: { view: true, edit: true },
    expected_end_date: { view: true, edit: true },
    program_awarding_body_id: { view: true, edit: true },
    programme_mode: { view: true, edit: true },
    programme_name: { view: true, edit: true },
    programme_type: { view: true, edit: true },
    programme_years: { view: true, edit: true },
    qualification_id: { view: true, edit: true },
    subject_code: { view: true, edit: true },
    uuid: { view: true, edit: true },
    course_identifier: { view: true, edit: true },
    bittm_id: { view: true, edit: true },
    clsdcrs_id: { view: true, edit: true },
    flexible_indicator_id: { view: true, edit: true },
    pre_requisite_id: { view: true, edit: true },
    sandwich_id: { view: true, edit: true },
    ttcid_id: { view: true, edit: true },
  },
}
const initialState = {
  programmesOptions: null,
  tableOptions: {
    academic_years_list: [],
    managed_by: [],
    programme_modes: [],
    status: [],
    reason_course_session_ending: [],
  },
  programme: {},
  isLoading: false,
  options: {
    static: {},
    dynamic: {},
  },
  permissions: initialPermissions,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.SET_PROGRAMME:
      return {
        ...state,
        programme: action.payload,
      }
    case types.SET_PROGRAMMES_OPTIONS:
      return {
        ...state,
        programmesOptions: action.payload,
      }
    case types.SET_PROGRAMMES_POSTGARD_OPTIONS:
      return {
        ...state,
        tableOptions: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.CLEAR_PERMISSIONS:
      return {
        ...state,
        permissions: initialPermissions,
      }
    default:
      return state
  }
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './moduleInstances.types'
import { actions } from './moduleInstances.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, studentCourseSessionId, contactId, moduleInstancesId } = data
  try {
    const response = yield call(API.studies.studentCourseSession.moduleInstances.get, studentCourseSessionId, moduleInstancesId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/module_instances`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, studyProgrammeId, contactId, studentCourseSessionId } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.moduleInstances.create, studentCourseSessionId, data)
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/module_instances/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Module Instances has been created successfully!' }))
  } catch (error) {
    const errors = error.response.data.messages
    if (errors['studyProgrammeId']) {
      history.push('/home/studies/programmes/study')
    } else {
      yield put(actions.set(data))
      yield put(actions.setErrors(errors))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { moduleInstancesId, studentCourseSessionId, data } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.moduleInstances.edit, studentCourseSessionId, moduleInstancesId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The Module Instances has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.messages
    yield put(actions.set(data))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, studyProgrammeId, studentCourseSessionId, moduleInstancesId } = data
  try {
    yield call(API.studies.studentCourseSession.moduleInstances.remove, studentCourseSessionId, moduleInstancesId)
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/module_instances`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Module Instances has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.studies.studentCourseSession.moduleInstances.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      accreditation_prior_experiential_learning: generateObject(data.accreditation_prior_experiential_learning),
      continuing_modules: generateObject(data.continuing_modules),
      inactive_module_flags: generateObject(data.inactive_module_flags),
      module_counts: generateObject(data.module_counts),
      module_outcomes: generateObject(data.module_outcomes),
      module_results: generateObject(data.module_results),
      include_in_hesa: [...data?.include_in_hesa].map(obj => ({ value: obj.id, label: `${obj.label}` })),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('module_instances', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_MODULE_INSTANCES, get),
    takeEvery(types.CREATE_MODULE_INSTANCES, create),
    takeEvery(types.EDIT_MODULE_INSTANCES, edit),
    takeEvery(types.DELETE_MODULE_INSTANCES, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

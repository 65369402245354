import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { QualificationAwardForm } from '@Root/components'
import { qualificationAwardActions, qualificationAwardSelectors } from '@Store/studies'
import { Redirect } from 'react-router-dom'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateQualificationAward = ({ parentURL, onOpenDescriptionModal }) => {
  const qualificationAward = useSelector(qualificationAwardSelectors.qualificationAward)
  const options = useSelector(qualificationAwardSelectors.options)
  const isLoading = useSelector(qualificationAwardSelectors.isLoading)
  const errors = useSelector(qualificationAwardSelectors.errors)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const { id: engagementId, contactId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_qualification_award

  const onCreate = data => {
    dispatch(qualificationAwardActions.create({ data, history, engagementId, contactId }))
  }

  const onCancel = () => {
    history.goBack()
    dispatch(qualificationAwardActions.clearData([]))
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />
  return (
    <div>
      <QualificationAwardForm
        onSave={onCreate}
        onCancel={onCancel}
        canEdit={true}
        isNew
        initialValue={qualificationAward}
        options={options}
        isLoading={isLoading}
        initialErrors={errors}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

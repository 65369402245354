import React, { useEffect } from 'react'
import { VenueActivityForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { venueActivitySelectors, venueActivityActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const EditVenueActivity = ({ onOpenDescriptionModal, programmeRecord }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, contactId, venueActivityId } = useParams()
  const venueActivity = useSelector(venueActivitySelectors.venueActivity)
  const isSuccess = useSelector(venueActivitySelectors.isSuccess)
  const isLoading = useSelector(venueActivitySelectors.isLoading)
  const options = useSelector(venueActivitySelectors.options)
  const permissions = useSelector(venueActivitySelectors.permissions)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic
  const errors = useSelector(venueActivitySelectors.errors)

  useEffect(() => {
    if (!programmeRecord.student_course_session_id) return
    dispatch(
      venueActivityActions.get({
        data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, venueActivityId },
        history,
      })
    )
  }, [programmeRecord])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This Off Venue Activity will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          venueActivityActions.delete({
            data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, venueActivityId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(venueActivityActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, venueActivityId }))
  }

  const onCancel = memoData => {
    dispatch(venueActivityActions.set(memoData))
    dispatch(venueActivityActions.setErrors([]))
  }

  return (
    <VenueActivityForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onCancel={onCancel}
      onDelete={onDelete}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={venueActivity}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      dynamicOptions={dynamicOptions}
      errorsFields={errors}
    />
  )
}

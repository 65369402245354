import { capitalizeLetter } from '@Helpers/capitalizeLetter'

export const highlightMatchedString = (initialValue = '', searchValue = '') => {
	const lowerCaseName = initialValue.toLowerCase()
	const lowerCaseSearchValue = searchValue.toLowerCase()
	const isMatched = lowerCaseName.includes(lowerCaseSearchValue)
	const matchedIndex = isMatched ? lowerCaseName.indexOf(lowerCaseSearchValue) : -1
	let beforeMatch = ''
	let afterMatch = ''
	let matchValue = initialValue
	if (isMatched) {
		beforeMatch = initialValue.substr(0, matchedIndex)
		afterMatch = initialValue.substr(matchedIndex + searchValue.length, initialValue.length)
		matchValue = !beforeMatch.length && lowerCaseSearchValue ? capitalizeLetter(lowerCaseSearchValue) : lowerCaseSearchValue
	}
	return { beforeMatch, afterMatch, matchValue, isMatched }
}

import React, { useEffect, useState } from 'react'
import { AttachmentsForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { studentCourseSessionAttachmentActions, studentCourseSessionAttachmentSelectors, studentCourseSessionNoteActions } from '@Root/store'

export const StudentCourseSessionAttachmentForm = ({ studentCourseSessionId, canManipulate = true }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(studentCourseSessionAttachmentSelectors.isLoading)
  const isSuccess = useSelector(studentCourseSessionAttachmentSelectors.isSuccess)
  const attachments = useSelector(studentCourseSessionAttachmentSelectors.attachments)
  const permissions = useSelector(studentCourseSessionAttachmentSelectors.permissions)

  useEffect(() => {
    if (canManipulate) dispatch(studentCourseSessionAttachmentActions.get({ id: studentCourseSessionId }))

    return () => {
      dispatch(studentCourseSessionNoteActions.set([]))
    }
  }, [dispatch, studentCourseSessionId, canManipulate])

  const onCreate = payload => {
    dispatch(studentCourseSessionAttachmentActions.create({ ...payload, student_course_session_id: studentCourseSessionId }))
  }

  const onEdit = (id, payload) => {
    dispatch(studentCourseSessionAttachmentActions.edit({ id, data: { ...payload, student_course_session_id: studentCourseSessionId } }))
  }

  const onDelete = id => {
    dispatch(studentCourseSessionAttachmentActions.delete(id))
  }

  return (
    <AttachmentsForm
      canManipulate={canManipulate}
      canCreate={permissions?.create}
      initialValues={attachments}
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={onEdit}
      isLoading={isLoading}
      isSuccess={isSuccess}
    />
  )
}

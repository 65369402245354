export const TOGGLE_LOADING = 'programme/courseRole/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'programme/courseRole/TOGGLE_SUCCESS'
export const GET_COURSE_ROLE = 'programme/courseRole/GET_COURSE_ROLE'
export const SET_COURSE_ROLE = 'programme/courseRole/SET_COURSE_ROLE'
export const CREATE_COURSE_ROLE = 'programme/courseRole/CREATE_COURSE_ROLE'
export const DELETE_COURSE_ROLE = 'programme/courseRole/DELETE_COURSE_ROLE'
export const EDIT_COURSE_ROLE = 'programme/courseRole/EDIT_COURSE_ROLE'
export const GET_OPTIONS = 'programme/courseRole/GET_OPTIONS'
export const SET_OPTIONS = 'programme/courseRole/SET_OPTIONS'
export const CLEAR_DATA = 'programme/courseRole/CLEAR_DATA'
export const SET_PERMISSIONS = 'programme/courseRole/SET_PERMISSIONS'
export const SET_ERRORS = 'qualificationAward/SET_ERRORS'

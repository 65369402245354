import * as types from './permissions.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	getAdditional: payload => ({
		type: types.GET_ADDITIONAL_PERMISSIONS,
		payload,
	}),
	setAdditional: payload => ({
		type: types.SET_ADDITIONAL_PERMISSIONS,
		payload,
	}),
	getDataCreation: payload => ({
		type: types.GET_DATA_CREATION_PERMISSIONS,
		payload,
	}),
	setDataCreation: payload => ({
		type: types.SET_DATA_CREATION_PERMISSIONS,
		payload,
	}),
	getDataDeleting: payload => ({
		type: types.GET_DATA_DELETING_PERMISSIONS,
		payload,
	}),
	setDataDeleting: payload => ({
		type: types.SET_DATA_DELETING_PERMISSIONS,
		payload,
	}),
	getRule: payload => ({
		type: types.GET_RULE_PERMISSIONS,
		payload,
	}),
	setRule: payload => ({
		type: types.SET_RULE_PERMISSIONS,
		payload,
	}),
	getMain: payload => ({
		type: types.GET_MAIN_PERMISSIONS,
		payload,
	}),
	setMain: payload => ({
		type: types.SET_MAIN_PERMISSIONS,
		payload,
	}),
	getTabs: payload => ({
		type: types.GET_TABS_PERMISSIONS,
		payload,
	}),
	setTabs: payload => ({
		type: types.SET_TABS_PERMISSIONS,
		payload,
	}),
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InstitutionForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { institutionActions, institutionSelectors } from '@Store/admin'

export const EditInstitution = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { institutionId } = useParams()
  const institution = useSelector(institutionSelectors.institution)
  const isLoading = useSelector(institutionSelectors.isLoading)
  const options = useSelector(institutionSelectors.options)
  const isSuccess = useSelector(institutionSelectors.isSuccess)
  const permissions = useSelector(institutionSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).institutions

  useEffect(() => {
    dispatch(institutionActions.get({ id: institutionId }))
    return () => {
      dispatch(institutionActions.set({}))
    }
  }, [dispatch, institutionId])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This institution will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(institutionActions.remove({ id: institutionId, history }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onSubmit = data => {
    dispatch(institutionActions.edit(data))
  }

  return (
    <InstitutionForm
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={institution}
      onDelete={onDelete}
      onSave={onSubmit}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
    />
  )
}

import * as types from './entryQualificationAwarded.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  entryQualificationAwarded: {
    qualification_award_identifier: null,
    own_award_identifier: null,
    qualification_result_id: null,
    qualification_type_id: null,
    qualification_year: null,
  },
  options: {},
  errors: [],
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_ENTRY_QUALIFICATION_AWARDED:
      return {
        ...state,
        entryQualificationAwarded: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        entryQualificationAwarded: initialState.entryQualificationAwarded,
        errors: [],
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: payload,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    default:
      return state
  }
}

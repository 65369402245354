import React from 'react'
import { CourseInitiativeForm } from '@Root/components/forms/contacts/CourseInitiativeForm/index'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { courseInitiativeSelectors, courseInitiativeActions } from '@Store/studies'

export const CreateCourseInitiative = ({ onOpenDescriptionModal, parentURL }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId } = useParams()
  const courseInitiative = useSelector(courseInitiativeSelectors.courseInitiative)
  const options = useSelector(courseInitiativeSelectors.options)
  const isLoading = useSelector(courseInitiativeSelectors.isLoading)

  const onCreate = data => {
    dispatch(courseInitiativeActions.create({ data, history, studyProgrammeId }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }

  return (
    <CourseInitiativeForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={courseInitiative}
      options={options}
      isLoading={isLoading}
    />
  )
}

import * as types from './collaborativeProvision.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	collaborativeProvision: {
		colprovtypeid_id: null,
		partnernumhus: null,
		partnersid: null,
		partnerukprn: null,
	},
	options: {},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.SET_COLLABORATIVE_PROVISION:
			return {
				...state,
				collaborativeProvision: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: payload,
			}
		case types.CLEAR_DATA:
			return {
				...state,
				collaborativeProvision: initialState.collaborativeProvision,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		default:
			return state
	}
}

import React, { useCallback, useEffect, useMemo } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { optionsSelectors, snackbarActions } from '@Root/store'
import { API } from '@Root/API'
import { Table } from '@Root/components'
import { errorMessage, getDataOptionsFromLocalStorage, onOpenEmail } from '@Root/helpers'
import { useLocation } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

export const FinanceTable = ({ styleConfig }) => {
	const location = useLocation()
	const dispatch = useDispatch()
	const tab = location.pathname.split('/')[3]
	const financeTuitionFeeStaticOptions = useSelector(optionsSelectors.financeTuitionFees).static
	const financeFundingOptions = useSelector(optionsSelectors.financeFundingOptions).static
	const rulePermissions = useSelector(permissionsSelectors.rulePermissions)

	const tuitionFeesTableData = useCallback(() => {
		return {
			fetchInitialDataHandler: () => {
				if (!financeTuitionFeeStaticOptions) getDataOptionsFromLocalStorage(['finance_tuition'])
			},
			table: () => {
				const {
					academic_years,
					sponsors,
					accommodations,
					managed_by = [],
					main_source_of_fee_hesas = [],
					employer_fees = [],
					statuses,
				} = financeTuitionFeeStaticOptions
				const options = {
					academic_year: academic_years,
					sponsor: sponsors,
					accommodation: accommodations,
					managed_by: managed_by.map(x => x.label),
					main_source_of_fee_label: main_source_of_fee_hesas.map(x => x.label),
					employer_fees: employer_fees.map(x => x.label),
					status: statuses,
				}
				return {
					name: 'tuition-fees',
					hasRules: true,
					hasSorting: true,
					hasFilters: true,
					fetchDataHandler: params => API.finance.tuition.getStudentsWithFinancialSupport(params),
					fetchSaveColumnOptions: options => API.saveColumnOptions('tuition-fees', options),
					fetchColumnOptions: () => API.getColumnOptions('tuition-fees'),
					clickLinkHandlers: {
						ID: row => this.props.history.push(`/home/contacts/all-contacts/${row.SID}`),
						Name: row => this.props.history.push(`/home/contacts/all-contacts/${row.SID}`),
						Email: onOpenEmail,
					},
					options,
					permissions: {
						rule: rulePermissions['tuition-fees'],
					},
				}
			},
		}
	}, [financeTuitionFeeStaticOptions])

	const fundingTableData = useCallback(() => {
		return {
			fetchInitialDataHandler: () => {
				if (!financeFundingOptions) getDataOptionsFromLocalStorage(['finance_funding'])
			},
			table: () => {
				const { academic_years = [], statuses = [], funding_bodies = [], funding_completions = [] } = financeFundingOptions
				const options = {
					academic_year: academic_years,
					status: statuses.map(({ label }) => label),
					funding_body: funding_bodies.map(({ label }) => label),
					funding_completion: funding_completions.map(({ label }) => label),
				}
				return {
					name: 'fundings',
					hasRules: true,
					hasSorting: true,
					hasFilters: true,
					fetchDataHandler: params => API.finance.funding.getStudentsWithFunding(params),
					fetchSaveColumnOptions: options => API.saveColumnOptions('fundings', options),
					fetchColumnOptions: () => API.getColumnOptions('fundings'),
					options,
					permissions: {
						rule: rulePermissions.fundings,
					},
				}
			},
		}
	}, [financeFundingOptions])

	const tableData = useCallback(
		() => ({
			'tuition-fees': tuitionFeesTableData(),
			fundings: fundingTableData(),
		}),
		[fundingTableData, tuitionFeesTableData]
	)

	const onSuccess = text => {
		dispatch(snackbarActions.setSuccessNotification({ text }))
	}

	const onError = error => {
		dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}

	const { table, fetchInitialDataHandler } = useMemo(() => {
		const data = tableData()[tab]
		return {
			table: data.table(),
			fetchInitialDataHandler: data.fetchInitialDataHandler,
		}
	}, [tab, tableData])

	useEffect(() => {
		fetchInitialDataHandler()
	}, [fetchInitialDataHandler])

	const {
		name,
		columns,
		fetchDataHandler,
		clickLinkHandlers,
		hasRules,
		hasSorting,
		hasFilters,
		fetchColumnOptions,
		fetchSaveColumnOptions,
		options,
		permissions,
	} = table
	return (
		<div className={classes.wrapper}>
			<Table
				key={name}
				name={name}
				columns={columns}
				hasRules={hasRules}
				hasSorting={hasSorting}
				hasFilters={hasFilters}
				fetchDataHandler={fetchDataHandler}
				fetchColumnOptions={fetchColumnOptions}
				fetchSaveColumnOptions={fetchSaveColumnOptions}
				options={options}
				fetchExportedDataHander={() => {}}
				clickLinkHandlers={clickLinkHandlers}
				permissions={permissions}
				errorHandler={onError}
				successHandler={onSuccess}
				styleConfig={{ ...styleConfig.table, tableBar: styleConfig.tableBar }}
				permissions={permissions}
			/>
		</div>
	)
}

import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { modalActions, snackbarActions } from '@Root/store'
import { errorMessage } from '@Root/helpers'
import { API } from '@Root/API'
import { useHistory } from 'react-router'

export const useEditedMode = ({ isEditable, clickToggleEditModeButtonHandler, clickCancelButtonHandler, clickBackButtonHandler, idForm, pathRedirect }) => {
  const timerIdle = useRef(null)
  const timer = useRef(null)
  const [refreshTime, setRefreshTime] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (refreshTime === null || !isEditable) return
    document.addEventListener('mousemove', onActivity)
    document.addEventListener('keydown', onActivity)
    return () => {
      removeEventListener()
    }
  }, [refreshTime])

  const removeEventListener = () => {
    document.removeEventListener('mousemove', onActivity)
    document.removeEventListener('keydown', onActivity)
  }

  const onActivity = function(e) {
    startTimerIdle(refreshTime)
  }
  const startEditMode = async () => {
    if (!idForm) return
    try {
      const { data } = await API.editMode.editInit(idForm)
      clickToggleEditModeButtonHandler()
      setRefreshTime(data.refresh_time)
      startTimer(data.refresh_time)
      startTimerIdle(data.refresh_time)
    } catch (error) {
      const { data, status } = error.response
      if (status === 400) {
        dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
      } else {
        dispatch(
          snackbarActions.setSnackbar({
            text: `Sorry, this record is currently locked for editing by ${data.user_name}. Please wait until ${data.user_name} has finished making changes before trying to edit this record again`,
            isError: true,
          })
        )
        if (pathRedirect) history.push(pathRedirect)
      }
    }
  }

  const updateTime = async () => {
    try {
      const { data } = await API.editMode.updateInit(idForm)
      startTimer(data.refresh_time)
    } catch (error) {
      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    }
  }

  const exitEditMode = async type => {
    removeEventListener()
    try {
      const { data } = await API.editMode.deleteInit(idForm)
      if (type)
        if (type === 'cancel') {
          clickCancelButtonHandler()
        } else {
          clickBackButtonHandler()
        }
      clearTimeout(timer.current)
      clearTimeout(timerIdle.current)
      setRefreshTime(null)
      timer.current = null
      timerIdle.current = null
    } catch (error) {
      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    }
  }

  function onIdle() {
    dispatch(modalActions.showModal('ModalIdle', { updateTime, exitEditMode }))
  }

  function startTimerIdle(refresh_time = refreshTime) {
    clearTimeout(timerIdle.current)
    timerIdle.current = setTimeout(onIdle, refresh_time)
  }

  function startTimer(refresh_time = refreshTime) {
    clearTimeout(timer.current)
    timer.current = setTimeout(updateTime, refresh_time)
  }

  return { startEditMode, exitEditMode }
}

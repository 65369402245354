import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.organisation.church.isLoading

export const isSuccess = state => state.contactReducer.organisation.church.isSuccess

export const church = state => state.contactReducer.organisation.church.church

export const options = state => state.contactReducer.organisation.church.options

export const permissions = state => state.contactReducer.organisation.church.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

import React from 'react'
import styles from './style.module.scss'
import { createDataConfig } from '@Root/configs'
import { VerticalTabs, TableBlock } from '@Root/components'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { API } from '@Root/API'

export const DetailInformation = ({ engagementId, contactId, qualificationAwardId, permissions }) => {
	const defaultURL = `/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${qualificationAwardId}`
	const history = useHistory()
	const QualificationAwardAccreditation = () => {
		const columns = () => [
			{
				name: 'Accreditation ID',
				field: 'id',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'id_link',
			},
			{
				name: 'Accreditation identifier',
				field: 'accreditation',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig(
				'Entry Qualification Award Accreditation',
				[],
				columns(),
				'',
				'qualificationAwardAccreditation',
				false,
				false,
				false,
				false,
				false,
				false
			),
			fetchDataHandler: params => API.studies.engagement.qualificationAward.qualificationAwardAccreditation.getAll(qualificationAwardId, params),
			clickLinkHandlers: {
				'Accreditation ID': row => history.push(`${defaultURL}/qualification-award-accreditation/${row.id}`),
			},
		}
	}

	const tables = [
		...(permissions?.qualification_award_accreditation ? [{ name: 'Qualification award accreditation', label: 'qualification-award-accreditation', tableInformation: QualificationAwardAccreditation() }] : []),
	]
	return (
		<div className={styles.wrapper}>
			<VerticalTabs tabs={tables} isLink defaultURL={defaultURL} />
			<Switch>
				{tables.map(({ name, tableInformation, label }) => (
					<Route exact={true} key={name} path={`${defaultURL}/${label}`} render={() => <TableBlock title={name} config={tableInformation} />} />
				)) }
				<Redirect to={`${defaultURL}/${tables[0]?.label || ''}`} />
			</Switch>
		</div>
	)
}

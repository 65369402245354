import React, { useState } from 'react'
import styles from './style.module.scss'
import { InfoIcon } from '@Root/assets'

export const HoverVisibleNotification = ({ title, children, initialTop = '-35px', width = '410px' }) => {
  const [isOnHover, setIsOnHover] = useState(false)

  const onMouseEnter = () => {
    setIsOnHover(true)
  }

  const onMouseLeave = () => {
    setIsOnHover(false)
  }
  return (
    <div className={styles.notification}>
      <div className={`${styles.information} ${isOnHover ? styles.hover : ''}`} style={{ top: initialTop, width }}>
        <div>{children}</div>
      </div>
      <div className={styles.message}>
        <img src={InfoIcon} alt='' className={styles.img} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
        <span className={styles.content}>{title}</span>
      </div>
    </div>
  )
}

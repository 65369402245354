import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onEdit: 'Edit Module Subject', onRead: 'Module Subject', onCreate: 'Create Module Subject' }
export const StudiesModuleSubjectForm = ({
  initialValue,
  isLoading,
  isSuccess,
  options,
  descriptions,
  onSave,
  isNew,
  onCancel,
  onDelete,
  onOpenDescriptionModal,
  maxProportion,
  minProportion = 5,
  permissions,
  initialErrors,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(isNew)
  const { errors, setError, onClear: onClearError } = useError()

  useEffect(() => {
    if (initialErrors.subject_id) {
      setError('subject_id', initialErrors.subject_id)
    }
  }, [initialErrors])

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (isNew && options.subject_identifiers) setData({ subject_id: options.subject_identifiers[0].value })
  }, [isNew, options])

  const validateForm = () => {
    const { subject_id, module_proportion } = data
    const initialProportionNumber = Number(initialValue?.module_proportion)
    const newProportionNumber = Number(module_proportion)
    if (!subject_id) {
      setError('subject_id', 'Required')
      return false
    }
    if (!module_proportion) {
      setError('module_proportion', 'Required')
      return false
    }
    if (module_proportion > maxProportion && isNew) {
      setError('module_proportion', `Should be less than ${maxProportion}%`)
      return false
    }
    if (!isNew) {
      const newMaxProportion =
        initialProportionNumber === 100
          ? 100
          : initialProportionNumber < 100
          ? Number(maxProportion) + initialProportionNumber
          : Number(maxProportion) + initialProportionNumber
      if (Number(newProportionNumber) > newMaxProportion) {
        setError('module_proportion', `Should be less than ${newMaxProportion}%`)
        return false
      }
    }
    if (module_proportion < minProportion) {
      setError('module_proportion', `Should be at least ${minProportion}%`)
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    onClearError()
    if (!isNew) {
      onCancelEditMode()
      return
    }
    onCancel()
  }

  const { subject_id, module_proportion, id } = data

  const { onRead, onEdit, onCreate } = titles

  const { form } = permissions
  return (
    <div className={classes.StudiesModuleSubjectForm}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this module subject' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        canEdit={form?.edit}
        isEditable={isEditable}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={toggleEditable}
        isCreate={isNew}
        idForm={id ? `module_subject_${id}` : null}
        isSuccess={isSuccess}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.columnWrapper}>
            <Field
              label={'Subject identifier'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260, overflow: 'inherit' }}
              value={subject_id}
              onChange={onChangeValue('subject_id')}
              placeholder='Subject identifier'
              isDisabled={!isEditable}
              maxLength={6}
              isRequired={isEditable}
              error={errors.subject_id}
              description={descriptions.subject_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'select'}
              options={options.subject_identifiers}
              search={true}
            />
          </div>
          <div className={classes.columnWrapper}>
            <Field
              label={'Module proportion (%)'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={module_proportion}
              onChange={onChangeValue('module_proportion')}
              placeholder='Module proportion'
              isDisabled={!isEditable}
              maxLength={4}
              isRequired={isEditable}
              error={errors.module_proportion}
              description={descriptions.module_proportion}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              restriction='digits'
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

StudiesModuleSubjectForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string,
  }),
  initialData: PropTypes.shape({
    educational_module_id: PropTypes.number,
    subject_id: PropTypes.number,
    module_proportion: PropTypes.string,
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  maxProportion: PropTypes.number,
}

StudiesModuleSubjectForm.defaultProps = {
  permissions: [],
  title: {
    onRead: '',
    onEdit: '',
  },
  initialData: {
    educational_module_id: null,
    subject_id: null,
    module_proportion: null,
  },
  initialIsEditable: false,
  options: null,
  buttons: ['cancel', 'delete', 'save'],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  openDescriptionModal: () => {},
  maxProportion: 100,
}

import React, { useEffect } from 'react'
import { contactActions, contactSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors, snackbarActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { ContactInformation } from './ContactInformation'
import { organisationContactActions, organisationSelectors } from '@Store/contact/organisation'
import { personContactActions, personSelectors } from '@Store/contact/person'
import { permissionsSelectors } from '@Store/permissions'

export const CreateContact = ({ onOpenDescriptionModal }) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const error = useSelector(contactSelectors.error)
  const isLoading = useSelector(contactSelectors.isLoading)
  const isSuccess = useSelector(contactSelectors.isSuccess)
  const options = useSelector(contactSelectors.options)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions)
  const organisationRelationships = useSelector(organisationSelectors.relationships)
  const personRelationships = useSelector(personSelectors.relationships)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const permissions = useSelector(contactSelectors.permissions)

  const contactType = location.pathname
    .split('/')
    .pop()
    .slice(4)

  useEffect(() => {
    if (contactType) {
      dispatch(
        fieldTooltipManagerActions.getContact({
          entity: 'contacts',
          entity_type: contactType,
          entity_sub_type: 'form',
        })
      )
      if (contactType === 'person') {
        dispatch(personContactActions.getRelationShips({ isNew: true }))
      } else {
        dispatch(organisationContactActions.getRelationShips())
      }
    }
  }, [contactType, dispatch])

  const onSubmit = payload => {
    dispatch(contactActions.create({ data: payload, history, contactType }))
  }

  const onSuccess = message => {
    dispatch(snackbarActions.setSuccessNotification({ text: message }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(`/home/contacts/all-contacts`)
  }
  return permissions ? (
    <ContactInformation
      isNew
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      error={error}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      descriptions={descriptions}
      contactType={contactType}
      personRelationships={personRelationships}
      organisationRelationships={organisationRelationships}
      onCancel={onCancel}
      canCreateOrganisation={dataCreationPermissions.organisation}
      canCreatePerson={dataCreationPermissions.person}
      permissions={permissions}
    />
  ) : (
    <></>
  )
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { FieldInformation, PageWrapper, Preloader, Title } from '@Root/components'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fieldConstructorActions, fieldConstructorSelectors } from '@Store/admin'
import { modalActions } from '@Root/store'
import { Redirect } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

const parentURL = '/home/admin/additional-fields'

export const CreateFieldSubPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoading = useSelector(fieldConstructorSelectors.isLoading)
  const errors = useSelector(fieldConstructorSelectors.errors)
  const field = useSelector(fieldConstructorSelectors.field)
  const { additional_field } = useSelector(permissionsSelectors.dataCreationPermissions)

  useEffect(() => {
    return () => {
      dispatch(fieldConstructorActions.setField(null))
      dispatch(fieldConstructorActions.setErrors({}))
    }
  }, [dispatch])

  const onCancel = () => {
    history.push(parentURL)
  }

  const onSubmit = data => {
    dispatch(fieldConstructorActions.createField({ ...data, history }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!additional_field) return <Redirect to={parentURL} />

  return !isLoading ? (
    <PageWrapper>
      <div className={styles.header}>
        <Title>Create</Title>
      </div>
      <FieldInformation
        onSave={onSubmit}
        onCancel={onCancel}
        onOpenDescriptionModal={onOpenDescriptionModal}
        descriptions={{}}
        isNew
        initialErrors={errors}
        initialValue={field}
      />
    </PageWrapper>
  ) : (
    <Preloader />
  )
}

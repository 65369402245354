import React from 'react'
import styles from './style.module.scss'
import { EditButton, SectionTitle } from '@Root/components'

export const FormTitle = ({ titleOnEdit = '', titleOnRead = '', canEdit = true, isEditable = true, onChangeMode, hideEditButton = false, title = '' }) => {
	return (
		<div className={styles.titleWrapper}>
			<SectionTitle title={hideEditButton ? title : isEditable ? titleOnEdit : titleOnRead} />
			{!hideEditButton && <div className={styles.wrapper}>{canEdit && !isEditable && <EditButton clickHandler={() => onChangeMode(true)} />}</div>}
		</div>
	)
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { institutionActions, institutionSelectors } from '@Store/admin'
import { useHistory } from 'react-router'
import { InstitutionForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateInstitution = ({ onOpenDescriptionModal, parentURL }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const institution = useSelector(institutionSelectors.institution)
  const isLoading = useSelector(institutionSelectors.isLoading)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).institutions
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const options = useSelector(institutionSelectors.options)

  const onSubmit = data => {
    dispatch(institutionActions.create({ data, history }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }

  if (!dataCreationPermissions.institutions) return <Redirect to={parentURL} />
  return (
    <InstitutionForm
      canEdit
      isNew
      initialValue={institution}
      onSave={onSubmit}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
      onCancel={onCancel}
      options={options}
    />
  )
}

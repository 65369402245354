import * as types from './organisation.types'

export const actions = {
	getRelationShips: payload => ({
		type: types.GET_RELATIONSHIPS,
		payload,
	}),
	setRelationShips: payload => ({
		type: types.SET_RELATIONSHIPS,
		payload,
	}),
	getOrganisationOptions: payload => ({
		type: types.GET_ORGANISATION_OPTIONS,
		payload,
	}),
}

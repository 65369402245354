export const isLoading = state => state.studiesReducer.engagement.qualificationAward.reducer.isLoading

export const isSuccess = state => state.studiesReducer.engagement.qualificationAward.reducer.isSuccess

export const qualificationAward = state => state.studiesReducer.engagement.qualificationAward.reducer.qualificationAward

export const errors = state => state.studiesReducer.engagement.qualificationAward.reducer.errors

export const permissions = state => state.studiesReducer.engagement.qualificationAward.reducer.permissions

export const options = state => state.studiesReducer.engagement.qualificationAward.reducer.options

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { EntryQualificationAward } from './Page'
import { EntryQualificationSubjectPage } from './EntryQualificationSubjectPage'
import { entryQualificationAwardedActions, entryQualificationAwardedSelectors } from '@Store/studies'
import { useDispatch, useSelector } from 'react-redux'

export const EntryQualificationAwardPage = ({ onOpenDescriptionModal }) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const { id: engagementId, contactId, entryProfileId, entryQualificationAwardId } = useParams()
  const otherTabId = pathname.split('/')[11]

  useEffect(() => {
    getDataOptionsFromLocalStorage(['entry_qualification_award'])
  }, [])

  useEffect(() => {
    if (entryQualificationAwardId && entryQualificationAwardId !== 'new' && otherTabId) {
      dispatch(
        entryQualificationAwardedActions.get({
          data: {
            entryQualificationAwardId,
            entryProfileId,
            contactId,
            engagementId,
          },
          history,
        })
      )
      return () => {
        dispatch(entryQualificationAwardedActions.clearData())
      }
    }
  }, [entryQualificationAwardId, otherTabId])

  return (
    <div className={styles.wrapper}>
      <div className={styles.sub_page}>
        <Switch>
          <Route
            path={
              '/home/studies/engagements/:contactId/:id/entry-profile/:entryProfileId?/entry-qualification-award/:entryQualificationId?/entry-qualification-subject/new'
            }
            component={() => EntryQualificationSubjectPage({ onOpenDescriptionModal })}
            exact
          />{' '}
          <Route
            path={
              '/home/studies/engagements/:contactId/:id/entry-profile/:entryProfileId?/entry-qualification-award/:entryQualificationId?/entry-qualification-subject/:entryQualificationSubjectId'
            }
            component={() => EntryQualificationSubjectPage({ onOpenDescriptionModal })}
            exact
          />
          <Route component={() => EntryQualificationAward({ onOpenDescriptionModal })} />
        </Switch>
      </div>
    </div>
  )
}

import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
//import Ringer from '../../icons/Ringer/Ringer'
import { TwoFactorAutoIcon } from '@Root/assets/icons/index'
import { UserAvatar } from '@Root/components'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

export const HeaderUser = ({ userName, userIcon, path, logOut, canTwoFA }) => {
  const history = useHistory()
  return (
    <div className='HeaderUser'>
      <div className='divider' />
      {/* <Ringer /> */}
      {canTwoFA && (
        <Link to={'/home/security'} className='name'>
          <img className='two-factor-auto' src={TwoFactorAutoIcon} alt='' width='35' />
        </Link>
      )}

      <div>
        <Link to={path} className='name'>
          {userName}
        </Link>
        <div className='log-out' onClick={() => logOut()}>
          log out
        </div>
      </div>
      <UserAvatar src={userIcon} path={path} />
    </div>
  )
}

HeaderUser.propTypes = {
  userName: PropTypes.string,
  userIcon: PropTypes.string,
  logOut: PropTypes.func,
}

HeaderUser.defaultProps = {
  userName: '',
  userIcon: '',
  logOut: () => {},
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateFundingMonitoring } from './Create'
import { EditFundingMonitoring } from './Edit'
import { useDispatch } from 'react-redux'
import { studentCourseSessionActions } from '@Store/studies/studentCourseSession/index'
import { modalActions } from '@Root/store'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager/index'

export const FundingAndMonitoringPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { studyProgrammeId, contactId } = useParams()
  const isDuplicate = pathname.includes('duplicate')
  const DEFAULT_URL = `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_monitoring`

  useEffect(() => {
    dispatch(studentCourseSessionActions.get({ studyProgrammeId, isCopy: isDuplicate }))
    getDataOptionsFromLocalStorage(['funding_and_monitoring'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'funding-and-monitoring' }))
    return () => {
      dispatch(studentCourseSessionActions.clearData({}))
    }
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <SectionWrapper hasBackButton backButtonText={'Funding and Monitoring'} clickBackButtonHandler={() => history.push(DEFAULT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateFundingMonitoring isNew={isNew} onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        ) : (
          <EditFundingMonitoring onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

import React, { useEffect } from 'react'
import { SessionStatusForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { sessionStatusSelectors, sessionStatusActions } from '@Store/studies'


export const EditSessionStatus = ({ onOpenDescriptionModal, programmeRecord }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { studyProgrammeId, contactId, sessionStatusId } = useParams()
	const sessionStatus = useSelector(sessionStatusSelectors.sessionStatus)
	const isSuccess = useSelector(sessionStatusSelectors.isSuccess)
	const isLoading = useSelector(sessionStatusSelectors.isLoading)
	const options = useSelector(sessionStatusSelectors.options)
	const permissions = useSelector(sessionStatusSelectors.permissions)

	useEffect(() => {
		if (!programmeRecord.student_course_session_id) return
		dispatch(
			sessionStatusActions.get({
				data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, sessionStatusId },
				history,
			})
		)
		return () => {
			dispatch(sessionStatusActions.clearData())
		}
	}, [programmeRecord])

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This Session Status will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(sessionStatusActions.delete({ data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, sessionStatusId }, history }))
			})
			.finally(() => {
				dispatch(modalActions.hideModal())
			})
	}

	const onSave = data => {
		dispatch(sessionStatusActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, sessionStatusId }))
	}

	return (
		<SessionStatusForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onSave}
			onDelete={onDelete}
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			initialValue={sessionStatus}
			isLoading={isLoading}
			isSuccess={isSuccess}
			options={options}
		/>
	)
}

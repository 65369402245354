import React from 'react'
import styles from './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { CreateSessionYear } from './Create'
import {  modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import { EditSessionYear } from './Edit'


export const SessionYearPage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const DEFAULT_URL = '/home/admin/data/session-year'

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <SectionWrapper hasBackButton backButtonText='Session Year' clickBackButtonHandler={() => history.push(DEFAULT_URL)}>
      <div className={styles.wrapper}>
        {isNew ? (
          <CreateSessionYear onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        ) : (
          <EditSessionYear onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        )}
      </div>
    </SectionWrapper>
  )
}

export const TOGGLE_LOADING = 'admission/attachment/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admission/attachment/TOGGLE_SUCCESS'
export const GET_ATTACHMENT = 'admission/attachment/GET_ATTACHMENT'
export const SET_ATTACHMENTS = 'admission/attachment/SET_ATTACHMENTS'
export const CREATE_ATTACHMENT = 'admission/attachment/CREATE_ATTACHMENT'
export const SET_CREATED_ATTACHMENT = 'admission/attachment/SET_CREATED_ATTACHMENT'
export const EDIT_ATTACHMENT = 'admission/attachment/EDIT_ATTACHMENT'
export const SET_EDITED_ATTACHMENT = 'admission/attachment/SET_EDITED_ATTACHMENT'
export const DELETE_ATTACHMENT = 'admission/attachment/DELETE_ATTACHMENT'
export const SET_DELETED_ATTACHMENT = 'admission/attachment/SET_DELETED_ATTACHMENT'
export const SET_PERMISSIONS = 'admission/attachment/SET_PERMISSIONS'
export const GET_HISTORY = 'admission/attachment/GET_HISTORY'
export const SET_HISTORY = 'admission/attachment/SET_HISTORY'
export const CLEAR_HISTORY = 'admission/attachment/CLEAR_HISTORY'
export const RESET_ATTACHMENTS = 'admission/attachment/RESET_ATTACHMENTS'

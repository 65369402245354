import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { CustomScrollbar } from '@Root/HOCs'
import { getOffset, getWindowSizes, tableHeight } from '@Root/helpers'
import { Spinner, CheckboxInput, Select, TextInput, DatePicker } from '@Root/components'
import { arrowIcon, UserIcon } from '@Root/assets'
import { NavLink } from 'react-router-dom'
import { NO_DATA_AVAILABLE } from '@Root/configs'

export class TableData extends Component {
  state = {
    initialColumnWidth: 120,
    isResizing: false,
    element: null,
    columnWidth: {},
    activeColumnName: '',
    tableHeight: 600,
  }

  columnsWrapper = React.createRef()
  resizeHeaderFieldRef = React.createRef()

  getColumnsQuantity = () => {
    const { columns, hiddenColumnsNames } = this.props
    return columns.filter(column => !hiddenColumnsNames.includes(column.name)).length
  }

  getRowQuantity = () => {
    return this.props.data.length
  }
  setInitialColumnWidth = () => {
    const { columns, hiddenColumnsNames } = this.props
    const { columnsWrapper } = this
    const wrapperWidth = columnsWrapper.current.clientWidth
    const columnsQuantity = columns.filter(column => !hiddenColumnsNames.includes(column.name)).length
    const columnWidth = (wrapperWidth - 58) / columnsQuantity
    columnWidth > 120 && this.setState({ initialColumnWidth: columnWidth === Infinity ? 120 : columnWidth })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.columns.length !== this.props.columns.length) {
      this.setInitialColumnWidth()
    }
  }

  componentDidMount() {
    this.setInitialColumnWidth()
    if (this.columnsWrapper.current) {
      const elementOffset = getOffset(this.columnsWrapper.current)
      const userWindowSize = getWindowSizes()
      const height = userWindowSize.innerHeight - elementOffset.top - 40
      this.setState({ tableHeight: height })
    }
  }

  onDownLick = columnName => e => {
    this.setState({ isResizing: true, element: e.currentTarget, activeColumnName: columnName })
  }

  onMouseMove = () => {
    if (!this.state.isResizing && !this.state.element) return
    const elementWidth = this.state.element.offsetWidth
    this.setState(prevState => ({ columnWidth: { ...prevState.columnWidth, [prevState.activeColumnName]: elementWidth + 1 } }))
  }

  onMouseUp = () => {
    this.setState({ isResizing: false, element: null })
  }

  checkIsNoData = value => {
    return value === NO_DATA_AVAILABLE.label ? NO_DATA_AVAILABLE.value : value
  }

  render() {
    const { initialColumnWidth, columnWidth } = this.state
    const {
      columns,
      hiddenColumnsNames,
      data,
      dataIsFetching,
      checkedRowsIds,
      clickCheckboxHandler,
      clickCheckboxAllHandler,
      headersFilters,
      visibleRowsQuantity,
      changeHeaderFilterHandler,
      checkboxes = {},
      clickColumnCheckboxHandler = () => {},
      sorting = null,
      clickHeaderCellHandler = () => {},
      hasFilters = false,
      hasSorting = false,
      styleConfig = {},
      options,
      clickLinkHandlers,
    } = this.props

    const { columnsWrapper } = this
    return (
      <div
        className={classes.TableData}
        style={{
          height: styleConfig?.isFixedTableHeader
            ? this.state.tableHeight
            : tableHeight(styleConfig.showHeightByRow ? data.length : visibleRowsQuantity, hasFilters),
          zIndex: styleConfig?.isFixedTableHeader ? 15 : '',
        }}
        ref={columnsWrapper}
      >
        <CustomScrollbar horizontalOnly>
          <div className={`${classes.columnsWrapper} ${styleConfig?.isFixedTableHeader ? classes.positionSticky : ''}`}>
            <div
              className={styleConfig?.isFixedTableHeader ? classes.tablePositions : ''}
              style={styleConfig?.isFixedTableHeader ? { height: this.state.tableHeight - 10 } : {}}
            >
              <div className={`${classes.columnWrapper} ${styleConfig?.isFixedTableHeader ? classes.positionSticky : ''}`}>
                <div className={classes.column} style={{ width: '30px' }}>
                  <div className={classes.headerCell}>
                    <CheckboxInput
                      isChecked={!!data.length && data.every(row => checkedRowsIds.includes(row.id))}
                      changeHandler={() => clickCheckboxAllHandler(data.map(row => row.id))}
                    />
                  </div>
                  {hasFilters && <div className={classes.emptyBlock} />}
                </div>
                {columns.map(column => {
                  return (
                    <div
                      className={classes.column}
                      style={
                        hiddenColumnsNames.includes(column.name)
                          ? { display: 'none' }
                          : { width: columnWidth[column.field] ? columnWidth[column.field] : column.field === 'token' ? '170px' : initialColumnWidth }
                      }
                      key={column.name}
                    >
                      <div
                        className={classes.headerCell}
                        style={{ width: column.field === 'token' ? '170px' : initialColumnWidth, resize: 'horizontal' }}
                        ref={this.resizeHeaderFieldRef}
                        onMouseDown={this.onDownLick(column.field)}
                        onMouseMove={this.onMouseMove}
                        onMouseUp={this.onMouseUp}
                      >
                        <div
                          className={classes.headerCellInner}
                          style={hasSorting ? { cursor: 'pointer' } : null}
                          onClick={hasSorting ? () => clickHeaderCellHandler(column.field) : null}
                        >
                          <div>{column.name}</div>
                          {!!sorting && sorting.field === column.field && (
                            <div style={{ marginLeft: 4 }}>
                              <img style={{ transform: `rotate(${sorting.direction === 'asc' ? '90' : '-90'}deg)` }} src={arrowIcon} alt='' />
                            </div>
                          )}
                        </div>
                      </div>
                      {hasFilters && (
                        <div className={classes.cell} style={{ overflow: 'visible' }}>
                          {column.searchInputType === 'textInput' && (
                            <TextInput
                              classNames={['transparent']}
                              style={{ margin: '0 -8px', width: 'calc(100% + 16px)', height: 26 }}
                              value={column.field in headersFilters ? headersFilters[column.field] : ''}
                              changeHandler={value => changeHeaderFilterHandler({ key: column.field, value })}
                            />
                          )}
                          {column.searchInputType === 'select' && (
                            <Select
                              inputClassNames={['transparent']}
                              style={{ margin: '0 -8px', width: 'calc(100% + 16px)', height: 26 }}
                              inputStyle={{ height: 26 }}
                              options={column.options?.length > 0 ? column.options : options[column.field]}
                              value={column.field in headersFilters ? headersFilters[column.field] : null}
                              changeHandler={value => changeHeaderFilterHandler({ key: column.field, value })}
                            />
                          )}
                          {column.searchInputType === 'datePicker' && (
                            <DatePicker
                              classNames={['transparent']}
                              style={{ margin: '0 -8px', width: 'calc(100% + 16px)', height: 26 }}
                              value={column.field in headersFilters ? headersFilters[column.field] : null}
                              changeHandler={value => changeHeaderFilterHandler({ key: column.field, value })}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div style={{ display: 'flex', width: 'max-content', ...styleConfig.tableDataWrapper }}>
                <div className={classes.column} style={{ width: '30px' }}>
                  {data.map((row, i) => (
                    <div className={classes.cell} style={{ top: 46 * (i + 1 + 1) }} key={i}>
                      <CheckboxInput isChecked={checkedRowsIds.includes(row.id)} changeHandler={() => clickCheckboxHandler(row.id)} />
                    </div>
                  ))}
                </div>
                {columns.map((column, c) => (
                  <div
                    className={classes.column}
                    style={
                      hiddenColumnsNames.includes(column.name)
                        ? { display: 'none' }
                        : { width: columnWidth[column.field] ? columnWidth[column.field] : column.field === 'token' ? '170px' : initialColumnWidth }
                    }
                    key={column.name}
                  >
                    <div>
                      {data.map((row, k) => {
                        return (
                          <div className={classes.cell} style={{ top: 46 * (k + 1 + 1) }} key={k}>
                            {column.field in row ? (
                              <>
                                {column.cellType === 'text' && this.checkIsNoData(row[column.field])}
                                {column.cellType === 'link' &&
                                  (row[column.cellLinkField] ? (
                                    <NavLink className={classes.link} to={row[column.cellLinkField]}>
                                      {this.checkIsNoData(row[column.field])}
                                    </NavLink>
                                  ) : (
                                    <div
                                      className={clickLinkHandlers?.[column?.field] ? classes.link : ''}
                                      onClick={() => clickLinkHandlers?.[column?.field]?.(row) || null}
                                    >
                                      {this.checkIsNoData(row[column.field])}
                                    </div>
                                  ))}
                                {column.cellType === 'mailLink' && (
                                  <a className={classes.link} href={`mailTo:${row[column.field]}`}>
                                    {this.checkIsNoData(row[column.field])}
                                  </a>
                                )}
                                {column.cellType === 'image' && (
                                  <div className={classes.image} style={{ backgroundImage: `url("${row[column.field] || UserIcon}")` }} />
                                )}
                                {column.cellType === 'checkbox' && row[column.field] !== undefined && (
                                  <CheckboxInput
                                    isChecked={checkboxes[column.name].values[row[column.field]]}
                                    changeHandler={value =>
                                      checkboxes[column.name].changeHandler(row, () =>
                                        clickColumnCheckboxHandler(
                                          row.id,
                                          column.field,
                                          Object.keys(checkboxes[column.name].values).find(key => checkboxes[column.name].values[key] === value)
                                        )
                                      )
                                    }
                                    isDisabled={
                                      row.permissions?.[column.field]?.edit
                                        ? checkboxes[column.name].isDisabledWhenTrue
                                          ? checkboxes[column.name].values[row[column.field]]
                                          : false
                                        : true
                                    }
                                  />
                                )}
                              </>
                            ) : null}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!dataIsFetching && !data.length && <div className={classes.error}>No results found</div>}
        </CustomScrollbar>
        {dataIsFetching && (
          <div className={classes.spinnerWrapper}>
            <Spinner size='small' color='dark' />
          </div>
        )}
      </div>
    )
  }
}

TableData.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      searchInputType: PropTypes.oneOf(['textInput', 'select', 'datePicker']),
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
          }),
        ])
      ),
      cellType: PropTypes.oneOf(['text', 'image', 'link', 'checkbox', 'checkboxRemastered', 'mailLink']),
    })
  ),
  hiddenColumnsNames: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  dataIsFetching: PropTypes.bool,
  checkedRowsIds: PropTypes.arrayOf(PropTypes.number),
  clickCheckboxHandler: PropTypes.func,
  clickCheckboxAllHandler: PropTypes.func,
  headersFilters: PropTypes.object,
  changeHeaderFilterHandler: PropTypes.func,
  visibleRowsQuantity: PropTypes.number,
  // cellsAreInputs: PropTypes.bool,
  clickLinkHandlers: PropTypes.object,
  sorting: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }),
  clickHeaderCellHandler: PropTypes.func,
  checkboxes: PropTypes.object,
  clickColumnCheckboxHandler: PropTypes.func,
  hasFilters: PropTypes.bool,
  hasSorting: PropTypes.bool,
}

import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const Modal = ({ children }) => {
  return <div className={classes.Modal}>{children}</div>;
};

Modal.propTypes = {
  children: PropTypes.node
};

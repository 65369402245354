export const TOGGLE_LOADING = 'student/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'student/TOGGLE_SUCCESS'
export const GET_STUDENT = 'student/GET_STUDENT'
export const SET_STUDENT = 'student/SET_STUDENT'
export const EDIT_STUDENT = 'student/EDIT_STUDENT'
export const GET_STATIC_OPTIONS = 'student/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'student/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'student/SET_OPTIONS'
export const REMOVE_STUDENT = 'student/REMOVE_STUDENT'
export const SET_INFORMATION = 'student/SET_INFORMATION'
export const SET_PERMISSIONS = 'student/SET_PERMISSIONS'

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { all, emailIsValid, generateId, scrollWindowToPosition, isValidDataFormat } from '@Root/helpers'
import { SubformWrapper } from '@Root/HOCs'
import { SubformInputGroup } from './SubformInputGroup/'

export const Subform = ({
  name,
  initialData,
  isEditable,
  groupsConfigs,
  saveHandler,
  onOpenDescription,
  onOpenDeleteModal,
  onShowFieldError,
  onToggleEditMode,
  permissions,
  openTabDefalt = false,
}) => {
  const [data, setData] = useState(JSON.parse(JSON.stringify(initialData)))
  const [isOpened, setIsOpened] = useState({
    main: openTabDefalt,
    level0GroupConfig: {},
    level1GroupsConfigs: {},
    level2GroupsConfigs: {},
    level3GroupsConfigs: {},
  })
  const [isSpinning, setIsSpinning] = useState({
    level0GroupConfig: {
      full: false,
    },
    level1GroupConfig: {},
    level2GroupConfig: {},
    level3GroupConfig: {},
  })
  const [error, setError] = useState(null)
  const [deletionsLog, setDeletionsLog] = useState([])
  const [latestAddedElementDOMId, setLatestAddedElementDOMId] = useState(null)
  const { level0GroupConfig, level1GroupsConfigs = [], level2GroupsConfigs = [], level3GroupsConfigs = [] } = groupsConfigs

  const initialFieldValue = field => {
    return field !== 'valid_from' ? null : '1800-01-01'
  }

  const clearDeletionLog = () => {
    setDeletionsLog([])
  }

  const onToggleLoadingLevel1 = () => {
    setIsSpinning(prevState => ({ ...prevState, level0GroupConfig: { full: !prevState.level0GroupConfig.full } }))
  }

  const handleAddLevel0 = () => {
    const elementDOMId = generateId()
    const group0 = { id: null, elementDOMId }
    level0GroupConfig.inputs.forEach(input => {
      group0[input.field] = initialFieldValue(input.field)
    })
    if (level1GroupsConfigs) {
      level1GroupsConfigs.forEach((level1GroupConfig, i) => {
        const group1 = { id: null }
        level1GroupConfig.inputs.forEach(input => {
          group1[input.field] = initialFieldValue(input.field)
        })
        group0[level1GroupConfig.field] = [{ ...group1 }]
        if (level2GroupsConfigs) {
          level2GroupsConfigs.forEach((level2GroupConfig, j) => {
            const group2 = { id: null }
            level2GroupConfig.inputs.forEach(input => {
              group2[input.field] = initialFieldValue(input.field)
            })
            group0[level1GroupConfig.field][i][level2GroupConfig.field] = [{ ...group2 }]
            if (level3GroupsConfigs) {
              level3GroupsConfigs.forEach(level3GroupConfig => {
                const group3 = { id: null }
                level3GroupConfig.inputs.forEach(input => {
                  group3[input.field] = initialFieldValue(input.field)
                })
                group0[level1GroupConfig.field][i][level2GroupConfig.field][j][level3GroupConfig.field] = [{ ...group3 }]
              })
            }
          })
        }
      })
    }
    setData([...data, group0])
    setLatestAddedElementDOMId(elementDOMId)
  }

  const handleAddLevel1 = (group0Index, level1GroupConfigIndex, information) => {
    const elementDOMId = generateId()
    const dataCopy = JSON.parse(JSON.stringify(data))
    const group1 = { id: null, elementDOMId }
    level1GroupsConfigs[level1GroupConfigIndex].inputs.forEach(input => {
      group1[input.field] = initialFieldValue(input.field)
    })
    if (level2GroupsConfigs) {
      level2GroupsConfigs.forEach((level2GroupConfig, i) => {
        const group2 = { id: null }
        level2GroupConfig.inputs.forEach(input => {
          group2[input.field] = initialFieldValue(input.field)
        })
        group1[level2GroupConfig.field] = [{ ...group2 }]
        if (level3GroupsConfigs) {
          level3GroupsConfigs.forEach(level3GroupConfig => {
            const group3 = { id: null }
            level3GroupConfig.inputs.forEach(input => {
              group3[input.field] = initialFieldValue(input.field)
            })
            group1[level2GroupConfig.field][i][level3GroupConfig.field] = [{ ...group3 }]
          })
        }
      })
    }
    dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field] = [
      ...dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field],
      group1,
    ]

    if (!information.isInOpenMode) {
      const { name, pathToConfig, isInOpenMode } = information
      onToggleOpen(name, pathToConfig)(!isInOpenMode)
    }
    setData(dataCopy)
    setLatestAddedElementDOMId(elementDOMId)
  }

  const handleAddLevel2 = (group0Index, level1GroupConfigIndex, group1Index, level2GroupConfigIndex, information) => {
    const elementDOMId = generateId()
    const dataCopy = JSON.parse(JSON.stringify(data))
    const group2 = { id: null, elementDOMId }
    level2GroupsConfigs[level2GroupConfigIndex].inputs.forEach(input => {
      group2[input.field] = initialFieldValue(input.field)
    })
    if (level3GroupsConfigs) {
      level3GroupsConfigs.forEach(level3GroupConfig => {
        const group3 = { id: null }
        level3GroupConfig.inputs.forEach(input => {
          group3[input.field] = initialFieldValue(input.field)
        })
        group2[level3GroupConfig.field] = [{ ...group3 }]
      })
    }
    dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field] = [
      ...dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field],
      group2,
    ]
    if (!information.isInOpenMode) {
      const { name, pathToConfig, isInOpenMode } = information
      onToggleOpen(name, pathToConfig)(!isInOpenMode)
    }
    setData(dataCopy)
    setLatestAddedElementDOMId(elementDOMId)
  }

  const handleAddLevel3 = (group0Index, level1GroupConfigIndex, group1Index, level2GroupConfigIndex, group2Index, level3GroupConfigIndex) => {
    const elementDOMId = generateId()
    const dataCopy = JSON.parse(JSON.stringify(data))
    const group3 = { id: null, elementDOMId }
    level3GroupsConfigs[level3GroupConfigIndex].inputs.forEach(input => {
      group3[input.field] = initialFieldValue(input.field)
    })
    dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field][group2Index][
      level3GroupsConfigs[level3GroupConfigIndex].field
    ] = [
      ...dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field][group2Index][
        level3GroupsConfigs[level2GroupConfigIndex].field
      ],
      group3,
    ]
    setData(dataCopy)
    setLatestAddedElementDOMId(elementDOMId)
  }

  const handleChangeLevel0 = (group0Index, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    dataCopy[group0Index][field] = value
    setData(dataCopy)
  }

  const handleChangeLevel1 = (group0Index, group1Index, level1GroupConfigIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field
    dataCopy[group0Index][level1GroupConfigField][group1Index][field] = value
    setData(dataCopy)
  }

  const handleChangeLevel2 = (group0Index, group1Index, level1GroupConfigIndex, group2Index, level2GroupConfigIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field
    const level2GroupConfigField = level2GroupsConfigs[level2GroupConfigIndex].field
    dataCopy[group0Index][level1GroupConfigField][group1Index][level2GroupConfigField][group2Index][field] = value
    setData(dataCopy)
  }

  const handleChangeLevel3 = (
    group0Index,
    group1Index,
    level1GroupConfigIndex,
    group2Index,
    level2GroupConfigIndex,
    group3Index,
    level3GroupConfigIndex,
    field,
    value
  ) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field
    const level2GroupConfigField = level2GroupsConfigs[level2GroupConfigIndex].field
    const level3GroupConfigField = level3GroupsConfigs[level3GroupConfigIndex].field
    dataCopy[group0Index][level1GroupConfigField][group1Index][level2GroupConfigField][group2Index][level3GroupConfigField][group3Index][field] = value
    setData(dataCopy)
  }

  const deleteLevelO = group0Index => {
    const id0 = data[group0Index].id
    if (id0 !== null) {
      const deletionsLogCopy = deletionsLog.filter(log => log.level_0.id !== id0)
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0,
        },
      })
      setDeletionsLog(deletionsLogCopy)
    }
    setData(data.filter((group, i) => i !== group0Index))
  }

  const handleDeleteLevel0 = async group0Index => {
    if (level0GroupConfig.canBeSavedPart) {
      const groupData = data[group0Index]
      const response = await onOpenDeleteModal(level0GroupConfig.field, groupData, clearDeletionLog, onToggleLoadingLevel1)
      if (response) {
        deleteLevelO(group0Index)
        onToggleLoadingLevel1()
      }
      return
    }
    deleteLevelO(group0Index)
  }

  const handleDeleteLevel1 = (group0Index, group1Index, level1GroupConfigIndex) => {
    const id0 = data[group0Index].id
    const id1 = data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index].id
    if (id0 !== null && id1 !== null) {
      const deletionsLogCopy = deletionsLog.filter(log => !(log.level_0.id === id0 && log.level_1.id === id1))
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0,
        },
        level_1: {
          field: level1GroupsConfigs[level1GroupConfigIndex].field,
          id: id1,
        },
      })
      setDeletionsLog(deletionsLogCopy)
    }
    const dataCopy = JSON.parse(JSON.stringify(data))
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field
    dataCopy[group0Index][level1GroupConfigField] = dataCopy[group0Index][level1GroupConfigField].filter((group, i) => i !== group1Index)
    setData(dataCopy)
  }

  const handleDeleteLevel2 = (group0Index, group1Index, level1GroupConfigIndex, group2Index, level2GroupConfigIndex) => {
    const id0 = data[group0Index].id
    const id1 = data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index].id
    const id2 =
      data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field][group2Index].id
    if (id0 !== null && id1 !== null && id2 !== null) {
      const deletionsLogCopy = [...deletionsLog]
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0,
        },
        level_1: {
          field: level1GroupsConfigs[level1GroupConfigIndex].field,
          id: id1,
        },
        level_2: {
          field: level2GroupsConfigs[level2GroupConfigIndex].field,
          id: id2,
        },
      })
      setDeletionsLog(deletionsLogCopy)
    }
    const dataCopy = JSON.parse(JSON.stringify(data))
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field
    const level2GroupConfigField = level2GroupsConfigs[level2GroupConfigIndex].field
    dataCopy[group0Index][level1GroupConfigField][group1Index][level2GroupConfigField] = dataCopy[group0Index][level1GroupConfigField][group1Index][
      level2GroupConfigField
    ].filter((group, i) => i !== group2Index)
    setData(dataCopy)
  }

  const handleDeleteLevel3 = (group0Index, group1Index, level1GroupConfigIndex, group2Index, level2GroupConfigIndex, group3Index, level3GroupConfigIndex) => {
    const id0 = data[group0Index].id
    const id1 = data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index].id
    const id2 =
      data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field][group2Index].id
    const id3 =
      data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field][group2Index][
        level3GroupsConfigs[level3GroupConfigIndex].field
      ][group3Index].id
    if (id0 !== null && id1 !== null && id2 !== null && id3 !== null) {
      const deletionsLogCopy = [...deletionsLog]
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0,
        },
        level_1: {
          field: level1GroupsConfigs[level1GroupConfigIndex].field,
          id: id1,
        },
        level_2: {
          field: level2GroupsConfigs[level2GroupConfigIndex].field,
          id: id2,
        },
        level_3: {
          field: level3GroupsConfigs[level3GroupConfigIndex].field,
          id: id3,
        },
      })
      setDeletionsLog(deletionsLogCopy)
    }
    const dataCopy = JSON.parse(JSON.stringify(data))
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field
    const level2GroupConfigField = level2GroupsConfigs[level2GroupConfigIndex].field
    const level3GroupConfigField = level3GroupsConfigs[level3GroupConfigIndex].field
    dataCopy[group0Index][level1GroupConfigField][group1Index][level2GroupConfigField][group2Index][level3GroupConfigField] = dataCopy[group0Index][
      level1GroupConfigField
    ][group1Index][level2GroupConfigField][group2Index][level3GroupConfigField].filter((group, i) => i !== group3Index)
    setData(dataCopy)
  }

  const formIsValid = numberIndex => {
    let message
    let inputPath
    outer: for (let i in data) {
      const levelIndex = typeof numberIndex === 'number' ? numberIndex : i
      const rootGroup = data[levelIndex]
      for (let rootGroupInput in rootGroup) {
        const level0GroupEmbeddedFields = level1GroupsConfigs.map(level1GroupConfig => level1GroupConfig.field)
        const level0GroupConfigFields = level0GroupConfig.inputs.map(input => input.field)
        let input
        if (!level0GroupEmbeddedFields.includes(rootGroupInput)) {
          if (level0GroupConfigFields.includes(rootGroupInput)) {
            const title = level0GroupConfig.inputs.find(input => input.field === rootGroupInput).name
            const value = rootGroup[rootGroupInput]
            const validations = level0GroupConfig.inputs.find(input => input.field === rootGroupInput).validations || []
            for (let validation of validations) {
              if (validation === 'required') {
                if (!value) {
                  message = 'Required'
                  onShowFieldError(`${title} is ${message}`)
                  input = rootGroupInput
                  break
                }
              } else if (validation === 'email') {
                if (value && !emailIsValid(value)) {
                  message = 'Invalid email'
                  input = rootGroupInput
                  break
                }
              } else if (validation === 'unique') {
                let wasUsedTwoTimes = false
                let lastAppearanceIndex = null
                data.forEach((level0Group, level0GroupIndex) => {
                  if (level0Group[rootGroupInput] === value) {
                    lastAppearanceIndex = level0GroupIndex
                    if (level0GroupIndex !== +levelIndex) wasUsedTwoTimes = true
                  }
                })
                if (wasUsedTwoTimes && lastAppearanceIndex === +levelIndex) {
                  message = 'This option is already in use'
                  input = rootGroupInput
                  break
                }
              } else if (validation === 'percentage') {
                if (Number(value) > 100) {
                  message = 'Should be no more than 100%'
                  onShowFieldError(`${title}: ${message}`)
                  input = rootGroupInput
                  break
                }
              } else if (validation === 'dataValid') {
                if (value && !isValidDataFormat(value)) {
                  input = rootGroupInput
                  message = 'Invalid data'
                }
              }
            }
            if (input) {
              inputPath = `${levelIndex}/${input}`
              break outer
            }
          }
        } else {
          const level1GroupConfig = level1GroupsConfigs.find(level1GroupConfig => level1GroupConfig.field === rootGroupInput)
          const level1GroupEmbeddedFields = level2GroupsConfigs.map(level2GroupConfig => level2GroupConfig.field)
          const level1GroupConfigFields = level1GroupConfig.inputs.map(input => input.field)
          for (let k in rootGroup[rootGroupInput]) {
            const level1Group = rootGroup[rootGroupInput][k]
            for (let level1GroupInput in level1Group) {
              let input
              if (!level1GroupEmbeddedFields.includes(level1GroupInput)) {
                if (level1GroupConfigFields.includes(level1GroupInput)) {
                  const title = level1GroupConfig.inputs.find(input => input.field === level1GroupInput).name
                  const value = level1Group[level1GroupInput]
                  const validations = level1GroupConfig.inputs.find(input => input.field === level1GroupInput).validations || []
                  for (let validation of validations) {
                    if (validation === 'required') {
                      if (!value) {
                        message = 'Required'
                        onShowFieldError(`${title} is ${message}`)
                        input = level1GroupInput
                        break
                      }
                    } else if (validation === 'email') {
                      if (value && !emailIsValid(value)) {
                        message = 'Invalid email'
                        input = level1GroupInput
                        break
                      }
                    } else if (validation === 'percentage') {
                      if (Number(value) > 100) {
                        message = 'Should be no more than 100%'
                        input = level1GroupInput
                        break
                      }
                    } else if (validation === 'dataValid') {
                      if (value && !isValidDataFormat(value)) {
                        input = rootGroupInput
                        message = 'Invalid data'
                      }
                    }
                  }
                  if (input) {
                    inputPath = `${levelIndex}/${k}/${input}`
                    break outer
                  }
                }
              } else {
                const level2GroupConfig = level2GroupsConfigs.find(level2GroupConfig => level2GroupConfig.field === level1GroupInput)
                const level2GroupConfigFields = level2GroupConfig.inputs.map(input => input.field)
                for (let l in rootGroup[rootGroupInput][k][level1GroupInput]) {
                  const level2Group = rootGroup[rootGroupInput][k][level1GroupInput][l]
                  for (let level2GroupInput in level2Group) {
                    let input
                    if (level2GroupConfigFields.includes(level2GroupInput)) {
                      const title = level2GroupConfig.inputs.find(input => input.field === level2GroupInput).name
                      const value = level2Group[level2GroupInput]
                      const validations = level2GroupConfig.inputs.find(input => input.field === level2GroupInput).validations || []
                      for (let validation of validations) {
                        if (validation === 'required') {
                          if (!value) {
                            message = 'Required'
                            onShowFieldError(`${title} is ${message}`)
                            input = level2GroupInput
                            break
                          }
                        } else if (validation === 'email') {
                          if (value && !emailIsValid(value)) {
                            message = 'Invalid email'
                            input = level2GroupInput
                            break
                          }
                        } else if (validation === 'percentage') {
                          if (Number(value) > 100) {
                            message = 'Should be no more than 100%'
                            input = level2GroupInput
                            break
                          }
                        } else if (validation === 'dataValid') {
                          if (value && !isValidDataFormat(value)) {
                            input = rootGroupInput
                            message = 'Invalid data'
                          }
                        }
                      }
                      if (input) {
                        inputPath = `${levelIndex}/${k}/${l}/${input}`
                        break outer
                      }
                    } else {
                      const level3GroupConfig = level3GroupsConfigs.find(level3GroupConfig => level3GroupConfig.field === level2GroupInput)
                      const level3GroupConfigFields = level3GroupConfig?.inputs.map(input => input.field)
                      for (let m in rootGroup[rootGroupInput][k][level1GroupInput][l][level2GroupInput]) {
                        const level3Group = rootGroup[rootGroupInput][k][level1GroupInput][l][level2GroupInput][m]
                        for (let level3GroupInput in level3Group) {
                          let input
                          if (level3GroupConfigFields.includes(level3GroupInput)) {
                            const title = level3GroupConfig.inputs.find(input => input.field === level3GroupInput).name
                            const value = level3Group[level3GroupInput]
                            const validations = level3GroupConfig.inputs.find(input => input.field === level3GroupInput).validations || []
                            for (let validation of validations) {
                              if (validation === 'required') {
                                if (!value) {
                                  message = 'Required'
                                  onShowFieldError(`${title} is ${message}`)
                                  input = level3GroupInput
                                  break
                                }
                              } else if (validation === 'email') {
                                if (value && !emailIsValid(value)) {
                                  message = 'Invalid email'
                                  input = level3GroupInput
                                  break
                                }
                              } else if (validation === 'percentage') {
                                if (Number(value) > 100) {
                                  message = 'Should be no more than 100%'
                                  input = level3GroupInput
                                  break
                                }
                              } else if (validation === 'dataValid') {
                                if (value && !isValidDataFormat(value)) {
                                  input = rootGroupInput
                                  message = 'Invalid data'
                                }
                              }
                            }
                            if (input) {
                              inputPath = `${levelIndex}/${k}/${l}/${input}`
                              break outer
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (inputPath) {
      setError({ inputPath, message })
      return false
    } else {
      return true
    }
  }

  const handleSave = async () => {
    if (!formIsValid()) return
    setIsSpinning(prevState => ({ ...prevState, level0GroupConfig: { full: true } }))
    await saveHandler(data, clearDeletionLog)
    setIsSpinning(prevState => ({ ...prevState, level0GroupConfig: { full: false } }))
  }

  //TODO: Should be done for levels 2,3, if it is necessary by this template
  const handleSaveLevel1 = async (field, index) => {
    if (!formIsValid(index)) return
    setIsSpinning(prevState => ({ ...prevState, level0GroupConfig: { ...prevState.level0GroupConfig, [`${field}_${index}`]: true } }))
    const newData = data[index]
    await saveHandler(newData, clearDeletionLog)
    setIsSpinning(prevState => ({ ...prevState, level0GroupConfig: { ...prevState.level0GroupConfig, [`${field}_${index}`]: false } }))
  }

  const onToggleOpen = (group, path) => value => {
    setIsOpened(prevState => {
      if (!path) return { ...prevState, [group]: value }
      return { ...prevState, [group]: { ...prevState[group], [path]: value } }
    })
  }

  useEffect(() => {
    if (error !== null && !isEditable) setError(null)
  }, [isEditable])

  const onCancel = () => {
    setData(initialData)

    setDeletionsLog([])
    onToggleEditMode('cancel')
  }

  useEffect(() => {
    if (latestAddedElementDOMId) {
      const element = document.getElementById(latestAddedElementDOMId)
      if (element) {
        const DISTINCTION_BETWEEN_TOP_AND_SCROLL_IN_PX = 70
        const elementPos = element.getBoundingClientRect().top + window.scrollY - DISTINCTION_BETWEEN_TOP_AND_SCROLL_IN_PX
        const options = { top: elementPos }
        scrollWindowToPosition(options)
        setLatestAddedElementDOMId(null)
      }
    }
  }, [latestAddedElementDOMId])

  useEffect(() => {
    setData(JSON.parse(JSON.stringify(initialData)))
  }, [initialData])

  return (
    <SubformWrapper
      title={level0GroupConfig.title}
      isOpened={isOpened.main}
      isOpenedHandler={() => onToggleOpen('main')(!isOpened.main)}
      isEditable={isEditable}
      isRoot
      addIsAllowed={data.length < level0GroupConfig.quantity.max}
      addHandler={() =>
        all(
          () => handleAddLevel0(),
          () => onToggleOpen('main')(true)
        )
      }
      cancelHandler={onCancel}
      isSpinning={isSpinning.level0GroupConfig.full}
      saveHandler={handleSave}
      saveIsAllowed={level0GroupConfig.canBeSaved}
      isOnlyForView={level0GroupConfig.isOnlyForView}
    >
      {data.map((rootGroup, i) => (
        <SubformInputGroup
          inputs={level0GroupConfig.inputs}
          data={rootGroup}
          changeHandler={(field, value) => handleChangeLevel0(i, field, value)}
          deleteIsAllowed={data.length > level0GroupConfig.quantity.min && permissions?.delete}
          deleteHandler={() => handleDeleteLevel0(i)}
          isEditable={isEditable}
          key={i}
          inputPath={`${i}`}
          error={error}
          onOpenDescription={onOpenDescription}
          saveHandler={() => handleSaveLevel1(level0GroupConfig.field, i)}
          isSpinning={isSpinning.level0GroupConfig[`${level0GroupConfig.field}_${i}`]}
          saveIsAllowed={level0GroupConfig.canBeSavedPart}
          isOnlyForView={level0GroupConfig.isOnlyForView}
        >
          {level1GroupsConfigs.map((level1GroupConfig, l) => {
            const name = 'level1GroupsConfigs'
            const pathToConfig = `${i}/${l}`
            const isInOpenMode = Boolean(isOpened.level1GroupsConfigs[pathToConfig])
            return (
              <SubformWrapper
                title={level1GroupConfig.title}
                isEditable={isEditable}
                addIsAllowed={rootGroup[level1GroupConfig.field].length < level1GroupsConfigs[l].quantity.max}
                addHandler={() => handleAddLevel1(i, l, { name, pathToConfig, isInOpenMode })}
                key={l}
                isOpened={isInOpenMode}
                isOpenedHandler={() => onToggleOpen(name, pathToConfig)(!isInOpenMode)}
                isOnlyForView={level0GroupConfig.isOnlyForView}
              >
                {rootGroup[level1GroupConfig.field].map((level1Group, k) => (
                  <SubformInputGroup
                    inputs={level1GroupsConfigs[l].inputs}
                    rootParentOptions={rootGroup.options}
                    data={level1Group}
                    changeHandler={(field, value) => handleChangeLevel1(i, k, l, field, value)}
                    deleteIsAllowed={rootGroup[level1GroupConfig.field].length > level1GroupsConfigs[l].quantity.min && permissions?.delete}
                    deleteHandler={() => handleDeleteLevel1(i, k, l)}
                    isEditable={isEditable}
                    key={k}
                    inputPath={`${i}/${k}`}
                    error={error}
                    onOpenDescription={onOpenDescription}
                    isOnlyForView={level0GroupConfig.isOnlyForView}
                  >
                    {level2GroupsConfigs.map((level2GroupConfig, j) => {
                      const name = 'level2GroupsConfigs'
                      const pathToConfig = `${i}/${l}/${k}/${j}`
                      const isInOpenMode = Boolean(isOpened.level2GroupsConfigs[pathToConfig])
                      return rootGroup[level1GroupConfig.field][k][level2GroupConfig.field] ? (
                        <SubformWrapper
                          title={level2GroupConfig.title}
                          isEditable={isEditable}
                          addIsAllowed={rootGroup[level1GroupConfig.field][k][level2GroupConfig.field].length < level2GroupsConfigs[j].quantity.max}
                          addHandler={() => handleAddLevel2(i, l, k, j, { name, pathToConfig, isInOpenMode })}
                          key={j}
                          isOpened={isInOpenMode}
                          isOpenedHandler={() => onToggleOpen(name, pathToConfig)(!isInOpenMode)}
                          isOnlyForView={level0GroupConfig.isOnlyForView}
                        >
                          {rootGroup[level1GroupConfig.field][k][level2GroupConfig.field].map((level2Group, q) => (
                            <SubformInputGroup
                              inputs={level2GroupsConfigs[j].inputs}
                              data={level2Group}
                              changeHandler={(field, value) => handleChangeLevel2(i, k, l, q, j, field, value)}
                              deleteIsAllowed={rootGroup[level1GroupConfig.field].length > level1GroupsConfigs[l].quantity.min && permissions?.delete}
                              deleteHandler={() => handleDeleteLevel2(i, k, l, q, j)}
                              isEditable={isEditable}
                              key={q}
                              inputPath={`${i}/${k}/${q}`}
                              error={error}
                              onOpenDescription={onOpenDescription}
                              isOnlyForView={level0GroupConfig.isOnlyForView}
                            >
                              {level3GroupsConfigs.map((level3GroupConfig, m) => {
                                const name = 'level3GroupsConfigs'
                                const pathToConfig = `${i}/${l}/${k}/${j}/${q}/${m}`
                                const isInOpenMode = Boolean(isOpened.level3GroupsConfigs[pathToConfig])
                                return rootGroup[level1GroupConfig.field][k]?.[level2GroupConfig.field][q]?.[level3GroupConfig.field] ? (
                                  <SubformWrapper
                                    title={level3GroupConfig.title}
                                    isEditable={isEditable}
                                    addIsAllowed={
                                      rootGroup[level1GroupConfig.field][k]?.[level2GroupConfig.field][q]?.[level3GroupConfig.field]?.length >
                                      level1GroupsConfigs[m].quantity.max
                                    }
                                    addHandler={() => handleAddLevel3(i, l, k, j, q, m, { name, pathToConfig, isInOpenMode })}
                                    key={m}
                                    isOpened={isInOpenMode}
                                    isOpenedHandler={() => onToggleOpen(name, pathToConfig)(!isInOpenMode)}
                                    isOnlyForView={level0GroupConfig.isOnlyForView}
                                  >
                                    {rootGroup[level1GroupConfig.field][k][level2GroupConfig.field][q][level3GroupConfig.field].map((level3Group, n) => (
                                      <SubformInputGroup
                                        inputs={level3GroupsConfigs[m].inputs}
                                        data={level3Group}
                                        changeHandler={(field, value) => handleChangeLevel3(i, k, l, q, j, n, m, field, value)}
                                        deleteIsAllowed={
                                          rootGroup[level1GroupConfig.field][k][level2GroupConfig.field][q][level3GroupConfig.field].length >
                                            level3GroupsConfigs[m].quantity.min && permissions?.delete
                                        }
                                        deleteHandler={() => handleDeleteLevel3(i, k, l, q, j, n, m)}
                                        isEditable={isEditable}
                                        key={n}
                                        inputPath={`${i}/${k}/${q}/${n}`}
                                        error={error}
                                        onOpenDescription={onOpenDescription}
                                        isOnlyForView={level0GroupConfig.isOnlyForView}
                                      />
                                    ))}
                                  </SubformWrapper>
                                ) : (
                                  <></>
                                )
                              })}
                            </SubformInputGroup>
                          ))}
                        </SubformWrapper>
                      ) : (
                        <></>
                      )
                    })}
                  </SubformInputGroup>
                ))}
              </SubformWrapper>
            )
          })}
        </SubformInputGroup>
      ))}
    </SubformWrapper>
  )
}

const groupConfigShape = PropTypes.shape({
  title: PropTypes.string,
  field: PropTypes.string,
  quantity: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      type: PropTypes.oneOf(['textInput', 'select', 'dataList', 'dataListAsync', 'datePicker']),
      options: PropTypes.arrayOf(PropTypes.object),
      validations: PropTypes.arrayOf(PropTypes.oneOf(['required', 'email', 'unique'])),
    })
  ),
})

Subform.propTypes = {
  name: PropTypes.string,
  initialData: PropTypes.array,
  groupsConfigs: PropTypes.shape({
    level0GroupConfig: groupConfigShape,
    level1GroupsConfigs: PropTypes.arrayOf(groupConfigShape),
    level2GroupsConfigs: PropTypes.arrayOf(groupConfigShape),
  }),
  isEditable: PropTypes.bool,
  saveHandler: PropTypes.func,
}

Subform.defaultProps = {
  name: null,
  initialData: [],
  groupsConfigs: {
    level0GroupConfig: {},
    level1GroupsConfigs: [],
    level2GroupsConfigs: [],
  },
  isEditable: false,
  saveHandler: () => {},
}

export const isLoading = state => state.studiesReducer.studentCourseSession.venueActivity.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.venueActivity.isSuccess

export const venueActivity = state => state.studiesReducer.studentCourseSession.venueActivity.venueActivity

export const errors = state => state.studiesReducer.studentCourseSession.venueActivity.errors

export const options = state => state.studiesReducer.studentCourseSession.venueActivity.options

export const permissions = state => state.studiesReducer.studentCourseSession.venueActivity.permissions

export const canBeCreatedVenueActivity = state => state.studiesReducer.studentCourseSession.venueActivity.canBeCreatedVenueActivity

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SessionYearForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { sessionYearActions, sessionYearSelectors } from '@Store/admin'

export const EditSessionYear = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { sessionYearId } = useParams()
  const sessionYear = useSelector(sessionYearSelectors.sessionYear)
  const isLoading = useSelector(sessionYearSelectors.isLoading)
  const isSuccess = useSelector(sessionYearSelectors.isSuccess)
  const permissions = useSelector(sessionYearSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).sessionYear

  useEffect(() => {
    dispatch(sessionYearActions.get({ id: sessionYearId }))
    return () => {
      dispatch(sessionYearActions.set({}))
    }
  }, [dispatch, sessionYearId])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This session year will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(sessionYearActions.remove({ id: sessionYearId, history }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onSubmit = data => {
    dispatch(sessionYearActions.edit(data))
  }

  return (
    <SessionYearForm
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={sessionYear}
      onDelete={onDelete}
      onSave={onSubmit}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
      isSuccess={isSuccess}
    />
  )
}

import { API } from '@Root/API'
import { createDataConfig } from '@Root/configs'
// curse
export const venueActivityTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Off venue activity',
      field: 'venue_activity_identifier',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Activity duration amount',
      field: 'duration_amount',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Activity duration type',
      field: 'duration_type',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Activity end date',
      field: 'activity_end_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Activity start date',
      field: 'activity_start_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Activity type identifier',
      field: 'activity_type',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Country',
      field: 'activity_country',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Host identifier',
      field: 'host',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Host identifier type',
      field: 'host_type',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Mobility scheme',
      field: 'mobility_scheme',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module instance identifier',
      field: 'module_instance',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Off venue activity', [], columns(), 'getModules', 'offVenueActivity', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.venueActivity.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Off venue activity': row => history.push(`${defaultPathURL}/off_venue_activity/${row.id}`),
    },
  }
}

export const referencePeriodStudentTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Reference period',
      field: 'reference_period',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Year',
      field: 'year',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'RP Student load',
      field: 'rp_student_load',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Reference period student load', [], columns(), 'getModules', 'referencePeriodStudentLoad', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.referencePeriod.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Off Venue Activity Identifier': row => history.push(`${defaultPathURL}/reference_period_student_load/${row.id}`),
    },
  }
}

export const studyLocationTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Study location identifier',
      field: 'study_location_identifier',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Distance learning',
      field: 'session_distance_learning',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Primarily outside the UK',
      field: 'primarily_outside',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Study proportion',
      field: 'study_proportion',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Venue identifier',
      field: 'venue',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Study location', [], columns(), 'getModules', 'studyLocation', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.studyLocation.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Study location': row => history.push(`${defaultPathURL}/study_location/${row.id}`),
    },
  }
}

export const studentFinancialSupportTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Financial support type',
      field: 'supportType',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Access and participation flag',
      field: 'access_participation_flag',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Financial support amount',
      field: 'financial_support_amount',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Student financial support', [], columns(), 'getModules', 'studentFinancialSupport', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.studentFinancialSupport.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Student financial support': row => history.push(`${defaultPathURL}/student_financial_support/${row.id}`),
    },
  }
}

export const sessionStatusTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Status valid from',
      field: 'status_valid_from',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Status changed to',
      field: 'status_change',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Session Status', [], columns(), 'getModules', 'sessionStatus', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.sessionStatus.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Session Status': row => history.push(`${defaultPathURL}/session_status/${row.id}`),
    },
  }
}

export const fundingBodyTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Funding body',
      field: 'body',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
  ]
  return {
    ...createDataConfig('Funding body', [], columns(), 'getModules', 'sessionStatus', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.fundingBody.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Funding body': row => history.push(`${defaultPathURL}/funding_body/${row.id}`),
    },
  }
}

export const moduleInstanceTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Module instance identifier',
      field: 'module_instance_identifier',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Accreditation of prior experiential learning',
      field: 'accreditation_of_prior',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Continuing module',
      field: 'continuing_module',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Inactive module flag',
      field: 'inactive_module_flag',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Language percentage',
      field: 'language_percentage',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module instance fee amount',
      field: 'fee_amount',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module count',
      field: 'module_count',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module identifier',
      field: 'educational_module',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module instance start date',
      field: 'module_start_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module instance end date',
      field: 'module_end_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module outcome',
      field: 'module_outcome',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Module result',
      field: 'module_result',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Module instances', [], columns(), 'getModules', 'moduleInstance', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.moduleInstances.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Module instances': row => history.push(`${defaultPathURL}/module_instances/${row.id}`),
    },
  }
}

export const supervisorAllocationTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Supervisor allocation identifier',
      field: 'supervisor_identifier',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'HESA identifier',
      field: 'supervisor_hesa',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'REF2021 unit of assessment',
      field: 'ref2021_unit_of_assessment',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Supervisor allocation proportion',
      field: 'proportion',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Supervisor allocation', [], columns(), 'getModules', 'supervisorAllocation', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.supervisorAllocation.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Supervisor allocation': row => history.push(`${defaultPathURL}/supervisor_allocation/${row.id}`),
    },
  }
}

export const fundingAndMonitoringTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'ID',
      field: 'id',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Funding length',
      field: 'funding_lengths',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Funding Completion',
      field: 'funding_completion',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Equivalent or Lower Qualification',
      field: 'equivalent_qualification',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Non regulated fee flag',
      field: 'non_regulated_fee_flags',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Funding and Monitoring', [], columns(), 'getModules', 'fundingAndMonitoring', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.fundingAndMonitoring.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Funding and Monitoring': row => history.push(`${defaultPathURL}/funding-and-monitoring/${row.id}`),
    },
  }
}

export const postgradResearchTableData = (courseSessionId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'ID',
      field: 'id',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'Proposal Date',
      field: 'proposal_date',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'id_link',
    },
    {
      name: 'TEI Response',
      field: 'TCB_response',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Accred Inst Date',
      field: 'UOB_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'UOB Response Date',
      field: 'UOB_response_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Dissertation Topic',
      field: 'dissertation_topic',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Upgrade Date',
      field: 'upgrade_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Submission Date 1',
      field: 'submission_date_1',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Supervisor 1',
      field: 'supervisor_1',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Supervisor 2',
      field: 'supervisor_2',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Supervisor 3',
      field: 'supervisor_3',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Examiner',
      field: 'examiner',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Status',
      field: 'status',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'TEI Response Date',
      field: 'TCB_response_date',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Accred Inst Response',
      field: 'UOB_response',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Dissertation Title',
      field: 'dissertation_title',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Programme Manager',
      field: 'managed_by_id',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Submission Date 2',
      field: 'submission_date_2',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Hours 1',
      field: 'hours_1',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Hours 2',
      field: 'hours_2',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Hours 3',
      field: 'hours_3',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Upgrader',
      field: 'upgrader',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Postgrad research', [], columns(), 'getModules', 'fundingAndMonitoring', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.studentCourseSession.postgrad.getAll(courseSessionId, params),
    clickLinkHandlers: {
      'Postgrad research': row => history.push(`${defaultPathURL}/post_grad/${row.id}`),
    },
  }
}

// program
export const studentsTableData = (options, programmeId, suspendedMandatoryRules, rulePermissions, SLCLoan) => {
  const {
    managed_by = [],
    ordinand = [],
    institutions = [],
    program_awarding_bodies = [],
    learning_centre = [],
    sponsors = [],
    reason_for_student_course_session_ending = [],
    genders = [],
    study_years = [],
    academic_years_list = [],
  } = { ...options.static, ...options.dynamic }
  return {
    name: 'studies-student-programme',
    columns: [
      {
        name: 'Name',
        field: 'student',
        searchInputType: 'textInput',
        cellType: 'link',
        cellLinkField: 'student_link',
      },
      {
        name: 'Forenames',
        field: 'fore_names',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Surname',
        field: 'surname',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Gender',
        field: 'gender',
        searchInputType: 'select',
        options: genders.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Student ID',
        field: 'student_id',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Academic Year',
        field: 'academic_year',
        searchInputType: 'select',
        options: academic_years_list,
        cellType: 'text',
      },
      {
        name: 'Study Year',
        field: 'study_years',
        searchInputType: 'select',
        options: study_years.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Status',
        field: 'status_program',
        searchInputType: 'select',
        options: reason_for_student_course_session_ending.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Sponsor',
        field: 'sponsor',
        searchInputType: 'select',
        options: sponsors.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Program manager',
        field: 'managed_by',
        searchInputType: 'select',
        options: managed_by.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Ordinand',
        field: 'ordinand',
        searchInputType: 'select',
        options: ordinand.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Awarding Body',
        field: 'awarding_body',
        searchInputType: 'select',
        options: program_awarding_bodies.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Institution',
        field: 'institution',
        searchInputType: 'select',
        options: institutions.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'Learning Centre',
        field: 'training_mode',
        searchInputType: 'select',
        options: learning_centre.map(x => x.label),
        cellType: 'text',
      },
      {
        name: 'DOB',
        field: 'DOB',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'SLC',
        field: 'SLC',
        searchInputType: 'select',
        options: SLCLoan.map(x => x.label),
        cellType: 'text',
      },
    ],
    fetchDataHandler: params => API.studies.programme.getAttachedStudents(programmeId, params),
    fetchSaveColumnOptions: options => API.saveColumnOptions('studies-student-programme', options),
    fetchColumnOptions: async () => await API.getColumnOptions('studies-student-programme'),
    mandatoryRules: suspendedMandatoryRules,
    hasSorting: true,
    hasFilters: true,
    hasRules: true,
    clickLinkHandlers: {},
    permissions: {
      rule: rulePermissions['studies-student-programme'],
    },
  }
}

export const courseInitiativeTableData = (studyProgrammeId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Initiative identifier',
      field: 'initiative',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'initiative_link',
    },
    {
      name: 'Initiative valid from date',
      field: 'valid_from',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Initiative valid to date',
      field: 'valid_to',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Course initiative', [], columns(), 'getModules', 'courseInitiative', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.programme.courseInitiative.getAll(studyProgrammeId, params),
    clickLinkHandlers: {
      'Course initiative': row => history.push(`${defaultPathURL}/course_initiative/${row.id}`),
    },
  }
}

export const courseReferenceTableData = (studyProgrammeId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Course reference identifier',
      field: 'identifier',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'identifier_link',
    },
    {
      name: 'Course reference identifier type',
      field: 'identifier_type',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Course reference', [], columns(), 'getModules', 'courseReference', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.programme.courseReference.getAll(studyProgrammeId, params),
    clickLinkHandlers: {
      'Course reference': row => history.push(`${defaultPathURL}/course_reference/${row.id}`),
    },
  }
}

export const courseRoleTableData = (studyProgrammeId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'HESA identifier',
      field: 'hesa_identifier_label',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'hesa_identifier_link',
    },
    {
      name: 'Role type',
      field: 'role_type',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Course role proportion',
      field: 'proportion',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Course role', [], columns(), 'getModules', 'courseRole', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.programme.courseRole.getAll(studyProgrammeId, params),
    clickLinkHandlers: {
      'Course role': row => history.push(`${defaultPathURL}/course_role/${row.id}`),
    },
  }
}

export const curriculumAccreditationTableData = (studyProgrammeId, defaultPathURL, history) => {
  const columns = () => [
    {
      name: 'Accreditation identifier',
      field: 'identifier',
      searchInputType: 'textInput',
      cellType: 'link',
      cellLinkField: 'identifier_link',
    },
    {
      name: 'Accreditation valid from date',
      field: 'valid_from',
      searchInputType: 'textInput',
      cellType: 'text',
    },
    {
      name: 'Accreditation valid to date',
      field: 'valid_to',
      searchInputType: 'textInput',
      cellType: 'text',
    },
  ]
  return {
    ...createDataConfig('Curriculum accreditation', [], columns(), 'getModules', 'curriculumAccreditation', false, false, false, false, false, false),
    fetchDataHandler: params => API.studies.programme.curriculumAccreditation.getAll(studyProgrammeId, params),
    clickLinkHandlers: {
      'Curriculum accreditation': row => history.push(`${defaultPathURL}/curriculum_accreditation/${row.id}`),
    },
  }
}

import * as types from './role.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_ROLE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_ROLE,
		payload,
	}),
	set: payload => ({
		type: types.SET_ROLE,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_ROLE,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_ROLE,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: () => ({
		type: types.GET_DYNAMIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

export const TOGGLE_LOADING = 'programme/courseReference/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'programme/courseReference/TOGGLE_SUCCESS'
export const GET_COURSE_REFERENCE = 'programme/courseReference/GET_COURSE_REFERENCE'
export const SET_COURSE_REFERENCE = 'programme/courseReference/SET_COURSE_REFERENCE'
export const CREATE_COURSE_REFERENCE = 'programme/courseReference/CREATE_COURSE_REFERENCE'
export const DELETE_COURSE_REFERENCE = 'programme/courseReference/DELETE_COURSE_REFERENCE'
export const EDIT_COURSE_REFERENCE = 'programme/courseReference/EDIT_COURSE_REFERENCE'
export const GET_OPTIONS = 'programme/courseReference/GET_OPTIONS'
export const SET_OPTIONS = 'programme/courseReference/SET_OPTIONS'
export const CLEAR_DATA = 'programme/courseReference/CLEAR_DATA'
export const SET_PERMISSIONS = 'programme/courseReference/SET_PERMISSIONS'

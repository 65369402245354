import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { all } from '@Root/helpers'
import plusBig from '../../../assets/icons/plus-big.png'

export class FileInput extends Component {
  state = {
    error: null,
    inDropZone: false,
    dropDepth: 0,
  }

  input = React.createRef()

  validateFilesCount = (files, maxCount) => {
    if (files.length > maxCount) {
      this.showError('File is too big (Maximum file size is 20 MB)')
      return false
    }
    return true
  }

  validateFileRestriction = file => {
    const fileType = file.type.split('/')[0]
    return this.restrictionIsPassed(fileType)
  }

  validateFile = file => {
    if (file.size > 20971520) {
      this.showError('File is too big (Maximum file size is 20 MB)')
      return false
    }
    return this.validateFileRestriction(file)
  }

  pushFileToHandler = file => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      this.props.changeHandler(reader.result, file)
    }
  }

  onGetFile = files => {
    if (files.length) {
      const isInCount = this.validateFilesCount(files, 1)
      if (!isInCount) return
      const file = files[0]
      const isValid = this.validateFile(file)
      if (!isValid) return
      this.pushFileToHandler(file)
    } else {
      this.props.changeHandler(null, null)
    }
  }
  handleChange = e => {
    const files = e.target.files
    this.onGetFile(files)
  }

  handleRemove = e => {
    e.stopPropagation()
    this.input.current.value = ''
    this.props.removeHandler()
  }

  restrictionIsPassed = fileType => {
    if (this.props.restrictions && !this.props.restrictions.includes(fileType)) {
      this.showError('File type is not correct')
      return false
    } else {
      return true
    }
  }

  showError = async error => {
    this.setState({ error: null })
    this.setState({ error })
  }

  onDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    const files = e.dataTransfer.files
    this.onGetFile(files)
  }

  onDragOver = e => {
    e.preventDefault()
    e.stopPropagation()

    e.dataTransfer.dropEffect = 'copy'
    this.setState({ inDropZone: true })
  }

  onDragLeave = e => {
    const { dropDepth } = this.state
    e.preventDefault()
    e.stopPropagation()

    this.setState({ dropDepth: dropDepth - 1 })
    if (dropDepth > 0) return
    this.setState({ inDropZone: false })
  }

  onDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()

    this.setState(prevState => ({ dropDepth: prevState.dropDepth + 1 }))
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.props.error ? this.showError(this.props.error) : this.showError(null)
    }
  }

  render() {
    const { error } = this.state
    const { inputClassNames, style, circleStyle, value, file, isDisabled, hasText, dragAndDrop } = this.props
    const { input, handleChange, handleRemove } = this
    const fileType = file ? file.type.split('/')[0] : null

    const dragAndDropFileHandlers = dragAndDrop
      ? {
          onDrop: this.onDrop,
          onDragOver: this.onDragOver,
          onDragEnter: this.onDragEnter,
          onDragLeave: this.onDragLeave,
        }
      : {}

    return (
      <div
        className={`${classes.FileInput}  
                    ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
        style={{ ...style, borderColor: error ? '#D0021B' : null }}
        onClick={isDisabled ? e => e.preventDefault() : null}
      >
        <div
          className={`${classes.preview} ${isDisabled ? classes.disabled : ''}`}
          style={value ? { backgroundImage: `url("${value}")` } : null}
          onClick={() =>
            all(
              () => input.current.click(),
              () => this.setState({ error: null })
            )
          }
          {...dragAndDropFileHandlers}
        >
          {!value ? (
            <div className={classes.placeholder}>
              <div className={classes.circle} style={circleStyle}>
                <img src={plusBig} alt='' />
              </div>
              {hasText && !isDisabled && <div className={classes.text}>Click to upload</div>}
            </div>
          ) : (
            <>
              {!!file && fileType !== 'image' && <div className={classes.name}>{file.name}</div>}
              {!isDisabled && (
                <div className={classes.remove} onClick={handleRemove}>
                  Remove
                </div>
              )}
            </>
          )}
        </div>
        <input type='file' onChange={handleChange} ref={input} />
        {error && <div className={classes.error}>{error}*</div>}
      </div>
    )
  }
}

FileInput.propTypes = {
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless'])),
  style: PropTypes.object,
  circleStyle: PropTypes.object,
  value: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  changeHandler: PropTypes.func,
  removeHandler: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.oneOf(['image', 'application', 'text'])),
  isDisabled: PropTypes.bool,
  hasText: PropTypes.bool,
  error: PropTypes.string,
}

FileInput.defaultProps = {
  inputClassNames: [],
  style: {},
  circleStyle: {},
  value: '',
  file: null,
  changeHandler: () => {},
  removeHandler: () => {},
  restrictions: null,
  isDisabled: false,
  hasText: true,
  error: null,
  dragAndDrop: false,
}

export const TOGGLE_LOADING = 'programme/courseInitiative/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'programme/courseInitiative/TOGGLE_SUCCESS'
export const GET_COURSE_INITIATIVE = 'programme/courseInitiative/GET_COURSE_INITIATIVE'
export const SET_COURSE_INITIATIVE = 'programme/courseInitiative/SET_COURSE_INITIATIVE'
export const CREATE_COURSE_INITIATIVE = 'programme/courseInitiative/CREATE_COURSE_INITIATIVE'
export const DELETE_COURSE_INITIATIVE = 'programme/courseInitiative/DELETE_COURSE_INITIATIVE'
export const EDIT_COURSE_INITIATIVE = 'programme/courseInitiative/EDIT_COURSE_INITIATIVE'
export const GET_OPTIONS = 'programme/courseInitiative/GET_OPTIONS'
export const SET_OPTIONS = 'programme/courseInitiative/SET_OPTIONS'
export const CLEAR_DATA = 'programme/courseInitiative/CLEAR_DATA'
export const SET_PERMISSIONS = 'programme/courseInitiative/SET_PERMISSIONS'

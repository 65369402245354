import React, { useEffect } from 'react'
import { ActionsDropdown, QualificationAwardForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { qualificationAwardActions, qualificationAwardSelectors } from '@Store/studies'
import { DetailInformation } from './DetailInformation'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditQualificationAward = ({ onOpenDescriptionModal }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const qualificationAward = useSelector(qualificationAwardSelectors.qualificationAward)
  const isSuccess = useSelector(qualificationAwardSelectors.isSuccess)
  const isLoading = useSelector(qualificationAwardSelectors.isLoading)
  const options = useSelector(qualificationAwardSelectors.options)
  const errors = useSelector(qualificationAwardSelectors.errors)
  const permissions = useSelector(qualificationAwardSelectors.permissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const { id: engagementId,  contactId, qualificationAwardId } = useParams()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_qualification_award

  const actions = () => {
    return [
      ...(dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Qualification award accreditation',
              path: `/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${qualificationAwardId}/qualification-award-accreditation/new`,
            },
          ]
        : []),
    ]
  }

  useEffect(() => {
    dispatch(qualificationAwardActions.get({ data: { qualificationAwardId, engagementId, contactId }, history }))
    return () => {
      dispatch(qualificationAwardActions.clearData())
    }
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This qualification award will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(qualificationAwardActions.delete({ data: { engagementId, qualificationAwardId, contactId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onChooseAction = actionName => {
    actions()
      .find(action => action.name === actionName)
      .handler()
  }

  const onSave = data => {
    dispatch(qualificationAwardActions.edit({ data, engagementId }))
  }

  const onCancel = memoData => {
    dispatch(qualificationAwardActions.set(memoData))
    dispatch(qualificationAwardActions.setErrors([]))
  }

  return (
    <>
      {!!actions().length && <ActionsDropdown actions={actions()} clickHandler={onChooseAction} style={{ position: 'absolute', right: 10, top: -60 }} />}
      <QualificationAwardForm
        onSave={onSave}
        onDelete={onDelete}
        onCancel={onCancel}
        canEdit={permissions.form?.edit}
        canDelete={permissions.form?.delete}
        isLoading={isLoading}
        isSuccess={isSuccess}
        initialValue={qualificationAward}
        options={options}
        initialErrors={errors}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
      {permissions.tabs && (
        <DetailInformation contactId={contactId} engagementId={engagementId} qualificationAwardId={qualificationAwardId} permissions={permissions.tabs} />
      )}
    </>
  )
}

import React from 'react'
import styles from './style.module.scss'
import { createDataConfig } from '@Root/configs'
import { VerticalTabs, TableBlock } from '@Root/components'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { API } from '@Root/API'

export const DetailInformation = ({ engagementId, contactId, entryProfileId, permissions }) => {
	const defaultURL = `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}`
	const history = useHistory()
	const EntryQualificationAward = () => {
		const columns = () => [
			{
				name: 'Entry qualification awarded identifier',
				field: 'qualification_award_identifier',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'qualification_award_identifier_link',
			},
			{
				name: 'Own qualification identifier',
				field: 'own_award_identifier',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Qualification result',
				field: 'qualification_result_label',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Qualification type identifier',
				field: 'qualification_types_label',
				searchInputType: 'textInput',
				cellType: 'text',
			},
			{
				name: 'Qualification year',
				field: 'qualification_year',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Entry Qualification Award', [], columns(), '', 'entryQualificationAward', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.engagement.entryProfile.entryQualificationAwarded.getAll(entryProfileId, params),
			clickLinkHandlers: {
				'Entry qualification awarded identifier': row => history.push(`${defaultURL}/entry-qualification-award/${row.id}/entry-qualification-award`),
			},
		}
	}

	const tables = [
		...(permissions.entry_qualification_award
			? [{ name: 'Entry qualification award', label: 'entry-qualification-award', tableInformation: EntryQualificationAward() }]
			: []),
	]

	return (
		<div className={styles.wrapper}>
			<VerticalTabs tabs={tables} isLink defaultURL={defaultURL} />
			<Switch>
				{tables.map(({ name, tableInformation, label }) => (
					<Route exact={true} key={name} path={`${defaultURL}/${label}`} render={() => <TableBlock title={name} config={tableInformation} />} />
				))}
				<Redirect to={`${defaultURL}/${tables[0]?.label || ''}`} />
			</Switch>
		</div>
	)
}

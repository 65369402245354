import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { sessionYearActions, sessionYearSelectors } from '@Store/admin'
import { useHistory } from 'react-router'
import { SessionYearForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateSessionYear = ({ onOpenDescriptionModal, parentURL }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const sessionYear = useSelector(sessionYearSelectors.sessionYear)
	const isLoading = useSelector(sessionYearSelectors.isLoading)
	const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).sessionYear
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

	const onSubmit = data => {
		dispatch(sessionYearActions.create({ data, history }))
	}

	const onCancel = () => {
		history.length > 1 ? history.goBack() : history.push(parentURL)
	}

	if (!dataCreationPermissions.session_year) return <Redirect to={parentURL} />
	return (
		<SessionYearForm
			canEdit
			isNew
			initialValue={sessionYear}
			onSave={onSubmit}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			isLoading={isLoading}
			onCancel={onCancel}
		/>
	)
}

import * as types from './institution.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleSuccess: payload => ({
    type: types.TOGGLE_SUCCESS,
    payload,
  }),
  get: payload => ({
    type: types.GET_INSTITUTION,
    payload,
  }),
  set: payload => ({
    type: types.SET_INSTITUTION,
    payload,
  }),
  create: payload => ({
    type: types.CREATE_INSTITUTION,
    payload,
  }),
  edit: payload => ({
    type: types.EDIT_INSTITUTION,
    payload,
  }),
  remove: payload => ({
    type: types.DELETE_INSTITUTION,
    payload,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
  getOptions: payload => ({
    type: types.GET_OPTIONS,
    payload,
  }),
  setOptions: payload => ({
    type: types.SET_OPTIONS,
    payload,
  }),
}

export const generateStyleConfig = isNotTable => {
	return {
		navLinksStyles: {
			position: isNotTable ? 'static' : 'fixed',
			width: '100%',
			top: '103px',
			right: 0,
			zIndex: 10,
			padding: isNotTable ? 0 : '0 10px',
		},
		actionsDropdown: {
			position: isNotTable ? 'absolute' : 'fixed',
			right: isNotTable ? 10 : 20,
			top: isNotTable ? -120 : 122,
			zIndex: 12,
		},
		pageTitle: {
			position: isNotTable ? 'static' : 'fixed',
			top: 37,
			zIndex: 10,
			marginTop: isNotTable ? 50 : 10,
		},
		tableBar: {
			position: isNotTable ? 'static' : 'fixed',
			width: '100%',
			top: 205,
			padding: '0 10px',
			zIndex: 150,
		},
		table: {
			datePeriod: {
				top: 62,
			},
			datePeriodDropdownStyle: {
				position: isNotTable ? 'absolute' : 'fixed',
				top: isNotTable ? -140 : 62,
				zIndex: 50,
				right: isNotTable ? 0 : 10,
			},
			cleanFilters: {
				position: isNotTable ? 'static' : 'fixed',
				top: 170,
				background: isNotTable ? '#ffffff' : '#f5f5f5',
			},
			upperPaginationContainerStyles: {
				marginTop: isNotTable ? 0 : 250,
			},
			isFixedTableHeader: true,
			isHideScrollAtBody: true,
		},
	}
}

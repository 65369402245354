import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import styles from './style.module.scss'
import { CreateCostCenter } from './Create'
import { EditCostCenter } from './Edit'
import { costCenterActions, fieldTooltipManagerActions, modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'

const PARENT_URL = `/home/studies/modules`
export const CostCenterPage = () => {
	const { moduleId } = useParams()
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname
	const isNew = pathname.includes('new')
	const DEFAULT_URL = `/home/studies/modules/${moduleId}`
	useEffect(() => {
		dispatch(costCenterActions.getStaticOptions())
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'module-cost-center' }))
	}, [dispatch])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: description,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
					title,
				})
			)
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	return (
		<SectionWrapper hasBackButton backButtonText={'Back to Modules'} clickBackButtonHandler={() => history.push(`${PARENT_URL}/${moduleId}`)}>
			<div className={styles.section}>
				{isNew ? (
					<CreateCostCenter onOpenDescriptionModal={onOpenDescriptionModal} defaultURL={DEFAULT_URL} />
				) : (
					<EditCostCenter onOpenDescriptionModal={onOpenDescriptionModal} defaultURL={DEFAULT_URL} />
				)}
			</div>
		</SectionWrapper>
	)
}

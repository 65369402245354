import { useState, useEffect, useCallback, useRef } from 'react'
import { getFromQueryString } from '@Root/helpers'
import { useLocation } from 'react-router'

const defaultVisibleQuantity = {
  min: 10,
  max: 25,
}
const DEFAULT_PAGE = 1
export const usePagination = (initialVisibleRowsQuantity = defaultVisibleQuantity) => {
  const location = useLocation()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [visibleRowsQuantity, setVisibleRowsQuantity] = useState(initialVisibleRowsQuantity)
  const [data, setData] = useState({})
  const isRenderedRef = useRef(false)

  const onChangeParamsInUrl = useCallback(
    (page = DEFAULT_PAGE, perPage = defaultVisibleQuantity.max) => {
      window.history.replaceState(null, null, `${location.pathname}?page=${page}&count=${perPage}`)
    },
    [location.pathname]
  )

  const onChangePage = useCallback(value => {
    setPage(value)
  }, [])

  const onChangeVisibleRowsQuantity = field => value => {
    setVisibleRowsQuantity(prevState => ({ ...prevState, [field]: value }))
    setPage(1)
  }

  const onChangeData = useCallback(value => {
    setData(value)
  }, [])

  useEffect(() => {
    if (isRenderedRef.current) {
      const perPage = visibleRowsQuantity.max
      onChangeParamsInUrl(page, perPage)
    }
  }, [onChangeParamsInUrl, page, visibleRowsQuantity.max])

  useEffect(() => {
    const savedPage = getFromQueryString('page')
    const savedPerPage = getFromQueryString('count')
    if (savedPage && savedPerPage) {
      setVisibleRowsQuantity(prevState => ({ ...prevState, max: Number(savedPerPage) }))
      setPage(Number(savedPage))
    } else {
      onChangeParamsInUrl()
    }
    isRenderedRef.current = true
  }, [onChangeParamsInUrl])

  return { page, data, visibleRowsQuantity, onChangePage, onChangeVisibleRowsQuantity, onChangeData, isReady: isRenderedRef.current }
}

import * as types from './tableManager.types'

export const actions = {
	toggleLoading: () => ({
		type: types.TOGGLE_LOADING,
	}),
	get: payload => ({
		type: types.GET_TABLE_PERMISSIONS,
		payload,
	}),
	getAdditionalFields: () => ({
		type: types.GET_ADDITIONAL_FIELDS_TABLE_PERMISSIONS,
	}),
	editAdditionalFields: payload => ({
		type: types.EDIT_ADDITIONAL_FIELDS_TABLE_PERMISSIONS,
		payload,
	}),
	set: payload => ({
		type: types.SET_TABLE_PERMISSIONS,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_TABLE_PERMISSIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

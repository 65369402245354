import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentLink } from '@Root/helpers'
import DocumentTitle from 'react-document-title'
import { PageTitle, PageNavLinks } from '@Root/components'
import { ContactsTable } from './Table/'
import { ContactsDetailsPage } from './ContactsDetails'
import { contactsLinks } from '@Root/configs'
import { ErrorPage } from '@Root/pages'
import { useLocation } from 'react-router'
import { generateStyleConfig } from './lib'
import { useSelector } from 'react-redux'
import { permissionsSelectors } from '@Store/permissions'
import { Preloader } from '@Root/components/Loaders/Preloader/index'
import { usePermissions } from '@Hooks'

export const ContactsPage = () => {
  const location = useLocation()
  const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)
  const additionalPermissions = useSelector(permissionsSelectors.additionalPermissions)
  const pathname = location.pathname
  const currentLink = getCurrentLink(contactsLinks, location)
  const currentLinkName = currentLink.name
  const isUser = location.pathname.split('/')[4]
  const tab = pathname.split('/')[3]
  const isNotTable = pathname.includes('new-person') || pathname.includes('new-organisation') || Boolean(isUser)
  const isEmptyPermissions = usePermissions({ additionalPermissions, tab })

  const styleConfig = generateStyleConfig(isNotTable)

  const filteredPageLinks = useMemo(() => contactsLinks.filter(link => tabsPermissions?.contacts?.tabs?.[link.label]), [tabsPermissions])
  const PageInMemo = useMemo(() => () => <ContactsTable styleConfig={styleConfig} isEmptyPermissions={isEmptyPermissions} />, [styleConfig])
  return tabsPermissions ? (
    <DocumentTitle title={`${currentLinkName} - Contacts`}>
      <div className={styles.wrapper}>
        <PageTitle title={currentLinkName} style={styleConfig.pageTitle} />
        <PageNavLinks style={styleConfig.navLinksStyles} root={`/home/contacts`} links={filteredPageLinks} />
        <Switch>
          {filteredPageLinks.map((link, i) => (
            <Route exact path={`/home/contacts/${link.path}`} component={PageInMemo} key={i} />
          ))}
          {true && (
            <Route exact path='/home/contacts/:tab/new-person' render={() => <ContactsDetailsPage currentLink={currentLink} styleConfig={styleConfig} />} />
          )}
          {true && (
            <Route
              exact
              path='/home/contacts/:tab/new-organisation'
              render={() => <ContactsDetailsPage currentLink={currentLink} styleConfig={styleConfig} />}
            />
          )}
          <Route
            exact
            path='/home/contacts/:tab/:contactId/:subTab?'
            render={() => <ContactsDetailsPage currentLink={currentLink} styleConfig={styleConfig} />}
          />
          <Route exact path='/page-not-found' component={ErrorPage} />
          <Redirect to='/home/contacts/all-contacts' />
        </Switch>
      </div>
    </DocumentTitle>
  ) : (
    <Preloader />
  )
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './attachments.types'
import { actions } from './attachments.actions'
import { snackbarActions } from '../snackbar'
import { errorMessage } from '@Root/helpers'
import { createInstance } from '@Root/API'
import objectToFormData from 'object-to-formdata'

const config = {
	headers: {
		'Content-Type': 'multipart/form-data',
	},
}

function* createAttachment({ payload }) {
	const { url, data } = payload
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(createInstance(config).post, url, objectToFormData(data))
		yield put(actions.setAttachment(response.data.data))
		yield put(actions.toggleSuccess(true))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

function* editAttachment({ payload }) {
	const { url, data } = payload
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(createInstance(config).post, url, objectToFormData(data))
		yield put(actions.setEditAttachment(response.data.data))
		yield put(actions.toggleSuccess(true))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

function* deleteAttachment({ payload }) {
	const { url, id } = payload
	yield put(actions.toggleLoading(true))
	try {
		yield call(createInstance().delete, url)
		yield put(actions.setDeleteAttachment({ id }))
		yield put(actions.toggleSuccess(true))
	} catch (error) {
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

export function* rootSaga() {
	yield all([
		takeEvery(types.CREATE_ATTACHMENT, createAttachment),
		takeEvery(types.EDIT_ATTACHMENT, editAttachment),
		takeEvery(types.DELETE_ATTACHMENT, deleteAttachment),
	])
}

import React, { useEffect } from 'react'
import { moodleActions, moodleSelectors } from '@Store/admin'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router'
import { MoodleLinkForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'

export const Create = ({ parentURL }) => {
	const isLoading = useSelector(moodleSelectors.isLoading)
	const error = useSelector(moodleSelectors.error)
	const permissions = useSelector(moodleSelectors.permissions)
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		let timeout
		if (error) {
			timeout = setTimeout(() => {
				dispatch(moodleActions.setError(null))
			}, 1000)
		}
		return () => {
			dispatch(moodleActions.setError(null))
			dispatch(moodleActions.set(null))
			clearTimeout(timeout)
		}
	}, [dispatch, error])

	const onSubmit = data => {
		dispatch(moodleActions.create({ data, history }))
	}

	const onCancel = () => {
		history.goBack()
	}

	if (!dataCreationPermissions.snapshots) return <Redirect to={parentURL} />
	return <MoodleLinkForm isLoading={isLoading} onSubmit={onSubmit} onCancel={onCancel} isEditable error={error} isNew permissions={permissions} />
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions, moduleActions, moduleSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors } from '@Root/store'
import { ActionsDropdown, StudiesModuleForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { DetailInformation } from './DetailInformation'
import { permissionsSelectors } from '@Store/permissions'

export const EditModule = () => {
	const history = useHistory()
	const { moduleId } = useParams()
	const dispatch = useDispatch()
	const module = useSelector(moduleSelectors.module)
	const canBeCreatedSubject = useSelector(moduleSelectors.canBeCreatedSubject)
	const options = useSelector(moduleSelectors.options).static
	const isLoading = useSelector(moduleSelectors.isLoading)
	const isSuccess = useSelector(moduleSelectors.isSuccess)
	const permissions = useSelector(moduleSelectors.permissions)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).modules
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
	const moduleDeliveryId = module?.module_delivery?.module_delivery_id

	const actions = () => {
		return [
			...(dataCreationPermissions.modules_sub
				? [
						...(canBeCreatedSubject
							? [
									{
										name: 'Create a module subject',
										path: `/home/studies/modules/${moduleId}/module-subject/new`,
									},
							  ]
							: []),
						{
							name: 'Create a module cost centre',
							path: `/home/studies/modules/${moduleId}/module-delivery/${moduleDeliveryId}/module-cost-center/new`,
						},
						{
							name: 'Create a module delivery role',
							path: `/home/studies/modules/${moduleId}/module-delivery/${moduleDeliveryId}/module-delivery-role/new`,
						},
				  ]
				: []),
		]
	}

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This module will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(() => {
				dispatch(moduleActions.remove({ moduleId, history }))
			})
			.finally(() => dispatch(modalActions.hideModal()))
	}

	const onSubmit = payload => {
		dispatch(moduleActions.edit({ ...payload, module_id: moduleId }))
	}

	useEffect(() => {
		dispatch(moduleActions.get({ moduleId, history }))
		dispatch(moduleActions.getStaticOptions())
	}, [moduleId, dispatch, history])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: description,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
					title,
				})
			)
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'modules' }))
		return () => {
			dispatch(moduleActions.set({}))
			dispatch(moduleActions.clearPermissions())
		}
	}, [dispatch])

	return (
    <div className={styles.edit_section}>
      {!!actions().length && <ActionsDropdown actions={actions()} style={{ position: 'absolute', right: 10, top: -120 }} />}
      <StudiesModuleForm
        options={options}
        initialValue={module}
        onDelete={onDelete}
        onSave={onSubmit}
        onOpenDescriptionModal={onOpenDescriptionModal}
        descriptions={descriptions}
        isLoading={isLoading}
        isSuccess={isSuccess}
        permissions={permissions}
      />
      {moduleDeliveryId && permissions?.tabs && <DetailInformation moduleId={moduleId} moduleDeliveryId={moduleDeliveryId} permissions={permissions.tabs} />}
    </div>
  )
}

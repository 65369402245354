import React, { PureComponent } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { all } from '@Root/helpers'
import printer1 from '../../../assets/icons/printer1.png'
import printer2 from '../../../assets/icons/printer2.png'
import person from '../../../assets/icons/person.png'
import organisation from '../../../assets/icons/organisation.png'
import postbox from '../../../assets/icons/postbox.png'
import target from '../../../assets/icons/target.png'
import { CopyIcon } from '@Root/assets'
import { Link } from 'react-router-dom'

class Dropdown extends PureComponent {
	actionIcons = {
		'Get PDF': printer1,
		'Print photos': printer2,
		'Add a person': person,
		'Add an organisation': organisation,
		'Add recipients to mailshot': postbox,
		'Add contact to target group': target,
		'Duplicate Student Course Session': CopyIcon,
	}

	state = { actionsAreShown: false }

	handleClickOutside = () => {
		this.setState({ actionsAreShown: false })
	}

	render() {
		const { actionsAreShown } = this.state
		const { actions = [], clickHandler, style } = this.props
		const { actionIcons } = this

		return (
			<div className={classes.ActionsDropdown} style={actionsAreShown ? { ...style, zIndex: 200 } : style}>
				<button className={classes.button} onClick={() => this.setState({ actionsAreShown: true })}>
					Actions
				</button>
				{actionsAreShown && (
					<div className={classes.actions}>
						{actions.map(action =>
							action?.handler ? (
								<div
									className={classes.action}
									onClick={() =>
										all(
											() => clickHandler(action.name),
											() => this.setState({ actionsAreShown: false })
										)
									}
									key={action.name}
								>
									{!!actionIcons[action.name] && (
										<div className={classes.icon}>
											<img src={actionIcons[action.name]} alt='' />
										</div>
									)}
									<div className={classes.text} style={!!actionIcons[action.name] ? { paddingLeft: 0 } : null}>
										{action.name}
									</div>
								</div>
							) : (
								<Link to={action.path} className={classes.action} onClick={() => all(() => this.setState({ actionsAreShown: false }))} key={action.name}>
									{!!actionIcons[action.name] && (
										<div className={classes.icon}>
											<img src={actionIcons[action.name]} alt='' />
										</div>
									)}
									<div className={classes.text} style={!!actionIcons[action.name] ? { paddingLeft: 0 } : null}>
										{action.name}
									</div>
								</Link>
							)
						)}
					</div>
				)}
			</div>
		)
	}
}

Dropdown.propTypes = {
	actions: PropTypes.array.isRequired,
	clickHandler: PropTypes.func,
	style: PropTypes.object,
}

export const ActionsDropdown = onClickOutside(Dropdown)

import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import closeIcon from '../../../assets/icons/close.png'
import { RuleRow } from './RuleRow'
import { TextInput, RadioInputGroup } from '@Root/components'
import { matchTypes } from '@Root/configs'

export class TableRulePopup extends Component {
  state = {
    name: '',
    description: '',
    matchType: 'All of the rules',
    rows: [],
    newRow: {
      field: null,
      equality: null,
      filter: '',
      rule_suggest: '',
    },
    error: null,
  }
  handleSaveButtonClick = () => {
    if (!this.ruleIsValid()) return
    const { name, description, matchType, rows } = this.state
    this.props.clickSaveHandler({
      name,
      description,
      matchType,
      rows,
    })
  }

  ruleIsValid = () => {
    const { name } = this.state
    if (!name) {
      this.showError('name')
      return false
    } else {
      return true
    }
  }

  showError = input => {
    this.setState({ error: input }, () => {
      this.setState({ error: null })
    })
  }

  updateData = () => {
    const { name = '', description = '', matchType = 'All of the rules', rows = [] } = this.props
    this.setState({
      name,
      description,
      matchType,
      rows,
    })
  }

  changeRuleValuesHandler = (index, payload) => {
    const { newRow, rows } = this.state
    const { columns } = this.props
    if (index === null) {
      const newRule = this.auditFilter(newRow, payload)
      const currentColumn = columns.find(column => column?.value === payload.field)
      this.setState({ newRow: { ...newRule, rule_suggest: currentColumn?.rule_suggest || newRow.rule_suggest } })
    } else {
      const rowsFilter = [...rows].filter(el => el.field !== 'trashed')
      const newCurrentRows = [...rowsFilter].map((el, idx) => {
        if (idx === index) {
          const editRule = this.auditFilter(el, payload)
          return editRule
        }
        return el
      })
      this.setState({
        rows: [
          ...newCurrentRows,
          {
            field: 'trashed',
            equality: 'is',
            filter: 'No',
            isDisabled: true,
          },
        ],
      })
    }
  }
  auditFilter(prevRole, payload) {
    const rule = { ...prevRole, ...payload }
    Object.keys(payload).forEach(k => {
      if (k === 'field') rule.filter = ''
    })
    return rule
  }
  componentDidMount() {
    this.updateData()
  }

  render() {
    const { name, description, matchType, rows, newRow, error } = this.state
    const { isNew, columns, clickCloseHandler = () => {}, clickDeleteHandler = () => {}, options } = this.props
    const { handleSaveButtonClick, changeRuleValuesHandler } = this
    return (
      <div className={classes.TableRulePopup}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Custom scope</div>
          <div className={classes.icon} onClick={clickCloseHandler}>
            <img src={closeIcon} alt='' />
          </div>
        </div>
        <div className={classes.line} />
        <div className={classes.inputs}>
          <TextInput
            classNames={['borderless']}
            style={{ width: 220 }}
            value={name}
            changeHandler={name => this.setState({ name })}
            placeholder='Rule name'
            error={error === 'name' ? 'Required' : null}
          />
          <TextInput
            classNames={['borderless']}
            style={{ width: 220 }}
            value={description}
            changeHandler={description => this.setState({ description })}
            placeholder='Description'
          />
        </div>

        <div>
          <div className={classes.matches}>Matches:</div>
          <RadioInputGroup
            values={Object.keys(matchTypes)}
            currentValue={matchType}
            changeHandler={matchType => this.setState({ matchType })}
            isInline
            firstWordIsBold
          />
        </div>

        <div className={classes.line} />

        {rows
          .filter(rule => !rule.isDisabled)
          .map((row, i) => (
            <RuleRow
              row={row}
              index={i}
              columns={columns}
              clickDeleteRowHandler={() => this.setState({ rows: rows.filter((row, k) => i !== k) })}
              key={i}
              changeRuleValuesHandler={changeRuleValuesHandler}
              options={options}
            />
          ))}

        <RuleRow
          isNew
          row={newRow}
          columns={columns}
          clickAddRowHandler={() => this.setState({ rows: [...rows, newRow], newRow: { field: null, equality: null, filter: '', rule_suggest: '' } })}
          changeRuleValuesHandler={changeRuleValuesHandler}
          options={options}
        />

        <div className={classes.footer}>
          {!!rows.length && (
            <span className={classes.save} onClick={handleSaveButtonClick}>
              Save scope
            </span>
          )}
          {!isNew && (
            <span className={classes.delete} onClick={clickDeleteHandler}>
              Delete scope
            </span>
          )}
        </div>
      </div>
    )
  }
}

TableRulePopup.propTypes = {
  isNew: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  description: PropTypes.string,
  matchType: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      equality: PropTypes.string,
      filter: PropTypes.string,
    })
  ),
  clickCloseHandler: PropTypes.func,
  clickSaveHandler: PropTypes.func,
  clickDeleteHandler: PropTypes.func,
}

import { useState, useEffect } from 'react'
import classes from './style.module.scss'
import DocumentTitle from 'react-document-title'
import { useDispatch } from 'react-redux'
import { authActions } from '@Store/auth'
import { useHistory } from 'react-router'
import { API } from '@Root/API'
import { ActiveForm2fa } from '@Root/components'

export const Security = ({ isDisable = false, canDeactivated = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleDisable2FA = e => {
    dispatch(authActions.disableTwoFactoreAuth({ history }))
  }

  return isDisable ? (
    <section className={classes.Security}>
      <DocumentTitle title={`Google 2FA Authentication`} />
      <h2 className={classes.Tittle}>Google 2FA Authentication</h2>
      <p className={classes.DisconectInfo}>2FA verification is activated. You can turn it off by pressing the button</p>
      <button type='button' className={classes.DisconectBtn} onClick={handleDisable2FA} disabled={canDeactivated}>
        Disable 2FA
      </button>
    </section>
  ) : (
    <section className={classes.Security}>
      <ActiveForm2fa />
    </section>
  )
}

export const isLoading = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.reducer.isLoading

export const isSuccess = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.reducer.isSuccess

export const entryQualificationAwarded = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.reducer.entryQualificationAwarded

export const errors = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.reducer.errors

export const permissions = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.reducer.permissions

export const options = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.reducer.options

export const TOGGLE_LOADING = 'academic/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'academic/TOGGLE_SUCCESS'
export const GET_ACADEMIC = 'academic/GET_ACADEMIC'
export const SET_ACADEMIC = 'academic/SET_ACADEMIC'
export const EDIT_ACADEMIC = 'academic/EDIT_ACADEMIC'
export const DELETE_ACADEMIC = 'academic/DELETE_ACADEMIC'
export const GET_STATIC_OPTIONS = 'academic/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'academic/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'academic/SET_OPTIONS'
export const SET_PERMISSIONS = 'academic/SET_PERMISSIONS'

import * as types from './courseReference.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	courseReference: {
		identifier: null,
		identifier_type_id: null,
	},
	options: {},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.SET_COURSE_REFERENCE:
			return {
				...state,
				courseReference: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: payload,
			}
		case types.CLEAR_DATA:
			return {
				...state,
				courseReference: initialState.courseReference,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		default:
			return state
	}
}

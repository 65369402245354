import * as types from './user.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  user: {
    photo: null,
    login: null,
    name: null,
    password: null,
    email: null,
    user_roles: null,
    status: null,
    phone_number: null,
    address: null,
    venue_UKPRN: null,
    note: null,
  },
  options: {
    static: {},
    dynamic: {},
  },
  permissions: {},
  errors: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}

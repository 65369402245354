import React from 'react'
import styles from './style.module.scss'
import PropTypes from 'prop-types'
import { NO_DATA_AVAILABLE } from '@Configs/constants'
export const CheckboxInput = ({ isChecked = false, changeHandler = () => {}, label, style, isDisabled = false, isBlocked = false }) => {

	const isNotDatAvailable = isChecked === NO_DATA_AVAILABLE.label && isDisabled 

	return (
		<div
			className={`${styles.CheckboxInput} ${isDisabled ? styles.disabled : ''}`}
			style={style}
			onClick={() => (!isDisabled ? changeHandler(!isChecked) : null)}
		>
			<div className={styles.input}>{isChecked && !isNotDatAvailable && <div className={styles.checkmark} />}</div>
			{label && (
				<div className={styles.label} style={label.style}>
					{label.text}
					<span className={styles.message}>{isBlocked && <div>(Can`t be deleted)</div>}</span>
				</div>
			)}
		</div>
	)
}

CheckboxInput.propTypes = {
	isChecked: PropTypes.bool,
	changeHandler: PropTypes.func,
	label: PropTypes.shape({
		text: PropTypes.string.isRequired,
		style: PropTypes.object,
	}),
	style: PropTypes.object,
	isDisabled: PropTypes.bool,
}

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import uniqId from 'uniqid'
import { toggleArrayPrimitiveValue, toggleArrayPrimitiveValues, toggleObjectKeys, datePeriods, objectsAreEqual, getFromQueryString, all } from '@Root/helpers'
import { TableRulePopup } from './TableRulePopup'
import { TableRuleTile } from './TableRuleTile'
import { CustomScrollbar, Modal } from '@Root/HOCs'
import { matchTypes, equalities, defaultAllRule } from '@Root/configs'
import { RoundPlusButton, ExportDropdown, DatePeriodDropdown, ColumnFilterDropdown, Pagination, ConfigureExportModal } from '@Root/components'
import { TableData } from './TableData'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { snackbarActions, modalActions, tableRuleActions } from '@Root/store'

class Component extends React.Component {
  constructor(props) {
    super(props)
    const { minVisibleRowsQuantity, defaultOpenRuleId } = props

    this.state = {
      datePeriod: 'All',
      dataIsFetching: false,
      data: [],
      page: 1,
      paginationData: {},
      hiddenColumnsNames: [],
      hiddenExportColumnsNames: [],
      headersFilters: {},
      checkedRowsIds: [],
      rules: this.getGlobalRules(),
      additionalRules: [],
      suspendedRulesId: {},
      openedRuleId: null,
      activeRuleId: defaultOpenRuleId,
      exportedDataUrl: null,
      maxVisibleRowsQuantity: minVisibleRowsQuantity,
      delayedMaxVisibleRowsQuantity: minVisibleRowsQuantity,
      sorting: null,
      columns: props.columns,
      shouldBeUpdated: false,
      isTableInitialized: false,
      isOpenConfigureExport: false,
      additionalExportColumns: [],
      hasCheckedRows: false,
      exclusionEditionsFields: [],
    }

    this.fetchOptionsTimeout = null
    this.exportedDataLink = React.createRef()
  }

  getGlobalRules = () => {
    const { mandatoryRules = [], isHiddenDefaultRule } = this.props
    return [...[isHiddenDefaultRule ? null : defaultAllRule, ...mandatoryRules].filter(rule => rule).map(rule => ({ ...rule, isMandatory: true }))]
  }

  handleSaveRule = rule => {
    const { createRule, editRule, name } = this.props
    const { rules, openedRuleId } = this.state
    if (openedRuleId && openedRuleId !== 'new') {
      editRule({ ...rule, id: openedRuleId, entity: name })
      this.setState(prevState => ({
        rules: prevState.rules.map(prevRule => (prevRule.id === openedRuleId ? { ...rule, id: openedRuleId } : prevRule)),
        openedRuleId: null,
      }))
    } else {
      const newRuleObj = {
        ...rule,
        entity: name,
        id: uniqId(),
        rows: [
          ...rule.rows,
          {
            field: 'trashed',
            equality: 'is',
            filter: 'No',
            isDisabled: true,
          },
        ],
      }
      createRule(newRuleObj)
      this.setState({
        rules: [...rules, newRuleObj],
        openedRuleId: null,
      })
    }
  }

  handleDeleteRule = () => {
    const { openedRuleId, activeRuleId } = this.state
    const { name, deleteRule, defaultOpenRuleId } = this.props
    deleteRule({ id: openedRuleId, entity: name })
    const newActiveRuleId = openedRuleId === activeRuleId ? defaultOpenRuleId : activeRuleId
    this.setState(prevState => ({
      rules: prevState.rules.filter(rule => rule.id !== openedRuleId),
      activeRuleId: newActiveRuleId,
      openedRuleId: null,
    }))
    this.changeURLRule(newActiveRuleId)
    this.updateTableOptions('activeRuleId', null)
  }

  changeURLRule = activeRuleId => {
    window.history.replaceState(null, null, `${window.location.pathname}?rule=${activeRuleId}`)
  }

  ruleParams = params => {
    const { addLimitedRule } = this.props
    const { rules, activeRuleId, additionalRules } = this.state
    const activeRules = this.checkShouldBeAddedLimitedRule(activeRuleId, params || rules)
    const activeRule = activeRuleId
    const additionalRows = additionalRules.map(rule => rule.rows).flat()
    let foundedRule = activeRules.find(rule => rule.id === activeRule)
    if (!foundedRule.isMandatory) {
      if (additionalRules.length && additionalRows.length) {
        foundedRule = { ...foundedRule, rows: [...foundedRule.rows, additionalRows[0]] }
      } else {
        foundedRule = {
          ...foundedRule,
          rows: [
            ...foundedRule.rows,
            ...(addLimitedRule
              ? [
                  {
                    field: 'suspended',
                    equality: 'is',
                    filter: 'null',
                    isDisabled: true,
                  },
                ]
              : []),
          ],
        }
      }
    }
    const { matchType, rows } = foundedRule
    const rule = [...rows, ...additionalRows].reduce((acc, row, i) => acc + `${i === 0 ? '' : ';'}${row.field}:${row.filter}`, '')
    const rulesForFields = [...rows, ...additionalRows].reduce((acc, row, i) => acc + `${i === 0 ? '' : ';'}${row.field}:${equalities[row.equality]}`, '')
    return {
      params: { rule, rulesForFields, ruleJoin: matchTypes[matchType] },
      ruleId: foundedRule.id,
    }
  }

  searchParams = () => {
    const { headersFilters } = this.state
    if (Object.keys(headersFilters).length) {
      const search = Object.keys(headersFilters).reduce((acc, field, i) => acc + `${i === 0 ? '' : ';'}${field}:${headersFilters[field]}`, '')
      return { search, searchJoin: 'and' }
    } else {
      return {}
    }
  }

  setInitialOptions = async data => {
    const { defaultOpenRuleId, location } = this.props
    const currentParams = location.search.split('=')[1]
    const { activeRuleId: usedActiveRuleId } = this.state
    const {
      datePeriod = 'All',
      hiddenColumnsNames = [],
      hiddenExportColumnsNames = [],
      rules = [],
      activeRuleId = defaultOpenRuleId,
      maxVisibleRowsQuantity = this.minVisibleRowsQuantity,
    } = data.properties
    const allRules = [...this.getGlobalRules(), ...rules]
    const activeRule = allRules.find(rule => rule.id === activeRuleId)
    const newRules = this.checkShouldBeAddedLimitedRule(activeRuleId, allRules)
    const activeRuleIdFound = activeRule ? (currentParams ? currentParams : activeRule.id) : defaultOpenRuleId
    const ruleId = getFromQueryString('rule')
    this.setState({
      isTableInitialized: true,
      datePeriod,
      hiddenColumnsNames,
      hiddenExportColumnsNames,
      activeRuleId: activeRuleIdFound,
      maxVisibleRowsQuantity,
      rules: newRules,
    })
    if (usedActiveRuleId === activeRuleId) {
      this.setState({ shouldBeUpdated: true })
    }
    if (!ruleId || (ruleId && ruleId !== activeRuleIdFound)) {
      if (window.location.pathname === location.pathname) this.changeURLRule(activeRuleIdFound)
    }
    await this.fetchData()
  }

  sortParams = () => {
    const { defaultSortingField } = this.props
    const { sorting } = this.state
    return sorting ? { sortBy: `${sorting.field}:${sorting.direction}` } : { sortBy: `${defaultSortingField || 'id'}:desc` }
  }

  fetchOptions = async () => {
    const { fetchColumnOptions } = this.props
    this.setState({ dataIsFetching: true })
    try {
      const { data } = await fetchColumnOptions()
      this.setInitialOptions(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  getFetchDataHandlerByRuleId = async ruleId => {
    const { exceptionsFetchDataHandlers = {}, fetchDataHandler } = this.props
    const handler = exceptionsFetchDataHandlers[ruleId]
    return handler ? handler : fetchDataHandler
  }

  generateQueryParams = () => {
    const { maxVisibleRowsQuantity, datePeriod, page } = this.state
    const newParams = this.ruleParams()
    return {
      limit: maxVisibleRowsQuantity,
      start_date: datePeriods[datePeriod][0],
      end_date: datePeriods[datePeriod][1],
      page,
      ...newParams.params,
      ...this.searchParams(),
      ...this.sortParams(),
    }
  }

  fetchData = async () => {
    const { convertDataHandler, finishFetchDataHandler, errorHandler, showToggledItemCountToRule } = this.props
    const newParams = this.ruleParams()
    const fields = this.getColumnsInString()
    const params = this.generateQueryParams()

    this.setState({ dataIsFetching: true })
    try {
      const { data } = await (await this.getFetchDataHandlerByRuleId(newParams.ruleId))({ ...params, ...(fields ? { fields } : {}) })

      const columns = data.columns
      const additionalExportColumns = data.additional_export_columns
      const convertedData = convertDataHandler(data.data)
      const { from, to, per_page: perPage, last_page: lastPage, total } = data.meta
      const paginationData = {
        from,
        to,
        perPage,
        lastPage,
        total,
      }
      // if (newParams.ruleId && showToggledItemCountToRule) {
      //   this.setState(prevState => ({ suspendedRulesId: { ...prevState.suspendedRulesId, [newParams.ruleId]: data.data.length } }))
      // }
      !this.isUnmounted &&
        this.setState({
          data: convertedData,
          paginationData,
          columns: columns || this.state.columns,
          additionalExportColumns,
        })
      finishFetchDataHandler()
    } catch (error) {
      errorHandler(error)
    }
    this.setState({ dataIsFetching: false })
  }

  getColumnsInString = (customColumns = null) => {
    const { columns } = this.state
    return (customColumns || columns).map(column => (column.field ? column.field : column)).reduce((acc, field, i) => acc + `${i === 0 ? '' : ','}${field}`, '')
  }

  getColumnsForExport = () => {
    const { hiddenExportColumnsNames, columns, additionalExportColumns = [] } = this.state
    const { defaultExportFields = [] } = this.props
    return [
      ...defaultExportFields,
      ...[...columns, ...additionalExportColumns].filter(column => !hiddenExportColumnsNames.includes(column.name)).map(column => column.field),
    ]
  }

  fetchExportedData = async format => {
    const { checkedRowsIds, hasCheckedRows, data } = this.state
    const { fetchExportedDataHander, errorHandler, exportTypeName } = this.props
    const allowed_fields = this.getColumnsInString(this.getColumnsForExport())
    const fields = this.getColumnsInString()
    const generatedParams = this.generateQueryParams()
    const formatOptions = fetchExportedDataHander(format)
    const params = {
      format,
      ...(formatOptions.useCustomCollumns ? { allowed_fields } : { allowed_fields: fields }),
      ...(checkedRowsIds.length
        ? {
            ids: hasCheckedRows ? 'All' : checkedRowsIds.reduce((acc, id, i) => acc + `${i === 0 ? '' : ','}${id}`, ''),
            unChekedIds: hasCheckedRows
              ? data.reduce((acc, item, i) => {
                  if (!checkedRowsIds.includes(item.id)) acc.push(item.id)
                  return acc
                }, [])
              : [],
          }
        : []),
      ...generatedParams,
    }
    try {
      const { data } = await formatOptions.handler(params, exportTypeName)

      this.setState({ exportedDataUrl: data }, () => {
        this.exportedDataLink.current.click()
        this.setState({ exportedDataUrl: null })
      })
    } catch (error) {
      errorHandler(error)
    }
  }

  updateTableOptions = (field, value) => {
    const { datePeriod, hiddenColumnsNames, maxVisibleRowsQuantity, activeRuleId, hiddenExportColumnsNames } = this.state
    const { fetchSaveColumnOptions } = this.props
    const payload = {
      datePeriod,
      hiddenColumnsNames,
      hiddenExportColumnsNames,
      maxVisibleRowsQuantity,
      activeRuleId,
    }
    if (field && value) payload[field] = value

    fetchSaveColumnOptions(payload)
  }

  handleClickClearButton = () => {
    const { defaultOpenRuleId } = this.props
    this.setState({ datePeriod: 'All', headersFilters: {}, activeRuleId: defaultOpenRuleId })
  }

  handleClickHeaderCell = field => {
    const { sorting } = this.state
    if (!sorting) {
      this.setState({ sorting: { field, direction: 'asc' } })
    } else {
      if (sorting.field === field) {
        if (sorting.direction === 'asc') {
          this.setState({ sorting: { field, direction: 'desc' } })
        } else {
          this.setState({ sorting: null })
        }
      } else {
        this.setState({ sorting: { field, direction: 'asc' } })
      }
    }
  }

  handleClickColumnCheckbox = (rowId, field, value) => {
    const { data } = this.state
    const dataCopy = [...data]
    dataCopy.find(row => row.id === rowId)[field] = value
    this.setState({ data: dataCopy })
  }

  createPdf = () => {
    const { data, hiddenColumnsNames, columns } = this.state
    const doc = new jsPDF({ orientation: 'landscape' })
    const visibleColumns = columns.filter(column => !hiddenColumnsNames.includes(column.name))
    const headers = visibleColumns.map(column => column.name)
    const modifiedData = data.map((row, i) => {
      return visibleColumns.reduce((acc, column) => {
        acc.push(row[column.field])
        return acc
      }, [])
    })
    doc.autoTable({
      head: [headers],
      body: modifiedData,
      styles: { fontSize: 7 },
    })
    doc.save('table.pdf')
  }

  async componentDidMount() {
    const { saveCreatePdfMethod, styleConfig } = this.props
    if (styleConfig?.isHideScrollAtBody) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'none'
    }
    saveCreatePdfMethod && saveCreatePdfMethod(this.createPdf)
    await this.fetchOptions()
  }

  async componentDidUpdate(prevProps, prevState) {
    const { isTableInitialized, skipInitialization, columns, hiddenExportColumnsNames } = this.state
    if (
      !objectsAreEqual(this.state.headersFilters, prevState.headersFilters) ||
      this.state.datePeriod !== prevState.datePeriod ||
      this.state.activeRuleId !== prevState.activeRuleId ||
      this.state.maxVisibleRowsQuantity !== prevState.maxVisibleRowsQuantity
    ) {
      this.setState({ page: 1, checkedRowsIds: [] })
    }
    const activeRule = rules => rules.find(rule => rule.id === this.state.activeRuleId)
    if (
      this.state.maxVisibleRowsQuantity !== prevState.maxVisibleRowsQuantity ||
      this.state.page !== prevState.page ||
      !objectsAreEqual(this.state.headersFilters, prevState.headersFilters) ||
      this.state.datePeriod !== prevState.datePeriod ||
      this.state.sorting !== prevState.sorting ||
      this.state.activeRuleId !== prevState.activeRuleId ||
      (this.state.activeRuleId && !objectsAreEqual(activeRule(this.state.rules), activeRule(prevState.rules))) ||
      this.state.additionalRules.length !== prevState.additionalRules.length ||
      this.state.shouldBeUpdated
    ) {
      clearTimeout(this.timeout)
      if (isTableInitialized && !skipInitialization) {
        this.timeout = setTimeout(async () => {
          await this.fetchData()
          if (this.state.maxVisibleRowsQuantity !== prevState.maxVisibleRowsQuantity) {
            this.setState({ delayedMaxVisibleRowsQuantity: this.state.maxVisibleRowsQuantity })
          }
        }, 500)
        this.setState({ shouldBeUpdated: false })
      }
    }
    if (this.state.datePeriod !== prevState.datePeriod) {
      this.updateTableOptions()
    }
    if (this.props.shouldBeUpdated !== prevProps.shouldBeUpdated) {
      this.props.shouldBeUpdated && (await this.fetchData())
    }
    if (this.props.additionalRules.length !== prevProps.additionalRules.length) {
      this.useAdditionalRules(this.props.additionalRules)
    }
    if (this.props.showDefaultRuleId && !prevProps.showDefaultRuleId) {
      await this.changeActiveRule(this.props.defaultRuleId)
    }

    if (columns.length > 0 && prevState.columns.length === 0) {
      const filterHiddenExportColumnsNames = [...columns].reduce((acc, column) => {
        if (column?.is_not_export) acc.push(column.name)
        return acc
      }, [])
      this.setState({
        hiddenExportColumnsNames: [...new Set([...filterHiddenExportColumnsNames, ...hiddenExportColumnsNames])],
        exclusionEditionsFields: filterHiddenExportColumnsNames,
      })
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearTimeout(this.timeout)
    clearTimeout(this.fetchOptionsTimeout)
    document.body.style.overflowY = 'visible'
    this.isUnmounted = true
  }

  useAdditionalRules = rules => {
    this.setState({
      additionalRules: rules,
    })
  }

  changeMaxVisibleRowQuantity = maxVisibleRowsQuantity => {
    this.setState({ maxVisibleRowsQuantity })
    this.updateTableOptions('maxVisibleRowsQuantity', maxVisibleRowsQuantity)
  }

  checkShouldBeAddedLimitedRule = (activeRuleId, rules) => {
    const { addLimitedRule, secretRule } = this.props
    if (addLimitedRule) {
      const mandatoryRules = rules.filter(rule => rule.isMandatory)
      const noMandatoryRules = rules.filter(rule => !rule.isMandatory)
      const currentRule = noMandatoryRules.find(rule => rule.id === activeRuleId)
      const hasSecretRule = currentRule ? currentRule?.rows.some(row => row.field === secretRule.field && row.filter === secretRule.filter) : false
      if (hasSecretRule)
        return [...mandatoryRules, ...noMandatoryRules.map(rule => ({ ...rule, rows: rule.rows.filter(row => row.field !== secretRule?.field) }))]
      return [...mandatoryRules, ...noMandatoryRules.map(rule => ({ ...rule, rows: [...rule.rows, secretRule] }))]
    }
    return rules
  }

  changeActiveRule = activeRuleId => {
    this.setState({ activeRuleId })
    this.updateTableOptions('activeRuleId', activeRuleId)
    this.changeURLRule(activeRuleId)
  }

  handleSaveColumnOptions = () => {
    const { successHandler } = this.props
    try {
      this.updateTableOptions()
      successHandler('The options have been saved successfully!')
    } catch (error) {
      console.log(error)
    }
  }

  showMessageModal = () => {
    const { showModal, hideModal } = this.props
    new Promise((resolve, reject) => {
      showModal('NotificationModal', {
        text: 'At least one field should be active',
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve,
      })
    }).finally(() => hideModal())
  }

  toggleConfigureExportModal = () => {
    this.setState({ isOpenConfigureExport: !this.state.isOpenConfigureExport })
  }

  saveExportColumnNames = data => {
    const { successHandler } = this.props
    const { exclusionEditionsFields = [] } = this.state

    this.setState({ hiddenExportColumnsNames: [...data, ...exclusionEditionsFields] })
    this.updateTableOptions('hiddenExportColumnsNames', [...data, ...exclusionEditionsFields])
    successHandler('The options have been saved successfully!')
  }

  formatConfigureExportColumns = () => {
    const { columns = [], hiddenExportColumnsNames = [], additionalExportColumns = [] } = this.state

    const filterResult = [...columns, ...additionalExportColumns].filter(column => {
      if (!column?.is_not_export) return true
    })

    return filterResult.map(column =>
      hiddenExportColumnsNames.includes(column.name) ? { label: column.name, value: false } : { label: column.name, value: true }
    )
  }

  render() {
    const {
      datePeriod,
      dataIsFetching,
      data,
      page,
      paginationData,
      hiddenColumnsNames,
      headersFilters,
      checkedRowsIds,
      rules,
      openedRuleId,
      activeRuleId,
      exportedDataUrl,
      maxVisibleRowsQuantity,
      delayedMaxVisibleRowsQuantity,
      sorting,
      suspendedRulesId,
      columns,
      isOpenConfigureExport,
      hasCheckedRows,
      additionalRules,
    } = this.state

    const {
      datePeriodDropdownStyle,
      cleanFiltersStyle,
      fetchDataHandler,
      clickLinkHandlers,
      checkboxes,
      minVisibleRowsQuantity,
      permissions,
      clickImportButtonHandler,
      hasExportConfiguration,
      errorHandler,
      shouldBeUpdated,
      hasRules,
      hasFilters,
      hasSorting,
      datePeriodTop = -140,
      styleConfig = {},
      options,
    } = this.props

    const newColumns = [...columns].filter(el => !el?.is_not_in_table)
    const { fetchExportedData, handleSaveRule, handleClickClearButton, handleClickHeaderCell, handleClickColumnCheckbox } = this
    const columnsNames = newColumns.map(column => column.name)
    const { name, description, matchType, rows } =
      openedRuleId && openedRuleId !== 'new'
        ? rules.find(rule => rule.id === openedRuleId)
        : { name: '', description: '', matchType: 'All of the rules', rows: [] }
    const pathExport = this.props.match.path.split('/')[3]

    return (
      <div className={classes.Table} style={{ marginTop: 70 }}>
        <DatePeriodDropdown
          style={{ position: 'absolute', right: 0, top: datePeriodTop, zIndex: 10, ...datePeriodDropdownStyle, ...styleConfig.datePeriodDropdownStyle }}
          value={datePeriod}
          changeHandler={datePeriod => this.setState({ datePeriod })}
        />
        <div className={classes.cleanFilters} style={{ ...cleanFiltersStyle, ...styleConfig.cleanFilters }}>
          <span onClick={() => handleClickClearButton()}>Clear</span>
        </div>
        <div style={styleConfig.tableBar} className={classes.tableBarWrapper}>
          <div className={classes.tableBar} id='tableBar'>
            <div className={classes.tableBarLeft}>
              {hasRules && permissions?.rule?.create && <RoundPlusButton text='Add rule' clickHandler={() => this.setState({ openedRuleId: 'new' })} />}
              {openedRuleId !== null && (
                <Modal>
                  <TableRulePopup
                    isNew={openedRuleId === 'new'}
                    columns={newColumns.map(column => ({
                      value: column.field,
                      label: column.name,
                      rule_suggest: column.rule_suggest,
                      options: column.options,
                    }))}
                    name={name}
                    description={description}
                    matchType={matchType}
                    rows={rows || []}
                    clickCloseHandler={() => this.setState({ openedRuleId: null })}
                    clickSaveHandler={handleSaveRule}
                    clickDeleteHandler={this.handleDeleteRule}
                    options={options}
                  />
                </Modal>
              )}
              {isOpenConfigureExport && (
                <Modal>
                  <ConfigureExportModal
                    onClose={this.toggleConfigureExportModal}
                    onSave={data =>
                      all(
                        () => this.saveExportColumnNames(data),
                        () => this.toggleConfigureExportModal()
                      )
                    }
                    columns={this.formatConfigureExportColumns()}
                  />
                </Modal>
              )}
              <div className={classes.tilesWrapper} style={!hasRules ? { marginLeft: -10 } : styleConfig.ruleLine || null}>
                <CustomScrollbar horizontalOnly>
                  <div className={classes.tiles}>
                    {rules
                      .filter(rule => (!rule.checkInPermissions ? true : permissions?.rule?.rules?.view?.includes(rule.permission_id)))
                      .map(rule => (
                        <TableRuleTile
                          key={rule.id}
                          rule={rule}
                          dateRange={[datePeriods[datePeriod][0], datePeriods[datePeriod][1]]}
                          fetchDataHandler={fetchDataHandler}
                          clickBarHandler={() => this.changeActiveRule(rule.id)}
                          clickEditHandler={() => this.setState({ openedRuleId: rule.id })}
                          isActive={activeRuleId === rule.id}
                          errorHandler={error => errorHandler(error)}
                          shouldBeUpdated={shouldBeUpdated}
                          //suspendedRulesId={suspendedRulesId}
                          getFetchDataHandlerByRuleId={this.getFetchDataHandlerByRuleId}
                          rulePermissions={permissions?.rule}
                          additionalRules={additionalRules}
                        />
                      ))}
                  </div>
                </CustomScrollbar>
              </div>
            </div>
            <div className={classes.tableBarRight}>
              {hasExportConfiguration && permissions?.hasExport && (
                <div className={classes.importButton} onClick={this.toggleConfigureExportModal}>
                  Configure export
                </div>
              )}
              {permissions?.hasCSVImport && (
                <div className={classes.importButton} onClick={clickImportButtonHandler}>
                  CSV Import
                </div>
              )}
              {permissions?.hasExport && (
                <>
                  <ExportDropdown clickHandler={format => fetchExportedData(format)} permissions={permissions} path={pathExport} />
                  <a style={{ display: 'none' }} href={exportedDataUrl} ref={this.exportedDataLink} download>
                    Download
                  </a>
                </>
              )}
              <ColumnFilterDropdown
                columnsNames={columnsNames}
                hiddenColumnsNames={hiddenColumnsNames}
                clickHandler={columnName => this.setState({ hiddenColumnsNames: toggleArrayPrimitiveValue(hiddenColumnsNames, columnName) })}
                maxVisibleColumnsQuantity={10}
                onSaveColumnSettings={this.handleSaveColumnOptions}
                showMessageModal={this.showMessageModal}
              />
            </div>
          </div>
        </div>
        <div className={classes.paginationContainer} style={styleConfig.upperPaginationContainerStyles}>
          {!!Object.keys(paginationData).length && (
            <Pagination
              minVisibleRowsQuantity={minVisibleRowsQuantity}
              maxVisibleRowsQuantity={maxVisibleRowsQuantity}
              changeMaxVisibleRowsQuantityHandler={maxVisibleRowsQuantity => this.changeMaxVisibleRowQuantity(maxVisibleRowsQuantity)}
              page={page}
              changePageHandler={page => this.setState({ page })}
              paginationData={paginationData}
            />
          )}
        </div>
        <TableData
          columns={newColumns}
          options={options}
          hiddenColumnsNames={hiddenColumnsNames}
          dataIsFetching={dataIsFetching}
          data={data}
          checkedRowsIds={checkedRowsIds}
          clickCheckboxHandler={id => this.setState({ checkedRowsIds: toggleArrayPrimitiveValue(checkedRowsIds, id) })}
          clickCheckboxAllHandler={ids => {
            const checkedRowsArr = toggleArrayPrimitiveValues(checkedRowsIds, ids)
            this.setState({
              checkedRowsIds: checkedRowsArr,
              hasCheckedRows: checkedRowsArr.length > 0,
            })
          }}
          headersFilters={headersFilters}
          changeHeaderFilterHandler={({ key, value }) => {
            this.setState({ headersFilters: toggleObjectKeys(headersFilters, { key, value }) })
          }}
          visibleRowsQuantity={delayedMaxVisibleRowsQuantity > minVisibleRowsQuantity ? delayedMaxVisibleRowsQuantity : minVisibleRowsQuantity}
          clickLinkHandlers={clickLinkHandlers}
          checkboxes={checkboxes}
          sorting={sorting}
          clickHeaderCellHandler={field => handleClickHeaderCell(field)}
          clickColumnCheckboxHandler={(rowId, field, value) => handleClickColumnCheckbox(rowId, field, value)}
          hasFilters={hasFilters}
          hasSorting={hasSorting}
          styleConfig={styleConfig}
        />
        <div className={classes.paginationContainer}>
          {!!Object.keys(paginationData).length && (
            <Pagination
              minVisibleRowsQuantity={minVisibleRowsQuantity}
              maxVisibleRowsQuantity={maxVisibleRowsQuantity}
              changeMaxVisibleRowsQuantityHandler={maxVisibleRowsQuantity => this.setState({ maxVisibleRowsQuantity })}
              page={page}
              changePageHandler={page => this.setState({ page })}
              paginationData={paginationData}
            />
          )}
        </div>
      </div>
    )
  }
}

Component.propTypes = {
  style: PropTypes.object,
  datePeriodDropdownStyle: PropTypes.object,
  cleanFiltersStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  mandatoryRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      matchType: PropTypes.string,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          columnName: PropTypes.string,
          equality: PropTypes.string,
          filter: PropTypes.string,
        })
      ),
    })
  ),
  fetchDataHandler: PropTypes.func,
  finishFetchDataHandler: PropTypes.func,
  convertDataHandler: PropTypes.func,
  fetchExportedDataHander: PropTypes.func,
  columns: PropTypes.array,
  minVisibleRowsQuantity: PropTypes.number,
  clickLinkHandlers: PropTypes.object,
  checkboxes: PropTypes.object,
  hasImport: PropTypes.bool,
  clickImportButtonHandler: PropTypes.func,
  errorHandler: PropTypes.func,
  shouldBeUpdated: PropTypes.bool,
  hasRules: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasFilters: PropTypes.bool,
  hasSorting: PropTypes.bool,
  defaultOpenRuleId: PropTypes.string,
  additionalRules: PropTypes.array,
  invisibleRule: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      matchType: PropTypes.string,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          columnName: PropTypes.string,
          equality: PropTypes.string,
          filter: PropTypes.string,
        })
      ),
    })
  ),
}

Component.defaultProps = {
  style: {},
  datePeriodDropdownStyle: {},
  cleanFiltersStyle: {},
  minVisibleRowsQuantity: 10,
  checkboxes: {},
  options: {},
  fetchDataHandler: () => {},
  finishFetchDataHandler: () => {},
  convertDataHandler: initialData => initialData,
  hasImport: false,
  clickImportButtonHandler: () => {},
  errorHandler: () => {},
  successHandler: () => {},
  shouldBeUpdated: false,
  hasRules: false,
  hasExport: false,
  hasFilters: false,
  hasSorting: false,
  invisibleRule: {},
  defaultOpenRuleId: 'all',
  additionalRules: [],
  columns: [],
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
  return {
    createRule: payload => dispatch(tableRuleActions.createRule(payload)),
    editRule: payload => dispatch(tableRuleActions.editRule(payload)),
    deleteRule: payload => dispatch(tableRuleActions.deleteRule(payload)),
    successHandler: payload => dispatch(snackbarActions.setSuccessNotification({ text: payload })),
    showModal: (component, props) => dispatch(modalActions.showModal(component, props)),
    hideModal: () => dispatch(modalActions.hideModal()),
  }
}

export const Table = withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))

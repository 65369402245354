import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import {
  programmeSelectors,
  modalActions,
  fieldTooltipManagerSelectors,
  fieldTooltipManagerActions,
  studentCourseSessionPostgradSelectors,
  studentCourseSessionPostgradActions,
} from '@Root/store'
import { useHistory } from 'react-router'
import { useError, useToggle } from '@Root/hooks'
import { API } from '@Root/API'
import { checkDecimal } from '@Root/helpers'
import { Redirect } from 'react-router-dom'
import { programmeActions } from '@Store/studies/programme/index'

const titles = { onRead: 'Postgrad research Details', onEdit: 'Edit Postgrad research Details', onCreate: 'Create Postgrad Details' }
export const PostgradForm = ({ isNew, studyProgrammeId, canManipulate, contactId, isStudent, canCreate, parentURL }) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const [data, setData] = useState({})
  const { errors, setError } = useError()
  const isLoading = useSelector(studentCourseSessionPostgradSelectors.isLoading)
  const isSuccess = useSelector(studentCourseSessionPostgradSelectors.isSuccess)
  const postgrad = useSelector(studentCourseSessionPostgradSelectors.postgrad)
  const options = useSelector(studentCourseSessionPostgradSelectors.options)
  const dataForCreateProgramme = useSelector(programmeSelectors.options)
  const permissions = useSelector(studentCourseSessionPostgradSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['postgrad-student-details']
  const dispatch = useDispatch()
  const history = useHistory()

  const formIsValid = () => {
    const { hours_1, hours_2, hours_3 } = data
    if (hours_1 && !checkDecimal(hours_1)) {
      setError('hours_1', 'Invalid value. Should be a decimal')
      return false
    }
    if (hours_2 && !checkDecimal(hours_2)) {
      setError('hours_2', 'Invalid value. Should be a decimal')
      return false
    }
    if (hours_3 && !checkDecimal(hours_3)) {
      setError('hours_3', 'Invalid value. Should be a decimal')
      return false
    }
    return true
  }

  const fetchSupervisorHandler = params => API.options.getSupervisors(params)

  const onChangeValue = field => value => {
    setData({ ...data, [field]: value })
  }

  const handleClickCancelButton = () => {
    if (isNew) {
      history.goBack()
    } else {
      setData(postgrad)
      toggleEditable()
    }
  }

  const handleClickDeleteButton = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This postgrad will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(studentCourseSessionPostgradActions.delete({ data: data.post_grad_id, history, isStudent, contactId, studyProgrammeId }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)

    if (isNew) {
      dispatch(studentCourseSessionPostgradActions.create({ studyProgrammeId, contactId, data: modifiedData, history }))
    } else {
      dispatch(studentCourseSessionPostgradActions.edit({ data: modifiedData }))
    }
  }

  const fetchSupervisorLabel = async value => {
    const { data } = await fetchSupervisorHandler({ search: `id:${value}`, limit: '1' })
    return data.data[0].name
  }

  const checkSupervisorsValue = () => {
    return {
      isValue: Boolean(data['supervisor_1'] || data['supervisor_2'] || data['supervisor_3']),
      ids: [data['supervisor_1'], data['supervisor_2'], data['supervisor_3']].filter(value => value),
    }
  }
  const fetchSupervisorOptions = async value => {
    const { data } = await fetchSupervisorHandler({
      search: `name:${value};trashed:No;${checkSupervisorsValue().isValue ? 'not_ids:' : ''}${checkSupervisorsValue().ids.join(',')}`,
      searchJoin: 'and',
      limit: '100',
    })
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  useEffect(() => {
    if (isSuccess) toggleEditable(false)
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (postgrad) setData(postgrad)
  }, [postgrad])

  useEffect(() => {
    dispatch(studentCourseSessionPostgradActions.getStaticOptions())
    dispatch(programmeActions.getDynamicOptions())
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'postgrad-student-details' }))

    return () => {
      dispatch(studentCourseSessionPostgradActions.clearPermissions())
    }
  }, [dispatch])

  if (!canCreate && isNew) return <Redirect to={parentURL} />

  const { form, fields } = permissions
  const canEdit = form?.edit && canManipulate && !isEditable
  return (
    <FormWrapper
      buttons={isNew ? ['cancel', 'save'] : isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this postgrad' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
      canEdit={canEdit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      isCreate={isNew}
      idForm={data?.post_grad_id ? `student_financial_support_${data?.post_grad_id}` : null}
      isSuccess={isSuccess}
    >
      <div className={styles.grid}>
        <div className={styles.column}>
          <Field
            label={'Proposal Date'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.proposal_date}
            onChange={onChangeValue('proposal_date')}
            placeholder='Proposal Date'
            isDisabled={!isEditable || !fields?.proposal_date?.edit}
            isView={fields?.proposal_date?.view}
            description={descriptions.proposal_date}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />
          <Field
            label={'TEI Response'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            options={options.tcb_responses}
            value={data.TCB_response}
            onChange={onChangeValue('TCB_response')}
            placeholder='TEI Response'
            isDisabled={!isEditable || !fields?.TCB_response?.edit}
            isView={fields?.TCB_response?.view}
            description={descriptions.TCB_response}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
          />
          <Field
            label={'Accred Inst Date'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.UOB_date}
            onChange={onChangeValue('UOB_date')}
            placeholder='Accred Inst Date'
            isDisabled={!isEditable || !fields?.UOB_date?.edit}
            isView={fields?.UOB_date?.view}
            description={descriptions.UOB_date}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />{' '}
          <Field
            label={'UOB Response Date'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.UOB_response_date}
            onChange={onChangeValue('UOB_response_date')}
            placeholder='UOB Response Date'
            isDisabled={!isEditable || !fields?.UOB_response_date?.edit}
            isView={fields?.UOB_response_date?.view}
            description={descriptions.UOB_response_date}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />{' '}
          <Field
            label={'Dissertation Topic'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.dissertation_topic}
            onChange={onChangeValue('dissertation_topic')}
            placeholder='Dissertation Topic'
            isDisabled={!isEditable || !fields?.dissertation_topic?.edit}
            isView={fields?.dissertation_topic?.view}
            description={descriptions.dissertation_topic}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
          />
          <Field
            label={'Upgrade Date'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.upgrade_date}
            onChange={onChangeValue('upgrade_date')}
            placeholder='Upgrade Date'
            isDisabled={!isEditable || !fields?.upgrade_date?.edit}
            isView={fields?.upgrade_date?.view}
            description={descriptions.upgrade_date}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />{' '}
          <Field
            label={'Submission Date 1'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.submission_date_1}
            onChange={onChangeValue('submission_date_1')}
            placeholder='Submission Date 1'
            isDisabled={!isEditable || !fields?.submission_date_1?.edit}
            isView={fields?.submission_date_1?.view}
            description={descriptions.submission_date_1}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />{' '}
          <Field
            label={'Supervisor 1'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            listStyle={{ width: 260 }}
            value={data?.supervisor_1}
            onChange={onChangeValue('supervisor_1')}
            placeholder='Supervisor 1'
            isDisabled={!isEditable || !fields?.supervisor_1?.edit}
            isView={fields?.supervisor_1?.view}
            description={descriptions.supervisor_1}
            onOpenDescriptionModal={onOpenDescriptionModal}
            fetchLabelHandler={async value => fetchSupervisorLabel(value)}
            fetchOptionsHandler={async value => await fetchSupervisorOptions(value)}
            field={'dataListAsync'}
          />
          <Field
            label={'Supervisor 2'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            listStyle={{ width: 260 }}
            value={data?.supervisor_2}
            onChange={onChangeValue('supervisor_2')}
            placeholder='Supervisor 2'
            isDisabled={!isEditable || !fields?.supervisor_2?.edit}
            isView={fields?.supervisor_2?.view}
            description={descriptions.supervisor_2}
            onOpenDescriptionModal={onOpenDescriptionModal}
            fetchLabelHandler={async value => fetchSupervisorLabel(value)}
            fetchOptionsHandler={async value => await fetchSupervisorOptions(value)}
            field={'dataListAsync'}
          />{' '}
          <Field
            label={'Supervisor 3'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            listStyle={{ width: 260 }}
            value={data?.supervisor_3}
            onChange={onChangeValue('supervisor_3')}
            placeholder='Supervisor 3'
            isDisabled={!isEditable || !fields?.supervisor_3?.edit}
            isView={fields?.supervisor_3?.view}
            description={descriptions.supervisor_3}
            onOpenDescriptionModal={onOpenDescriptionModal}
            fetchLabelHandler={async value => fetchSupervisorLabel(value)}
            fetchOptionsHandler={async value => await fetchSupervisorOptions(value)}
            field={'dataListAsync'}
          />
          <Field
            label={'Examiner'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.examiner}
            onChange={onChangeValue('examiner')}
            placeholder='Examiner'
            isDisabled={!isEditable || !fields?.examiner?.edit}
            isView={fields?.examiner?.view}
            description={descriptions.examiner}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
          />
        </div>
        <div className={styles.column}>
          <Field
            label={'Status'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            value={data?.status}
            onChange={onChangeValue('status')}
            placeholder='Status'
            options={options.statuses}
            isDisabled={!isEditable || !fields?.status?.edit}
            isView={fields?.status?.view}
            description={descriptions.status}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
          />
          <Field
            label={'TEI Response Date'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.TCB_response_date}
            onChange={onChangeValue('TCB_response_date')}
            placeholder='TEI Response Date'
            isDisabled={!isEditable || !fields?.TCB_response_date?.edit}
            isView={fields?.TCB_response_date?.view}
            description={descriptions.TCB_response_date}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />
          {/*Note: In order to create space between rows*/}
          <div />
          <Field
            label={'Accred Inst Response'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            value={data?.UOB_response}
            onChange={onChangeValue('UOB_response')}
            placeholder='Accred Inst Response'
            options={options.uob_responses}
            isDisabled={!isEditable || !fields?.UOB_response?.edit}
            isView={fields?.UOB_response?.view}
            description={descriptions.UOB_response}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
          />{' '}
          <Field
            label={'Dissertation Title'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.dissertation_title}
            onChange={onChangeValue('dissertation_title')}
            placeholder='Dissertation Title'
            isDisabled={!isEditable || !fields?.dissertation_title?.edit}
            isView={fields?.dissertation_title?.view}
            description={descriptions.dissertation_topic}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
          />
          <Field
            label={'Programme Manager'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            options={{ ...dataForCreateProgramme.static, ...dataForCreateProgramme.dynamic }?.managed_by}
            value={data?.managed_by_id}
            onChange={onChangeValue('managed_by_id')}
            placeholder='Programme Manager'
            isDisabled={!isEditable || !fields?.managed_by_id?.edit}
            isView={fields?.managed_by_id?.view}
            description={descriptions.managed_by_id}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
            error={errors.managed_by_id}
          />
          <Field
            label={'Submission Date 2'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.submission_date_2}
            onChange={onChangeValue('submission_date_2')}
            placeholder='Submission Date 2'
            isDisabled={!isEditable || !fields?.submission_date_2?.edit}
            isView={fields?.submission_date_2?.view}
            description={descriptions.submission_date_2}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'datePicker'}
          />
          <Field
            label={'Hours 1'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.hours_1}
            onChange={onChangeValue('hours_1')}
            placeholder='Hours 1'
            isDisabled={!isEditable || !fields?.hours_1?.edit}
            isView={fields?.hours_1?.view}
            description={descriptions.hours_1}
            onOpenDescriptionModal={onOpenDescriptionModal}
            error={errors.hours_1}
          />
          <Field
            label={'Hours 2'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.hours_2}
            onChange={onChangeValue('hours_2')}
            placeholder='Hours 2'
            isDisabled={!isEditable || !fields?.hours_2?.edit}
            isView={fields?.hours_2?.view}
            description={descriptions.hours_2}
            onOpenDescriptionModal={onOpenDescriptionModal}
            error={errors.hours_2}
          />{' '}
          <Field
            label={'Hours 3'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.hours_3}
            onChange={onChangeValue('hours_3')}
            placeholder='Hours 3'
            isDisabled={!isEditable || !fields?.hours_3?.edit}
            isView={fields?.hours_3?.view}
            description={descriptions.hours_3}
            onOpenDescriptionModal={onOpenDescriptionModal}
            error={errors.hours_3}
          />
          <Field
            label={'Upgrader'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={data?.upgrader}
            onChange={onChangeValue('upgrader')}
            placeholder='Upgrader'
            isDisabled={!isEditable || !fields?.upgrader?.edit}
            isView={fields?.upgrader?.view}
            description={descriptions.upgrader}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

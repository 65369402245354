import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './courseReference.types'
import { actions } from './courseReference.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, courseReferenceId } = data
  try {
    const response = yield call(API.studies.programme.courseReference.get, studyProgrammeId, courseReferenceId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/programmes/${studyProgrammeId}/course_reference`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, studyProgrammeId } = payload
  try {
    const response = yield call(API.studies.programme.courseReference.create, studyProgrammeId, data)
    history.push(`/home/studies/programmes/${studyProgrammeId}/course_reference/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Course Reference has been created successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    console.log(errors)
    if (errors['studyProgrammeId']) {
      history.push('/home/studies/programmes')
    } else {
      yield put(actions.set(data))
      yield put(actions.setErrors(errors))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { courseReferenceId, studyProgrammeId, data } = payload
  try {
    const response = yield call(API.studies.programme.courseReference.edit, studyProgrammeId, courseReferenceId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The Course Reference has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, courseReferenceId } = data
  try {
    yield call(API.studies.programme.courseReference.remove, studyProgrammeId, courseReferenceId)
    history.push(`/home/studies/programmes/${studyProgrammeId}/course_reference`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Course Reference has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.studies.programme.courseReference.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      course_reference_identifier_type: generateObject(data),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('courseReference', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_COURSE_REFERENCE, get),
    takeEvery(types.CREATE_COURSE_REFERENCE, create),
    takeEvery(types.EDIT_COURSE_REFERENCE, edit),
    takeEvery(types.DELETE_COURSE_REFERENCE, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

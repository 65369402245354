import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { EditIcon } from "@Root/assets/icons";

export const EditButton = ({ clickHandler }) => {
  return (
    <div className={classes.EditButton} onClick={clickHandler}>
      <img src={EditIcon} alt="" />
      <div className={classes.text}>Edit</div>
    </div>
  );
};

EditButton.propTypes = {
  clickHandler: PropTypes.func
};

import * as types from './attachment.types'
import { removeFromLocalStorage } from '@Helpers/localStorage'

const initialState = {
  isLoading: false,
  isSuccess: false,
  attachments: [],
  totalAttachments: 0,
  history: [],
  totalHistory: 0,
  isOpenHistory: false,
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: [...state.attachments, ...action.payload.data],
        totalAttachments: action.payload.meta.total,
      }
    case types.RESET_ATTACHMENTS:
      return {
        ...state,
        attachments: [],
        totalAttachments: 0,
        permissions: {},
      }
    case types.SET_CREATED_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.payload],
      }
    case types.SET_EDITED_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.map(attachment => (attachment.id === action.payload.id ? action.payload : attachment)),
      }
    case types.SET_DELETED_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(attachment => attachment.id !== action.payload.id),
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.SET_HISTORY:
      return {
        ...state,
        history: [...state.history, ...action.payload.data],
        totalHistory: action.payload.meta.total,
      }

    case types.CLEAR_HISTORY:
      removeFromLocalStorage('history')
      return {
        ...state,
        history: [],
        isOpenHistory: false,
      }
    default:
      return state
  }
}

import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const PageTitle = ({ title, style }) => {
  return title ? (
    <div className={classes.PageTitle} style={style}>
      {title}
    </div>
  ) : null;
};

PageTitle.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object
};

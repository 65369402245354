import * as types from './curriculumAccreditation.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	set: payload => ({
		type: types.SET_CURRICULUM_ACCREDITATION,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_CURRICULUM_ACCREDITATION,
		payload,
	}),
	get: payload => ({
		type: types.GET_CURRICULUM_ACCREDITATION,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_CURRICULUM_ACCREDITATION,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_CURRICULUM_ACCREDITATION,
		payload,
	}),
	getOptions: () => ({
		type: types.GET_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	clearData: () => ({
		type: types.CLEAR_DATA,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

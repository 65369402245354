import * as types from './note.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_NOTE,
		payload,
	}),
	set: payload => ({
		type: types.SET_NOTES,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_NOTE,
		payload,
	}),
	setEdited: payload => ({
		type: types.SET_EDITED_NOTE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_NOTE,
		payload,
	}),
	setCreated: payload => ({
		type: types.SET_CREATED_NOTE,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_NOTE,
		payload,
	}),
	setDeleted: payload => ({
		type: types.SET_DELETED_NOTE,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import * as types from './postgrad.types'

const initialPermissions = {
	fields: {
		TCB_response: { view: true, edit: true },
		TCB_response_date: { view: true, edit: true },
		UOB_date: { view: true, edit: true },
		UOB_response_date: { view: true, edit: true },
		dissertation_title: { view: true, edit: true },
		examiner: { view: true, edit: true },
		hours_1: { view: true, edit: true },
		hours_2: { view: true, edit: true },
		hours_3: { view: true, edit: true },
		managed_by_id: { view: true, edit: true },
		proposal_date: { view: true, edit: true },
		status: { view: true, edit: true },
		submission_date_2: { view: true, edit: true },
		supervisor_1: { view: true, edit: true },
		supervisor_2: { view: true, edit: true },
		supervisor_3: { view: true, edit: true },
		upgrade_date: { view: true, edit: true },
		upgrader: { view: true, edit: true },
		submission_date_1: { view: true, edit: true },
		dissertation_topic: { view: true, edit: true },
		UOB_response: { view: true, edit: true },
	},
}
const initialState = {
	postgrad: {},
	isSuccess: false,
	isLoading: false,
	canBeAttached: false,
	options: {
		static: {},
		dynamic: {},
	},
	permissions: initialPermissions,
}
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_CAN_BE_ATTACHED:
			return {
				...state,
				canBeAttached: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_POSTGRAD:
			return {
				...state,
				postgrad: action.payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[action.payload.name]: action.payload.data,
				},
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		case types.CLEAR_PERMISSIONS:
			return {
				...state,
				permissions: initialPermissions,
			}
		default:
			return state
	}
}

import * as types from './staff.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	staff: {},
	options: {
		static: {},
		dynamic: {},
	},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_STAFF:
			return {
				...state,
				staff: action.payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[action.payload.name]: action.payload.data,
				},
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

import React, { useMemo } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import closeIcon from '../../../assets/icons/close.png'
import { useWindowSize } from '@Root/hooks'

export const ImportModal = ({
	headerText,
	importDescription,
	importData,
	invalidImportDescription,
	invalidImportData,
	errors,
	leftButtonText,
	rightButtonText,
	clickRejectButtonHandler,
	clickResolveButtonHandler,
}) => {
	const { height } = useWindowSize()
	const wrapperHeight = useMemo(() => height - 170, [height])

	return (
		<div className={`${classes.wrapper}`}>
			<div className={`${classes.fields_wrapper} custom-scroll`} style={{ maxHeight: `${wrapperHeight}px` }}>
				<div className={classes.header}>
					<div className={classes.headerText}>{headerText}</div>
					<div className={classes.icon} onClick={clickRejectButtonHandler}>
						<img src={closeIcon} alt='' />
					</div>
				</div>
				<div className={classes.line} />
				<div className={classes.contentText}>{importDescription}</div>
				{!!importData.data.length && (
					<div className={`${classes.tableWrapper}`}>
						<table className={classes.table}>
							<tbody>
								{importData.data.map((row, i) => {
									return (
										<tr key={i}>
											{importData.fields.map((field, key) => (
												<td key={key}>{row[field]}</td>
											))}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				)}
				{typeof invalidImportDescription === 'string' && (
					<>
						<div className={classes.line} />
						<div className={classes.contentText}>{invalidImportDescription}</div>
						{!!invalidImportData.data.length && (
							<div className={`${classes.tableWrapper}`}>
								<table className={classes.table}>
									<tbody>
										{invalidImportData.data.map((row, i) => {
											return (
												<tr key={i}>
													{invalidImportData.fields.map((field, key) => (
														<td key={key}>{row[field]}</td>
													))}
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						)}
					</>
				)}
				{!!errors.length && (
					<>
						<div className={classes.line} />
						<div className={`${classes.errorsWrapper}`}>
							{errors.map((error, i) => (
								<React.Fragment key={i}>
									<div className={`${classes.errorsRow} ${classes.errorsRowTitle}`}>{error.title}:</div>
									{error.rows.map((row, k) => (
										<div className={classes.errorsRow} key={k}>
											{row}
										</div>
									))}
								</React.Fragment>
							))}
						</div>
					</>
				)}
			</div>
			<div className={classes.line} />
			<div className={classes.buttons}>
				{!!leftButtonText && (
					<button className={classes.buttonLeft} onClick={() => clickResolveButtonHandler('left')} disabled={false}>
						{leftButtonText}
					</button>
				)}
				<button className={classes.buttonRight} onClick={() => clickResolveButtonHandler('right')} disabled={false}>
					{rightButtonText}
				</button>
			</div>
		</div>
	)
}

ImportModal.propTypes = {
	headerText: PropTypes.string,
	importDescription: PropTypes.string,
	importData: PropTypes.shape({
		data: PropTypes.array,
		fields: PropTypes.array,
	}),
	invalidImportDescription: PropTypes.string,
	invalidImportData: PropTypes.shape({
		data: PropTypes.array,
		fields: PropTypes.array,
	}),
	errors: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			rows: PropTypes.arrayOf(PropTypes.string),
		})
	),
	leftButtonText: PropTypes.string,
	rightButtonText: PropTypes.string,
	clickRejectButtonHandler: PropTypes.func,
	clickResolveButtonHandler: PropTypes.func,
}

ImportModal.defaultProps = {
	headerText: null,
	importDescription: null,
	importData: { data: [], fields: [] },
	invalidImportDescription: null,
	invalidImportData: { data: [], fields: [] },
	errors: [],
	leftButtonText: null,
	rightButtonText: null,
	clickRejectButtonHandler: () => {},
	clickResolveButtonHandler: () => {},
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './supervisorAllocation.types'
import { actions } from './supervisorAllocation.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, studentCourseSessionId, contactId, supervisorAllocationId } = data
  try {
    const response = yield call(API.studies.studentCourseSession.supervisorAllocation.get, studentCourseSessionId, supervisorAllocationId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/supervisor_allocation`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrors({}))
  const { data, history, studyProgrammeId, contactId, studentCourseSessionId } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.supervisorAllocation.create, studentCourseSessionId, data)
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/supervisor_allocation/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Supervisor Allocation has been created successfully!' }))
  } catch (error) {
    const { messages } = error.response.data
    if (messages['studyProgrammeId']) {
      history.push('/home/studies/programmes/study')
    } else {
      yield put(actions.set(data))
      yield put(actions.setErrors(messages))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrors({}))
  const { supervisorAllocationId, studentCourseSessionId, data } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.supervisorAllocation.edit, studentCourseSessionId, supervisorAllocationId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The Supervisor Allocation has been saved successfully!' }))
  } catch (error) {
    const { messages } = error.response.data
    yield put(actions.set(data))
    yield put(actions.setErrors(messages))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, studyProgrammeId, studentCourseSessionId, supervisorAllocationId } = data
  try {
    yield call(API.studies.studentCourseSession.supervisorAllocation.remove, studentCourseSessionId, supervisorAllocationId)
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/supervisor_allocation`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Supervisor Allocation has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.studies.studentCourseSession.supervisorAllocation.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      hesa_identifiers: generateObject(data.hesa_identifiers),
      ref2021_unit_assessments: generateObject(data.ref2021_unit_assessments),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('supervisor_allocation', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_SUPERVISOR_ALLOCATION, get),
    takeEvery(types.CREATE_SUPERVISOR_ALLOCATION, create),
    takeEvery(types.EDIT_SUPERVISOR_ALLOCATION, edit),
    takeEvery(types.DELETE_SUPERVISOR_ALLOCATION, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

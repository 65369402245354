import React from 'react'
import { LeaverForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { leaverActions, leaverSelectors } from '@Store/studies'
import { Redirect } from 'react-router-dom'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateLeaver = ({ parentURL, onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId } = useParams()
  const leaver = useSelector(leaverSelectors.leaver)
  const options = useSelector(leaverSelectors.options)
  const isLoading = useSelector(leaverSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_leaver

  const onCreate = data => {
    dispatch(leaverActions.create({ data, history, engagementId, contactId }))
  }

  const onCancel = () => {
    history.goBack()
  }

  if (!dataCreationPermissions.engagements) return <Redirect to={parentURL} />

  return (
    <LeaverForm
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={leaver}
      options={options}
      isLoading={isLoading}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

import React, { useState, useEffect, useMemo } from 'react'
import styles from './styles.module.scss'
import { CancelButton, Field, SaveButton } from '@Root/components'
import { generateId } from '@Root/helpers'
import { CreateOptions } from './CreateOptions'
import { useError } from '@Root/hooks'
import { useDispatch } from 'react-redux'
import { modalActions } from '@Root/store'
import { useEditedMode } from '@Root/hooks'

const fieldsTypes = [
  { id: generateId(), label: 'Field', value: 'textArea' },
  { id: generateId(), label: 'Drop-down', value: 'select' },
  { id: generateId(), label: 'Drop-down multiselect', value: 'multiSelect' },
  { id: generateId(), label: 'Checkbox', value: 'multiCheckbox' },
  { id: generateId(), label: 'Date picker', value: 'datePicker' },
]

const contactTypes = [
  { id: generateId(), label: 'Person', value: 'person' },
  { id: generateId(), label: 'Organisation', value: 'organisation' },
  { id: generateId(), label: 'Both', value: 'both' },
]
export const FieldInformation = ({ initialValue, onOpenDescriptionModal, descriptions, onCancel, onSave, isEditable = true, isNew, initialErrors }) => {
  const dispatch = useDispatch()
  const { errors, setError } = useError()
  const [information, setInformation] = useState({
    name: '',
    is_required: false,
    is_in_table: false,
    is_in_help_text: false,
    is_in_csv_import: false,
    description: '',
    type: 'textArea',
    contact_type: null,
    options: [{ id: generateId(), value: '', label: '' }],
  })
  const idForm = information?.id ? `additional_fields_${information?.id}` : null

  const { startEditMode, exitEditMode } = useEditedMode({
    isEditable,
    clickToggleEditModeButtonHandler: () => {},
    clickCancelButtonHandler: onCancel,
    idForm,
    pathRedirect: '/home/admin/additional-fields',
  })

  const hasOptions = useMemo(() => {
    const { type } = information
    return type === 'select' || type === 'multiSelect' || type === 'multiCheckbox'
  }, [information])

  const canBeAddedToCSVImport = useMemo(() => {
    const { contact_type } = information
    return contact_type === 'person' || contact_type === 'both'
  }, [information])

  const validateOptions = () => {
    const { options } = information
    if (hasOptions) {
      return options.some(({ value, id }) => {
        if (!value) {
          setError(id, 'Required')
          return true
        }
        return false
      })
    }
    return false
  }

  const validateForm = () => {
    const { name, contact_type, type } = information
    if (!name) {
      setError('name', 'Required')
      return false
    }
    if (!contact_type) {
      setError('contact_type', 'Required')
      return false
    }
    if (!type) {
      setError('type', 'Required')
      return false
    }
    return !validateOptions()
  }

  const onChangeValue = field => value => {
    if (field === 'type') {
      if (information.type !== value && !isNew) {
        new Promise((resolve, reject) => {
          dispatch(
            modalActions.showModal('NotificationModal', {
              title: 'Notification',
              text: `Data will be lost`,
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve,
            })
          )
        }).finally(() => {
          dispatch(modalActions.hideModal())
          setInformation(prevState => ({ ...prevState, [field]: value }))
        })
        return
      }
    }
    if (field === 'contact_type') {
      if (value === 'organisation' && information.is_in_csv_import) {
        setInformation(prevState => ({ ...prevState, is_in_csv_import: false }))
      }
    }
    setInformation(prevState => ({ ...prevState, [field]: value }))
  }

  const onAddOption = item => {
    setInformation(prevState => ({ ...prevState, options: [...prevState.options, item] }))
  }

  const onChangeOptionValue = optionId => value => {
    if (value.length < 201) {
      setInformation(prevState => ({
        ...prevState,
        options: prevState.options.map(option => (option.id === optionId ? { ...option, value, label: value } : option)),
      }))
      return
    }
    setError(optionId, 'Max length 200 characters')
  }

  const onRemoveAdditionalOption = optionId => {
    setInformation(prevState => ({ ...prevState, options: prevState.options.filter(option => option.id !== optionId) }))
  }

  const onCancelEdit = () => {
    exitEditMode('cancel')
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(information)
  }

  useEffect(() => {
    if (initialValue) setInformation(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (idForm) startEditMode()
    return () => {
      if (idForm) exitEditMode()
    }
  }, [idForm])

  useEffect(() => {
    if (initialErrors.name) setError('name', initialErrors.name)
  }, [initialErrors])

  return (
    <div className={styles.form}>
      <div className={styles.fields}>
        <div className={styles.field}>
          <Field
            label={'Name'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 520 }}
            value={information?.name}
            onChange={onChangeValue('name')}
            placeholder='Name'
            isDisabled={!isEditable}
            isRequired={isEditable}
            maxLength={100}
            description={descriptions.name}
            error={errors.name}
            onOpenDescriptionModal={onOpenDescriptionModal}
          />{' '}
        </div>
        <div className={styles.field}>
          <Field
            label={'Contact type'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 520 }}
            optionsStyle={{ width: 520 }}
            value={information?.contact_type}
            onChange={onChangeValue('contact_type')}
            placeholder='Contact type'
            isDisabled={!isEditable}
            isRequired={isEditable}
            description={descriptions.contact_type}
            error={errors.contact_type}
            field={'select'}
            options={contactTypes}
            onOpenDescriptionModal={onOpenDescriptionModal}
          />
        </div>
        <div className={styles.field}>
          <Field
            label={'Description'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 520 }}
            value={information?.description}
            onChange={onChangeValue('description')}
            placeholder='Description'
            isDisabled={!isEditable}
            maxLength={1000}
            description={descriptions.description}
            error={errors.description}
            field={'textArea'}
            autoHeight
            onOpenDescriptionModal={onOpenDescriptionModal}
          />
        </div>{' '}
        <div className={styles.field}>
          <Field
            label={'Add to Help Text?'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 520 }}
            value={information?.is_in_help_text}
            onChange={onChangeValue('is_in_help_text')}
            isDisabled={!isEditable}
            maxLength={2000}
            description={descriptions.is_in_help_text}
            error={errors.is_in_help_text}
            field={'checkbox'}
            onOpenDescriptionModal={onOpenDescriptionModal}
          />
        </div>{' '}
        <div className={styles.field}>
          <Field
            label={'Type'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 520 }}
            listStyle={{ width: 520 }}
            value={information?.type}
            onChange={onChangeValue('type')}
            placeholder='Type'
            isDisabled={!isEditable}
            isRequired={isEditable}
            description={descriptions.type}
            error={errors.type}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
            options={fieldsTypes}
          />
        </div>
        {hasOptions && (
          <CreateOptions
            fields={information.options}
            onAddOption={onAddOption}
            onChangeOptionValue={onChangeOptionValue}
            onRemoveAdditionalOption={onRemoveAdditionalOption}
            errors={errors}
          />
        )}
        <div className={styles.field}>
          <Field
            label={'Required'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ height: 38 }}
            value={information?.is_required}
            onChange={onChangeValue('is_required')}
            placeholder='Required'
            isDisabled={!isEditable}
            description={descriptions.is_required}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'checkbox'}
          />
        </div>{' '}
        <div className={styles.field}>
          <Field
            label={'Add to Contact grid'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ height: 38 }}
            value={information?.is_in_table}
            onChange={onChangeValue('is_in_table')}
            placeholder='Add to Contact grid'
            isDisabled={!isEditable}
            description={descriptions.is_in_table}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'checkbox'}
          />
        </div>{' '}
        {canBeAddedToCSVImport && (
          <div className={styles.field}>
            <Field
              label={'Add to CSV import and export'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ height: 38 }}
              value={information?.is_in_csv_import}
              onChange={onChangeValue('is_in_csv_import')}
              placeholder='Add to CSV import and export'
              isDisabled={!isEditable}
              description={descriptions.is_in_csv_import}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'checkbox'}
            />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <CancelButton onClick={onCancelEdit}>Cancel</CancelButton>
        <SaveButton onClick={onSubmitForm}>Save Changes</SaveButton>
      </div>
    </div>
  )
}

import { axios } from '@Root/API'
import { validateContactAddress, validationStudentData } from '@Root/helpers'

export const create = (contactType, data) => {
	const { relationship_ids, dbsses, ...rest } = data

	const newData = validationStudentData(rest)(rest.addresses, 'addresses')
	const { addresses, ...validatedData } = { ...newData, addresses: validateContactAddress(newData.addresses) }
	const formData = new FormData()
	Object.keys(validatedData).forEach(key => formData.append(key, validatedData[key]))
	addresses.forEach((address, index) => {
		for (let key in address) {
			formData.append(`addresses[${index}][${key}]`, address[key])
		}
	})

	if (contactType !== 'organisation') {
		relationship_ids.forEach(relationship_id => {
			formData.append(`relationship_ids[]`, relationship_id)
		})
		dbsses.forEach((dbs, index) => {
			for (let key in dbs) {
				formData.append(`dbsses[${index}][${key}]`, dbs[key])
			}
		})
	}
	const url = {
		person: 'persons',
		organisation: 'organisations',
	}
	return axios.post(url[contactType], formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

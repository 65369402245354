import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { RoundPlusButton, DragAndDropFields, Title, PageWrapper, Pagination, Preloader } from '@Root/components'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fieldConstructorActions, fieldConstructorSelectors } from '@Store/admin'
import { modalActions } from '@Root/store'
import { useDebounce, usePagination } from '@Root/hooks'
import { reorder } from '@Root/helpers'
import { SearchField } from './SearchField'
import { permissionsSelectors } from '@Store/permissions'
import { CustomScrollbar } from '@Root/HOCs/CustomScrollbar/index'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'
export const AdditionalFieldsPage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const fields = useSelector(fieldConstructorSelectors.fields)
  const isLoading = useSelector(fieldConstructorSelectors.isLoading)
  const permissions = useSelector(fieldConstructorSelectors.permissions)
  const paginationInformation = useSelector(fieldConstructorSelectors.paginationInformation)
  const { additional_field } = useSelector(permissionsSelectors.dataCreationPermissions)

  const {
    page,
    data: paginationData,
    visibleRowsQuantity,
    onChangePage,
    onChangeVisibleRowsQuantity,
    onChangeData: onChangePaginationData,
    isReady,
  } = usePagination()

  const value = useDebounce(isReady ? { page, limit: visibleRowsQuantity.max, sort: 'name:asc', search: `name:${searchValue}` } : null, 1000)

  useEffect(() => {
    onChangePaginationData(paginationInformation)
  }, [onChangePaginationData, paginationInformation])

  useEffect(() => {
    if (value) {
      dispatch(fieldConstructorActions.toggleLoading(true))
      dispatch(fieldConstructorActions.getFields(value))
    } else {
      dispatch(fieldConstructorActions.toggleLoading(true))
    }
    //Note: Don't change to a single "value" variable as dependency, because there will be link on object and useEffect will be triggered for each time after debounce
  }, [dispatch, value?.page, value?.limit, value?.search])

  const onChangeSearchValue = value => {
    setSearchValue(value)
    onChangePage(1)
  }

  const onCreateNewField = () => {
    history.push({ pathname: `${location.pathname}/create` })
  }

  const onEditField = async id => {
    //NOTE: In order to skip a short blink after transition to edit page
    await checkIsEditOtherPerson(id, () => history.push({ pathname: `${location.pathname}/${id}`, state: { from: location.pathname, id } }))
  }

  const checkIsEditOtherPerson = async (id, callBack) => {
    try {
      const res = await API.editMode.editInit(`additional_fields_${id}`)
      callBack()
    } catch (error) {
      const { data } = error?.response
      dispatch(
        snackbarActions.setSnackbar({
          text: `Sorry, this record is currently locked for editing by ${data.user_name}. Please wait until ${data.user_name} has finished making changes before trying to edit this record again`,
          isError: true,
        })
      )
    }
  }

  const onDeleteField = id => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'Are you sure you want to delete this field? The information will be lost in all contacts and cannot be restored',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        checkIsEditOtherPerson(id, () => dispatch(fieldConstructorActions.removeField({ id, pagination: value })))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return
    }

    const items = reorder(fields, source.index, destination.index)
    dispatch(fieldConstructorActions.setFields(items))
    dispatch(fieldConstructorActions.saveOrder({ fields: items, limit: value.limit, page }))
  }

  return !isLoading && value ? (
    <PageWrapper>
      <div className={styles.header}>
        <div className={styles.createButton}>
          {additional_field && (
            <>
              <RoundPlusButton clickHandler={onCreateNewField} />
              <Title>New Field</Title>{' '}
            </>
          )}
        </div>

        <SearchField onChange={onChangeSearchValue} value={searchValue} />
      </div>
      <div className={styles.pagination}>
        <Pagination
          minVisibleRowsQuantity={visibleRowsQuantity.min}
          maxVisibleRowsQuantity={visibleRowsQuantity.max}
          changeMaxVisibleRowsQuantityHandler={onChangeVisibleRowsQuantity('max')}
          page={page}
          changePageHandler={onChangePage}
          paginationData={paginationData}
        />
      </div>
      <div className={styles.wrapList}>
        <CustomScrollbar verticalOnly>
          <DragAndDropFields
            fields={fields}
            onClickEdit={onEditField}
            onClickDelete={onDeleteField}
            onDragEnd={onDragEnd}
            hasFields={fields.length > 0}
            isDraggable={permissions?.fields?.is_draggable}
          />
        </CustomScrollbar>
      </div>
      <div className={styles.pagination}>
        <Pagination
          minVisibleRowsQuantity={visibleRowsQuantity.min}
          maxVisibleRowsQuantity={visibleRowsQuantity.max}
          changeMaxVisibleRowsQuantityHandler={onChangeVisibleRowsQuantity('max')}
          page={page}
          changePageHandler={onChangePage}
          paginationData={paginationData}
        />
      </div>
    </PageWrapper>
  ) : (
    <Preloader />
  )
}

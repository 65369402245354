import { all } from 'redux-saga/effects'
import { authRootSaga } from '@Store/auth'
import { attachmentsRootSaga } from './attachments'
import { optionsRootSaga } from './options'
import { consentsRootSaga } from '@Store/consents'
import { contactRootSaga } from '@Store/contact'
import { studiesRootSaga } from '@Store/studies'
import { fieldTooltipManagerRootSaga } from '@Store/fieldTooltipManager'
import { adminRootSaga } from '@Store/admin'
import { admissionRootSaga } from '@Store/admission'
import { tableRootSaga } from '@Store/table'
import { permissionsRootSaga } from './permissions'

export function* rootSaga() {
	yield all([
		authRootSaga(),
		attachmentsRootSaga(),
		optionsRootSaga(),
		consentsRootSaga(),
		contactRootSaga(),
		studiesRootSaga(),
		fieldTooltipManagerRootSaga(),
		adminRootSaga(),
		admissionRootSaga(),
		tableRootSaga(),
		permissionsRootSaga(),
	])
}

import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useError, useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'
import { validateData } from '@Root/helpers'

const titles = { onRead: 'Off Venue Activity', onEdit: 'Edit Off Venue Activity', onCreate: 'Create Off Venue Activity' }
export const VenueActivityForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
  dynamicOptions,
  errorsFields,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions)?.person.hesa_details?.student_course_sessions
  const [customCode, setCustomCode] = useState({ label: '', value: '' })
  const [hostIdentifierOptions, setHostIdentifierOptions] = useState([])
  const { errors, setError, onClear: onClearError } = useError()
  const memoData = useRef({})

  //init data operation
  useEffect(() => {
    if (options?.host_identifiers) {
      if (options.host_identifiers.find(el => el.value === initialValue.host_identifier) || initialValue.host_identifier === null) {
        setHostIdentifierOptions([...options.host_identifiers])
      } else {
        setHostIdentifierOptions([...options.host_identifiers, { label: initialValue.host_identifier, value: initialValue.host_identifier }])
      }
    }
    setData(initialValue)
    if (!memoData.current?.venue_activity_identifier) memoData.current = initialValue
  }, [initialValue, options])

  useEffect(() => {
    if (errorsFields['venue_activity_identifier']) {
      const error = errorsFields['venue_activity_identifier']
      setError('venue_activity_identifier', error[0])
    }
  }, [errorsFields])

  const validateForm = () => {
    const {
      venue_activity_identifier,
      duration_amount,
      duration_type_id,
      activity_type_id,
      activity_country_id,
      mobility_scheme_id,
      activity_start_date,
      activity_end_date,
    } = data
    if (!venue_activity_identifier) {
      setError('venue_activity_identifier', 'Required')
      return false
    }
    if (!duration_amount) {
      setError('duration_amount', 'Required')
      return false
    }
    if (!duration_type_id) {
      setError('duration_type_id', 'Required')
      return false
    }
    if (!activity_type_id) {
      setError('activity_type_id', 'Required')
      return false
    }
    if (!activity_country_id) {
      setError('activity_country_id', 'Required')
      return false
    }
    if (!mobility_scheme_id) {
      setError('mobility_scheme_id', 'Required')
      return false
    }

    if (validateData([activity_start_date, activity_end_date])) {
      return false
    }

    return true
  }

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      setData(memoData.current)
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const onChangeCustomCode = field => value => {
    setCustomCode({ [field]: value, value: value })
  }

  const onAddCustomCode = () => {
    if (!hostIdentifierOptions.find(el => el.value === customCode.value)) {
      setData(prevState => ({ ...prevState, host_identifier: customCode.value }))
      setHostIdentifierOptions([...hostIdentifierOptions, customCode])
    } else {
      setData(prevState => ({ ...prevState, host_identifier: customCode.value }))
    }
    setCustomCode({ label: '', value: '' })
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Off Venue Activity' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `off_venue_activity_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Off venue activity identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.venue_activity_identifier}
                onChange={onChangeValue('venue_activity_identifier')}
                placeholder='Off venue activity identifier'
                isDisabled={!isEditable}
                description={descriptions?.venue_activity_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={50}
                isRequired={isEditable}
                error={errors.venue_activity_identifier}
              />
              <Field
                label={'Activity duration amount'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.duration_amount}
                onChange={onChangeValue('duration_amount')}
                placeholder='Activity duration amount'
                isDisabled={!isEditable}
                description={descriptions?.duration_amount}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={3}
                isRequired={isEditable}
                error={errors.duration_amount}
              />
              <Field
                label={'Activity duration type'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.duration_type_id}
                onChange={onChangeValue('duration_type_id')}
                placeholder='Activity duration type'
                isDisabled={!isEditable}
                options={options.activity_duration_types}
                description={descriptions?.duration_type_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isRequired={isEditable}
                error={errors.duration_type_id}
              />
              <Field
                label={'Mobility scheme'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.mobility_scheme_id}
                onChange={onChangeValue('mobility_scheme_id')}
                placeholder={'Mobility scheme'}
                isDisabled={!isEditable}
                options={options.mobility_schemes}
                description={descriptions?.mobility_scheme_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isRequired={isEditable}
                error={errors.mobility_scheme_id}
              />
              <Field
                label={'Module instance identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_instance_id}
                onChange={onChangeValue('module_instance_id')}
                placeholder={'Module instance identifier'}
                isDisabled={!isEditable}
                options={dynamicOptions.moduleInstances}
                description={descriptions?.module_instance_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Activity start date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.activity_start_date}
                onChange={onChangeValue('activity_start_date')}
                placeholder='Activity start date'
                isDisabled={!isEditable}
                description={descriptions?.activity_start_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Activity type identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.activity_type_id}
                onChange={onChangeValue('activity_type_id')}
                placeholder='Activity type identifier'
                isDisabled={!isEditable}
                options={options.activity_type_identifiers}
                description={descriptions?.activity_type_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isRequired={isEditable}
                error={errors.activity_type_id}
              />
              <Field
                label={'Country'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.activity_country_id}
                onChange={onChangeValue('activity_country_id')}
                placeholder={'Country'}
                isDisabled={!isEditable}
                options={options.countries}
                description={descriptions?.activity_country_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isRequired={isEditable}
                error={errors.activity_country_id}
              />
              <Field
                label={'Host identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.host_identifier}
                onChange={onChangeValue('host_identifier')}
                placeholder={'Host identifier'}
                isDisabled={!isEditable}
                options={hostIdentifierOptions}
                description={descriptions?.host_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                optionsStyle={{ overflow: 'inherit' }}
                customComponents={
                  <Field
                    classNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={customCode.label}
                    onChange={onChangeCustomCode('label')}
                    placeholder='Set custom code'
                    isDisabled={!isEditable}
                    field={'textField'}
                    maxLength={8}
                  />
                }
                callBack={onAddCustomCode}
              />
              <Field
                label={'Host identifier type'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.host_type_id}
                onChange={onChangeValue('host_type_id')}
                placeholder={'Host identifier type'}
                isDisabled={!isEditable}
                options={options.host_identifier_types}
                description={descriptions?.host_type_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Activity end date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.activity_end_date}
                onChange={onChangeValue('activity_end_date')}
                placeholder='Activity end date'
                isDisabled={!isEditable}
                description={descriptions?.activity_end_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

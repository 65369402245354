import { createSelector } from 'reselect'

export const isLoading = state => state.consentsReducer.isLoading

export const consent = state => state.consentsReducer.consent

export const isSuccess = state => state.consentsReducer.isSuccess

export const permissions = state => state.consentsReducer.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

import * as types from './moodle.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_MOODLE,
		payload,
	}),
	set: payload => ({
		type: types.SET_MOODLE,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_MOODLE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_MOODLE,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_MOODLE,
		payload,
	}),
	setError: payload => ({
		type: types.SET_ERROR,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'

export const Preloader = ({ style }) => {
	useEffect(() => {
		const app = document.querySelector('.App')
		if (app) app.style.overflowY = 'hidden'

		return () => {
			app.style.overflowY = 'visible'
		}
	}, [])

	return <div className={`${styles.ldsDualRing}`} style={{ ...style }} />
}

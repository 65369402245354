export const TOGGLE_LOADING = 'programme/curriculumAccreditation/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'programme/curriculumAccreditation/TOGGLE_SUCCESS'
export const GET_CURRICULUM_ACCREDITATION = 'programme/curriculumAccreditation/GET_CURRICULUM_ACCREDITATION'
export const SET_CURRICULUM_ACCREDITATION = 'programme/curriculumAccreditation/SET_CURRICULUM_ACCREDITATION'
export const CREATE_CURRICULUM_ACCREDITATION = 'programme/curriculumAccreditation/CREATE_CURRICULUM_ACCREDITATION'
export const DELETE_CURRICULUM_ACCREDITATION = 'programme/curriculumAccreditation/DELETE_CURRICULUM_ACCREDITATION'
export const EDIT_CURRICULUM_ACCREDITATION = 'programme/curriculumAccreditation/EDIT_CURRICULUM_ACCREDITATION'
export const GET_OPTIONS = 'programme/curriculumAccreditation/GET_OPTIONS'
export const SET_OPTIONS = 'programme/curriculumAccreditation/SET_OPTIONS'
export const CLEAR_DATA = 'programme/curriculumAccreditation/CLEAR_DATA'
export const SET_PERMISSIONS = 'programme/curriculumAccreditation/SET_PERMISSIONS'

import React, { PureComponent } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { all } from '@Root/helpers'
import onClickOutside from 'react-onclickoutside'
import DatePickerPopup from './DatePickerPopup/'
import { ArrowIcon, CrossIcon } from '@Root/assets'
import { NO_DATA_AVAILABLE } from '@Root/configs'
import InputMask from 'react-input-mask'
import { isValidDataFormat, getNumberOfData } from '@Root/helpers'

class Picker extends PureComponent {
  state = { popupIsShown: false, error: null }

  handleClickOutside = () => {
    this.setState({ popupIsShown: false })
  }

  showError = async error => {
    this.setState({ error })
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.props.error ? this.showError(this.props.error) : this.showError(null)
    }
  }

  handelBlur(valueInput) {
    if (!valueInput) return
    if (!isValidDataFormat(valueInput)) {
      this.setState({ error: 'Invalid Data' })
    } else {
      this.setState({ error: null })
    }
  }

  handelChange(valueInput) {
    const { changeHandler } = this.props
    const valueEx = getNumberOfData(valueInput)
    if (valueInput === '' || !valueInput) return changeHandler(valueInput)
    if (!valueEx) return changeHandler(valueEx)

    changeHandler(valueInput)
  }

  render() {
    const { popupIsShown, error } = this.state
    const { classNames, style, value, changeHandler, isDisabled, placeholder = '', minDate, maxDate } = this.props
    const isNotDataAvailable = value === NO_DATA_AVAILABLE.label

    return (
      <div
        className={`${classes.DatePicker} ${isDisabled ? classes.disabled : null} 
      ${classNames ? classNames.reduce((acc, className) => acc + ` ${classes[className]}`, '') : ''}
      `}
        style={{ ...style, borderColor: error ? '#D0021B' : null }}
      >
        <div
          className={classes.input}
          onClick={() =>
            all(
              () => !isDisabled && this.setState({ popupIsShown: !popupIsShown }),
              () => this.setState({ error: null })
            )
          }
        >
          {!isNotDataAvailable ? (
            <InputMask
              mask='99-99-9999'
              value={value ? value : ''}
              onChange={e => this.handelChange(e.target.value)}
              onBlur={e => this.handelBlur(e.target.value)}
              disabled={isDisabled}
              style={isDisabled ? { cursor: 'not-allowed' } : {}}
              className={classes.value}
              placeholder={placeholder}
            />
          ) : (
            <div className={classes.value}>{NO_DATA_AVAILABLE.value}</div>
          )}

          {!isDisabled && !isNotDataAvailable && (
            <>
              {value && (
                <div
                  className={classes.xIcon}
                  onClick={event =>
                    all(
                      () => event.stopPropagation(),
                      () => this.handelChange(null),
                      () => this.setState({ error: null })
                    )
                  }
                >
                  <img src={CrossIcon} alt='' />
                </div>
              )}
              <div className={classes.icon}>
                <img style={popupIsShown ? { transform: 'rotate(180deg)' } : null} src={ArrowIcon} alt='' />
              </div>
            </>
          )}
        </div>
        {error && <div className={classes.error}>{error}*</div>}
        {popupIsShown && (
          <div className={classes.popup}>
            <DatePickerPopup
              value={value}
              changeHandler={changeHandler}
              maxDate={maxDate}
              minDate={minDate}
              onClose={() => this.setState({ popupIsShown: false })}
            />
          </div>
        )}
      </div>
    )
  }
}

Picker.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
  style: PropTypes.object,
  popupStyles: PropTypes.object,
  value: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  errorIsInfinite: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
}

Picker.defaultProps = {
  style: {},
}

export const DatePicker = onClickOutside(Picker)

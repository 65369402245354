import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import * as types from './options.types'
import { API, createInstance } from '@Root/API'
import { actions } from './options.actions'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'

function* getDataForCreateContact() {
	try {
		const response = yield call(createInstance().get, 'data-for-create-contact')
		const entries = Object.entries(response.data)
		const mutationArray = entries.map(entry => {
			if (entry[0] !== '_debugbar') {
				const mutationArray = entry[1].map(obj => ({ value: obj.id, label: obj?.label || obj?.display_name || obj?.name || obj.title }))
				return [entry[0], mutationArray]
			} else return entry
		})
		yield put(actions.setDataForCreateContact(Object.fromEntries(mutationArray)))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getCountries() {
	try {
		const { data } = yield API.getCountries()
		const formattedData = data.data.map(country => ({ value: country.id, label: country.name }))
		yield put(actions.setCountries(formattedData))
		saveDataOptionsToLocalStorage('countries', formattedData)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getInstitutions() {
	try {
		const { data } = yield API.admin.institution.getRelated()
		yield put(actions.setInstitutions(data.data.map(({ id, name }) => ({ value: id, label: name }))))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getAdmissionAuditingBapStatuses() {
	try {
		const { data } = yield API.getAdmissionBapStatuses()
		const formattedData = data.map(obj => ({ value: obj.id, label: obj.name }))
		yield put(actions.setAdmissionBapStatuses(formattedData))
		saveDataOptionsToLocalStorage('admission_bap_statuses', formattedData)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getFinanceFundingStatic() {
	try {
		const {
			data: { academic_years, funding_bodies, funding_completions, statuses },
		} = yield call(API.finance.funding.options.getFinanceFundingStaticOptions)
		const formattedData = {
			academic_years: academic_years.map(obj => ({ value: obj.id, label: obj.name })),
			funding_bodies: funding_bodies.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
			funding_completions: funding_completions.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
			statuses: statuses.map(obj => ({ value: obj.id, label: obj.label })),
		}
		yield put(actions.setFinanceFunding({ name: 'static', data: formattedData }))
		saveDataOptionsToLocalStorage('finance_funding', formattedData)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getFinanceFundingDynamic() {
	try {
		yield put(actions.setFinanceFunding({ name: 'dynamic', data: [] }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getFinanceTuitionStatic() {
	try {
		const {
			data: { academic_years, accommodations, employer_fees, main_source_of_fee_hesas, managed_by, ordinands, sponsors, statuses },
		} = yield call(API.finance.tuition.options.getFinanceTuitionStaticOptions)

		const formattedData = {
			academic_years: academic_years.map(obj => ({ value: obj.id, label: obj.name })),
			accommodations: accommodations.map(obj => ({ value: obj.id, label: obj.name })),
			employer_fees: employer_fees.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
			main_source_of_fee_hesas: main_source_of_fee_hesas.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
			managed_by: managed_by.map(obj => ({ value: obj.id, label: obj.name })),
			ordinands: ordinands.map(obj => ({ value: obj.id, label: obj.name })),
			sponsors: sponsors.map(obj => ({ value: obj.id, label: obj.label })),
			statuses: statuses.map(obj => ({ value: obj.id, label: obj.label })),
		}
		yield put(actions.setFinanceTuition({ name: 'static', data: formattedData }))
		saveDataOptionsToLocalStorage('finance_tuition', formattedData)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getStudiesSuspensionsOptions() {
	try {
		const { data } = yield API.getStudiesSuspensionsOptions()
		const { reason_for_student_course_session_ending = [], academic_years_list = [], institutions = [] } = data
		yield put(
			actions.setStudiesSuspensions({
				reason_for_student_course_session_ending: reason_for_student_course_session_ending.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
				academic_years_list: academic_years_list.map(obj => ({ value: obj.id, label: obj.name })),
				institutions: institutions.map(obj => ({ value: obj.id, label: obj.name })),
			})
		)
	} catch (error) {
		console.log(error)
	}
}

function* getRoles() {
	try {
		const { data } = yield call(API.options.getRolesOptions)
		yield put(actions.setRoles(data))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

export function* rootSaga() {
	yield all([
		takeLatest(types.GET_DATA_FOR_CREATE_CONTACT, getDataForCreateContact),
		takeEvery(types.GET_COUNTRIES, getCountries),
		takeEvery(types.GET_INSTITUTIONS, getInstitutions),
		takeEvery(types.GET_ADMISSION_BAP_STATUSES, getAdmissionAuditingBapStatuses),
		takeEvery(types.GET_FINANCE_FUNDING_STATIC, getFinanceFundingStatic),
		takeEvery(types.GET_FINANCE_FUNDING_DYNAMIC, getFinanceFundingDynamic),
		takeEvery(types.GET_FINANCE_TUITION_STATIC, getFinanceTuitionStatic),
		takeEvery(types.GET_STUDIES_SUSPENSIONS_OPTIONS, getStudiesSuspensionsOptions),
		takeEvery(types.GET_ROLES, getRoles),
	])
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './fundingAndMonitoring.types'
import { actions } from './fundingAndMonitoring.actions'
import { snackbarActions } from '@Root/store'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import { API } from '@Root/API'

function* create({ payload }) {
  const { studyProgrammeId, contactId, data, history } = payload
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.studies.studentCourseSession.fundingAndMonitoring.create, studyProgrammeId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding-and-monitoring`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The funding and monitoring information has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
  const { data } = payload
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.studies.studentCourseSession.fundingAndMonitoring.edit, data)
    yield put(actions.toggleSuccess(true))
    yield put(actions.clearPermissions())
    yield put(snackbarActions.setSuccessNotification({ text: 'The funding and monitoring information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* remove({ payload }) {
  const { monitoringId, contactId, studyProgrammeId, history } = payload
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.studies.studentCourseSession.fundingAndMonitoring.remove, monitoringId)
    yield put(actions.set(null))
    yield put(actions.toggleSuccess(true))
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/off_venue_activity`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The funding and monitoring information has been deleted successfully!' }))
  } catch (error) {
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.studies.studentCourseSession.fundingAndMonitoring.options.getStatic)
    const { fundingLengths = [], equivalentQualification = [], fundingCompletion = [], nonRegulatedFeeFlags = [] } = data
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    }
    const formattedData = {
      fundingLengths: mapToValueAndLabel(fundingLengths),
      equivalentQualification: mapToValueAndLabel(equivalentQualification),
      fundingCompletion: mapToValueAndLabel(fundingCompletion),
      nonRegulatedFeeFlags: mapToValueAndLabel(nonRegulatedFeeFlags),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('funding_and_monitoring', formattedData)
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.CREATE_FUNDING_AND_MONITORING, create),
    takeEvery(types.EDIT_FUNDING_AND_MONITORING, edit),
    takeEvery(types.DELETE_FUNDING_AND_MONITORING, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './studentFinancialSupport.types'
import { actions } from './studentFinancialSupport.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, studentCourseSessionId, contactId, studentFinancialSupportId } = data
	try {
		const response = yield call(API.studies.studentCourseSession.studentFinancialSupport.get, studentCourseSessionId, studentFinancialSupportId)
		yield put(actions.set(response.data.data))
		yield put(actions.setPermissions(response.data.permissions))
	} catch (error) {
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/student_financial_support`)
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history, studyProgrammeId, contactId, studentCourseSessionId } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.studentFinancialSupport.create, studentCourseSessionId, data)
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/student_financial_support/${response.data.data.id}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Student Financial Support has been created successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		if (errors['studyProgrammeId']) {
			history.push('/home/studies/programmes/study')
		} else {
			yield put(actions.set(data))
			yield put(actions.setErrors(errors))
		}
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { studentFinancialSupportId, studentCourseSessionId, data } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.studentFinancialSupport.edit, studentCourseSessionId, studentFinancialSupportId, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The Student Financial Support has been saved successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		yield put(actions.set(payload))
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { contactId, studyProgrammeId, studentCourseSessionId, studentFinancialSupportId} = data
	try {
		yield call(API.studies.studentCourseSession.studentFinancialSupport.remove, studentCourseSessionId, studentFinancialSupportId)
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/student_financial_support`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Student Financial Support has been deleted successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* getOptions() {
	try {
		const {data} = yield call(API.studies.studentCourseSession.studentFinancialSupport.options.getStatic)
		const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
		const formattedData = {
			financial_support_types: generateObject(data.financial_support_types) ,
			access_participation_flags: generateObject(data.access_participation_flags) ,
		}
		yield put(actions.setOptions(formattedData))
		saveDataOptionsToLocalStorage('studentFinancialSupport', formattedData)
	} catch (error) {
		console.log(error)
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_STUDENT_FINANCIAL_SUPPORT, get),
		takeEvery(types.CREATE_STUDENT_FINANCIAL_SUPPORT, create),
		takeEvery(types.EDIT_STUDENT_FINANCIAL_SUPPORT, edit),
		takeEvery(types.DELETE_STUDENT_FINANCIAL_SUPPORT, remove),
		takeEvery(types.GET_OPTIONS, getOptions),
	])
}

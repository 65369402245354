export const isLoading = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.isLoading

export const isSuccess = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.isSuccess

export const entryQualificationSubject = state =>
	state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.entryQualificationSubject

export const errors = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.errors

export const permissions = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.permissions

export const options = state => state.studiesReducer.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.options

import * as types from './user.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './user.actions'
import { API } from '@Root/API'
import { authActions, snackbarActions } from '@Root/store'
import { permissionsRootSaga } from './permissions'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(id ? API.admin.user.getById : API.admin.user.get, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.admin.user.create, data)
    history.push(`/home/admin/manage-system-team/system-users/${response.data.data.id}`)
    if (response.data.message) {
      yield put(snackbarActions.setSnackbar({ text: response.data.message, isError: true }))
    } else {
      yield put(snackbarActions.setSuccessNotification({ text: 'The user has been created successfully!' }))
    }
  } catch (error) {
    const entities = Object.entries(error.response.data.errors)
    const errors = entities.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1][0] }), {})
    yield put(actions.setErrors(errors))
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, loggedUserId } = payload
  try {
    const response = yield call(API.admin.user.edit, data)
    yield put(actions.setErrors({}))
    yield put(authActions.getUser())
    const user = response.data.data
    yield put(actions.set(user))
    if (user.id === loggedUserId) yield put(authActions.setUser(data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The user has been saved successfully!' }))
  } catch (error) {
    const entities = Object.entries(error.response.data.errors)
    const errors = entities.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1][0] }), {})
    yield put(actions.setErrors(errors))
    console.log(error)
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const response = yield all([yield call(API.admin.role.options.getStatic), yield call(API.admin.user.options.getUserStatic)])
    const { status_list, option_reset_2fa } = response[1].data.data
    yield put(
      actions.setOptions({
        name: 'static',
        data: {
          roles: response[0].data.data.map(obj => ({ value: obj.id, label: obj.name })),
          statuses: status_list.map(obj => ({ value: obj.id, label: obj.label })),
          reset_2fa: option_reset_2fa.map(obj => ({ value: obj.id, label: obj.text })),
        },
      })
    )
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_USER, get),
    takeEvery(types.CREATE_USER, create),
    takeEvery(types.EDIT_USER, edit),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    permissionsRootSaga(),
  ])
}

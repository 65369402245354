import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './role.types'
import { actions } from './role.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, history } = payload
  try {
    const { data } = yield call(API.studies.module.role.get, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions({ ...data.permissions.form }))
  } catch (error) {
    history.push('/home/studies/engagements')
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, data, moduleId } = payload
  try {
    yield call(API.module.role.create, data)
    history.push(`/home/studies/modules/${moduleId}/module-delivery-role-identifier`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The module role has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.module.role.edit, payload)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The module role has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { history, id, moduleId } = payload
  try {
    yield call(API.module.role.remove, id)
    history.push(`/home/studies/modules/${moduleId}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The module role has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  yield put(actions.toggleLoading(true))
  try {
    const {
      data: { franchise_indicators, hesa_identificators },
    } = yield call(API.module.role.options.getStatic)
    yield put(
      actions.setOptions({
        name: 'static',
        data: {
          franchise_indicators: franchise_indicators.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
          hesa_identificators: hesa_identificators.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
        },
      })
    )
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ROLE, get),
    takeEvery(types.CREATE_ROLE, create),
    takeEvery(types.EDIT_ROLE, edit),
    takeEvery(types.DELETE_ROLE, remove),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
  ])
}

export const isLoading = state => state.studiesReducer.engagement.qualificationAward.qualificationAwardAccreditation.isLoading

export const isSuccess = state => state.studiesReducer.engagement.qualificationAward.qualificationAwardAccreditation.isSuccess

export const qualificationAwardAccreditation = state =>
	state.studiesReducer.engagement.qualificationAward.qualificationAwardAccreditation.qualificationAwardAccreditation

export const errors = state => state.studiesReducer.engagement.qualificationAward.qualificationAwardAccreditation.errors

export const permissions = state => state.studiesReducer.engagement.qualificationAward.qualificationAwardAccreditation.permissions

export const options = state => state.studiesReducer.engagement.qualificationAward.qualificationAwardAccreditation.options

import * as types from './academic.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_ACADEMIC,
		payload,
	}),
	set: payload => ({
		type: types.SET_ACADEMIC,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_ACADEMIC,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_ACADEMIC,
		payload,
	}),
	getStaticOptions: payload => ({
		type: types.GET_STATIC_OPTIONS,
		payload,
	}),
	getDynamicOptions: payload => ({
		type: types.GET_DYNAMIC_OPTIONS,
		payload,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import { reducer } from './qualificationAward.reducer'
import { qualificationAwardAccreditationReducer } from './qualificationAwardAccreditation'

import * as qualificationAwardSelectors from './qualificationAward.selectors'
import { combineReducers } from 'redux'
export { actions as qualificationAwardActions } from './qualificationAward.actions'
export { rootSaga as qualificationAwardRootSaga } from './qualificationAward.saga'
export * from './qualificationAwardAccreditation'

export { qualificationAwardSelectors }

export const qualificationAwardReducer = combineReducers({ reducer, qualificationAwardAccreditation: qualificationAwardAccreditationReducer })

export const isLoading = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.isSuccess

export const studentFinancialSupport = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.studentFinancialSupport

export const errors = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.errors

export const options = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.options

export const permissions = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.permissions

export const canBeCreatedStudentFinancialSupport = state => state.studiesReducer.studentCourseSession.studentFinancialSupport.canBeCreatedStudentFinancialSupport
import * as types from './costCenter.types'

const initialCostCenterValue = {
	//NOTE: This value van be changed by backend. 42 - theology and religious studies. If smth went wrong with code (there it`s 142 for now) ask of that backend developer
	hesa_cost_centre_id: 42,
	hesa_cost_centre_proportion: 100,
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	costCenter: initialCostCenterValue,
	options: {
		static: {},
		dynamic: {},
	},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_COST_CENTER:
			return {
				...state,
				costCenter: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[payload.name]: payload.data,
				},
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		case types.CLEAR_FORM:
			return {
				...state,
				module: initialCostCenterValue,
			}
		default:
			return state
	}
}

export const TOGGLE_LOADING = 'fieldConstructor/TOGGLE_LOADING'
export const TOGGLE_SUCCESSES = 'fieldConstructor/TOGGLE_SUCCESSES'
export const GET_FIELDS = 'fieldConstructor/GET_FIELDS'
export const SET_FIELDS = 'fieldConstructor/SET_FIELDS'
export const SET_PAGINATION_INFORMATION = 'fieldConstructor/SET_PAGINATION_INFORMATION'
export const GET_FIELD = 'fieldConstructor/GET_FIELD'
export const SET_FIELD = 'fieldConstructor/SET_FIELD'
export const CREATE_FIELD = 'fieldConstructor/CREATE_FIELD'
export const EDIT_FIELD = 'fieldConstructor/EDIT_FIELD'
export const REMOVE_FIELD = 'fieldConstructor/REMOVE_FIELD'
export const GET_FIELDS_FOR_CONTACT = 'fieldConstructor/GET_FIELDS_FOR_CONTACT'
export const SET_FIELDS_FOR_CONTACT = 'fieldConstructor/SET_FIELDS_FOR_CONTACT'
export const SET_ERRORS = 'fieldConstructor/SET_ERRORS'
export const SAVE_ORDER = 'fieldConstructor/SAVE_ORDER'
export const SET_PERMISSIONS = 'fieldConstructor/SET_PERMISSIONS'

import { combineReducers } from 'redux'
import { reducer } from './organisation.reducer'
import { churchReducer } from './church'
import { dioceseReducer } from './diocese'
import { trainingFacilityReducer } from './trainingFacility'
import * as organisationSelectors from './organisations.selectors'

export { actions as organisationContactActions } from './organisation.actions'
export { rootSaga as organisationContactRootSaga } from './organisation.saga'
export { organisationSelectors }

export * from './church'
export * from './diocese'
export * from './trainingFacility'
export const organisationReducer = combineReducers({ reducer, church: churchReducer, diocese: dioceseReducer, trainingFacility: trainingFacilityReducer })

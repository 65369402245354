import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentLinkName } from '@Root/helpers'
import DocumentTitle from 'react-document-title'
import { PageTitle, PageNavLinks } from '@Root/components'
import { AdmissionsTable } from './Table/'
import { ApplicationPage } from './Application'
import { admissionsLinks } from '@Root/configs'
import { useLocation } from 'react-router'
import { generateStyleConfig } from '@Root/pages/Home/Admissions/lib'
import { permissionsSelectors } from '@Store/permissions'
import { useSelector } from 'react-redux'

export const AdmissionsPage = () => {
  const location = useLocation()
  const isApplication = location.pathname.split('/')[4]
  const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)

  const currentLinkName = getCurrentLinkName(admissionsLinks, location)
  const styleConfig = generateStyleConfig(Boolean(isApplication))

  const filteredPageLinks = useMemo(() => admissionsLinks.filter(link => tabsPermissions?.admissions?.tabs?.[link.label]), [tabsPermissions])
  const PageInMemo = useMemo(() => () => <AdmissionsTable styleConfig={styleConfig} />, [styleConfig])

  return (
    <DocumentTitle title={`${currentLinkName} - Admissions`}>
      <div className={styles.wrapper}>
        <PageTitle title={currentLinkName} style={styleConfig.pageTitle} />
        <PageNavLinks style={styleConfig.navLinksStyles} root={`/home/admissions`} links={filteredPageLinks} />
        <Switch>
          {filteredPageLinks.map((link, i) => (
            <Route exact path={`/home/admissions/${link.path}`} component={PageInMemo} key={i} />
          ))}
          <Route exact path='/home/admissions/applications/:applicationId/*' component={ApplicationPage} />
          <Redirect to='/home/admissions/admissions-details' />
        </Switch>
      </div>
    </DocumentTitle>
  )
}

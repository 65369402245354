import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { costCenterActions, costCenterSelectors, fieldTooltipManagerSelectors } from '@Root/store'
import { StudiesModuleCostCenterForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateCostCenter = ({ onOpenDescriptionModal, defaultURL }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { moduleId, moduleDelId: moduleDeliveryId } = useParams()
	const isLoading = useSelector(costCenterSelectors.isLoading)
	const staticOptions = useSelector(costCenterSelectors.options).static
	const costCenter = useSelector(costCenterSelectors.costCenter)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['module-cost-center']
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

	useEffect(() => {
		dispatch(costCenterActions.getStaticOptions())
	}, [dispatch])

	const onSubmit = payload => {
		dispatch(costCenterActions.create({ data: { ...payload, module_delivery_id: moduleDeliveryId }, history, moduleId }))
	}

	const onCancel = () => {
		history.push(defaultURL)
	}
	if (!dataCreationPermissions.modules_sub) return <Redirect to={defaultURL} />
	return (
		<StudiesModuleCostCenterForm
			options={staticOptions}
			onSave={onSubmit}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			isLoading={isLoading}
			initialValue={costCenter}
			onCancel={onCancel}
			canEdit
			isNew
		/>
	)
}

import React from 'react'
import styles from './style.module.scss'

export const SaveButton = ({ children, onClick, disabled }) => {
	return (
		<button className={styles.button} onClick={onClick} disabled={disabled}>
			{children}
		</button>
	)
}

export const TOGGLE_LOADING = 'admin/qualification/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admin/qualification/TOGGLE_SUCCESS'
export const GET_QUALIFICATION = 'admin/qualification/GET_QUALIFICATION'
export const SET_QUALIFICATION = 'admin/qualification/SET_QUALIFICATION'
export const EDIT_QUALIFICATION = 'admin/qualification/EDIT_QUALIFICATION'
export const CREATE_QUALIFICATION = 'admin/qualification/CREATE_QUALIFICATION'
export const DELETE_QUALIFICATION = 'admin/qualification/DELETE_QUALIFICATION'
export const GET_STATIC_OPTIONS = 'admin/qualification/GET_STATIC_OPTIONS'
export const SET_OPTIONS = 'admin/qualification/SET_OPTIONS'
export const SET_PERMISSIONS = 'admin/qualification/SET_PERMISSIONS'
export const CLEAR_DATA = 'admin/qualification/CLEAR_DATA'

import React, { useEffect, useRef } from 'react'
import classes from './style.module.scss'
import { EditButton, SectionTitle } from '@Root/components'
import { Subform } from '../Subform'
import { useDispatch, useSelector } from 'react-redux'
import { contactSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions, snackbarActions } from '@Root/store'
import { admissionActions, admissionSelectors } from '@Store/contact'
import { useToggle } from '@Root/hooks'
import { useHistory } from 'react-router'
import { studentSelectors } from '@Store/contact/person/student'
import { useEditedMode } from '@Root/hooks'

export const AdmissionForm = ({ contactId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isEditable, setIsEditable] = useToggle(false)
  const staticOptions = useSelector(admissionSelectors.options)
  const permissions = useSelector(admissionSelectors.permissions)
  const data = useSelector(admissionSelectors.admission)
  const { id } = useSelector(studentSelectors.information)
  const isSuccess = useSelector(admissionSelectors.isSuccess)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details
  const editButtonRef = useRef(null)
  const hasEngagement = staticOptions?.engagement?.length > 0
  const idForm = `admission_form_${contactId}`

  const { startEditMode, exitEditMode } = useEditedMode({
    isEditable,
    clickToggleEditModeButtonHandler: setIsEditable,
    clickCancelButtonHandler: setIsEditable,
    idForm,
  })

  useEffect(() => {
    if (isSuccess) exitEditMode()
  }, [isSuccess])

  useEffect(() => {
    return () => {
      if (idForm) exitEditMode()
    }
  }, [idForm])

  useEffect(() => {
    if (isSuccess) setIsEditable()
  }, [isSuccess, setIsEditable])

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getHesaDescriptions())
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      dispatch(admissionActions.get({ contactId, history }))
      dispatch(admissionActions.getOptions({ id }))
    }
  }, [contactId, dispatch, id])

  useEffect(() => {
    if (!data.length || !hasEngagement) return
    if (data[0]?.admission?.length === 0) {
      setIsEditable()
    }
  }, [data, dispatch])

  const onOpenDescription = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const saveAdmisionForm = (formName, currentData, callback) => {
    return new Promise((resolve, reject) => {
      callback()
      const payload = {
        data: {
          ...currentData.admission[0],
          engagement_id: currentData.engagement_id,
        },
        formName,
        contactId,
        resolve,
        reject,
        admisionId: currentData.admission[0].admission_id,
      }
      if (payload.admisionId) {
        dispatch(admissionActions.edit(payload))
      } else {
        dispatch(admissionActions.create(payload))
      }
    })
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
  }

  const onOpenDeleteModal = (formName, dataFrom, callback, onToggleLoading) => {
    return new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The Admison will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        onToggleLoading()
        dispatch(modalActions.hideModal())
        callback()
        if (data.length > 0) await dispatch(admissionActions.delete({ contactId, admissionsId: dataFrom.admission[0].admission_id }))
        return true
      })
      .catch(() => {
        dispatch(modalActions.hideModal())
        return false
      })
  }

  const onShowFieldError = error => {
    dispatch(snackbarActions.setSnackbar({ text: error, isError: true, delay: 15000 }))
  }

  const { fields, form } = permissions
  const subforms = [
    {
      name: 'admision',
      initialData: data,
      groupsConfigs: {
        level0GroupConfig: {
          title: 'Admission',
          field: 'admission',
          quantity: {
            min: 0,
            max: hasEngagement ? Infinity : 0,
          },
          canBeSaved: false,
          canBeSavedPart: true,
          inputs: [
            {
              name: 'Engagement',
              field: 'engagement_id',
              type: 'select',
              options: staticOptions.engagement,
              validations: ['required', 'unique'],
              description: descriptions?.engagements?.engagements_id,
            },
          ],
        },
        level1GroupsConfigs: [
          {
            title: 'Admission',
            field: 'admission',
            quantity: {
              min: 0,
              max: 1,
            },
            inputs: [
              {
                name: 'Enquiry Date',
                field: 'enquiry_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.enquiry_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions.enquiry_date,
              },
              {
                name: 'Application Date',
                field: 'application_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.application_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions.open_day_date,
              },
              {
                name: 'Open Day Date',
                field: 'open_day_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.open_day_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions.open_day_date,
              },
              {
                name: 'BAP Date',
                field: 'bap_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.bap_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.bap_date,
              },
              {
                name: 'Interview Date',
                field: 'interview_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.interview_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions?.interview_date,
              },
              {
                name: 'BAP Status',
                field: 'bap_status',
                type: 'select',
                validations: [...(!fields?.bap_status?.edit ? ['disabled'] : [])],
                options: staticOptions?.admission_bap_statuses,
                maxLength: 2,
                description: descriptions.bap_status,
              },
              {
                name: 'Application Status',
                field: 'application_status',
                type: 'select',
                validations: [...(!fields?.application_status?.edit ? ['disabled'] : [])],
                options: staticOptions?.applications_status,
                maxLength: 2,
                description: descriptions.application_status,
              },
              {
                name: 'Ref Sent Date',
                field: 'ref_sent_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.ref_sent_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions.ref_sent_date,
              },
              {
                name: 'Ref Received Date',
                field: 'ref_received_date',
                type: 'datePicker',
                validations: ['dataValid', ...(!fields?.ref_received_date?.edit ? ['disabled'] : [])],
                maxLength: 2,
                description: descriptions.ref_received_date,
              },
              {
                name: 'DDO Name',
                field: 'DDO_name',
                type: 'textInput',
                validations: [...(!fields?.DDO_name?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions.DDO_name,
              },
              {
                name: 'DDO Email',
                field: 'DDO_Email',
                type: 'textInput',
                validations: [...(!fields?.DDO_Email?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions.DDO_Email,
              },
              {
                name: 'Sponsor Name',
                field: 'sponsor_name',
                type: 'textInput',
                validations: [...(!fields?.sponsor_name?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions.sponsor_name,
              },
              {
                name: 'Visa Status',
                field: 'visa_status',
                type: 'textInput',
                validations: [...(!fields?.visa_status?.edit ? ['disabled'] : [])],
                maxLength: 50,
                description: descriptions.visa_status,
              },
            ],
          },
        ],
      },
    },
  ]

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <SectionTitle title='Admission Details' />
      </div>
      {(data?.length > 0 || hasEngagement) && (
        <div className={`${classes.edit_wrapper}`} ref={editButtonRef}>
          {form?.edit && !isEditable && <EditButton clickHandler={startEditMode} />}
        </div>
      )}
      <div className={classes.contentWrapper}>
        <div className={classes.subforms}>
          {subforms?.map(subform => (
            <Subform
              name={subform.name}
              initialData={subform.initialData}
              isEditable={isEditable}
              groupsConfigs={subform.groupsConfigs}
              saveHandler={(data, callback) => saveAdmisionForm(subform.name, data, callback)}
              key={subform.name}
              onOpenDescription={onOpenDescription}
              onOpenDeleteModal={onOpenDeleteModal}
              onShowFieldError={onShowFieldError}
              onToggleEditMode={setIsEditable}
              permissions={form}
              openTabDefalt={true}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { MultiSelect, RoleManager } from '@Root/components'
import classes from '@Root/components/roleManager/style.module.scss'
import { useDispatch } from 'react-redux'

export const EditPermissions = ({ onSave, roles, initialValue }) => {
	const dispatch = useDispatch()
	const [data, setData] = useState({})

	useEffect(() => {
		if (initialValue) setData(initialValue)
	}, [initialValue])

	const onDataChange = field => data => {
		setData(prevState => ({ ...prevState, [field]: data }))
	}

	const { additional_functions, create_data_permissions, permissions, table_rules_permissions } = data

	return (
		<RoleManager
			onSave={onSave}
			title={'Manage Role'}
			showRolesSelect
			showSaveButton
			showInstruction
			roles={roles}
			onDataChange={onDataChange}
			additionalFunctions={additional_functions}
			createDataPermissions={create_data_permissions}
			permissions={permissions}
			tableRulesPermissions={table_rules_permissions}
			isEditable
		>
			<div>
				<p className={classes.instruction}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
					quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
					cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				</p>
				<span className={classes.little_title}>Assigned Role</span>
				<MultiSelect
					inputStyle={{ width: 260, background: '#ffffff' }}
					options={roles}
					values={data.user_ids}
					changeHandler={onDataChange('user_ids')}
					optionsStyle={{ width: 260 }}
					hideCross
				/>
			</div>
		</RoleManager>
	)
}

import React from 'react'
import './style.scss'
import DocumentTitle from 'react-document-title'
import { useDispatch, useSelector } from 'react-redux'
import { PasswordRecoveryForm, PasswordRecoverySuccessForm } from '@Root/components'
import { authActions, authSelectors } from '@Root/store'
import { RedirectAuthUser } from '@Root/processes'
import { useHistory, useLocation } from 'react-router'

export const PasswordRecoveryPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const error = useSelector(authSelectors.error)

	const onSendRecoverToken = email => {
		dispatch(authActions.sendRecoveryEmail(email, history))
	}

	return (
		<DocumentTitle title='Password recovery'>
			<RedirectAuthUser>
				<div className='PasswordRecoveryPage'>
					{location.hash !== '#success' ? (
						<PasswordRecoveryForm error={error} history={history} recoveryEmailHandler={onSendRecoverToken} />
					) : (
						<PasswordRecoverySuccessForm />
					)}
				</div>
			</RedirectAuthUser>
		</DocumentTitle>
	)
}

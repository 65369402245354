import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.person.academic.isLoading

export const isSuccess = state => state.contactReducer.person.academic.isSuccess

export const academic = state => state.contactReducer.person.academic.academic

export const options = state => state.contactReducer.person.academic.options

export const permissions = state => state.contactReducer.person.academic.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => {
	return permissions.fields ? Object.keys(permissions.fields).length > 0 : false
})

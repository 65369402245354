import * as types from './hesa.types'

const initialState = {
	isSuccess: false,
	hesa: {
		student: {
			disabilities: [],
			language_proficiencies: [],
			national_identities: [],
		},
		module_instances: [],
		student_course_session: [],
		engagements: [],
	},
	snapshots: null,
	options: {
		static: {
			student: {},
			disability: {},
			languageProficiencies: {},
			nationalIdentity: {},
			studentCourseSessions: {},
			moduleInstance: {},
		},
		dynamic: {},
	},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_HESA:
			return {
				...state,
				hesa: action.payload,
			}
		case types.SET_SNAPSHOTS:
			return {
				...state,
				snapshots: action.payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[action.payload.name]: action.payload.data,
				},
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

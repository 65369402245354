import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { all } from '@Root/helpers'
import triangleBlack from '../../../assets/icons/triangle-black.png'
import { options } from './lib/tittleExport'

class Dropdown extends Component {
	state = {
		popupIsShown: false,
		options,
		allowedOptions: [],
	}

	componentDidMount() {
		const { permissions, path } = this.props
		const { options } = this.state
		this.setState({ allowedOptions: options[path].filter(option => permissions[option.checkField]) })
	}

	handleClickOutside = () => {
		this.setState({ popupIsShown: false })
	}

	render() {
		const { popupIsShown, allowedOptions } = this.state
		const { clickHandler = () => {} } = this.props

		return (
			<div className={classes.ExportDropdown}>
				<div className={classes.opener} onClick={() => this.setState({ popupIsShown: !popupIsShown })}>
					<div className={classes.text}>Export</div>
					<div className={classes.icon}>
						<img style={popupIsShown ? { transform: 'rotate(180deg)' } : null} src={triangleBlack} alt='' />
					</div>
				</div>
				{popupIsShown && (
					<div className={classes.options}>
						{allowedOptions.map(({ value, label }, i) => (
							<div
								className={classes.option}
								onClick={() =>
									all(
										() => clickHandler(value),
										() => {
											this.setState({ popupIsShown: false })
										}
									)
								}
								key={i}
							>
								{label}
							</div>
						))}
					</div>
				)}
			</div>
		)
	}
}

Dropdown.propTypes = {
	clickHandler: PropTypes.func,
	permissions: PropTypes.object,
	path: PropTypes.string,
}

export const ExportDropdown = onClickOutside(Dropdown)

export const TOGGLE_LOADING = 'modules/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'modules/TOGGLE_SUCCESS'
export const GET_MODULE = 'modules/GET_MODULE_'
export const SET_MODULE = 'modules/SET_MODULE_'
export const CREATE_MODULE = 'modules/CREATE_MODULE'
export const DELETE_MODULE = 'modules/DELETE_MODULE'
export const EDIT_MODULE = 'modules/EDIT_MODULE'
export const GET_STATIC_OPTIONS = 'options/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'options/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'modules/SET_OPTIONS'
export const SET_PERMISSIONS = 'modules/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'modules/CLEAR_PERMISSIONS'

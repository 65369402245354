import * as types from './instance.types'

export const actions = {
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
}

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import closeIcon from '../../../assets/icons/close.png'

export const ConfirmationModal = ({ text, clickRejectButtonHandler, clickResolveButtonHandler }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.icon} onClick={clickRejectButtonHandler}>
          <img src={closeIcon} alt='' />
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.contentText}>{text}</div>
      <div className={classes.line} />
      <div className={classes.buttons}>
        <button className={classes.buttonLeft} onClick={clickRejectButtonHandler}>
          Cancel
        </button>
        <button className={classes.buttonRight} onClick={clickResolveButtonHandler}>
          Proceed
        </button>
      </div>
    </div>
  )
}

ConfirmationModal.propTypes = {
  text: PropTypes.string,
  clickRejectButtonHandler: PropTypes.func,
  clickResolveButtonHandler: PropTypes.func,
}

ConfirmationModal.defaultProps = {
  text: null,
  clickRejectButtonHandler: () => {},
  clickResolveButtonHandler: () => {},
}

import { delay } from 'redux-saga'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { API, axios } from '@Root/API'
import { actions } from './auth.actions'
import { snackbarActions } from '../snackbar'
import { errorMessage } from '@Root/helpers'
import { permissions } from '../../configs'
import * as types from './auth.types'
import { permissionsActions } from '@Store/permissions'

function* logIn({ login, password, history, code }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setAuthError(null))
  try {
    const { data } = yield API.postLoginPassword(login, password, code)
    const token = yield data.data['response-content'].access_token
    yield (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`)
    yield (localStorage.token = token)
    yield (sessionStorage.isCurrentSession = true)
    yield put(actions.setToken(token))
    history.push('/home')
  } catch (err) {
    if (err.response.data?.data?.google2fa_active || err.response.data?.data?.is_force_activated) {
      const { google2fa_active, is_force_activated, qr_code, google2fa_secret } = err.response.data?.data
      yield put(actions.setTwoFactoreAuth({ google2fa_active, is_force_activated, qr_code, google2fa_secret }))
    } else {
      let error = err.response.data.errors ? Object.keys(err.response.data.errors)[0] : err.response.data.message
      error === 'email' && (error = 'login')
      yield put(actions.setAuthError(error))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* logOut({ history }) {
  try {
    yield API.deleteUserToken()
    yield put(actions.setTwoFactoreAuth({ google2fa_active: false, is_force_activated: false, qr_code: null, google2fa_secret: null }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  } finally {
    localStorage.removeItem('token')
    localStorage.removeItem('tabs')
    sessionStorage.removeItem('isCurrentSession')
    yield put(actions.setToken(null))
    yield put(actions.setUser({}))
    yield history.push('/login')
  }
}

function* removeToken({ payload }) {
  const { history } = payload
  localStorage.removeItem('token')
  sessionStorage.removeItem('isCurrentSession')
  if (history) yield history.push('/login')
  yield put(actions.setToken(null))
  yield put(actions.setUser({}))
  yield put(actions.setTwoFactoreAuth({ google2fa_active: false, is_force_activated: false, qr_code: null, google2fa_secret: null }))
}

function* sendRecoveryEmail({ email, history }) {
  try {
    yield API.postRecoveryEmail(email)
    yield history.replace({ hash: '#success' })
  } catch (err) {
    let error = Object.keys(err.response.data.errors)[0]
    yield put(actions.setAuthError(error))
    yield delay(3000)
    yield put(actions.setAuthError(null))
  }
}

function* checkRecoveryToken({ token, history }) {
  try {
    yield API.postRecoveryToken(token)
    yield put(actions.setRecoveryToken(token))
  } catch (err) {
    yield history.replace('/password-recovery')
  }
}

function* sendNewPassword({ password, history }) {
  const token = yield select(state => state.authReducer.recoveryToken)
  try {
    yield API.postNewPassword(token, password)
    yield history.replace('/login')
    yield put(actions.setRecoveryToken(null))
  } catch (err) {
    yield put(actions.setAuthError('recoveryToken'))
    yield delay(3000)
    yield put(actions.setAuthError(null))
    yield history.replace('/password-recovery')
    yield put(actions.setRecoveryToken(null))
  }
}

function* getUser() {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.user.get)
    yield put(permissionsActions.getDataCreation())
    yield put(permissionsActions.getRule())
    const user = data.data
    const activePermissions = permissions[user.user_roles_names] || []
    yield put(actions.setUser({ ...user, permissions: activePermissions }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* initTwoFactoreAuth({ payload }) {
  const { code } = payload
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.google2fa.postGoogle2fa, code)
    const user = data.data
    const activePermissions = permissions[user.user_roles_names] || []
    yield put(actions.setUser({ ...user, permissions: activePermissions }))
    yield put(snackbarActions.setSuccessNotification({ text: 'Two factor authentication is activated!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: 'The code is invalid!', isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* disableTwoFactoreAuth() {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.google2fa.deleteGoogle2fa)
    const user = data.data
    const activePermissions = permissions[user.user_roles_names] || []
    yield put(actions.setUser({ ...user, permissions: activePermissions }))
    yield put(snackbarActions.setSuccessNotification({ text: 'Two factor authentication is turned off!!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([
    takeEvery(types.LOG_IN, logIn),
    takeEvery(types.LOG_OUT, logOut),
    takeEvery(types.REMOVE_TOKEN, removeToken),
    takeEvery(types.SEND_RECOVERY_EMAIL, sendRecoveryEmail),
    takeEvery(types.CHECK_RECOVERY_TOKEN, checkRecoveryToken),
    takeEvery(types.SEND_NEW_PASSWORD, sendNewPassword),
    takeEvery(types.GET_USER, getUser),
    takeEvery(types.INIT_2FA_CODE, initTwoFactoreAuth),
    takeEvery(types.DISABLE_2FA, disableTwoFactoreAuth),
  ])
}

export const isLoading = state => state.studiesReducer.engagement.collaborationProvision.isLoading

export const isSuccess = state => state.studiesReducer.engagement.collaborationProvision.isSuccess

export const collaborativeProvision = state => state.studiesReducer.engagement.collaborationProvision.collaborativeProvision

export const errors = state => state.studiesReducer.engagement.collaborationProvision.errors

export const permissions = state => state.studiesReducer.engagement.collaborationProvision.permissions

export const options = state => state.studiesReducer.engagement.collaborationProvision.options

import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.additionalInformation.isLoading

export const isSuccess = state => state.contactReducer.additionalInformation.isSuccess

export const additionalInformation = state => state.contactReducer.additionalInformation.additionalInformation

export const formattedAdditionalInformation = createSelector(additionalInformation, information =>
	information.reduce((acc, { id, value, type }) => ({ ...acc, [id]: { type, value } }), {})
)

export const permissions = state => state.contactReducer.additionalInformation.permissions

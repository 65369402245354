import { axios } from "@Root/API";
import objectToFormdata from "object-to-formdata";

export const postNewAttachment = (contact_id, file, description) => {
  return axios.post("upload/attachment", objectToFormdata({ contact_id, file, description }), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

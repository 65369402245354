import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './moodle.actions'
import * as types from './moodle.types'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { moodleId } = payload
	try {
		const response = yield call(API.admin.moodle.get, moodleId)
		const data = response.data
		yield put(actions.set(data.data))
		yield put(actions.setPermissions(data.permissions))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	const { history, data } = payload
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(API.admin.moodle.create, data)
		yield put(actions.toggleSuccess(true))
		history.push(`/home/admin/moodle-links/${response.data.data.id}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The moodle link has been created successfully!' }))
	} catch (error) {
		const data = error.response.data.errors.link[0]
		yield put(actions.setError({ field: 'link', data }))
		console.log(error.response)
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	const { data, moodleId } = payload
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(API.admin.moodle.edit, moodleId, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The moodle link has been saved successfully!' }))
	} catch (error) {
		const data = error.response.data.errors.link[0]
		yield put(actions.setError({ field: 'link', data }))
		console.log(error)
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	const { history, ...data } = payload
	yield put(actions.toggleLoading(true))
	try {
		yield call(API.admin.moodle.remove, data.moodleId)
		yield put(actions.set(null))
		history.push('/home/admin/moodle-links')
		yield put(snackbarActions.setSuccessNotification({ text: 'The module link has been deleted!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}
export function* rootSaga() {
	yield all([
		takeEvery(types.GET_MOODLE, get),
		takeEvery(types.CREATE_MOODLE, create),
		takeEvery(types.EDIT_MOODLE, edit),
		takeEvery(types.DELETE_MOODLE, remove),
	])
}

import React, { useEffect } from 'react'

import { NotesForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { studentCourseSessionNoteActions, studentCourseSessionNoteSelectors } from '@Root/store'

export const StudentCourseSessionNotesForm =
	({ contactId, canManipulate, studentCourseSessionId }) => {
		const dispatch = useDispatch()
		const isLoading = useSelector(studentCourseSessionNoteSelectors.isLoading)
		const isSuccess = useSelector(studentCourseSessionNoteSelectors.isSuccess)
		const notes = useSelector(studentCourseSessionNoteSelectors.notes)
		const permissions = useSelector(studentCourseSessionNoteSelectors.permissions)

		useEffect(() => {
			if (canManipulate ) dispatch(studentCourseSessionNoteActions.get({ studentCourseSessionId }))

			return () => {
				dispatch(studentCourseSessionNoteActions.set([]))
			}
		}, [canManipulate, dispatch, studentCourseSessionId])

		const onCreate = payload => {
			dispatch(studentCourseSessionNoteActions.create({ ...payload, contact_id: contactId, student_course_session_id: studentCourseSessionId }))
		}

		const OnSave = (id, payload) => {
			dispatch(studentCourseSessionNoteActions.edit({ id, data: payload }))
		}

		const onDelete = id => {
			dispatch(studentCourseSessionNoteActions.delete({ id }))
		}

		return (
			<NotesForm
				onEdit={OnSave}
				onCreate={onCreate}
				isLoading={isLoading}
				isSuccess={isSuccess}
				initialValue={notes}
				onDelete={onDelete}
				canManipulate={canManipulate}
				canCreate={permissions?.create}
			/>
		)
	}

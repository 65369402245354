import React from 'react'
import styles from './style.module.scss'
import { NavLink } from 'react-router-dom'

export const ActionButton = ({ onClick, children, isLink, url }) => {
	return !isLink ? (
		<button className={styles.button} onClick={onClick}>
			{children}
		</button>
	) : (
		<NavLink to={url} className={styles.button}>
			{children}
		</NavLink>
	)
}

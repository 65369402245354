import React from 'react'
import { StudyRecordProgrammeForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors, studentCourseSessionActions, studentCourseSessionSelectors, programmeSelectors } from '@Root/store'
import { useHistory, useLocation } from 'react-router'
import { Redirect } from 'react-router-dom'
import { permissionsSelectors } from '@Store/permissions'

export const CreateStudentCourseSession = ({ onOpenDescriptionModal, backButtonHandler }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const pathname = location.pathname
  const contactId = pathname.split('/')[6]
  const programmeId = pathname.split('/')[7]
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['records-of-studies']
  const options = useSelector(studentCourseSessionSelectors.formattedOptions)

  const programmesList = useSelector(programmeSelectors.programmesListOptions)
  const isLoading = useSelector(studentCourseSessionSelectors.isLoading)
  const programmeRecord = useSelector(studentCourseSessionSelectors.programmeRecord)
  const errors = useSelector(studentCourseSessionSelectors.errorsFields)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const permissions = useSelector(studentCourseSessionSelectors.permissions)

  const onCreate = data => {
    dispatch(studentCourseSessionActions.create({ data: { ...data, contact_id: contactId }, history, contactId }))
  }

  if (!dataCreationPermissions.student_course_session) return <Redirect to={'/home/studies/records-of-studies'} />
  return (
    <StudyRecordProgrammeForm
      descriptions={descriptions}
      options={{ ...options, programmes: programmesList || [] }}
      onOpenDescriptionModal={onOpenDescriptionModal}
      initialErrors={errors}
      initialValue={{ ...programmeRecord, program_id: Number(programmeId) }}
      onSave={onCreate}
      onCancel={backButtonHandler}
      isLoading={isLoading}
      permissions={permissions}
      isNew
    />
  )
}

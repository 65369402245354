import React, { useEffect } from 'react'
import { ActionsDropdown, EntryQualificationAwardForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { DetailInformation } from './DetailInformation'
import { entryQualificationAwardedActions, entryQualificationAwardedSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'
import { permissionsSelectors } from '@Store/permissions'

export const EditEntryQualificationAward = ({ onOpenDescriptionModal }) => {
  const entryQualificationAward = useSelector(entryQualificationAwardedSelectors.entryQualificationAwarded)
  const options = useSelector(entryQualificationAwardedSelectors.options)
  const isLoading = useSelector(entryQualificationAwardedSelectors.isLoading)
  const isSuccess = useSelector(entryQualificationAwardedSelectors.isSuccess)
  const errors = useSelector(entryQualificationAwardedSelectors.errors)
  const permissions = useSelector(entryQualificationAwardedSelectors.permissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const history = useHistory()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).entry_qualification_award
  const { id: engagementId, contactId, entryProfileId, entryQualificationAwardId, entryQualificationSubjectId: otherTabId } = useParams()
  const dispatch = useDispatch()

  const { can_be_created_entry_qualification_subject } = entryQualificationAward
  const actions = () => {
    return [
      ...(can_be_created_entry_qualification_subject && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Entry qualification subject',
              path: `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${entryQualificationAwardId}/entry-qualification-subject/new`,
            },
          ]
        : []),
    ]
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This entry profile will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(entryQualificationAwardedActions.delete({ data: { entryQualificationAwardId, entryProfileId, contactId, engagementId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onChooseAction = actionName => {
    actions()
      .find(action => action.name === actionName)
      .handler()
  }

  const onSave = data => {
    dispatch(entryQualificationAwardedActions.edit({ data, entryQualificationAwardId, entryProfileId }))
  }

  useEffect(() => {
    if (entryQualificationAwardId && entryQualificationAwardId !== 'new' && !otherTabId) {
      dispatch(
        entryQualificationAwardedActions.get({
          data: {
            entryQualificationAwardId,
            entryProfileId,
            contactId,
            engagementId,
          },
          history,
        })
      )
    }
  }, [])

  const onCancel = memoData => {
    dispatch(entryQualificationAwardedActions.set(memoData))
    dispatch(entryQualificationAwardedActions.setErrors([]))
  }

  return (
    <div>
      {!!actions().length && <ActionsDropdown actions={actions()} clickHandler={onChooseAction} style={{ position: 'absolute', right: 10, top: -60 }} />}
      <EntryQualificationAwardForm
        onSave={onSave}
        onDelete={onDelete}
        onCancel={onCancel}
        canEdit={permissions.form?.edit}
        canDelete={permissions.form?.delete}
        initialValue={entryQualificationAward}
        options={options}
        isLoading={isLoading}
        isSuccess={isSuccess}
        initialErrors={errors}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
      {permissions.tabs && (
        <DetailInformation
          contactId={contactId}
          engagementId={engagementId}
          entryProfileId={entryProfileId}
          entryQualificationAwardId={entryQualificationAwardId}
          permissions={permissions.tabs}
        />
      )}
    </div>
  )
}

export const TOGGLE_LOADING = 'engagement/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'engagement/TOGGLE_SUCCESS'
export const GET_ENGAGEMENT = 'engagement/GET_ENGAGEMENT'
export const SET_ENGAGEMENT = 'engagement/SET_ENGAGEMENT'
export const EDIT_ENGAGEMENT = 'engagement/EDIT_ENGAGEMENT'
export const DELETE_ENGAGEMENT = 'engagement/DELETE_ENGAGEMENT'
export const SET_PROFILE_RECORDS = 'engagement/SET_PROFILE_RECORDS'
export const SET_ENTRY_PROFILE = 'engagement/SET_ENTRY_PROFILE'
export const CREATE_ENGAGEMENT = 'engagement/CREATE_ENGAGEMENT'
export const SET_ERRORS = 'engagement/SET_ERRORS'
export const GET_ENGAGEMENT_OPTIONS = 'engagement/GET_ENGAGEMENT_OPTIONS'
export const GET_OTPIONS = 'engagement/GET_OTPIONS'
export const SET_OPTIONS = 'engagement/SET_OPTIONS'
export const SET_PERMISSIONS = 'engagement/SET_PERMISSIONS'
export const CLEAR_DATA = 'engagement/CLEAR_DATA'

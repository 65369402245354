import * as types from './moduleInstances.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleSuccess: payload => ({
    type: types.TOGGLE_SUCCESS,
    payload,
  }),
  set: payload => ({
    type: types.SET_MODULE_INSTANCES,
    payload,
  }),
  create: payload => ({
    type: types.CREATE_MODULE_INSTANCES,
    payload,
  }),
  get: payload => ({
    type: types.GET_MODULE_INSTANCES,
    payload,
  }),
  edit: payload => ({
    type: types.EDIT_MODULE_INSTANCES,
    payload,
  }),
  delete: payload => ({
    type: types.DELETE_MODULE_INSTANCES,
    payload,
  }),
  getOptions: () => ({
    type: types.GET_OPTIONS,
  }),
  setOptions: payload => ({
    type: types.SET_OPTIONS,
    payload,
  }),
  clearData: () => ({
    type: types.CLEAR_DATA,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
  setCanBeCreated: payload => ({
    type: types.SET_CAN_BE_CREATED,
    payload,
  }),
  setErrors: payload => ({
    type: types.SET_ERRORS,
    payload,
  }),
}

import React from 'react'
import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'

export const VerticalTabs = ({ tabs, onClick, isLink, defaultURL }) => {
	return (
		<div className={styles.tabs_wrapper}>
			<div className={styles.tabs} style={{ gridTemplateColumns: `repeat(${tabs.length}, 1fr)` }}>
				{!isLink
					? tabs.map(tab => (
							<div key={tab.label} onClick={() => onClick(tab.label)} className={styles.tab}>
								{tab.name}
							</div>
					  ))
					: tabs.map(tab => (
							<NavLink to={`${defaultURL}/${tab.label}`} key={tab.label} className={styles.tab} activeClassName={styles.active}>
								{tab.name}
							</NavLink>
					  ))}
			</div>
		</div>
	)
}

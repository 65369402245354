export const TOGGLE_LOADING = 'staff/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'staff/TOGGLE_SUCCESS'
export const GET_STAFF = 'staff/GET_STAFF'
export const SET_STAFF = 'staff/SET_STAFF'
export const EDIT_STAFF = 'staff/EDIT_STAFF'
export const DELETE_STAFF = 'staff/DELETE_STAFF'
export const GET_STATIC_OPTIONS = 'staff/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'staff/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'staff/SET_OPTIONS'
export const SET_PERMISSIONS = 'staff/SET_PERMISSIONS'

import React, { useEffect, useState, useRef } from 'react'
import { FormWrapper } from '@Root/HOCs'
import { EditButton, Field, InputLabel, SectionTitle, TextInput } from '@Root/components'
import styles from './style.module.scss'
import { useError } from '@Root/hooks'

const EDIT_TITLE = 'Edit the Moodle Link'
const READ_TITLE = 'Moodle Link'
const CREATE_TITLE = 'Create the Moodle Link'

export const MoodleLinkForm = ({
  onSubmit,
  onCancel,
  onDelete,
  isLoading,
  initialData,
  isEditable = false,
  isSuccess,
  onToggleEdit,
  error,
  isNew,
  onOpenDescriptionModal,
  permissions,
}) => {
  const [data, setData] = useState({ link_name: '', link: '', is_encrypted: false })
  const { errors, setError, onClear: onClearError } = useError()
  const isEncryptedMemo = useRef(false)
  const memoData = useRef({})

  useEffect(() => {
    if (error) setError(error.field, error.data)
  }, [error, setError])

  useEffect(() => {
    if (isSuccess) {
      onClearError()
      memoData.current = initialData
    }
  }, [isSuccess])

  useEffect(() => {
    if (initialData) {
      setData(initialData)
      isEncryptedMemo.current = initialData.is_encrypted
    }
    if (!memoData.current?.link_name) memoData.current = initialData
  }, [data.id, initialData])

  const isValidateForm = () => {
    if (!data.link_name) {
      setError('link_name', 'Required')
      return false
    }
    if (!data.link) {
      setError('link', 'Required')
      return false
    }
    return true
  }

  const onSave = () => {
    if (!isValidateForm()) return
    onSubmit(data)
  }

  const onChange = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onCancelEdit = () => {
    if (isNew) {
      onCancel()
      return
    }
    onClearError()
    onCancel(memoData.current)
  }

  const { link_name, link, is_encrypted } = data
  const { form } = permissions
  return (
    <div>
      <FormWrapper
        buttons={isEditable ? ['cancel', 'save', ...(isNew ? [] : form?.delete ? ['delete'] : [])] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this moodle link' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        isEditable={isEditable}
        isCreate={isNew}
        canEdit={form?.edit}
        readTitle={READ_TITLE}
        editTitle={EDIT_TITLE}
        createTitle={CREATE_TITLE}
        clickToggleEditModeButtonHandler={onToggleEdit}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSave}
        idForm={data?.id ? `moodle_link_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.form}>
          <div className={styles.column}>
            <div className={styles.input}>
              <InputLabel text='Display name' hasAsterisk={isEditable} description={'2'} onOpenDescription={onOpenDescriptionModal} />
              <TextInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={link_name}
                changeHandler={onChange('link_name')}
                isDisabled={!isEditable}
                error={errors.link_name}
              />
            </div>
          </div>
          <div className={styles.column}>
            <Field
              label={'Is encrypted'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 30 }}
              value={is_encrypted}
              placeholder='Is encrypted'
              isDisabled={!isEditable}
              onChange={onChange('is_encrypted')}
              field={'checkbox'}
            />
            <div>
              <Field
                label={'URL'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={link}
                placeholder='Is encrypted'
                isDisabled={!isEditable}
                field={'textField'}
                onChange={onChange('link')}
                error={errors.link}
              />
              {isEditable && isEncryptedMemo.current && (
                <p className={styles.warning}>
                  *If you want to change the encrypted link, then to correctly save and encrypt the new data, first delete the old link, and then add a new one{' '}
                </p>
              )}
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

import s from './style.module.scss'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const SkeletonNote = ({ isReply = false }) => {
  return (
    <div className={s.Card} style={{ width: isReply ? '700px' : '900px' }}>
      <div className={s.Avatar}>
        <Skeleton circle height='50px' width='50px' containerClassName='avatar-skeleton' />
      </div>
      <div className={s.Content}>
        <div className={s.Wrapper}>
          <div className={s.Marck}>
            <Skeleton width={70} />
            <Skeleton width={70} />
            <Skeleton width={70} />
          </div>
          <div className={s.Btn}>
            <Skeleton width={70} />
            <Skeleton width={70} />
            <Skeleton width={70} />
          </div>
        </div>
        <div className={s.Text}>
          <Skeleton width={500} />
          <Skeleton width={300} />
        </div>
      </div>
    </div>
  )
}

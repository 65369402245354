export const TOGGLE_LOADING = 'contact/TOGGLE_LOADING'
export const TOGGLE_SAVING = 'contact/TOGGLE_SAVING'
export const TOGGLE_SUCCESS = 'contact/TOGGLE_SUCCESS'
export const GET_CONTACT = 'contact/GET_CONTACT'
export const SET_CONTACT = 'contact/SET_CONTACT'
export const CREATE_CONTACT = 'contact/CREATE_CONTACT'
export const EDIT_CONTACT = 'contact/EDIT_CONTACT'
export const DELETE_CONTACT = 'contact/DELETE_CONTACT'
export const GET_STATIC_OPTIONS = 'contact/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'contact/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'contact/SET_OPTIONS'
export const SET_CONTACT_TYPE = 'contact/SET_CONTACT_TYPE'
export const SET_ERROR = 'contact/SET_ERROR'
export const SET_MOODLE_LINKS = 'contact/SET_MOODLE_LINKS'
export const SET_PERSON = 'person/SET_PERSON'
export const SET_ORGANISATION = 'person/SET_ORGANISATION'
export const SET_INFORMATION = 'person/SET_INFORMATION'
export const SET_PERMISSIONS = 'person/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'person/CLEAR_PERMISSIONS'

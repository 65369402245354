import React, { useState } from 'react'
import classes from '../style.module.scss'
import { Table, CancelButton } from '@Root/components'
import { suspendedRule } from '@Root/configs'
import { snackbarActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import { errorMessage } from '@Root/helpers'

export const StudentsTab = ({ table, styleConfig, suspendedSecretRule, isShowDefault }) => {
  const [additionalRules, setAdditionalRule] = useState([])
  const dispatch = useDispatch()
  const setError = error => {
    dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  const onToggleShowSuspendedStudents = () => {
    setAdditionalRule(prevState => {
      const hasSuspendedRule = prevState.find(rule => rule.id === suspendedRule.id)
      if (hasSuspendedRule) {
        return prevState.filter(rule => rule.id !== suspendedRule.id)
      }
      return [...prevState, suspendedRule]
    })
  }

  return (
    <>
      <div className={classes.tableTitle}>Students</div>
      <CancelButton style={{ position: 'absolute', right: 100, top: 150, zIndex: 10, padding: '6px 26px' }} onClick={() => onToggleShowSuspendedStudents()}>
        {!!additionalRules.length ? 'Hide' : 'Show'} Non-active Students
      </CancelButton>
      <Table
        style={{ marginTop: 70 }}
        datePeriodDropdownStyle={{ top: -70 }}
        name={table.name}
        columns={table.columns}
        hasSorting={table.hasSorting}
        hasFilters={table.hasFilters}
        hasRules={table.hasRules}
        mandatoryRules={table.mandatoryRules}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        clickLinkHandlers={table.clickLinkHandlers}
        permissions={table.permissions}
        errorHandler={setError}
        styleConfig={{
          ...styleConfig.table,
          datePeriodDropdownStyle: {
            top: -70,
          },
          ruleLine: {
            marginRight: 275,
          },
        }}
        isHiddenDefaultRule
        additionalRules={additionalRules}
        secretRule={suspendedSecretRule}
        addLimitedRule
        showToggledItemCountToRule
        defaultRuleId={'current_students'}
        showDefaultRuleId={isShowDefault}
      />
    </>
  )
}

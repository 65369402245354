import * as types from './studentTypes.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	types: [],
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_STUDENT_TYPES:
			return {
				...state,
				types: action.payload,
			}
		case types.SET_REMOVED_STUDENT_TYPE:
			return {
				...state,
				types: state.types.filter(type => type.id !== action.payload.name),
			}
		case types.SET_CREATED_STUDENT_TYPE:
			return {
				...state,
				types: [...state.types, action.payload],
			}
			
		default:
			return state
	}
}

import React from 'react'
import styles from './style.module.scss'
import { ActionButton, SectionTitle } from '@Root/components'

export const TitleWithButton = ({ title, url, isLink, canDoAction, children }) => {
	return (
		<div className={styles.wrapper}>
			<SectionTitle title={title} />
			{canDoAction && (
				<ActionButton isLink={isLink} url={url}>
					<span>{children}</span>
				</ActionButton>
			)}
		</div>
	)
}

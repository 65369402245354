import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useLocation } from 'react-router'
import { Field } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors } from '@Root/store'
import { FormWrapper } from '@Root/HOCs'
import { useError, useToggle } from '@Root/hooks'

const buttons = ['cancel', 'save']
const titles = { onRead: '', onEdit: 'Edit the helper messages' }
export const HelpMessageManager = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const [data, setData] = useState([])
  const [isEditable, toggleEditable] = useToggle()
  const messages = useSelector(fieldTooltipManagerSelectors.messages)
  const isLoading = useSelector(fieldTooltipManagerSelectors.isLoading)
  const isSuccess = useSelector(fieldTooltipManagerSelectors.isSuccess)
  const permissions = useSelector(fieldTooltipManagerSelectors.permissions)

  const { errors, setError } = useError()
  const arrayPathname = pathname.split('/')
  const hasAdminPageReference = pathname.includes('admin')
  const entity = arrayPathname[arrayPathname.length - 2]
  const entityType = arrayPathname[arrayPathname.length - 1]

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      dispatch(fieldTooltipManagerActions.toggleSuccess(false))
    }
  }, [dispatch, isSuccess, toggleEditable])

  useEffect(() => {
    if (messages) setData(messages)
  }, [messages])

  useEffect(() => {
    if (!hasAdminPageReference) dispatch(fieldTooltipManagerActions.get({ entity, entity_type: entityType }))
    else dispatch(fieldTooltipManagerActions.get({ entity: 'admin', entity_type: entityType }))
  }, [dispatch, entity, entityType, hasAdminPageReference])

  const validationForm = () => {
    const booleanValues = data.map(({ value, name }) => {
      if (!value) {
        setError(name, 'Required')
        return false
      }
      return true
    })
    return !booleanValues.some(value => !value)
  }

  const onSubmit = () => {
    // if (!validationForm()) return
    if (!hasAdminPageReference) {
      dispatch(fieldTooltipManagerActions.edit({ entity, entity_type: entityType, properties: data }))
      return
    }
    dispatch(fieldTooltipManagerActions.edit({ entity: 'admin', entity_type: entityType, properties: data }))
  }
  const onChange = name => description => {
    if (description.length < 1000) {
      setData(prevState => prevState.map(item => (item.name === name ? { ...item, description } : item)))
      return
    }
    setError(name, `You can't write more then 1000 characters`)
  }

  const onCancel = () => {
    setData(messages)
    toggleEditable()
  }

  return (
    <div className={styles.wrapper}>
      <FormWrapper
        buttons={isEditable ? buttons : []}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancel}
        clickToggleEditModeButtonHandler={toggleEditable}
        clickSaveButtonHandler={onSubmit}
        isEditable={isEditable}
        canEdit={permissions?.form?.edit}
        editTitle={titles.onEdit}
        readTitle={titles.onRead}
        idForm={entityType ? `${entityType}_help_text` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.wrapper}>
          {data.map(({ name, label, description }) => {
            return (
              <Field
                label={label}
                onChange={onChange(name)}
                maxLength={1000}
                value={description}
                error={errors[name]}
                isDisabled={!isEditable}
                classNames={!isEditable ? ['borderless'] : []}
              />
            )
          })}
        </div>
      </FormWrapper>
    </div>
  )
}

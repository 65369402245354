import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import { PageTitle, PageNavLinks } from '@Root/components'
import { UserPage } from './User'
import { RolePage } from './Role'
import { InstitutionPage } from './Institution'
import { SessionYearPage } from './SessionYear'
import { QualificationPage } from './Qualification'
import { VenuePage } from './Venue'
import { RoleManagerPage } from './User/RoleManager'
import { adminLinks } from '@Root/configs'
import { AdminTable } from './Table'
import { CreateFieldSubPage, EditFieldSubPage } from './AdditionalFields'
import { MoodleLinkPage } from './MoodleLink'
import { SnapshotPage } from './Snapshot'
import { useLocation } from 'react-router'
import { generateStyleConfig } from './lib'
import { permissionsSelectors } from '@Store/permissions'
import { useSelector } from 'react-redux'
import { Error } from '@Root/HOCs'

export const AdminPage = () => {
  const location = useLocation()
  const pathname = location.pathname
  const currentLinkPath = pathname.split('/')[3]
  const currentSubLinkPath = pathname.split('/')[4]
  const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)
  const currentSubLink = currentLink?.subLinks.find(link => link.path === currentSubLinkPath)
  const isNotTable = false
  const isSnapshot = currentLinkPath?.includes('snapshots')
  const isMoodleLinks = currentLinkPath?.includes('moodle-links')
  const pagesWithTable = ['system-users', 'user-roles', 'snapshots', 'institutions', 'qualifications', 'venues']
  const styleConfig = generateStyleConfig(isNotTable, isSnapshot, isMoodleLinks)
  const getComponent = (Component, conf) => {
    return () => (Component ? <Component styleConfig={styleConfig} conf={conf} /> : <Error>No data available</Error>)
  }

  const PageInMemo = useMemo(() => () => <AdminTable styleConfig={styleConfig} />, [styleConfig])
  const filteredPageLinks = useMemo(
    () =>
      adminLinks.filter(link => {
        const tabs = tabsPermissions?.admin?.tabs?.[link.label]
        const isObject = typeof tabs === 'object'
        return !isObject ? tabs : tabs?.view
      }),
    [tabsPermissions]
  )

  const filteredCategriesLinks = useMemo(() => {
    return filteredPageLinks.map(link => {
      const permissionsTabs = tabsPermissions.admin.tabs?.[link.label]
      const isPermissionsTabsObject = typeof permissionsTabs === 'object'
      return {
        ...link,
        subLinks: link.subLinks.filter(subLink => {
          return isPermissionsTabsObject ? permissionsTabs?.tabs[subLink.label] : permissionsTabs
        }),
      }
    })
  })

  const currentLink = filteredCategriesLinks.find(link => link.path === currentLinkPath)
  const pageTitle = currentLink?.title === 'Table Manager' ? `${currentLink.title} - ${currentSubLink?.name}` : currentSubLink?.name || currentLink?.title
  return (
    <DocumentTitle title={`${currentLink?.title === 'Table Manager' ? currentLink.title : currentSubLink?.name || currentLink?.title} - Admin`}>
      <div className={styles.wrapper}>
        <PageTitle title={pageTitle} style={styleConfig.pageTitle} />
        <PageNavLinks
          style={styleConfig.navLinksStyles}
          root={`/home/admin`}
          links={filteredCategriesLinks}
          subLinks
          currentLink={currentLink}
          subStyles={styleConfig.subPageNavLinksStyles}
        />
        <Switch>
          {filteredCategriesLinks.map(({ subLinks, Component, path, hasSlug }, i) => {
            return subLinks.length > 0 ? (
              subLinks.map((subLink, i) => {
                return (
                  !subLink?.isHidden && (
                    <Route
                      exact
                      path={`/home/admin/${path}/${subLink.path}${hasSlug ? '/:slug?/:sub?' : ''}`}
                      component={pagesWithTable.includes(subLink.path) ? PageInMemo : getComponent(subLink.Component, subLink?.conf)}
                      key={`${path}/${subLink.path}`}
                    />
                  )
                )
              })
            ) : (
              <Route exact path={`/home/admin/${path}`} component={getComponent(Component)} key={i} />
            )
          })}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.system_user && (
            <Route exact path='/home/admin/manage-system-team/system-users/new' component={UserPage} />
          )}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.system_user && (
            <Route exact path='/home/admin/manage-system-team/system-users/:userId' component={UserPage} />
          )}
          {tabsPermissions.admin.tabs.moodle_link && <Route exact path='/home/admin/moodle-links/:moodleId' component={MoodleLinkPage} />}
          {tabsPermissions.admin.tabs.moodle_link && <Route exact path='/home/admin/moodle-links/new' component={MoodleLinkPage} />}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.user_role && (
            <Route exact path='/home/admin/manage-system-team/system-users/:userId/role-manager' component={RoleManagerPage} />
          )}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.user_role && (
            <Route exact path='/home/admin/manage-system-team/user-roles/new' component={RolePage} />
          )}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.user_role && (
            <Route exact path='/home/admin/manage-system-team/user-roles/:roleId' component={RolePage} />
          )}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.user_role && (
            <Route exact path='/home/admin/manage-system-team/user-roles/:roleId/manage-exceptions' component={RolePage} />
          )}
          {tabsPermissions.admin.tabs.data.tabs.institution && <Route exact path='/home/admin/data/institutions/:institutionId' component={InstitutionPage} />}
          {tabsPermissions.admin.tabs.snapshot && <Route exact path='/home/admin/snapshots/:snapshotId' component={SnapshotPage} />}
          {tabsPermissions.admin.tabs.data.tabs.qualification && (
            <Route exact path='/home/admin/data/qualifications/:qualificationId' component={QualificationPage} />
          )}
          {tabsPermissions.admin.tabs.data.tabs.session_year && <Route exact path='/home/admin/data/session-year/:sessionYearId' component={SessionYearPage} />}
          {tabsPermissions.admin.tabs.data.tabs.venue && <Route exact path='/home/admin/data/venues/:venueId' component={VenuePage} />}
          {tabsPermissions.admin.tabs.additional_field && <Route exact path='/home/admin/additional-fields/create' component={CreateFieldSubPage} />}
          {tabsPermissions.admin.tabs.additional_field && <Route exact path='/home/admin/additional-fields/:id' component={EditFieldSubPage} />}
          {tabsPermissions.admin.tabs.manage_system_team.tabs.table_manager && (
            <Redirect exact from='/home/admin/table-manager' to='/home/admin/table-manager/contacts' />
          )}
          {currentLink && currentLink.subLinks.length > 0 && !currentSubLink && (
            <Redirect to={`/home/admin/${currentLink?.path}/${currentLink.subLinks[0]?.path}`} />
          )}
          <Redirect from={'/home/admin/'} to={'/home/admin/manage-system-team/system-users'} />
        </Switch>
      </div>
    </DocumentTitle>
  )
}

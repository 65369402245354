import * as types from './moduleInstances.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  moduleInstances: {
    module_instance_identifier: null,
    accreditation_of_prior_id: null,
    continuing_module_id: null,
    inactive_module_flag_id: null,
    language_percentage: null,
    fee_amount: null,
    module_count_id: null,
    module_start_date: null,
    module_end_date: null,
    module_outcome_id: null,
    module_result_id: null,
    include_in_hesa: null,
  },
  errors: {},
  options: {},
  canBeCreatedModuleInstances: false,
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_MODULE_INSTANCES:
      return {
        ...state,
        moduleInstances: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        moduleInstances: initialState.moduleInstances,
        errors: {},
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.SET_CAN_BE_CREATED:
      return {
        ...state,
        canBeCreatedModuleInstances: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}

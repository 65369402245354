export const TOGGLE_LOADING = 'entryProfile/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'entryProfile/TOGGLE_SUCCESS'
export const GET_ENTRY_PROFILE = 'entryProfile/GET_ENTRY_PROFILE'
export const SET_ENTRY_PROFILE = 'entryProfile/SET_ENTRY_PROFILE'
export const CREATE_ENTRY_PROFILE = 'entryProfile/CREATE_ENTRY_PROFILE'
export const DELETE_ENTRY_PROFILE = 'entryProfile/DELETE_ENTRY_PROFILE'
export const EDIT_ENTRY_PROFILE = 'entryProfile/EDIT_ENTRY_PROFILE'
export const GET_OPTIONS = 'entryProfile/GET_OPTIONS'
export const SET_OPTIONS = 'entryProfile/SET_OPTIONS'
export const CLEAR_DATA = 'entryProfile/CLEAR_DATA'
export const SET_PERMISSIONS = 'entryProfile/SET_PERMISSIONS'

import * as types from './snapshot.types'

const initialState = {
	isLoading: false,
	snapshot: null,
	options: {
		static: {},
		dynamic: {},
	},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_SNAPSHOT:
			return {
				...state,
				snapshot: payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		default:
			return state
	}
}

import * as types from './person.types'

export const actions = {
	getRelationShips: payload => ({
		type: types.GET_RELATIONSHIPS,
		payload,
	}),
	setRelationShips: payload => ({
		type: types.SET_RELATIONSHIPS,
		payload,
	}),
}

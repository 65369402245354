import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import isDecimal from 'validator/lib/isDecimal'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { modalActions, feeSelectors, feeActions, fieldTooltipManagerSelectors, fieldTooltipManagerActions } from '@Root/store'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, TextInput, Select } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'

const titles = { onRead: 'Fees Details', onEdit: 'Edit Fees Details', onCreate: 'Create Fees' }
export const FeesForm = ({ isNew, canManipulate, config, contactId, studyProgrammeId, canCreate, parentURL }) => {
  const [isEditable, setIsEditable] = useState(isNew)
  const [error, setError] = useState(null)
  const [data, setData] = useState({ main_source_of_fee: '', gross_fee: '', deductions: '' })
  const fee = useSelector(feeSelectors.fee)
  const isLoading = useSelector(feeSelectors.isLoading)
  const isSuccess = useSelector(feeSelectors.isSuccess)
  const options = useSelector(feeSelectors.options)
  const permissions = useSelector(feeSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).fees
  const dispatch = useDispatch()
  const history = useHistory()

  const showError = (input, message) => setError({ input, message })

  const errorMessage = input => (error && error.input === input ? error.message : null)

  const formIsValid = () => {
    const { gross_fee, deductions } = data
    if (gross_fee && !isDecimal(gross_fee)) {
      showError('gross_fee', 'Invalid value. Should be a decimal')
      return false
    }
    if (deductions && !isDecimal(deductions)) {
      showError('deductions', 'Invalid value. Should be a decimal')
      return false
    }
    return true
  }

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const handleClickCancelButton = () => {
    if (isNew) {
      dispatch(feeActions.set(null))
      history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/off_venue_activity`)
    } else {
      setIsEditable(false)
    }
  }

  const handleClickDeleteButton = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'These fee will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(feeActions.delete({ url: config.deleteFeesLink(data.fees_id), history, data: { contactId, studyProgrammeId } }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    if (isNew) {
      dispatch(
        feeActions.create({ url: config.createFeesLink, data: { ...modifiedData, contact_id: contactId, program_course_id: studyProgrammeId }, history })
      )
    } else {
      dispatch(feeActions.edit({ url: config.editFeesLink, data: { ...modifiedData, contact_id: contactId, program_course_id: studyProgrammeId } }))
    }
  }

  const toggleEditMode = () => {
    setIsEditable(prevState => !prevState)
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  useEffect(() => {
    if (fee) setData(fee)
  }, [fee])

  useEffect(() => {
    if (isSuccess) setIsEditable(false)
  }, [isSuccess])

  useEffect(() => {
    error !== null && setError(null)
  }, [error])

  useEffect(() => {
    dispatch(feeActions.getOptions())
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'fees' }))

    return () => {
      dispatch(feeActions.clearPermissions())
    }
  }, [dispatch])

  if (isNew && !canCreate) return <Redirect to={parentURL} />

  const { fields, form } = permissions
  const canEdit = canManipulate && !isEditable && form?.edit

  return data ? (
    <FormWrapper
      buttons={isNew ? ['cancel', 'save'] : isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete these fees' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
      canEdit={canEdit}
      clickToggleEditModeButtonHandler={toggleEditMode}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      createTitle={titles.onCreate}
      isCreate={isNew}
      idForm={data?.id ? `fees_details_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Main Source of Fee HESA' description={descriptions.main_source_of_fee} onOpenDescription={onOpenDescriptionModal} />
          <Select
            inputClassNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 180, width: 260 }}
            options={options}
            value={data.main_source_of_fee}
            changeHandler={value => handleChangeInput('main_source_of_fee', value)}
            isDisabled={!isEditable || !fields?.main_source_of_fee?.edit}
            isView={fields?.main_source_of_fee?.view}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Gross Fee' description={descriptions.gross_fee} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 180, width: 260 }}
            value={data.gross_fee}
            changeHandler={value => handleChangeInput('gross_fee', value)}
            isDisabled={!isEditable || !fields?.gross_fee?.edit}
            isView={fields?.gross_fee?.view}
            error={errorMessage('gross_fee')}
          />
          <InputLabel
            style={{ position: 'absolute', left: 520 }}
            text='Deductions'
            description={descriptions.deductions}
            onOpenDescription={onOpenDescriptionModal}
          />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 670, width: 260 }}
            value={data.deductions}
            changeHandler={value => handleChangeInput('deductions', value)}
            isDisabled={!isEditable || !fields?.deductions?.edit}
            isView={fields?.deductions?.view}
            error={errorMessage('deductions')}
          />
        </div>
      </div>
    </FormWrapper>
  ) : (
    <></>
  )
}

FeesForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  initialData: PropTypes.shape({
    main_source_of_fee: PropTypes.number,
    gross_fee: PropTypes.string,
    deductions: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
}

FeesForm.defaultProps = {
  permissions: [],
  isNew: false,
  initialData: {
    main_source_of_fee: null,
    gross_fee: null,
    deductions: null,
  },
  options: [],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
}

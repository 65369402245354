import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onEdit: 'Edit Qualification Award', onRead: 'Qualification Award Details', onCreate: 'Create Qualification Award' }
export const QualificationAwardForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  initialErrors,
  descriptions,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const memoData = useRef({})

  useEffect(() => {
    if (initialErrors.includes('qualification_id')) {
      setError('qualification_id', 'The qualification identifier has already been taken')
    }
    if (initialErrors.includes('qualification_awarded_identifier')) {
      setError('qualification_awarded_identifier', 'The qualification awarded identifier has already been taken')
    }
  }, [initialErrors])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      memoData.current = initialValue
    }
  }, [isSuccess])

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.qualification_id) memoData.current = initialValue
  }, [initialValue])

  const validateForm = () => {
    const { qualification_awarded_identifier, qualification_id } = data
    if (!qualification_awarded_identifier) {
      setError('qualification_awarded_identifier', 'Required')
      return false
    }
    if (!qualification_id) {
      setError('qualification_id', 'Required')
      return false
    }
    onClearError()
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onChangeMode()
      setData(memoData.current)
      onCancel(memoData.current)
      onClearError()
    } else {
      onCancel()
    }
  }

  const { qualification_awarded_identifier, qualification_id, qualification_result_id, thesis_title, id } = data

  const { qualification_results, qualifications } = options
  const { onCreate, onRead, onEdit } = titles

  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this qualification award' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={id ? `qualification_award_details_${id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Qualification awarded identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_awarded_identifier}
                onChange={onChangeValue('qualification_awarded_identifier')}
                placeholder='Qualification awarded identifier'
                isDisabled={!isEditable}
                maxLength={50}
                error={errors.qualification_awarded_identifier}
                description={descriptions?.qualification_awarded_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                isRequired
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Qualification identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_id}
                onChange={onChangeValue('qualification_id')}
                placeholder='Qualification identifier'
                isDisabled={!isEditable}
                maxLength={4}
                field={'select'}
                options={qualifications}
                error={errors.qualification_id}
                description={descriptions?.qualification_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                isRequired
              />
            </div>
          </div>{' '}
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Qualification result'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={qualification_result_id}
                onChange={onChangeValue('qualification_result_id')}
                placeholder='Qualification result'
                isDisabled={!isEditable}
                maxLength={4}
                field={'select'}
                options={qualification_results}
                optionsStyle={{ width: 260 }}
                description={descriptions?.qualification_result}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Thesis title'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={thesis_title}
                onChange={onChangeValue('thesis_title')}
                placeholder='Thesis title'
                isDisabled={!isEditable}
                maxLength={500}
                field={'textArea'}
                description={descriptions?.thesis_title}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

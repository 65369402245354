import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.organisation.diocese.isLoading

export const isSuccess = state => state.contactReducer.organisation.diocese.isSuccess

export const diocese = state => state.contactReducer.organisation.diocese.diocese

export const options = state => state.contactReducer.organisation.diocese.options

export const permissions = state => state.contactReducer.organisation.diocese.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

import * as types from './venue.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './venue.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admin.venue.get, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.admin.venue.create, data)
    history.push(`/home/admin/data/venues/${response.data.data.venue_id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The venue has been created successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(data))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.venue.edit, payload)
    yield put(actions.set(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The venue has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, history } = payload
  try {
    yield call(API.admin.venue.remove, id)
    history.push('/home/admin/data/venues')
    yield put(snackbarActions.setSuccessNotification({ text: 'The venue has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([takeEvery(types.GET_VENUE, get), takeEvery(types.CREAT_VENUE, create), takeEvery(types.EDIT_VENUE, edit), takeEvery(types.DELETE_VENUE, remove)])
}

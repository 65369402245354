export const TOGGLE_LOADING = 'studentCourseSession/sessionStatus/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/sessionStatus/TOGGLE_SUCCESS'
export const GET_SESSIONSTATUS = 'studentCourseSession/sessionStatus/GET_SESSIONSTATUS'
export const SET_SESSIONSTATUS = 'studentCourseSession/sessionStatus/SET_SESSIONSTATUS'
export const CREATE_SESSIONSTATUS = 'studentCourseSession/sessionStatus/CREATE_SESSIONSTATUS'
export const DELETE_SESSIONSTATUS = 'studentCourseSession/sessionStatus/DELETE_SESSIONSTATUS'
export const EDIT_SESSIONSTATUS = 'studentCourseSession/sessionStatus/EDIT_SESSIONSTATUS'
export const GET_OPTIONS = 'studentCourseSession/sessionStatus/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/sessionStatus/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/sessionStatus/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/sessionStatus/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/sessionStatus/SET_CAN_BE_CREATED'
import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './engagement.types'
import { actions } from './engagement.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { collaborationProvisionRootSaga } from './collaborativeProvision'
import { entryProfileRootSaga } from './entryProfile'
import { leaverRootSaga } from './leaver'
import { qualificationAwardRootSaga } from './qualificationAward'
import { accreditationAimRootSaga } from './accreditationAim'
import { studentInitiativesRootSaga } from './studentInitiatives'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.studies.engagement.get, data)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push('/home/studies/engagements')
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.studies.engagement.create, data)
    yield put(actions.setErrors({}))
    history.push(`/home/studies/engagements/${data.student_id}/${response.data.data.id}/study-records`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The engagement has been created successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    if (errors['student_id']) {
      history.push('/home/studies/engagements')
    } else {
      yield put(actions.set(data))
      yield put(actions.setErrors(errors))
    }
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.studies.engagement.edit, payload)
    yield put(actions.set(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The engagement has been saved successfully!' }))
    yield put(actions.setErrors({}))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    yield call(API.studies.engagement.remove, data)
    history.push('/home/studies/engagements')
    yield put(snackbarActions.setSuccessNotification({ text: 'The engagement has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: {
        employing_schools = [],
        entryrte = [],
        fee_eligility_types = [],
        fee_statuses = [],
        lead_schools = [],
        incoming_exchanges = [],
        qualified_teacher_status = [],
        nhs_employers = [],
        research_council_students = [],
        study_intentions = [],
        include_in_hesa = [],
      },
    } = yield call(API.studies.engagement.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      employing_schools: generateObject(employing_schools),
      entryrte: generateObject(entryrte),
      fee_eligility_types: generateObject(fee_eligility_types),
      fee_statuses: generateObject(fee_statuses),
      incoming_exchanges: generateObject(incoming_exchanges),
      lead_schools: generateObject(lead_schools),
      nhs_employers: generateObject(nhs_employers),
      qualified_teacher_status: generateObject(qualified_teacher_status),
      research_council_students: generateObject(research_council_students),
      study_intentions: generateObject(study_intentions),
      include_in_hesa: include_in_hesa.map(obj => ({ value: obj.id, label: `${obj.label}` })),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('engagements', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ENGAGEMENT, get),
    takeEvery(types.CREATE_ENGAGEMENT, create),
    takeEvery(types.EDIT_ENGAGEMENT, edit),
    takeEvery(types.DELETE_ENGAGEMENT, remove),
    takeEvery(types.GET_OTPIONS, getOptions),
    collaborationProvisionRootSaga(),
    entryProfileRootSaga(),
    leaverRootSaga(),
    qualificationAwardRootSaga(),
    accreditationAimRootSaga(),
    studentInitiativesRootSaga(),
  ])
}

import { createSelector } from 'reselect'

export const programmeRecord = state => state.studiesReducer.studentCourseSession.reducer.programmeRecord

export const isLoading = state => state.studiesReducer.studentCourseSession.reducer.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.reducer.isSuccess

export const errorsFields = state => state.studiesReducer.studentCourseSession.reducer.errorsFields

export const options = state => state.studiesReducer.studentCourseSession.reducer.options

export const permissions = state => state.studiesReducer.studentCourseSession.reducer.permissions

export const formattedOptions = createSelector(options, options => {
  return {
    ...options.static,
    ...options.dynamic,
    ...options.dynamicByStudent,
  }
})

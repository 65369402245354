import * as types from './referencePeriod.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  referencePeriod: {
    reference_period_id: null,
    year: null,
    rp_student_load: null,
  },
  options: {},
  errors: {},
  canBeCreatReferencePeriod: false,
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_REFERENCEPERIOD:
      return {
        ...state,
        referencePeriod: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        referencePeriod: initialState.referencePeriod,
        errors: {},
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.SET_CAN_BE_CREATED:
      return {
        ...state,
        canBeCreatReferencePeriod: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}

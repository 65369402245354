import { regExpressions } from '@Root/configs'

export const toNumber = value => {
	return !value ? 0 : Number(value)
}

export const numberRound = value => {
	return Math.round(Number(value))
}

export const fixedNumber = (value, count) => {
	return Number(value).toFixed(count)
}

export const checkPointNumber = number => {
	return number.includes('.') || number.includes(',')
}

export const checkOnlyNumber = number => {
	return regExpressions.only_number.test(number)
}

export const checkDecimalNumber = number => {
	return regExpressions.only_number_decimal.test(number)
}

export const checkWholeNumber = number => {
	return checkOnlyNumber(number) && !checkPointNumber(number)
}

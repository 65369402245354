import * as types from './attachment.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	attachments: [],
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_ATTACHMENTS:
			return {
				...state,
				attachments: action.payload,
			}
		case types.SET_CREATED_ATTACHMENT:
			return {
				...state,
				attachments: [...state.attachments, action.payload],
			}
		case types.SET_EDITED_ATTACHMENT:
			return {
				...state,
				attachments: state.attachments.map(attachment => (attachment.id === action.payload.id ? action.payload : attachment)),
			}
		case types.SET_DELETED_ATTACHMENT:
			return {
				...state,
				attachments: state.attachments.filter(attachment => attachment.id !== action.payload.id),
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

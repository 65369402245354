import React from 'react'
import styles from './style.module.scss'
import {
  CheckboxInput,
  DataListAsync,
  DatePicker,
  InputLabel,
  MultiCheckbox,
  MultiDataListAsync,
  MultiSelect,
  RadioInputGroup,
  Select,
  TextArea,
  TextInput,
} from '@Root/components'

export const Field = ({
  onChange,
  maxLength = 255,
  classNames,
  error = '',
  value = '',
  label = '',
  isRequired = false,
  description = '',
  onOpenDescriptionModal,
  style = {},
  placeholder = '',
  isDisabled,
  field = 'textField',
  options,
  optionsStyle,
  optionsSliceQuantity,
  restriction,
  listStyle,
  fetchLabelHandler,
  fetchOptionsHandler,
  autoHeight = false,
  pattern,
  errorIsInfinite,
  customComponents = null,
  callBack,
  search,
}) => {
  const renderField = () => {
    switch (field) {
      case 'textField':
        return (
          <TextInput
            classNames={classNames}
            value={value}
            changeHandler={onChange}
            isDisabled={isDisabled}
            maxLength={maxLength}
            error={error}
            style={style}
            placeholder={placeholder}
            restriction={restriction}
            pattern={pattern}
            errorIsInfinite={errorIsInfinite}
          />
        )
      case 'select':
        return (
          <Select
            inputClassNames={classNames}
            inputStyle={style}
            style={style}
            options={options}
            value={value}
            changeHandler={onChange}
            error={error}
            isDisabled={isDisabled}
            placeholder={placeholder}
            optionsStyle={optionsStyle}
            optionsSliceQuantity={optionsSliceQuantity}
            errorIsInfinite={errorIsInfinite}
            customComponents={customComponents}
            callBack={callBack}
            search={search}
          />
        )
      case 'textArea':
        return (
          <TextArea
            classNames={classNames}
            value={value}
            changeHandler={onChange}
            isDisabled={isDisabled}
            maxLength={maxLength}
            error={error}
            style={style}
            placeholder={placeholder}
            autoHeight={autoHeight}
            errorIsInfinite={errorIsInfinite}
          />
        )
      case 'datePicker':
        return (
          <DatePicker
            classNames={classNames}
            style={style}
            value={value}
            changeHandler={onChange}
            error={error}
            isDisabled={isDisabled}
            placeholder={placeholder}
            errorIsInfinite={errorIsInfinite}
          />
        )
      case 'dataListAsync':
        return (
          <DataListAsync
            inputClassNames={classNames}
            inputStyle={style}
            listStyle={listStyle}
            fetchLabelHandler={fetchLabelHandler}
            fetchOptionsHandler={fetchOptionsHandler}
            changeHandler={onChange}
            error={error}
            isDisabled={isDisabled}
            placeholder={placeholder}
            value={value}
            errorIsInfinite={errorIsInfinite}
          />
        )
      case 'multiDataListAsync':
        return (
          <MultiDataListAsync
            inputClassNames={classNames}
            inputStyle={style}
            listStyle={listStyle}
            fetchLabelHandler={fetchLabelHandler}
            fetchOptionsHandler={fetchOptionsHandler}
            changeHandler={onChange}
            error={error}
            isDisabled={isDisabled}
            placeholder={placeholder}
            values={value}
            errorIsInfinite={errorIsInfinite}
          />
        )
      case 'multiSelect':
        return (
          <MultiSelect
            inputClassNames={classNames}
            inputStyle={style}
            options={options}
            values={value || []}
            changeHandler={onChange}
            error={error}
            isDisabled={isDisabled}
            optionsStyle={optionsStyle}
            errorIsInfinite={errorIsInfinite}
          />
        )
      case 'multiCheckbox':
        return <MultiCheckbox style={style} options={options} changeHandler={onChange} isDisabled={isDisabled} values={value || []} />
      case 'checkbox':
        return <CheckboxInput style={style} isChecked={value} changeHandler={onChange} isDisabled={isDisabled} errorIsInfinite={errorIsInfinite} />
      case 'radioInput':
        return <RadioInputGroup values={options} currentValue={value} isInline={isDisabled} isDisabled={isDisabled} />
      default:
        return <div>Field not found</div>
    }
  }
  return (
    <div className={`${field === 'multiCheckbox' ? styles.inputSingleColumnWrapper : styles.inputDoubleColumnWrapper} ${styles.inputWrapper}`}>
      <InputLabel text={label} hasAsterisk={isRequired} description={description} onOpenDescription={onOpenDescriptionModal} hideHeight />
      {renderField()}
    </div>
  )
}

import * as types from './curriculumAccreditation.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	curriculumAccreditation: {
		identifier: null,
		valid_from: null,
		valid_to: null,
	},
	options: {},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.SET_CURRICULUM_ACCREDITATION:
			return {
				...state,
				curriculumAccreditation: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: payload,
			}
		case types.CLEAR_DATA:
			return {
				...state,
				curriculumAccreditation: initialState.curriculumAccreditation,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		default:
			return state
	}
}

import { useState, useEffect } from 'react'

const initialState = {}
export const useError = isInfinite => {
  const [errors, setError] = useState(initialState)

  const onClear = () => {
    setError(initialState)
  }

  const onToggleError = (field, message) => {
    onClear()
    setError({ [field]: message })
  }

  return { errors, setError: onToggleError, onClear }
}

import React, { useEffect } from 'react'
import { ActionsDropdown, EntryProfileForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { entryProfileActions, entryProfileSelectors } from '@Store/studies'
import { DetailInformation } from './DetailInformation'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditEntryProfile = ({ onOpenDescriptionModal }) => {
  const location = useLocation()
  const history = useHistory()
  const entryProfile = useSelector(entryProfileSelectors.entryProfile)
  const isSuccess = useSelector(entryProfileSelectors.isSuccess)
  const isLoading = useSelector(entryProfileSelectors.isLoading)
  const options = useSelector(entryProfileSelectors.options)
  const permissions = useSelector(entryProfileSelectors.permissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const pathname = location.pathname
  const { id: engagementId, contactId, entryProfileId } = useParams()
  const otherTabId = pathname.split('/')[9]
  const dispatch = useDispatch()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_entry_profile
  const { can_be_created_entry_qualification_award } = entryProfile

  const actions = () => {
    return [
      ...(can_be_created_entry_qualification_award && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Entry qualification award',
              path: `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/new`,
            },
          ]
        : []),
    ]
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This entry profile will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(entryProfileActions.delete({ data: { engagementId, contactId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onChooseAction = actionName => {
    actions()
      .find(action => action.name === actionName)
      .handler()
  }

  const onSave = data => {
    dispatch(entryProfileActions.edit({ data, engagementId }))
  }

  useEffect(() => {
    if (entryProfileId && entryProfileId !== 'new' && !otherTabId) {
      dispatch(entryProfileActions.get({ data: { entryProfileId, engagementId, contactId }, history }))
      return () => {
        dispatch(entryProfileActions.clearData())
      }
    }
  }, [entryProfileId, otherTabId])

  return (
    <>
      {!!actions().length && <ActionsDropdown actions={actions()} clickHandler={onChooseAction} style={{ position: 'absolute', right: 10, top: -60 }} />}
      <EntryProfileForm
        onSave={onSave}
        onDelete={onDelete}
        canEdit={permissions.form?.edit}
        canDelete={permissions.form?.delete}
        isLoading={isLoading}
        isSuccess={isSuccess}
        initialValue={entryProfile}
        options={options}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
      {permissions.tabs && (
        <DetailInformation contactId={contactId} engagementId={engagementId} entryProfileId={entryProfileId} permissions={permissions.tabs} />
      )}
    </>
  )
}

export const isLoading = state => state.studiesReducer.studentCourseSession.studyLocation.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.studyLocation.isSuccess

export const studyLocation = state => state.studiesReducer.studentCourseSession.studyLocation.studyLocation

export const errors = state => state.studiesReducer.studentCourseSession.studyLocation.errors

export const options = state => state.studiesReducer.studentCourseSession.studyLocation.options

export const permissions = state => state.studiesReducer.studentCourseSession.studyLocation.permissions

export const canBeCreatedStudyLocation = state => state.studiesReducer.studentCourseSession.studyLocation.canBeCreatedStudyLocation

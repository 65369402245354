import React, { useEffect } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import DocumentTitle from 'react-document-title'
import { DetailInformation } from '../DetailInformation'
import { Spinner, BackButton, NameTitles } from '@Root/components'
import { admissionActions, admissionSelectors } from '@Store/admission'
import { useHistory, useParams } from 'react-router'
import { programmeActions, programmeSelectors } from '@Root/store'

export const ApplicationPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { applicationId } = useParams()
  const application = useSelector(admissionSelectors.application)
  const isLoading = useSelector(admissionSelectors.isLoading)
  //const programmes = useSelector(programmeSelectors.programmesListOptions)

  useEffect(() => {
    dispatch(admissionActions.getApplication({ id: applicationId }))
    dispatch(programmeActions.getProgrammesOptions())
  }, [dispatch, applicationId])

  return (
    <DocumentTitle title='Application'>
      <div className={classes.AdmissionsApplication}>
        <div className={classes.pageWrapper}>
          <div className={classes.backButtonWrapper}>
            <BackButton text='All Applications' clickHandler={() => history.goBack()} />
            {!isLoading ? application && <NameTitles data={application?.student} /> : <></>}
          </div>

          <div className={classes.line} />
          {!isLoading ? <DetailInformation /> : <Spinner style={{ height: 'calc(100vh - 280px)' }} />}
        </div>
      </div>
    </DocumentTitle>
  )
}

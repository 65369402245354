import React from 'react'
import classes from './style.module.scss'
import { Table } from '@Root/components'

const styleConfig = {
	tableWrapper: {
		marginTop: 20,
	},
	datePeriodDropdownStyle: {
		top: -40,
	},
	tableDataWrapper: {
		position: 'relative',
		top: -45,
	},
	showHeightByRow: true,
}

export const TableBlock = ({
	name,
	columns,
	fetchColumnOptions,
	fetchSaveColumnOptions,
	fetchDataHandler,
	fetchExportedDataHandler,
	clickLinkHandlers,
	errorHandler,
	successHandler,
	tabName,
}) => {
	return (
		<div className={classes.tableWrapper}>
			<div className={classes.tableTitle}>{tabName}</div>
			<Table
				style={{ marginTop: 70 }}
				datePeriodDropdownStyle={{ top: -70 }}
				name={name}
				columns={columns}
				fetchColumnOptions={fetchColumnOptions}
				fetchSaveColumnOptions={fetchSaveColumnOptions}
				fetchDataHandler={fetchDataHandler}
				fetchExportedDataHander={fetchExportedDataHandler}
				clickLinkHandlers={clickLinkHandlers}
				errorHandler={errorHandler}
				successHandler={successHandler}
				styleConfig={styleConfig}
				skipInitialization
			/>
		</div>
	)
}

import React, { useEffect } from 'react'
import { EntryQualificationSubjectForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { entryQualificationSubjectActions, entryQualificationSubjectSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditEntryQualificationSubject = ({ onOpenDescriptionModal }) => {
  const entryQualificationSubject = useSelector(entryQualificationSubjectSelectors.entryQualificationSubject)
  const options = useSelector(entryQualificationSubjectSelectors.options)
  const isLoading = useSelector(entryQualificationSubjectSelectors.isLoading)
  const isSuccess = useSelector(entryQualificationSubjectSelectors.isSuccess)
  const permissions = useSelector(entryQualificationSubjectSelectors.permissions)
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, entryProfileId, entryQualificationId, entryQualificationSubjectId } = useParams()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).qualification_subject

  useEffect(() => {
    dispatch(entryQualificationSubjectActions.get({ data: { entryQualificationSubjectId, entryQualificationId, entryProfileId, engagementId }, history }))
    return () => {
      dispatch(entryQualificationSubjectActions.clearData())
    }
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This entry profile will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          entryQualificationSubjectActions.delete({
            data: { entryQualificationId, entryProfileId, contactId, engagementId, entryQualificationSubjectId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(entryQualificationSubjectActions.edit({ data, entryQualificationId, entryQualificationSubjectId }))
  }

  return (
    <EntryQualificationSubjectForm
      onSave={onSave}
      onDelete={onDelete}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={entryQualificationSubject}
      options={options}
      isLoading={isLoading}
      isSuccess={isSuccess}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

import React from 'react'
import classes from './TEIDLogo.module.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { TrinLogo } from '@Root/assets/icons'

const TEIDLogo = ({ style, link }) => (
  <div className={classes.TEIDLogo} style={style}>
    {link ? (
      <Link className={classes.Link} to={link}>
        <img src={TrinLogo} alt='Logo' height={36} />
      </Link>
    ) : (
      <img src={TrinLogo} alt='Logo' height={36} />
    )}
  </div>
)

TEIDLogo.propTypes = {
  style: PropTypes.object,
  link: PropTypes.string,
}

TEIDLogo.defaultProps = {
  style: {},
}

export default TEIDLogo

import React from 'react'
import { QualificationAwardAccreditationForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { qualificationAwardAccreditationActions, qualificationAwardAccreditationSelectors } from '@Store/studies'
import { Redirect } from 'react-router-dom'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateQualificationAwardAccreditation = ({ parentURL, onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, qualificationAwardId, contactId } = useParams()
  const isLoading = useSelector(qualificationAwardAccreditationSelectors.isLoading)
  const options = useSelector(qualificationAwardAccreditationSelectors.options)
  const qualificationAwardAccreditation = useSelector(qualificationAwardAccreditationSelectors.qualificationAwardAccreditation)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).qualification_award_accreditation

  const onCreate = data => {
    dispatch(qualificationAwardAccreditationActions.create({ data, history, engagementId, qualificationAwardId, contactId }))
  }

  const onCancel = () => {
    history.goBack()
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />
  return (
    <div>
      <QualificationAwardAccreditationForm
        onSave={onCreate}
        onCancel={onCancel}
        canEdit={true}
        isNew
        options={options}
        isLoading={isLoading}
        initialValue={qualificationAwardAccreditation}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Route, Switch } from 'react-router'
import { StudiesProgrammePage } from './StudiesProgrammePage'
import { CourseInitiativePage } from './courseInitiativePage'
import { CourseReferencePage } from './courseReferencePage'
import { CurriculumAccreditationPage } from './curriculumAccreditation'
import { CourseRolePage } from './courseRole'

export const StudiesProgramme = ({ styleConfig }) => {
  const PageInMemo = useMemo(() => () => <StudiesProgrammePage styleConfig={styleConfig} />, [])
  return (
    <div className={styles.wrapper}>
      <div className={styles.sub_page}>
        <Switch>
          <Route path={'/home/studies/programmes/:studyProgrammeId/course_initiatives/new'} component={CourseInitiativePage} exact />
          <Route path={'/home/studies/programmes/:studyProgrammeId/course_initiatives/:courseInitiativeId'} component={CourseInitiativePage} exact />
          <Route path={'/home/studies/programmes/:studyProgrammeId/course_reference/new'} component={CourseReferencePage} exact />
          <Route path={'/home/studies/programmes/:studyProgrammeId/course_reference/:courseReferenceId'} component={CourseReferencePage} exact />
          <Route path={'/home/studies/programmes/:studyProgrammeId/course_role/new'} component={CourseRolePage} exact />
          <Route path={'/home/studies/programmes/:studyProgrammeId/course_role/:courseRoleId'} component={CourseRolePage} exact />
          <Route path={'/home/studies/programmes/:studyProgrammeId/curriculum_accreditation/new'} component={CurriculumAccreditationPage} exact />
          <Route
            path={'/home/studies/programmes/:studyProgrammeId/curriculum_accreditation/:curriculumAccreditationId'}
            component={CurriculumAccreditationPage}
            exact
          />
          <Route component={PageInMemo} />
        </Switch>
      </div>
    </div>
  )
}

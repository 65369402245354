import * as types from './attachments.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  attachments: [],
}
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      }
    case types.SET_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.payload],
      }
    case types.SET_EDIT_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.map(attachment => (attachment.id === action.payload.id ? action.payload : attachment)),
      }
    case types.SET_DELETE_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter(attachment => attachment.id !== action.payload.id),
      }
    default:
      return state
  }
}

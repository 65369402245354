import React from 'react'
import { VenueActivityForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { venueActivitySelectors, venueActivityActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const CreateVenueActivity = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const contactId = pathname.split('/')[5]
  const studyProgrammeId = pathname.split('/')[6]
  const venueActivity = useSelector(venueActivitySelectors.venueActivity)
  const options = useSelector(venueActivitySelectors.options)
  const isLoading = useSelector(venueActivitySelectors.isLoading)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic
  const errors = useSelector(venueActivitySelectors.errors)

  const onCreate = data => {
    dispatch(venueActivityActions.create({ data, history, studentCourseSessionId: programmeRecord.student_course_session_id, studyProgrammeId, contactId }))
  }

  const onCancel = () => {
    handleBack()
    dispatch(venueActivityActions.clearData())
  }

  return (
    <VenueActivityForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={venueActivity}
      options={options}
      isLoading={isLoading}
      dynamicOptions={dynamicOptions}
      errorsFields={errors}
    />
  )
}

import React from 'react'
import styles from './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { CreateVenue } from './Create'
import { modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import { EditVenue } from './Edit'

const PARENT_URL = '/home/admin/data/venues'
export const VenuePage = () => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const pathname = location.pathname
	const isNew = pathname.includes('new')


	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	return (
		<SectionWrapper hasBackButton backButtonText='Venues' clickBackButtonHandler={() => history.push(PARENT_URL)}>
			<div className={styles.wrapper}>
				{isNew ? (
					<CreateVenue onOpenDescriptionModal={onOpenDescriptionModal} parentURL={PARENT_URL} />
				) : (
					<EditVenue onOpenDescriptionModal={onOpenDescriptionModal} />
				)}
			</div>
		</SectionWrapper>
	)
}

export const permissions = state => state.authReducer.user.permissions

export const isLoading = state => state.authReducer.isLoading

export const token = state => state.authReducer.token

export const isGoogle2fa_active = state => state.authReducer.isGoogle2fa_active

export const isForce_activated = state => state.authReducer.isForce_activated

export const qr_code = state => state.authReducer.qr_code

export const google2fa_secret = state => state.authReducer.google2fa_secret

export const recoveryToken = state => state.authReducer.recoveryToken

export const error = state => state.authReducer.error

export const user = state => state.authReducer.user

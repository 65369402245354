import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateStudyLocation } from './Create'
import { EditStudyLocation } from './Edit'
import { useDispatch, useSelector } from 'react-redux'
import { studyLocationActions } from '@Store/studies'
import { modalActions } from '@Root/store'
import { studentCourseSessionSelectors, studentCourseSessionActions } from '@Store/studies/studentCourseSession/index'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager/index'
import { hesaActions } from '@Store/contact/person'

export const StudyLocationPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const programmeRecord = useSelector(studentCourseSessionSelectors.programmeRecord)
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { studyProgrammeId, contactId } = useParams()
  const isDuplicate = pathname.includes('duplicate')
  const DEFAULT_URL = `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/study_location`

  useEffect(() => {
    dispatch(studentCourseSessionActions.get({ studyProgrammeId, isCopy: isDuplicate }))
    dispatch(studyLocationActions.getOptions())
    dispatch(fieldTooltipManagerActions.getHesaDescriptions())
    dispatch(hesaActions.getDynamicOptions({ contactId }))
    return () => {
      dispatch(studentCourseSessionActions.clearData({}))
      dispatch(studyLocationActions.clearData())
    }
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }
  const handleBack = () => {
    history.push(DEFAULT_URL)
  }
  return (
    <SectionWrapper hasBackButton backButtonText={'Study Location Details'} clickBackButtonHandler={handleBack} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateStudyLocation onOpenDescriptionModal={onOpenDescriptionModal} programmeRecord={programmeRecord} handleBack={handleBack} />
        ) : (
          <EditStudyLocation onOpenDescriptionModal={onOpenDescriptionModal} programmeRecord={programmeRecord} />
        )}
      </div>
    </SectionWrapper>
  )
}

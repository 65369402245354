export const isLoading = state => state.studiesReducer.engagement.studentInitiatives.isLoading

export const isSuccess = state => state.studiesReducer.engagement.studentInitiatives.isSuccess

export const studentInitiatives = state => state.studiesReducer.engagement.studentInitiatives.studentInitiatives

export const errors = state => state.studiesReducer.engagement.studentInitiatives.errors

export const options = state => state.studiesReducer.engagement.studentInitiatives.options

export const permissions = state => state.studiesReducer.engagement.studentInitiatives.permissions

import React, { useEffect } from 'react'
import { ActionsDropdown, Preloader } from '@Root/components'
import { contactActions, contactSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions, programmeSelectors } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { organisationContactActions, organisationSelectors } from '@Store/contact/organisation'
import { personContactActions, personSelectors, studentSelectors } from '@Store/contact/person'
import { useHistory, useParams } from 'react-router'
import { ContactInformation } from './ContactInformation'
import { permissionsActions, permissionsSelectors } from '@Store/permissions'
import DocumentTitle from 'react-document-title'

export const EditContact = ({ styleConfig, onError }) => {
  const { contactId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const programmesList = useSelector(programmeSelectors.programmesListOptions)
  const contactType = useSelector(contactSelectors.contactType)
  const person = useSelector(contactSelectors.person)
  const organisationRelationships = useSelector(organisationSelectors.relationships)
  const personRelationships = useSelector(personSelectors.relationships)
  const error = useSelector(contactSelectors.error)
  const isLoading = useSelector(contactSelectors.isLoading)
  const isSuccess = useSelector(contactSelectors.isSuccess)
  const studentInformation = useSelector(studentSelectors.information)
  const options = useSelector(contactSelectors.options)
  const organisation = useSelector(contactSelectors.organisation)
  const permissions = useSelector(contactSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  const actions = [
    ...(contactType
      ? [
          {
            name: 'Edit the Relationship',
            handler: () => {
              new Promise((resolve, reject) => {
                const props = {
                  person: {
                    title: 'Edit the Relationships',
                    inputType: 'multi-select',
                    options: personRelationships,
                    initialValue: person.contact.relationship_ids,
                    placeholder: 'Select the relationships',
                  },
                  organisation: {
                    title: 'Edit the Relationship',
                    inputType: 'select',
                    options: organisationRelationships,
                    initialValue: person.contact.relationship_id,
                    placeholder: 'Select the relationship',
                  },
                }
                dispatch(
                  modalActions.showModal('InputModal', {
                    title: props[contactType].title,
                    inputType: props[contactType].inputType,
                    options: props[contactType].options,
                    initialValue: props[contactType].initialValue,
                    valueIsRequired: true,
                    placeholder: props[contactType].placeholder,
                    resolveButtonText: 'Save',
                    clickRejectButtonHandler: reject,
                    clickResolveButtonHandler: resolve,
                  })
                )
              })
                .then(value => {
                  const dataSubmit = contactType === 'organisation' ? { ...organisation.contact } : { ...person.contact }

                  onSubmit({
                    ...dataSubmit,
                    publicly_fundable: Number(person?.contact?.publicly_fundable),
                    [contactType === 'person' ? 'relationship_ids' : 'relationship_id']: value,
                  })
                })
                .finally(() => dispatch(dispatch(modalActions.hideModal())))
            },
          },
        ]
      : []),
    ...(person?.contact?.id && programmesList && dataCreationPermissions.student_course_session
      ? [
          {
            name: 'Сreate an engagement',
            handler: () => {
              history.push(`/home/studies/engagements/${person?.contact?.id}/new`)
            },
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (contactId) {
      dispatch(contactActions.get({ contactId: Number(contactId) }))
      dispatch(permissionsActions.getMain({ entity: 'person', contactId: Number(contactId) }))
    }
  }, [contactId, dispatch])

  useEffect(() => {
    if (contactType) {
      dispatch(contactActions.setPerson({}))
      dispatch(contactActions.setOrganisation({}))
      dispatch(
        fieldTooltipManagerActions.getContact({
          entity: 'contacts',
          entity_type: contactType,
          entity_sub_type: 'form',
        })
      )
      if (contactType === 'person') {
        dispatch(personContactActions.getRelationShips({ isNew: false }))
      } else {
        dispatch(organisationContactActions.getRelationShips())
      }
    }
    return () => {
      if (contactType) {
        dispatch(contactActions.clearPermissions())
        if (contactType === 'person') {
          dispatch(contactActions.setPerson({}))
          dispatch(contactActions.setContactType(null))
        } else {
          dispatch(contactActions.setOrganisation({}))
          dispatch(contactActions.setContactType(null))
        }
      }
    }
  }, [contactType, dispatch])

  const onSubmit = payload => {
    dispatch(
      contactActions.edit({
        data: {
          contact_id: Number(contactId),
          ...payload,
        },
        contactType,
      })
    )
  }

  const showModal = (component, props) => {
    dispatch(modalActions.showModal(component, props))
  }

  const hideModal = () => {
    dispatch(modalActions.hideModal())
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title })
    }).finally(() => hideModal())
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      showModal('ConfirmationModal', {
        text: 'This contact will be removed?',
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve,
      })
    })
      .then(async () => {
        dispatch(contactActions.delete({ contactId, history }))
      })
      .finally(() => hideModal())
  }

  const onCancel = () => {
    dispatch(contactActions.setError({ errorName: '', errorMessage: '' }))
  }

  return person.contact.id || organisation.contact.id ? (
    <DocumentTitle
      title={`All contacts - Contacts - ${person.contact.name || organisation.contact.name} ${person.contact.surname || organisation.contact.surname}`}
    >
      <div>
        {!!actions.length && (
          <ActionsDropdown
            actions={actions}
            clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
            style={styleConfig.actionsDropdown}
          />
        )}
        <ContactInformation
          contactId={contactId}
          onOpenDescriptionModal={onOpenDescriptionModal}
          onDelete={onDelete}
          onError={onError}
          error={error}
          isLoading={isLoading}
          isSuccess={isSuccess}
          options={options}
          initialValuesPerson={person.contact}
          initialValuesOrganisation={organisation.contact}
          descriptions={descriptions}
          onSubmit={onSubmit}
          hideModal={hideModal}
          showModal={showModal}
          onCancel={onCancel}
          contactType={contactType}
          personRelationships={personRelationships}
          organisationRelationships={organisationRelationships}
          canCreateOrganisation={dataCreationPermissions.organisation}
          canCreatePerson={dataCreationPermissions.person}
          permissions={permissions}
        />
      </div>
    </DocumentTitle>
  ) : (
    <Preloader />
  )
}

import React, { useEffect } from 'react'
import { StudiesModuleDeliveryRoleForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useHistory, useLocation, useParams } from 'react-router'
import { roleActions, roleSelectors } from '@Store/studies'

export const EditCostCenter = ({ onOpenDescriptionModal, defaultURL }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const { moduleId, moduleRoleId } = useParams()
	const staticOptions = useSelector(roleSelectors.options).static
	const costCenter = useSelector(roleSelectors.role)
	const isLoading = useSelector(roleSelectors.isLoading)
	const isSuccess = useSelector(roleSelectors.isSuccess)
	const permissions = useSelector(roleSelectors.permissions)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['module-role']

	useEffect(() => {
		dispatch(roleActions.get({ id: moduleRoleId, history }))
		return () => {
			dispatch(roleActions.set({}))
		}
	}, [dispatch, moduleRoleId])

	const onEdit = payload => {
		dispatch(roleActions.edit({ module_delivery_role_id: moduleRoleId, ...payload }))
	}

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This module delivery role will be removed?',
					rejectButtonText: 'Cancel',
					resolveButtonText: 'Proceed',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(roleActions.remove({ id: moduleRoleId, history, moduleId }))
			})
			.finally(() => dispatch(modalActions.hideModal()))
	}

	const onCancel = () => {
		history.goBack()
	}

	return (
		<StudiesModuleDeliveryRoleForm
			options={staticOptions}
			initialValue={costCenter}
			onDelete={onDelete}
			onSave={onEdit}
			onCancel={onCancel}
			location={location}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			parentURL={defaultURL}
			isLoading={isLoading}
			isSuccess={isSuccess}
			canEdit={permissions.edit}
			canDelete={permissions.delete}
		/>
	)
}

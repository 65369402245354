import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './postgrad.types'
import { actions } from './postgrad.actions'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { API } from '@Root/API'

function* get({ payload }) {
  // const { studyProgrammeId, data } = payload
  yield put(actions.toggleLoading(true))
  try {
    // const response = yield call(API.studies.studentCourseSession.postgrad.get, studyProgrammeId, data)
    // yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* create({ payload }) {
  const { studyProgrammeId, contactId, data, history } = payload
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.studies.studentCourseSession.postgrad.create, studyProgrammeId, data)

    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
    yield put(actions.toggleSuccess(true))
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/postgrad_research`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The postgrad information has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
  const { data } = payload
  yield put(actions.toggleLoading(true))
  try {
    const response = yield call(API.studies.studentCourseSession.postgrad.edit, data)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The postgrad information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* remove({ payload }) {
  const { data, history, isStudent, contactId, studyProgrammeId } = payload
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.studies.studentCourseSession.postgrad.remove, data)
    history.push(
      isStudent ? `/home/contacts/all-contacts/${contactId}/student` : `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/off_venue_activity`
    )
    yield put(actions.set({}))
    yield put(actions.toggleSuccess(true))
    yield put(actions.clearPermissions())
    yield put(snackbarActions.setSuccessNotification({ text: 'The postgrad information has been deleted successfully!' }))
  } catch (error) {
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading(false))
  yield put(actions.toggleSuccess(false))
}

function* getStaticOptions() {
  try {
    const response = yield call(API.studies.studentCourseSession.postgrad.getStatic)
    const statusesData = response[0].data
    const tcbData = response[1].data
    const uobData = response[2].data
    const formattedData = {
      statuses: statusesData.map(({ id, label }) => ({ value: id, label })),
      tcb_responses: tcbData.map(({ id, label }) => ({ value: id, label })),
      uob_responses: uobData.map(({ id, label }) => ({ value: id, label })),
    }

    yield put(actions.setOptions({ name: 'static', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.CREATE_POSTGRAD, create),
    takeEvery(types.EDIT_POSTGRAD, edit),
    takeEvery(types.DELETE_POSTGRAD, remove),
    takeEvery(types.GET_POSTGRAD, get),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
  ])
}

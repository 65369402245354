import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './style.module.scss'

export const SectionTabsLinks = ({ tabs = [], defaultUrl, style, tabsToOtherSites = [] }) => {
  return (
    <div
      className={`${classes.SectionTabs} ${classes.linksSectionTabs}`}
      style={{ ...style, gridTemplateRows: `repeat(${tabs.length + tabsToOtherSites.length}, max-content)` }}
    >
      {tabs
        .filter(tab => !tab.hideInTableManager)
        .map((tab, i) => {
          return (
            <div key={tab.path}>
              <NavLink
                className={`${classes.tab} ${classes.navWrapper} 
                    ${tab.isShifted ? classes.shifted : ''}`}
                activeClassName={classes.active}
                to={`${defaultUrl}/${tab.path}`}
              >
                <div>{tab.name}</div>
              </NavLink>
              <div className={classes.subTabWrapper}>
                {tab.subLinks &&
                  tab.subLinks.map((subLink, j) => {
                    return (
                      <NavLink
                        to={`${defaultUrl}/${tab.path}/${subLink.path}`}
                        key={subLink.path}
                        activeClassName={classes.active}
                        className={`${classes.tab} ${subLink.isShifted ? classes.shifted : ''}`}
                      >
                        {subLink.name}
                      </NavLink>
                    )
                  })}
              </div>
            </div>
          )
        })}
      {tabsToOtherSites.map(({ link, link_name }, i) => {
        return (
          <a href={link} key={i} className={`${classes.tab} ${classes.navWrapper} ${classes.customLink}`} target='_blank'>
            {link_name}
          </a>
        )
      })}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { modalActions } from '@Root/store'
import { roleActions, roleSelectors } from '@Store/admin'
import { RoleForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { getFromLocalStorage, removeFromLocalStorage } from '@Root/helpers'
import { useToggle } from '@Root/hooks'

export const EditRole = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const location = useLocation()
	const [roleParams, setRoleParams] = useState({})
	const [isReadyForRender, toggleReadyForRender] = useToggle(false)
	const { pathname } = location
	const role = useSelector(roleSelectors.role)
	const isLoading = useSelector(roleSelectors.isLoading)
	const isSuccess = useSelector(roleSelectors.isSuccess)
	const permissions = useSelector(roleSelectors.permissions)
	const roleId = pathname.split('/').pop()

	useEffect(() => {
		const savedRoleData = getFromLocalStorage('saved_role_data')
		const roleParams = getFromLocalStorage('role_form_params')
		const hasPermissions = savedRoleData ? savedRoleData : role?.additional_functions ? Object.keys(role.additional_functions).length > 0 : false

		if (hasPermissions) {
			if (roleParams) {
				setRoleParams(roleParams)
				removeFromLocalStorage('role_form_params')
				if (savedRoleData) {
					if (savedRoleData?.id !== Number(roleId)) {
						removeFromLocalStorage('role_form_params')
						removeFromLocalStorage('saved_role_data')
						dispatch(roleActions.setInitialRole())
						dispatch(roleActions.get({ id: roleId }))
						return
					}
					dispatch(roleActions.set(savedRoleData))
					removeFromLocalStorage('saved_role_data')
				}
				toggleReadyForRender(true)
				return
			}
			if (role.additional_functions && !Object.keys(role.additional_functions).length > 0) {
				dispatch(roleActions.get({ id: roleId }))
				removeFromLocalStorage('saved_role_data')
				removeFromLocalStorage('role_form_params')
			}
			toggleReadyForRender(true)
			return
		}
		toggleReadyForRender(true)
		dispatch(roleActions.get({ id: roleId }))
		return () => {
			toggleReadyForRender(false)
		}
	}, [roleId, dispatch, role, toggleReadyForRender])

	const onSave = payload => {
		dispatch(roleActions.edit(payload))
	}

	const onDelete = () => {
		if (role.can_be_deleted) {
			new Promise((resolve, reject) => {
				dispatch(
					modalActions.showModal('ConfirmationModal', {
						text: 'This role will be removed?',
						clickRejectButtonHandler: reject,
						clickResolveButtonHandler: resolve,
					})
				)
			})
				.then(() => {
					dispatch(roleActions.remove({ id: roleId, history }))
				})
				.finally(() => dispatch(modalActions.hideModal()))
			return
		}
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: `This role can't be removed`,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	return isReadyForRender ? (
		<RoleForm
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			initialValue={role}
			onDelete={onDelete}
			onSave={onSave}
			isLoading={isLoading}
			isSuccess={isSuccess}
			isInitialEditMode={Boolean(roleParams?.isInitialEditMode)}
			initialMainCategoryId={roleParams?.mainCategoryId}
			initialCategoryId={roleParams?.categoryId}
			initialSubCategoryId={roleParams?.subCategoryId}
			initialMainAccordion={roleParams?.checkBy}
		/>
	) : (
		<></>
	)
}

import * as types from './attachments.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	createAttachment: payload => ({
		type: types.CREATE_ATTACHMENT,
		payload,
	}),
	editAttachment: payload => ({
		type: types.EDIT_ATTACHMENT,
		payload,
	}),
	deleteAttachment: payload => ({
		type: types.DELETE_ATTACHMENT,
		payload,
	}),
	setAttachments: payload => ({
		type: types.SET_ATTACHMENTS,
		payload,
	}),
	setAttachment: payload => ({
		type: types.SET_ATTACHMENT,
		payload,
	}),
	setEditAttachment: payload => ({
		type: types.SET_EDIT_ATTACHMENT,
		payload,
	}),
	setDeleteAttachment: payload => ({
		type: types.SET_DELETE_ATTACHMENT,
		payload,
	}),
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateQualificationAwardAccreditation } from './Create'
import { EditQualificationAwardAccreditation } from './Edit'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { useSelector } from 'react-redux'
import { qualificationAwardSelectors } from '@Store/studies'
import { useDispatch } from 'react-redux'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'

export const QualificationAwardAccreditationPage = ({ onOpenDescriptionModal }) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const qualificationAwardPermissions = useSelector(qualificationAwardSelectors.permissions)
  const isNew = pathname.includes('new')
  const { id: engagementId, contactId } = useParams()
  const qualificationAwardId = pathname.split('/')[7]
  const PARENT_URL = `/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${qualificationAwardId}/qualification-award-accreditation`

  useEffect(() => {
    getDataOptionsFromLocalStorage(['qualification_award_accreditation'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'qualification_award_accreditation' }))
  }, [])

  if (!qualificationAwardId) history.push(PARENT_URL)

  if (qualificationAwardPermissions.tabs && !qualificationAwardPermissions.tabs.qualification_award_accreditation) return <Redirect to={PARENT_URL} />

  return (
    <SectionWrapper hasBackButton backButtonText={'Qualification Award Details'} clickBackButtonHandler={() => history.push(PARENT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateQualificationAwardAccreditation parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditQualificationAwardAccreditation parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.reducer.isLoading

export const isSaving = state => state.contactReducer.reducer.isSaving

export const isSuccess = state => state.contactReducer.reducer.isSuccess

export const contactType = state => state.contactReducer.reducer.information.type

export const hasStudentCourseSession = state => state.contactReducer.reducer.information.has_course_session

const personData = state => state.contactReducer.reducer.person

export const mainInformation = createSelector(personData, person => {
	return {
		id: person.student_id,
		name: person.name,
		surname: person.surname,
	}
})

const organisationData = state => state.contactReducer.reducer.organisation

export const relationshipsInformation = state => state.contactReducer.reducer.information.relationships

const moodleLinks = state => state.contactReducer.reducer.moodleLinks

export const error = state => state.contactReducer.reducer.error

export const permissions = state => state.contactReducer.reducer.permissions

const noFormattedOptions = state => state.contactReducer.reducer.options

export const options = createSelector(noFormattedOptions, options => {
	return {
		...options.static,
		...options.dynamic,
	}
})
export const person = createSelector([moodleLinks, personData], (moodleLinks, contact) => {
	return {
		moodleLinks,
		contact,
	}
})

const postGradOptions = state => state.contactReducer.reducer.options.post_grad

export const personOptions = createSelector([postGradOptions], postGrad => ({
	postGrad,
}))

export const organisation = createSelector([organisationData], contact => {
	return {
		contact,
	}
})

import React, { PureComponent } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import TEIDLogo from "../../../icons/TEIDLogo/TEIDLogo";

export class PasswordRecoveryForm extends PureComponent {
  state = {
    email: ""
  };

  handleSubmit = async e => {
    if (!e.target.checkValidity()) return;
    e.preventDefault();
    await this.props.recoveryEmailHandler(this.state.email.trim(), this.props.history);
  };

  render() {
    const { email } = this.state;
    const { error } = this.props;
    const { handleSubmit } = this;

    return (
      <form className={classes.PasswordRecoveryForm} onSubmit={handleSubmit}>
        <TEIDLogo style={{ marginBottom: 20 }} />
        <div className={classes.title}>Password recovery</div>
        <div className={classes.description}>Please enter your email to get a recovery password</div>
        <div className={classes.wrapper}>
          <div className={classes.row}>
            <span className={classes.label}>Enter your email</span>
            <input
              className={error === "email" ? classes.invalid : ""}
              type="email"
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              required
            />
          </div>
          {error === "email" && <div className={classes.error}>Please enter a correct email*</div>}
        </div>
        <div className={classes.divider} />
        <button type="submit">Send</button>
      </form>
    );
  }
}

PasswordRecoveryForm.propTypes = {
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
  recoveryEmailHandler: PropTypes.func.isRequired
};

import * as entryQualificationAwardedSelectors from './entryQualificationAwarded.selectors'
import { reducer } from './entryQualificationAwarded.reducer'
import { entryQualificationSubjectReducer } from './entryQualificationSubject'
import { combineReducers } from 'redux'

export { actions as entryQualificationAwardedActions } from './entryQualificationAwarded.actions'
export { rootSaga as entryQualificationAwardedRootSaga } from './entryQualificationAwarded.saga'

export { entryQualificationAwardedSelectors }
export * from './entryQualificationSubject'

export const entryQualificationAwardedReducer = combineReducers({ reducer, entryQualificationSubject: entryQualificationSubjectReducer })

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import closeIcon from '../../../assets/icons/close.png'

export const NotificationModal = ({ text, clickRejectButtonHandler, clickResolveButtonHandler, title }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header} style={{ justifyContent: title ? 'space-between' : 'flex-end' }}>
        {title && <h3 className={classes.title}>{title}</h3>}
        <div className={classes.icon} onClick={clickRejectButtonHandler}>
          <img src={closeIcon} alt='' />
        </div>
      </div>
      <div className={classes.line} />
      <p className={classes.contentText} dangerouslySetInnerHTML={{ __html: text }}></p>
      <div className={classes.line} />
      <div className={classes.buttons}>
        <button className={classes.buttonRight} onClick={clickResolveButtonHandler}>
          Ok
        </button>
      </div>
    </div>
  )
}

NotificationModal.propTypes = {
  text: PropTypes.string,
  clickRejectButtonHandler: PropTypes.func,
  clickResolveButtonHandler: PropTypes.func,
}

NotificationModal.defaultProps = {
  text: null,
  title: '',
  clickRejectButtonHandler: () => {},
  clickResolveButtonHandler: () => {},
}

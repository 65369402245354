import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { EditStudentInitiativesPage } from './Edit'
import { CreateStudentInitiativesPage } from './Create'
import { useSelector, useDispatch } from 'react-redux'
import { engagementSelectors } from '@Store/studies'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'
import { modalActions } from '@Store/modal'

export const StudentInitiativesPage = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const engagement = useSelector(engagementSelectors.engagement)
  const engagementPermissions = useSelector(engagementSelectors.permissions)
  const canBeCreated = engagement.can_be_created_student_initiatives
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { id: engagementId, contactId } = useParams()
  const initiativesId = pathname.split('/')[7]

  const PARENT_URL = `/home/studies/engagements/${contactId}/${engagementId}/student-initiatives`

  useEffect(() => {
    getDataOptionsFromLocalStorage(['student_initiatives'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'engagement_student_initiatives' }))
  }, [])

  if (!initiativesId) history.push(PARENT_URL)

  if (!canBeCreated && initiativesId === 'new' && engagement.id) return <Redirect to={PARENT_URL} />

  if (engagementPermissions.tabs && !engagementPermissions.tabs.student_initiatives) return <Redirect to={PARENT_URL} />

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
          title,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }
  return (
    <SectionWrapper hasBackButton backButtonText={'Engagement Details'} clickBackButtonHandler={() => history.push(PARENT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateStudentInitiativesPage parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditStudentInitiativesPage parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

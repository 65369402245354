import React from 'react'
import styles from './style.module.scss'
import { CheckboxInput, InputLabel } from '@Root/components'

export const MultiCheckbox = ({ options, isDisabled, changeHandler, values = [] }) => {
	const onChange = id => value => {
		if (value) {
			const mappedOptions = [...values, id]
			changeHandler(mappedOptions)
			return
		}
		const mappedOptions = values.filter(value => value !== id)
		changeHandler(mappedOptions)
	}

	return (
		<div className={styles.fields}>
			{options.map(({ label, value: id }) => {
				const value = values?.find(value => value === id)
				return (
					<div key={id} className={styles.field}>
						<InputLabel text={label} hideHeight />
						<CheckboxInput isChecked={Boolean(value)} changeHandler={onChange(id)} isDisabled={isDisabled} />
					</div>
				)
			})}
		</div>
	)
}

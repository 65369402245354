import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRange } from 'react-date-range'
import moment from 'moment'
import { DATE } from '@Root/configs'

export const DateRangePopup = ({ startDate, endDate }) => {
	return (
		<div className='DateRangePopup'>
			<DateRange
				months={1}
				showMonthAndYearPickers={false}
				className={'calendar-range'}
				onChange={() => {}}
				ranges={[
					{
						startDate: moment(startDate, DATE.format).toDate(),
						endDate: moment(endDate, DATE.format).toDate(),
						color: '#f0f1f1',
						showDateDisplay: false,
						disabled: false,
						autoFocus: true,
					},
				]}
			/>
		</div>
	)
}

DateRangePopup.propTypes = {
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
}

import * as types from './snapshot.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	get: payload => ({
		type: types.GET_SNAPSHOT,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_SNAPSHOT,
		payload,
	}),
	set: payload => ({
		type: types.SET_SNAPSHOT,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_SNAPSHOT,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import * as types from './note.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	notes: [],
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_NOTES:
			return {
				...state,
				notes: action.payload,
			}
		case types.SET_CREATED_NOTE:
			return {
				...state,
				notes: [...state.notes, action.payload],
			}
		case types.SET_EDITED_NOTE:
			return {
				...state,
				notes: state.notes.map(note => (note.id === action.payload.id ? action.payload : note)),
			}
		case types.SET_DELETED_NOTE:
			return {
				...state,
				notes: state.notes.filter(note => note.id !== action.payload.id),
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { BackIcon } from "@Root/assets/icons";

export const BackButton = ({ text, clickHandler }) => {
  return (
    <button className={classes.BackButton} onClick={clickHandler}>
      <img src={BackIcon} alt="" />
      {text && <span className={classes.text}>{text}</span>}
    </button>
  );
};

BackButton.propTypes = {
  text: PropTypes.string,
  clickHandler: PropTypes.func
};

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useParams } from 'react-router'
import { FieldInformation, PageWrapper, Preloader, Title } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldConstructorActions, fieldConstructorSelectors } from '@Store/admin'
import { modalActions } from '@Root/store'
import { Redirect } from 'react-router'

const parentURL = '/home/admin/additional-fields'
export const EditFieldSubPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const field = useSelector(fieldConstructorSelectors.field)
  const isLoading = useSelector(fieldConstructorSelectors.isLoading)
  const errors = useSelector(fieldConstructorSelectors.errors)

  useEffect(() => {
    dispatch(fieldConstructorActions.getField({ id: params.id }))
  }, [dispatch, params])

  useEffect(() => {
    return () => {
      dispatch(fieldConstructorActions.setField(null))
      dispatch(fieldConstructorActions.setErrors({}))
    }
  }, [])

  const onCancel = () => {
    history.push(parentURL)
  }

  const onSubmit = data => {
    dispatch(fieldConstructorActions.editField({ ...data, history }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (field && !field?.edit) return <Redirect to={parentURL} />

  return !isLoading ? (
    <PageWrapper>
      <div className={styles.header}>
        <Title>Edit</Title>
      </div>
      <FieldInformation
        initialValue={field}
        onOpenDescriptionModal={onOpenDescriptionModal}
        descriptions={{}}
        onCancel={onCancel}
        onSave={onSubmit}
        initialErrors={errors}
      />
    </PageWrapper>
  ) : (
    <Preloader />
  )
}

import { CollaborativeProvisionForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { collaborationProvisionActions, collaborationProvisionSelectors } from '@Store/studies'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateCollaborativeProvision = ({ parentURL, onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId } = useParams()
  const accreditationAim = useSelector(collaborationProvisionSelectors.collaborativeProvision)
  const options = useSelector(collaborationProvisionSelectors.options)
  const isLoading = useSelector(collaborationProvisionSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_collaborative_provision

  const onCreate = data => {
    const payload = { ...data, student_id: Number(contactId) }
    dispatch(collaborationProvisionActions.create({ data: payload, history, engagementId, contactId }))
  }

  const onCancel = () => {
    history.goBack()
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />
  return (
    <CollaborativeProvisionForm
      onSave={onCreate}
      onCancel={onCancel}
      canEdit
      initialValue={accreditationAim}
      isNew
      options={options}
      isLoading={isLoading}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { getFromLocalStorage, setToLocalStorage } from '@Root/helpers'

export const ListenBrowserLocation = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    const initialPath = location.pathname
    const searchParams = location.search
    const path = `${initialPath}${searchParams}`
    setToLocalStorage('location', path)
    setToLocalStorage('prevLocation', path)
    //Leave this dependency array as empty. It must be triggered only during first render
  }, [])

  useEffect(() => {
    history.listen(location => {
      const locationLS = getFromLocalStorage('location')
      const prevLocationLS = getFromLocalStorage('prevLocation')
      const prevLoc = locationLS === location.pathname ? prevLocationLS : locationLS || location.pathname
      setToLocalStorage('location', location.pathname)
      setToLocalStorage('prevLocation', prevLoc)
    })
  }, [history])

  return <>{children}</>
}

import * as types from './permissions.types'

const initialState = {
	additionalPermissions: {},
	dataCreationPermissions: null,
	dataDeletingPermissions: null,
	rulePermissions: {},
	mainPermissions: {},
	tabsPermissions: null,
	isLoading: false,
}
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.SET_ADDITIONAL_PERMISSIONS:
			return {
				...state,
				additionalPermissions:{...state.additionalPermissions, ...action.payload,} 
			}
		case types.SET_DATA_CREATION_PERMISSIONS:
			return {
				...state,
				dataCreationPermissions: action.payload,
			}
		case types.SET_DATA_DELETING_PERMISSIONS:
			return {
				...state,
				dataDeletingPermissions: action.payload,
			}
		case types.SET_RULE_PERMISSIONS:
			return {
				...state,
				rulePermissions: action.payload,
			}
		case types.SET_MAIN_PERMISSIONS:
			return {
				...state,
				mainPermissions: { ...state, ...action.payload },
			}
		case types.SET_TABS_PERMISSIONS:
			return {
				...state,
				tabsPermissions: action.payload,
			}
		default:
			return state
	}
}

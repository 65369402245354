import React, { useCallback, useMemo, useRef, useState } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { SettingsButton, CheckboxInput, TextInput } from '@Root/components'
import { CustomScrollbar } from '@Root/HOCs'
import { popupHeight as getPopupHeight } from '@Root/helpers'
import { useOnClickOutside, useToggle, useWindowSize } from '@Root/hooks'

const OFF_SET_TOP_HEIGHT = 85
const INPUT_HEIGHT = 42
const BUTTON_ZONE_HEIGHT = 38
export const ColumnFilterDropdown = ({ columnsNames, hiddenColumnsNames, clickHandler, maxVisibleColumnsQuantity, onSaveColumnSettings, showMessageModal }) => {
	const [popupIsShown, togglePopupIsShown] = useToggle(false)
	const [filter, setFilter] = useState('')
	const componentRef = useRef(null)
	const { outerHeight, ...rest } = useWindowSize()

	const calculateComponentHeight = useCallback(height => {
		return Number(height.replace('px', '')) + OFF_SET_TOP_HEIGHT + INPUT_HEIGHT + BUTTON_ZONE_HEIGHT * 3
	}, [])

	const filteredColumnsNames = useMemo(
		() => (filter ? columnsNames.filter(columnName => columnName.toLowerCase().includes(filter.trim().toLowerCase())) : columnsNames),
		[filter, columnsNames]
	)

	const calculatePopupHeight = useCallback(
		count => {
			return getPopupHeight(filteredColumnsNames.length, count, 38)
		},
		[filteredColumnsNames.length]
	)

	const popupHeight = useMemo(() => calculatePopupHeight(maxVisibleColumnsQuantity), [calculatePopupHeight, maxVisibleColumnsQuantity])

	const checkPopupHeight = useMemo(() => {
		let newHeight = null
		const totalComponentHeight = calculateComponentHeight(popupHeight)
		const isTotalComponentHeight = totalComponentHeight > outerHeight
		const array = new Array(maxVisibleColumnsQuantity - 1).fill(null).map((_, index) => maxVisibleColumnsQuantity - (index + 1))

		if (isTotalComponentHeight) {
			array.some(element => {
				const height = calculatePopupHeight(element - 1)

				if (calculateComponentHeight(height) <= outerHeight) {
					newHeight = height
					return true
				}

				return false
			})
		} else {
			newHeight = calculatePopupHeight(maxVisibleColumnsQuantity - 2)
		}
		return newHeight
	}, [calculateComponentHeight, popupHeight, outerHeight, maxVisibleColumnsQuantity, calculatePopupHeight])

	const isLatestUnChecked = hiddenColumnsNames.length + 1 === columnsNames.length

	useOnClickOutside(componentRef, togglePopupIsShown, popupIsShown)
	return (
		<div className={classes.ColumnFilterDropdown}>
			<SettingsButton clickHandler={togglePopupIsShown} />
			{popupIsShown && (
				<div className={classes.popup} ref={componentRef}>
					<div className={classes.search}>
						<TextInput
							classNames={['transparent']}
							style={{ width: '100%' }}
							value={filter}
							changeHandler={filter => setFilter(filter)}
							placeholder='Search column'
						/>
					</div>
					<div style={{ height: checkPopupHeight, position: 'relative' }}>
						<CustomScrollbar verticalOnly>
							{filteredColumnsNames.map((columnName, i) => {
								const isChecked = !hiddenColumnsNames.includes(columnName)
								return (
									<div className={classes.column} key={i}>
										<CheckboxInput
											isChecked={isChecked}
											changeHandler={!isChecked ? () => clickHandler(columnName) : !isLatestUnChecked ? () => clickHandler(columnName) : showMessageModal}
											label={{ text: columnName, style: { color: '#4a4a4a' } }}
											style={{ width: '100%', height: '100%' }}
										/>
									</div>
								)
							})}
						</CustomScrollbar>
					</div>
					{filteredColumnsNames.length > 0 && (
						<div className={classes.action_wrapper}>
							<button className={classes.save_button} onClick={onSaveColumnSettings}>
								Save
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

ColumnFilterDropdown.propTypes = {
	columnsNames: PropTypes.arrayOf(PropTypes.string),
	hiddenColumnsNames: PropTypes.arrayOf(PropTypes.string),
	clickHandler: PropTypes.func,
	onSaveColumnSettings: PropTypes.func,
	maxVisibleColumnsQuantity: PropTypes.number,
}

import { useState, useEffect } from 'react'
import { delay } from 'redux-saga'

export const useNotificationAnimation = (changeHandler, options) => {
  const wait = 500
  const [bottom, setBottom] = useState(-60)
  const [opacity, setOpacity] = useState(1)
  useEffect(() => {
    if (options) {
      const delayMs = options.delay || 5000

      ;(async () => {
        await delay(1)
        setBottom(40)
        await delay(delayMs)
        setOpacity(0)
        await delay(wait)
        changeHandler(null)
      })()
      return
    }
    setBottom(-60)
    setOpacity(1)
  }, [options])

  return { bottom, opacity, wait }
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { emailIsValid, switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { TextInput, InputLabel } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useError, useToggle } from '@Root/hooks'
import { dioceseActions, dioceseSelectors, organisationSelectors } from '@Store/contact/organisation'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
const relationShip = 'Diocese'
const titles = { onRead: 'Diocese Details', onEdit: 'Edit Diocese Details' }
export const DioceseForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, toggleEditable] = useToggle(false)
  const [data, setData] = useState({})
  const { errors, setError } = useError()
  const relationships = useSelector(organisationSelectors.relationships)
  const diocese = useSelector(dioceseSelectors.diocese)
  const isLoading = useSelector(dioceseSelectors.isLoading)
  const isSuccess = useSelector(dioceseSelectors.isSuccess)
  const permissions = useSelector(dioceseSelectors.permissions)
  const isPermissionsLoaded = useSelector(dioceseSelectors.isPermissionsLoaded)
  const staticOptions = useSelector(dioceseSelectors.options).static
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).organisation.dioceses
  const contactPermissions = useSelector(contactSelectors.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    const hasOptions = Object.keys(staticOptions).length > 0
    if (!hasOptions) dispatch(dioceseActions.getStaticOptions())
  }, [dispatch, staticOptions])

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'organisation', entity_sub_type: 'dioceses' }))
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      dispatch(dioceseActions.get({ contactId }))
      dispatch(permissionsActions.getMain({ entity: 'diocese', contactId: Number(contactId) }))
    }
  }, [dispatch, contactId])

  useEffect(() => {
    if (diocese) setData(diocese)
  }, [diocese])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  const formIsValid = () => {
    if (data.email && !emailIsValid(data.email)) {
      setError('email', 'Invalid email')
      return false
    }
    return true
  }

  const onChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onCancel = () => {
    setData(diocese)
    toggleEditable()
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationShip}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationShip).value
        dispatch(dioceseActions.remove({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = async () => {
    if (!formIsValid()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    dispatch(dioceseActions.edit({ ...modifiedData, id: contactId }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!contactPermissions.tabs?.diocese) return <Redirect to={PARENT_URL} />
  const { form, fields } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', 'save'] : []} // ...(form?.delete ? ['delete'] : [])
      //buttonsNames={[{ button: 'delete', name: 'Delete this diocese' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={contactId ? `diocese_details_${contactId}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Diocese Name' description={descriptions.diocese_name} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.diocese_name}
            changeHandler={value => onChange('diocese_name', value)}
            isDisabled={!isEditable || !fields.diocese_name?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Address Data' description={descriptions.address} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.address}
            changeHandler={value => onChange('address', value)}
            isDisabled={!isEditable || !fields.address?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 490 }}
            text='Telephone Number'
            description={descriptions.phone}
            onOpenDescription={onOpenDescriptionModal}
          />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 640, width: 260 }}
            value={data.phone}
            changeHandler={value => onChange('phone', value)}
            isDisabled={!isEditable || !fields.phone?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Email' description={descriptions.email} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.email}
            changeHandler={value => onChange('email', value)}
            isDisabled={!isEditable || !fields.email?.edit}
            error={errors.email}
          />
          <InputLabel
            style={{ position: 'absolute', left: 490 }}
            text='DDO Details'
            description={descriptions.ddo}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 640 }}>
            <TextInput
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.ddo}
              changeHandler={value => onChange('ddo', value)}
              isDisabled={!isEditable || !fields.ddo?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Bishop Identifier' description={descriptions.bishop_identifier} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.bishop_identifier}
            changeHandler={value => onChange('bishop_identifier', value)}
            isDisabled={!isEditable || !fields.bishop_identifier?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Bishop Name' description={descriptions.bishop_name} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 150 }}>
            <TextInput
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.bishop_name}
              changeHandler={value => onChange('bishop_name', value)}
              isDisabled={!isEditable || !fields.bishop_name?.edit}
            />
          </div>
          <InputLabel
            style={{ position: 'absolute', left: 490 }}
            text='Bishop Title'
            description={descriptions.bishop_title}
            onOpenDescription={onOpenDescriptionModal}
          />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 640, width: 260 }}
            value={data.bishop_title}
            changeHandler={value => onChange('bishop_title', value)}
            isDisabled={!isEditable || !fields.bishop_title?.edit}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

DioceseForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    diocese_name: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    ddo: PropTypes.string,
    bishop_identifier: PropTypes.string,
    bishop_id: PropTypes.number,
    bishop_title: PropTypes.string,
    comment: PropTypes.string,
  }),
  titleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  fetchContactHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

DioceseForm.defaultProps = {
  permissions: [],
  initialData: {
    diocese_name: null,
    address: null,
    phone: null,
    email: null,
    ddo: null,
    bishop_identifier: null,
    bishop_id: null,
    bishop_title: null,
    comment: null,
  },
  titleOptions: [],
  fetchContactHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
}

import React, { useState, useEffect } from 'react'
import { NotesItem } from '../NotesItem'
import classes from '../style.module.scss'
import { TextArea } from '@Root/components'
import { SkeletonNote } from '@Root/components/Skeleton/SkeletonNote/index'
import { CustomScrollbar } from '@Root/HOCs'

export const Reply = ({
  replies,
  parentId,
  onSizeReply,
  onOpenHistoryOfChanges,
  openIdHistoryOfChanges,
  onEditNote,
  onReplyNote,
  editedNoteId,
  canManipulate,
  onDeleteNote,
  renderEditNote,
  editValue,
  loadingConf,
  onSubmit,
  errors,
  replyNoteName,
  currentNoteId,
  replyNote,
  cancelReplyNote,
  setReplyNote,
  lastPage,
  paginateReply,
}) => {
  const [pageReply, setPageReply] = useState(1)
  const [lastPageReply, setLastPageReply] = useState(lastPage)

  const handleLoadMoreReply = () => {
    setPageReply(pageReply + 1)
    new Promise((resolve, reject) => {
      paginateReply(pageReply, parentId, resolve, reject)
    })
      .then(data => {
        setLastPageReply(data.lastPage)
        return true
      })
      .catch(() => {
        return false
      })
  }

  const renderReplyNote = (value, replyNoteName) => {
    return (
      <div className={classes.textareaWrapper}>
        <p className={classes.titleReply}>{`You reply to ${replyNoteName}`}</p>
        <TextArea style={{ width: 900, height: 84 }} value={value} changeHandler={value => setReplyNote(value)} error={errors.note} />
        <div className={classes.buttonsWrapper}>
          <button className={classes.buttonSecondary} onClick={cancelReplyNote} disabled={loadingConf.isCreateReplyLoadin.status}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={() => onSubmit({ type: 'Reply' })} disabled={loadingConf.isCreateReplyLoadin.status}>
            Add
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      {replyNote !== null && parentId === currentNoteId && renderReplyNote(replyNote, replyNoteName)}
      {replies.length > 0 && (
        <div className={classes.replyWrapper} style={{ minHeight: onSizeReply(replies) }}>
          {replies.map((reply, i) =>
            (loadingConf.isDeleteLoading.status && loadingConf.isDeleteLoading.id === reply.id) ||
            (loadingConf.isEditLoading.status && loadingConf.isEditLoading.id === reply.id) ? (
              <SkeletonNote isReply />
            ) : (
              <NotesItem
                key={i}
                note={reply}
                loadingConf={loadingConf}
                onOpenHistoryOfChanges={onOpenHistoryOfChanges}
                openIdHistoryOfChanges={openIdHistoryOfChanges}
                onEditNote={onEditNote}
                onReplyNote={onReplyNote}
                editedNoteId={editedNoteId}
                canManipulate={canManipulate}
                onDeleteNote={onDeleteNote}
                renderEditNote={renderEditNote}
                editValue={editValue}
                isReply={true}
                parentId={parentId}
                pageReply={pageReply}
                callBack={setLastPageReply}
              />
            )
          )}
          {loadingConf.isCreateReplyLoadin.status && parentId === loadingConf.isCreateReplyLoadin.id && <SkeletonNote isReply />}
          {loadingConf.isPaginateloding.status && loadingConf.isPaginateloding.id === parentId && <SkeletonNote isReply />}
        </div>
      )}
      {replies.length >= 100 && pageReply !== lastPageReply && lastPageReply !== null && (
        <button type='button' onClick={handleLoadMoreReply} className={classes.loadMoreReplyBtn}>
          Load more Reply
        </button>
      )}
    </>
  )
}

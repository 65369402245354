import * as types from './sessionYear.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleSuccess: payload => ({
    type: types.TOGGLE_SUCCESS,
    payload,
  }),
  get: payload => ({
    type: types.GET_SESSIONYEAR ,
    payload,
  }),
  set: payload => ({
    type: types.SET_SESSIONYEAR ,
    payload,
  }),
  create: payload => ({
    type: types.CREAT_SESSIONYEAR ,
    payload,
  }),
  edit: payload => ({
    type: types.EDIT_SESSIONYEAR ,
    payload,
  }),
  remove: payload => ({
    type: types.DELETE_SESSIONYEAR ,
    payload,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
}

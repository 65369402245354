import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors } from '@Root/store'
import { UserForm } from '@Root/components'
import { useHistory } from 'react-router'
import { userActions, userSelectors } from '@Store/admin'

export const CreateUser = ({ onOpenDescriptionModal }) => {
	const history = useHistory()
	const isLoading = useSelector(userSelectors.isLoading)
	const user = useSelector(userSelectors.user)
	const options = useSelector(userSelectors.formattedOptions)
	const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions)['system-users']
	const errors = useSelector(userSelectors.errors)
	const dispatch = useDispatch()

	const onSubmit = payload => {
		dispatch(userActions.create({ data: payload, history }))
	}

	const onCancel = () => {
		history.length > 1 ? history.goBack() : history.push('/home/admin/manage-system-team/system-users')
	}

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'admin', entity_type: 'system-users' }))

		return () => {
			dispatch(userActions.setErrors({}))
		}
	}, [dispatch])

	return (
		<UserForm
			isNew
			options={options}
			onSave={onSubmit}
			descriptions={descriptions}
			isLoading={isLoading}
			onCancel={onCancel}
			canEdit={!user?.is_main}
			initialValue={user}
			onOpenDescriptionModal={onOpenDescriptionModal}
			initialErrors={errors}
		/>
	)
}

import { combineReducers } from 'redux'
import { userReducer } from './user'
import { qualificationReducer } from './qualification'
import { venueReducer } from './venue'
import { institutionReducer } from './institution'
import { roleReducer } from './role'
import { fieldConstructorReducer } from './fieldConstuctor'
import { moodleReducer } from './moodle'
import { snapshotReducer } from './snapshot'
import { studentTypesReducer } from './studentTypes'
import { tableManagerReducer } from './tableManager'
import { sessionYearReducer } from './sessionYear'

export * from './user'
export * from './qualification'
export * from './venue'
export * from './institution'
export * from './role'
export * from './fieldConstuctor'
export * from './moodle'
export * from './snapshot'
export * from './studentTypes'
export * from './tableManager'
export * from './sessionYear'
export { rootSaga as adminRootSaga } from './admin.saga'

export const adminReducer = combineReducers({
	user: userReducer,
	qualification: qualificationReducer,
	venue: venueReducer,
	institution: institutionReducer,
	role: roleReducer,
	fieldConstructor: fieldConstructorReducer,
	moodle: moodleReducer,
	sessionYear: sessionYearReducer,
	snapshot: snapshotReducer,
	studentTypes: studentTypesReducer,
	tableManager: tableManagerReducer,
})

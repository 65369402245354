import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { Error, FormWrapper } from '@Root/HOCs'
import { RoundPlusButton, SectionTitle, InputLabel, TextInput } from '@Root/components'
import { emailIsValid } from '@Root/helpers'
import { useError } from '@Root/hooks'

const validations = {
  email: value => emailIsValid(value),
}

export const AdminFormTemplate = ({
  title,
  initialData,
  saveHandler,
  editHandler,
  deleteHandler,
  keyWord,
  deleteByField,
  getValueByField,
  actions,
  configuration,
  validation,
}) => {
  const [data, setData] = useState([...initialData])
  const [newType, setNewType] = useState(null)
  const [isSpinning, setIsSpinning] = useState(false)
  const { errors, setError, onClear: onClearError } = useError()

  const errorMessage = (id, value) => {
    const validationCallback = validations[validation]

    if (!value) {
      return 'Field is empty'
    }
    if (initialData.find(item => item.id !== id && item[getValueByField] === value)) {
      return `This ${keyWord.toLowerCase()} already exists`
    }
    if (typeof validationCallback === 'function' && !validationCallback(value)) return 'Incorrect email'
  }

  const errorByTypeId = typeId => (errors && errors.typeId === typeId ? errors[typeId] : null)

  const handleChangeInput = (id, value) => {
    const dataCopy = [...data]
    dataCopy.find(type => type.id === id)[getValueByField] = value
    setData(dataCopy)
  }

  const handleClickAddButton = async () => {
    const message = errorMessage(null, newType)
    if (message) {
      setError('default', message)
      return
    }
    setIsSpinning(true)
    await saveHandler({ [getValueByField]: newType }, () => {
      if (AdminFormTemplate.isMounted) {
        setNewType(null)
      }
    })
    AdminFormTemplate.isMounted && setIsSpinning(false)
  }

  const handleClickEditButton = async typeId => {
    const dataCopy = [...data]
    dataCopy.find(type => type.id === typeId).isEdited = true
    setData(dataCopy)
  }

  const handleClickSaveButton = async (id, value) => {
    const message = errorMessage(id, value)

    if (message) {
      setError(id, message)
      return
    } else {
      onClearError()
    }
    setIsSpinning(true)
    await editHandler({ id, [getValueByField]: value })
    AdminFormTemplate.isMounted && setIsSpinning(false)
  }

  const handleClickDeleteButton = async item => {
    await deleteHandler(item, isSpinning => {
      AdminFormTemplate.isMounted && setIsSpinning(isSpinning)
    })
  }

  const onChange = value => {
    setNewType(value)
  }

  useEffect(() => {
    AdminFormTemplate.isMounted = true
    return () => {
      AdminFormTemplate.isMounted = false
    }
  }, [])

  useEffect(() => {
    AdminFormTemplate.isMounted && setData(JSON.parse(JSON.stringify(initialData)))
  }, [initialData])
  return (
    <FormWrapper isSpinning={isSpinning}>
      <div className={classes.header}>
        <SectionTitle title={title} />
        {newType === null && actions.can_create && (
          <RoundPlusButton
            style={{ marginLeft: 10 }}
            clickHandler={() => {
              setNewType('')
            }}
          />
        )}
      </div>
      <div className={classes.content}>
        {data.map((item, i) => {
          return (
            <div key={i} className={classes.item}>
              <div className={classes.block}>
                <InputLabel style={{ minWidth: 100 }} text={Array.isArray(keyWord) ? `${keyWord[i]}` : `${keyWord} ${i + 1}`} />
                {item.isEdited ? (
                  <>
                    <TextInput
                      classNames={['borderless']}
                      style={{ width: 380 }}
                      value={item[getValueByField]}
                      changeHandler={value => handleChangeInput(item.id, value)}
                      error={errorByTypeId(item.id)}
                      maxLength={configuration?.maxLength || 255}
                    />
                    <button className={`${classes.button} ${classes.primary}`} onClick={() => handleClickSaveButton(item.id, item[getValueByField])}>
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <div className={classes.text}>{item[getValueByField]}</div>
                    {item.edit && (
                      <button className={`${classes.button} ${classes.secondary} ${classes.edit}`} onClick={() => handleClickEditButton(item.id)}>
                        Edit
                      </button>
                    )}
                    {item.delete && (
                      <button
                        className={`${classes.button} ${classes.secondary} ${classes.delete}`}
                        onClick={() => handleClickDeleteButton(item[deleteByField])}
                      >
                        Delete
                      </button>
                    )}
                  </>
                )}
              </div>
              {errors[item.id] && (
                <span className={classes.error}>
                  <Error>{errors[item.id]}</Error>
                </span>
              )}
            </div>
          )
        })}
        {newType !== null && (
          <div className={classes.item}>
            <div className={classes.block}>
              <InputLabel style={{ minWidth: 100 }} text={`${keyWord} ${data.length + 1}`} />
              <TextInput classNames={['borderless']} style={{ width: 380 }} value={newType} changeHandler={onChange} error={errorByTypeId(null)} />
              <button className={`${classes.button} ${classes.primary}`} onClick={handleClickAddButton}>
                Add
              </button>
            </div>
            {errors.default && (
              <span className={classes.error}>
                <Error>{errors.default}</Error>
              </span>
            )}
          </div>
        )}
      </div>
    </FormWrapper>
  )
}

AdminFormTemplate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  initialData: PropTypes.arrayOf(PropTypes.object),
  saveHandler: PropTypes.func,
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
}

AdminFormTemplate.defaultProps = {
  permissions: [],
  title: '',
  deleteByField: 'id',
  initialData: [],
  saveHandler: () => {},
  editHandler: () => {},
  deleteHandler: () => {},
}

import * as types from './fee.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleNewFee: payload => ({
		type: types.TOGGLE_NEW_FEE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_FEE,
		payload,
	}),
	set: payload => ({
		type: types.SET_FEE,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_FEE,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_FEE,
		payload,
	}),
	getOptions: () => ({
		type: types.GET_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearPermissions: payload => ({
		type: types.CLEAR_PERMISSIONS,
		payload,
	}),
}

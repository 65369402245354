export const isLoading = state => state.studiesReducer.studentCourseSession.referencePeriod.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.referencePeriod.isSuccess

export const referencePeriod = state => state.studiesReducer.studentCourseSession.referencePeriod.referencePeriod

export const errors = state => state.studiesReducer.studentCourseSession.referencePeriod.errors

export const options = state => state.studiesReducer.studentCourseSession.referencePeriod.options

export const permissions = state => state.studiesReducer.studentCourseSession.referencePeriod.permissions

export const canBeCreatReferencePeriod = state => state.studiesReducer.studentCourseSession.referencePeriod.canBeCreatReferencePeriod

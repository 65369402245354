import * as types from './studentTypes.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './studentTypes.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'
import { errorMessage } from '@Root/helpers'

function* get() {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.studentTypes.get)
    yield put(actions.set(data.data))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.studentTypes.create, payload)
    yield put(actions.setCreated(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The student type has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { name } = payload
  try {
    yield call(API.admin.studentTypes.remove, name)
    yield put(actions.setRemoved({ name }))
    yield put(snackbarActions.setSuccessNotification({ text: 'The student type has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([takeEvery(types.GET_STUDENT_TYPES, get), takeEvery(types.CREATE_STUDENT_TYPE, create), takeEvery(types.DELETE_STUDENT_TYPE, remove)])
}

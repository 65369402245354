import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './fee.types'
import { actions } from './fee.actions'
import { snackbarActions } from '@Root/store'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import { API, createInstance } from '@Root/API'

function* create({ payload }) {
	const { url, data, history } = payload
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(createInstance().post, url, data)
		yield put(actions.set(response.data.data))
		yield put(actions.setPermissions(response.data.permissions))
		yield put(actions.toggleNewFee(false))
		yield put(actions.toggleSuccess(true))
		history.push(`/home/studies/programmes/study/${data.contact_id}/${data.program_course_id}/fees`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The fee information has been created successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
	const { url, data } = payload
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(createInstance().put, url, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The fee information has been saved successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

function* remove({ payload }) {
	const { url, history, data } = payload
	yield put(actions.toggleLoading(true))
	try {
		yield call(createInstance().delete, url)
		yield put(actions.set(null))
		yield put(actions.toggleSuccess(true))
		yield put(actions.clearPermissions())
		history.push(`/home/studies/programmes/study/${data.contactId}/${data.studyProgrammeId}/off_venue_activity`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The fee information has been deleted successfully!' }))
	} catch (error) {
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

function* getOptions() {
	try {
		const { data } = yield call(API.studies.studentCourseSession.fee.options.getStatic)
		const formattedData = data.map(obj => ({ value: obj.id, label: obj.label }))
		yield put(actions.setOptions(formattedData))
		saveDataOptionsToLocalStorage('fee', formattedData)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.CREATE_FEE, create),
		takeEvery(types.EDIT_FEE, edit),
		takeEvery(types.DELETE_FEE, remove),
		takeEvery(types.GET_OPTIONS, getOptions),
	])
}

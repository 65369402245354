import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.person.student.reducer.isLoading

export const isSuccess = state => state.contactReducer.person.student.reducer.isSuccess

export const student = state => state.contactReducer.person.student.reducer.student

export const options = state => state.contactReducer.person.student.reducer.options

export const information = state => state.contactReducer.person.student.reducer.information

export const permissions = state => state.contactReducer.person.student.reducer.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

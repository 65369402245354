import * as entryProfileSelectors from './entryProfile.selectors'
import { combineReducers } from 'redux'
import { reducer } from './entryProfile.reducer'
import { entryQualificationAwardedReducer } from './entryQualificationAwarded'

export { actions as entryProfileActions } from './entryProfile.actions'
export { rootSaga as entryProfileRootSaga } from './entryProfile.saga'
export { entryProfileSelectors }
export * from './entryQualificationAwarded'

export const entryProfileReducer = combineReducers({ reducer, entryQualificationAwarded: entryQualificationAwardedReducer })

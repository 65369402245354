import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateCurriculumAccreditation } from './Create'
import { EditCurriculumAccreditation } from './Edit'
import { useDispatch } from 'react-redux'
import { modalActions } from '@Root/store'
import { curriculumAccreditationActions } from '@Store/studies'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager/index'

export const CurriculumAccreditationPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { studyProgrammeId } = useParams()
  const DEFAULT_URL = `/home/studies/programmes/${studyProgrammeId}/curriculum_accreditation`

  useEffect(() => {
    dispatch(curriculumAccreditationActions.getOptions())
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'programmes_curriculum_accreditation' }))
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
          title,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }
  return (
    <SectionWrapper hasBackButton backButtonText={'Curriculum Accreditation Details'} clickBackButtonHandler={() => history.push(DEFAULT_URL)}>
      <div className={styles.sections}>
        {isNew ? (
          <CreateCurriculumAccreditation onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        ) : (
          <EditCurriculumAccreditation onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

import React, { useEffect } from 'react'
import { QualificationAwardAccreditationForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { qualificationAwardAccreditationActions, qualificationAwardAccreditationSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditQualificationAwardAccreditation = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, qualificationAwardId, qualificationAwardAccreditationId } = useParams()
  const isLoading = useSelector(qualificationAwardAccreditationSelectors.isLoading)
  const isSuccess = useSelector(qualificationAwardAccreditationSelectors.isSuccess)
  const options = useSelector(qualificationAwardAccreditationSelectors.options)
  const qualificationAwardAccreditation = useSelector(qualificationAwardAccreditationSelectors.qualificationAwardAccreditation)
  const permissions = useSelector(qualificationAwardAccreditationSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).qualification_award_accreditation

  useEffect(() => {
    dispatch(
      qualificationAwardAccreditationActions.get({
        data: {
          qualificationAwardAccreditationId,
          qualificationAwardId,
          engagementId,
          contactId,
        },
        history,
      })
    )
    return () => {
      dispatch(qualificationAwardAccreditationActions.clearData())
    }
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This qualification award accreditation will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          qualificationAwardAccreditationActions.delete({
            data: {
              qualificationAwardId,
              engagementId,
              qualificationAwardAccreditationId,
            },
            history,
            contactId,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(qualificationAwardAccreditationActions.edit({ data, qualificationAwardId }))
  }

  return (
    <QualificationAwardAccreditationForm
      onSave={onSave}
      onDelete={onDelete}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      initialValue={qualificationAwardAccreditation}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

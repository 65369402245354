import { combineReducers } from 'redux'
import * as moduleSelectors from './module.selectors'
import { reducer } from './modules.reducer'
import { subjectReducer } from './subject'
import { instanceReducer } from './instance'
import { costCenterReducer } from './costCenter'
import { roleReducer } from './role'

export { rootSaga as moduleRootSaga } from './modules.saga'
export { actions as moduleActions } from './modules.actions'
export { moduleSelectors }

export * from './subject'
export * from './instance'
export * from './costCenter'
export * from './role'

export const moduleReducer = combineReducers({ reducer, subject: subjectReducer, instance: instanceReducer, costCenter: costCenterReducer, role: roleReducer })

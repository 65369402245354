import React, { useEffect } from 'react'
import { CurriculumAccreditationForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { curriculumAccreditationSelectors, curriculumAccreditationActions } from '@Store/studies'

export const EditCurriculumAccreditation = ({ onOpenDescriptionModal }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { studyProgrammeId, curriculumAccreditationId } = useParams()
	const curriculumAccreditation = useSelector(curriculumAccreditationSelectors.curriculumAccreditation)
	const isSuccess = useSelector(curriculumAccreditationSelectors.isSuccess)
	const isLoading = useSelector(curriculumAccreditationSelectors.isLoading)
	const options = useSelector(curriculumAccreditationSelectors.options)
	const permissions = useSelector(curriculumAccreditationSelectors.permissions)

	useEffect(() => {
		dispatch(
			curriculumAccreditationActions.get({
				data: { studyProgrammeId, curriculumAccreditationId },
				history,
			})
		)
		return () => {
			dispatch(curriculumAccreditationActions.clearData())
		}
	}, [])

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This Curriculum Accreditation will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(
					curriculumAccreditationActions.delete({
						data: { studyProgrammeId, curriculumAccreditationId },
						history,
					})
				)
			})
			.finally(() => {
				dispatch(modalActions.hideModal())
			})
	}

	const onSave = data => {
		dispatch(curriculumAccreditationActions.edit({ data, curriculumAccreditationId, studyProgrammeId }))
	}

	return (
		<CurriculumAccreditationForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onSave}
			onDelete={onDelete}
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			initialValue={curriculumAccreditation}
			isLoading={isLoading}
			isSuccess={isSuccess}
			options={options}
		/>
	)
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'
import { useError } from '@Root/hooks'
import { validateData } from '@Root/helpers'

const titles = { onRead: 'Course Initiative', onEdit: 'Edit Course Initiative', onCreate: 'Create Course Initiative' }
export const CourseInitiativeForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).programmes_course_initiative

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
    }
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }
  const formIsValid = () => {
    const { initiative_id, valid_from, valid_to } = data
    if (!initiative_id) {
      setError('initiative_id', 'Required')
      return false
    }
    if (!valid_from) {
      setError('valid_from', 'Required')
      return false
    }
    if (validateData([valid_from, valid_to])) {
      return false
    }
    onClearError()
    return true
  }
  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!formIsValid()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      onClearError()
      return
    }
    onCancel()
  }

  const { onEdit, onRead, onCreate } = titles

  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Course Initiative' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `course_initiative_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Initiative identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.initiative_id}
                onChange={onChangeValue('initiative_id')}
                placeholder='Initiative identifier'
                isDisabled={!isEditable}
                options={options.initiative_identifier}
                description={descriptions?.initiative_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                error={errors.initiative_id}
                isRequired={isEditable}
              />
              <Field
                label={'Initiative valid from date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.valid_from}
                onChange={onChangeValue('valid_from')}
                placeholder='Initiative valid from date'
                isDisabled={!isEditable}
                description={descriptions?.valid_from}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
                isRequired={isEditable}
                error={errors.valid_from}
              />
              <Field
                label={'Initiative valid to date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.valid_to}
                onChange={onChangeValue('valid_to')}
                placeholder='Initiative valid to date'
                isDisabled={!isEditable}
                description={descriptions?.valid_to}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { qualificationActions, qualificationSelectors } from '@Store/admin'
import { QualificationForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { fieldTooltipManagerSelectors, modalActions } from '@Root/store'

export const EditQualification = ({ onOpenDescriptionModal }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { qualificationId } = useParams()
	const qualification = useSelector(qualificationSelectors.qualification)
	const options = useSelector(qualificationSelectors.options).static
	const isLoading = useSelector(qualificationSelectors.isLoading)
	const isSuccess = useSelector(qualificationSelectors.isSuccess)
	const permissions = useSelector(qualificationSelectors.permissions)
	const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).qualifications

	useEffect(() => {
		dispatch(qualificationActions.get({ id: qualificationId }))
		return () => {
			dispatch(qualificationActions.clearData())
		}
	}, [dispatch, qualificationId])

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This qualification will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(() => {
				dispatch(qualificationActions.remove({ id: qualificationId, history }))
			})
			.finally(() => dispatch(modalActions.hideModal()))
	}

	const onSubmit = data => {
		dispatch(qualificationActions.edit(data))
	}

	return (
		<QualificationForm
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			options={options}
			initialValue={qualification}
			onDelete={onDelete}
			onSave={onSubmit}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			isLoading={isLoading}
			isSuccess={isSuccess}
		/>
	)
}

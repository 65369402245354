import { StudyLocationForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { studyLocationSelectors, studyLocationActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const CreateStudyLocation = ({ onOpenDescriptionModal, programmeRecord, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const contactId = pathname.split('/')[5]
  const studyProgrammeId = pathname.split('/')[6]
  const studyLocation = useSelector(studyLocationSelectors.studyLocation)
  const options = useSelector(studyLocationSelectors.options)
  const errors = useSelector(studyLocationSelectors.errors)
  const isLoading = useSelector(studyLocationSelectors.isLoading)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic

  const onCreate = data => {
    dispatch(studyLocationActions.create({ data, history, studentCourseSessionId: programmeRecord.student_course_session_id, studyProgrammeId, contactId }))
  }

  const onCancel = () => {
    handleBack()
  }

  return (
    <StudyLocationForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      errorsFields={errors}
      initialValue={studyLocation}
      options={options}
      isLoading={isLoading}
      dynamicOptions={dynamicOptions}
    />
  )
}

import React, { useEffect, useState } from 'react'
import { AttachmentsForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { admissionAttachmentActions, admissionAttachmentSelectors } from '@Store/admission'

import { Redirect } from 'react-router'

const PARENT_URL = '/home/contacts/all-contacts'
export const ApplicationsAttachmentForm = ({ applicant_id }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(admissionAttachmentSelectors.isLoading)
  const isSuccess = useSelector(admissionAttachmentSelectors.isSuccess)
  const attachments = useSelector(admissionAttachmentSelectors.attachments)
  const permissions = useSelector(admissionAttachmentSelectors.permissions)
  const history = useSelector(admissionAttachmentSelectors.history)
  const totalHistory = useSelector(admissionAttachmentSelectors.totalHistory)
  const totalAttachments = useSelector(admissionAttachmentSelectors.totalAttachments)
  const [page, setPage] = useState(1)
  const [openIdHistoryOfChanges, setOpenIdHistoryOfChanges] = useState(null)
  const [pageHistory, setPageHistory] = useState(1)

  useEffect(() => {
    dispatch(admissionAttachmentActions.get({ id: applicant_id, page }))
    return () => {
      dispatch(admissionAttachmentActions.resetAttachments())
    }
  }, [dispatch, applicant_id])

  const onCreate = payload => {
    dispatch(admissionAttachmentActions.create({ ...payload, applicant_id }))
  }

  const onEdit = (id, payload) => {
    dispatch(admissionAttachmentActions.edit({ id, data: { ...payload, applicant_id } }))
  }

  const onDelete = id => {
    dispatch(admissionAttachmentActions.delete(id))
  }

  //if (!contactPermissions.tabs?.attachment) return <Redirect to={PARENT_URL} />

  const handelPaginationAttachmentAction = () => {
    setPage(page + 1)
    dispatch(admissionAttachmentActions.get({ id: applicant_id, page: page + 1 }))
  }

  const onOpenHistoryOfChanges = id => {
    dispatch(admissionAttachmentActions.getHistory({ id, pageHistory }))
    setOpenIdHistoryOfChanges(id)
  }

  const clearHistory = () => {
    dispatch(admissionAttachmentActions.clearHistory())
    setPageHistory(1)
  }
  const historyPaginate = () => {
    setPageHistory(pageHistory + 1)
    dispatch(admissionAttachmentActions.getHistory({ id: openIdHistoryOfChanges, page: pageHistory + 1 }))
  }
  return (
    <AttachmentsForm
      canManipulate
      canCreate={permissions?.create}
      initialValues={attachments}
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={onEdit}
      isLoading={isLoading}
      isSuccess={isSuccess}
      history={history}
      totalHistory={totalHistory}
      totalAttachments={totalAttachments}
      handelPaginationAttachmentAction={handelPaginationAttachmentAction}
      onOpenHistoryOfChanges={onOpenHistoryOfChanges}
      clearHistory={clearHistory}
      historyPaginate={historyPaginate}
    />
  )
}

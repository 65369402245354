export const isLoading = state => state.studiesReducer.module.subject.isLoading

export const isSuccess = state => state.studiesReducer.module.subject.isSuccess

export const subject = state => state.studiesReducer.module.subject.subject

export const options = state => state.studiesReducer.module.subject.options

export const permissions = state => state.studiesReducer.module.subject.permissions

export const errors = state => state.studiesReducer.module.subject.errors

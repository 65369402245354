import React, { useEffect } from 'react'
import { StudiesModuleCostCenterForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { costCenterActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useHistory, useLocation, useParams } from 'react-router'
import { costCenterSelectors } from '@Store/studies'

export const EditCostCenter = ({ onOpenDescriptionModal, defaultURL }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const { moduleId, moduleCostCentId } = useParams()
	const staticOptions = useSelector(costCenterSelectors.options).static
	const costCenter = useSelector(costCenterSelectors.costCenter)
	const isLoading = useSelector(costCenterSelectors.isLoading)
	const isSuccess = useSelector(costCenterSelectors.isSuccess)
	const permissions = useSelector(costCenterSelectors.permissions)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['module-cost-center']

	useEffect(() => {
		dispatch(costCenterActions.get({ id: moduleCostCentId, history }))
		return () => {
			dispatch(costCenterActions.clearForm())
		}
	}, [dispatch, moduleCostCentId])

	const onEdit = payload => {
		dispatch(costCenterActions.edit({ cost_centre_id: moduleCostCentId, ...payload }))
	}

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This module cost center will be removed?',
					rejectButtonText: 'Cancel',
					resolveButtonText: 'Proceed',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(costCenterActions.remove({ id: moduleCostCentId, history, moduleId }))
			})
			.finally(() => dispatch(modalActions.hideModal()))
	}

	const onCancel = () => {
		history.goBack()
	}

	return (
		<StudiesModuleCostCenterForm
			options={staticOptions}
			initialValue={costCenter}
			onDelete={onDelete}
			onSave={onEdit}
			onCancel={onCancel}
			location={location}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			parentURL={defaultURL}
			isLoading={isLoading}
			isSuccess={isSuccess}
			canEdit={permissions?.form?.edit}
			canDelete={permissions?.form?.delete}
		/>
	)
}
